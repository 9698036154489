/**
 * @file 头像组件
 * @author aron.yao 2022-08-11
 */

import { FC } from 'react'
import defaultAvatarImg from './assets/default-avatar.svg'
import './index.less'

export interface AvatarProps {
  // 头像图片的地址
  src?: string;
}

const AgPopover: FC<AvatarProps> = (props) => {
  const { src } = props

  const imgSrc = src || defaultAvatarImg

  return (
    <div className='ag-avatar'>
      <img className='ag-avatar-img' src={imgSrc} />
    </div>
  )
}

export default AgPopover