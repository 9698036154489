/**
 * @file 场景作业 入口页面
 * @author jamie.zhan 2022-09-06
 */
import { FC } from 'react'
import style from './index.module.less'

const TaskPage: FC = ({ children }) => {

  return (
    <div className={style['page-container']}>
      {children}
    </div>
  )
}

export default TaskPage