/**
 * @file graphql 相关的公共方法
 * @author lian.duan 2022-08-12
 */

import type { Polygon, Geometry, Feature, LineString } from 'geojson'
import isNumber from 'lodash/isNumber'

interface CentroidOptions {
  lat?: number;
  lng?: number;
}

/**
 * 转为 graphql 需要的分页信息
 *
 * @param {*} pageNumber 第几页
 * @param {*} pageSize 分页的数量
 */
export function page2FirstAfter(pageNumber: number, pageSize: number): {
  first: number;
  after: string;
} {
  if (pageNumber > 0 && pageSize > 0) {
    return {
      first: pageSize,
      after: ((pageNumber - 1) * pageSize).toString()
    }
  }
  throw new Error('pageNumber and pageSize illegal')
}

/**
 * 全量拉取分页拉取接口
 * @param paginationHandler 处理分页数据的方法
 * @param hasNextChecker 检查是否还有下一页方法
 * @param resFormatter 返回结果格式化为所需格式方法
 * @param callback 每次调用分页接口的回调方法
 * @param initPage 初始请求页码
 * @param pageSize 每次请求数据条数
 * @type T 经过resFormatter后数据条目的格式类型
 * 比如返回的为[{key, value}...]则T即为{key: string, value: string}
 * @type R 接口返回值的类型，一般由graph自动生成,即为xxxQuery等
 */
export function fetchAllDataByPagination<T, R>(
  paginationHandler: (param: { first: number; after: string }) => Promise<R>,
  hasNextChecker: (res: R) => boolean,
  resFormatter: (res: R) => Array<T>,
  callback?: (formattersRes: Array<T>, currentPage?: number) => void,
  initPage = 1,
  // 默认拉取100条数据
  pageSize = 100
) {
  let result: Array<T> = []
  let currentPage = initPage

  let recursivePromise: ReturnType<typeof paginationHandler>

  const paginationPromise = new Promise<Array<T>>((resolve, reject) => {

    function fetchData() {
      recursivePromise = paginationHandler(page2FirstAfter(currentPage, pageSize))
      recursivePromise.then((res) => {
        result = result.concat(resFormatter(res))
        if (callback) {
          callback(resFormatter(res), currentPage)
        }
        if (hasNextChecker(res)) {
          currentPage += 1
          fetchData()
        } else {
          // 拉取完成
          resolve(result)
        }
      }).catch((err) => {
        reject(err)
      })
    }
    fetchData()
  })

  return paginationPromise
}

/**
 * 将前端的geojson转为接口参数需要的“geojson”，后端需要的是一个字符串
 */
export function GeoJSON2Params(geojson: Feature<Geometry>) {
  return JSON.stringify(geojson.geometry)
}

/**
 * 转换后端给的geojson数据为边界点数据
 */
export function GeoJSONPolygon2Latlngs(geojson: Polygon): L.LatLng[] {
  return geojson?.coordinates?.[0]?.map((item) => L.latLng(item[1], item[0])) || []
}

/**
 * 转换后端给的geojson数据为航点数据
 */
export function GeoJSONLineString2Latlngs(geojson: LineString): L.LatLng[] {
  return geojson?.coordinates?.map((item) => L.latLng(item[1], item[0])) || []
}


/**
 * 判断后端返回的坐标点是否合法
 */
export function isValidCentroid(centroid: CentroidOptions | undefined) {
  return isNumber(centroid?.lat) && isNumber(centroid?.lng)
}
