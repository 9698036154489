/**
 * @file 存放一些公共的ts类型
 * @author xinqiang.wei 2022/8/2
 */
import { TYPE_ALL, REBUILD_SOURCE } from '@/constants/common'
import { ProcessingJobFragmentFragment } from '@/types/graphql-types'
import { PHOTO_TYPES } from '@/types/photo.d'

// 重建状态
export enum REBUILD_STATUS {
  PENDING = 1,
  SUCCESS = 2,
  FAILED = 3
}
export enum REBUILD_REASON_CODE {
  // 因内存溢出导致重建失败
  LACK_OF_RESOURCE_137 = '137',
  // 因航测照片拍摄倾斜角不足导致失败
  PARAMETER_ERROR_1 = '1',
  PARAMETER_ERROR_1002 = '1002',
  // 因航测照片重叠率不足导致失败
  UNDEROVERLAP_134 = '134',
  // 因服务器响应超时导致失败
  SERVER_EXCEPTION_139 = '139',
  SERVER_EXCEPTION_3010 = '3010',
}

// 搜索的类型
export interface ISearchForm {
  // 搜索的类型
  jobType: PHOTO_TYPES | TYPE_ALL;
  // 搜索的状态
  status: REBUILD_STATUS | TYPE_ALL;
  // 搜索的开始时间
  startTime: string;
  // 搜索的结束时间
  endTime: string;
  // 搜索的关键字
  name: string;
  // 搜索的组织信息
  departmentUuids: string;
  // 来源
  source: REBUILD_SOURCE;
}

// 重建表格的数据类型
export interface IRebuildTaskItem extends ProcessingJobFragmentFragment {
  uuid: string;
  // 任务状态
  rebuildStatus?: REBUILD_STATUS;
  // 重建名称
  rebuildStatusName?: string;
  // 失败原因
  rebuildReason?: string;
  tags?: {
    nodes: {
      uuid: string;
    }[];
  };
  // 来源
  source: REBUILD_SOURCE;
}


export interface IProcessingJobsStatus {
  uuid: string;
  status: REBUILD_STATUS;
  name: string;
  percentRemote: number;
  createdAt: string;
}


export interface ITreeDataResource {
  name: string;
  path?: string;
  http?: boolean;
  children: ITreeData[];
}


