import { photoTypeText } from '@/services/filters'
import { PHOTO_TYPES } from '@/types/photo.d'
import { t } from 'i18next'

/**
 * @file 照片库
 * @author lian.duan 2022-05-31
 */

// 照片信息 dialog 用途
export const USAGE = {
  CREATE: 0, // 创建
  EDIT: 1 // 编辑
}

// 文件扩展名类型
export const FILE_EXTENSION_TYPE = {
  JPG: 'JPG',
  TIF: 'TIF'
}

// oss上传状态
export const OSS_INFO_STATUS = {
  NOT_UPLOADED: 0, // 未上传oss
  UPLOADED: 1 // 已上传oss
}

// 上传的状态
export const FILE_UPLOAD_STATUS = {
  // 等待上传中
  PENDING: 'pending',
  // 上传成功
  FINISHED: 'finished',
  // 正在上传
  LOADING: 'loading',
  // 上传失败
  FAIL: 'fail',
  // 文件格式错误
  FILE_ERROR: 'fileError'
}

// 文件夹上传的状态
export const FOLDER_UPLOAD_STATUS = {
  // 正在上传
  LOADING: 'loading',
  // 上传成功
  FINISHED: 'finished'
}

// 错误类型
export const FILE_ERRORS = {
  FILE_TYPE_ERROR: 'file type error', // 文件类型错误
  FILE_EXIF_VALIDATION_FAIL: 'file exif validation fail' // 文件的exif校验失败
}

// 任务的类型
export const JOB_TYPES = {
  FARM: 0, // 农田模式
  CITY: 1, // 城市模式（即大田模式)
  FRUIT_TREE: 2, // 果树模式
  MULTI_SPECTRAL: 4 // 多光谱模式
}

// 最大自动上传次数
export const MAX_AUTO_UPLOAD_COUNT = {
  // 单个文件
  FILE: 2,
  // 文件夹
  FOLDER: 1
}

// 照片上传时最大并发数量
export const MAX_PHOTO_UPLOAD_CONCURRENCY = 3

// 照片上传异常的类型
// 文档：https://confluence.djicorp.com/pages/viewpage.action?pageId=322090396
export const PHOTO_UPLOAD_ERRORS = () => ({
  // 文件解析失败
  PARSE_FILE_ERROR: t('common.photo.photoUploadErrors.parseFileError'),
  // 文件类型错误
  FILE_TYPE_ERROR: t('common.photo.photoUploadErrors.fileTypeError'),
  // 网络错误
  NETWORK_ERROR: t('common.photo.photoUploadErrors.networkError'),
  // 缺失经纬度信息
  MISS_GEOLOCATION_INFO: t('common.photo.photoUploadErrors.missGeolocationInfo'),
  // 缺失测绘设备信息
  MISS_MAPPING_DEVICE_INFO: t('common.photo.photoUploadErrors.missMappingDeviceInfo'),
  // 照片数量超过上限
  QUANTITY_EXCEEDS_LIMIT: t('common.photo.photoUploadErrors.quantityExceedsLimit'),
  // 照片库已删除
  PHOTO_LIBRARY_DELETED: t('common.photo.photoUploadErrors.photoLibraryDeleted'),
  // 非法的照片倾斜角度
  INVALID_PHOTO_PITCH: t('common.photo.photoUploadErrors.invalidPhotoPitch')
})

// 照片库的类型 数组
export const PHOTO_TYPES_LIST = () => {
  return [
    {
      label: photoTypeText(PHOTO_TYPES.FARM),
      value: PHOTO_TYPES.FARM
    },
    {
      label: photoTypeText(PHOTO_TYPES.FRUIT_TREE),
      value: PHOTO_TYPES.FRUIT_TREE
    },
    {
      label: photoTypeText(PHOTO_TYPES.MULTI_SPECTRAL),
      value: PHOTO_TYPES.MULTI_SPECTRAL
    },
    {
      label: photoTypeText(PHOTO_TYPES.PATROL_FARM),
      value: PHOTO_TYPES.PATROL_FARM
    }
  ]
}

// 照片库数据无备注时的默认值
export const UNKNOWN_TXT = '-'

// 显示照片原图的大小（20M） 阿里oss只能处理20M以下的图片
export const SHOW_ORIGIN_PHOTO_SIZE_MAX = 20 * 1024 * 1024

// 支持重建的倾斜角度范围的照片
export const VALID_REBUILD_PHOTO_PITCH = {
  MIN: -125,
  MAX: -35
}

export default {
  USAGE,
  FILE_EXTENSION_TYPE,
  OSS_INFO_STATUS,
  FILE_UPLOAD_STATUS,
  FOLDER_UPLOAD_STATUS,
  FILE_ERRORS,
  JOB_TYPES,
  MAX_AUTO_UPLOAD_COUNT,
  MAX_PHOTO_UPLOAD_CONCURRENCY,
  PHOTO_UPLOAD_ERRORS
}
