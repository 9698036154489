/**
 * @file 农业分析模块的store
 * @author super.yang 2022-09-22
*/

import { createStore, IStoreDispatch, IStoreModels, IStoreRootState } from 'ice'
import cropEstimation from './models/cropEstimation'

interface IAnalysisStoreModels extends IStoreModels {
  cropEstimation: typeof cropEstimation;
}

const analysisStore: IAnalysisStoreModels = {
  cropEstimation
}

const store = createStore(analysisStore)

export default store

export type IAnalysisDispatch = IStoreDispatch<typeof analysisStore>;
export type IAnalysisState = IStoreRootState<typeof analysisStore>;