/**
 * @file Defer 类
 * @author Yangholmes 2022-06-17
 */

export class Defer<T> {
  private readonly _promise: Promise<T>;
  private _resolve?: (value: T) => void;
  private _reject?: (reason?: any) => void;

  static resolve<TR>(value: TR): Promise<TR> {
    return Promise.resolve(value)
  }

  static reject(reason: any): Promise<any> {
    return Promise.reject(reason)
  }

  constructor() {
    this._promise = new Promise((resolve, reject) => {
      this._resolve = resolve
      this._reject = reject
    })
  }
  resolve(value: T) {
    this._resolve?.(value)
  }
  reject(reason: any) {
    this._reject?.(reason)
  }
  get promise() {
    return this._promise
  }
  set promise(value) {
    console.error('can not set promise in Defer')
  }
}
