/**
 * @file 资源异常类型定义
 * @author aron.yao 2022-02-06
 */

import {
  TYPES,
  RESOURCES
} from './types'
import { BasicTypeError, getErrorName } from './typeError'

/*** 资源异常 ***/
export class BasicResourceError extends BasicTypeError {
  constructor(
    public readonly resource: RESOURCES,
    type: TYPES,
    originalError?: Error,
    message?: string
  ) {
    super(type, originalError, message)
    this.name = getErrorName(type, resource)
  }
}

/** 自定义的模块异常类型 **/

// 语义图tif资源错误
export class TifResourceError extends BasicResourceError {
  constructor(
    type: TYPES,
    originalError?: Error,
    message?: string
  ) {
    super(RESOURCES.SEGMENT_TIFF, type, originalError, message)
    this.name = getErrorName(type, RESOURCES.SEGMENT_TIFF)
  }
}

// 语义图tfw资源错误
export class TfwResourceError extends BasicResourceError {
  constructor(
    type: TYPES,
    originalError?: Error,
    message?: string
  ) {
    super(RESOURCES.SEGMENT_TFW, type, originalError, message)
    this.name = getErrorName(type, RESOURCES.SEGMENT_TFW)
  }
}

// 语义图colortable资源错误
export class ColorTableResourceError extends BasicResourceError {
  constructor(
    type: TYPES,
    originalError?: Error,
    message?: string
  ) {
    super(RESOURCES.SEGMENT_COLOR_TABLE, type, originalError, message)
    this.name = getErrorName(type, RESOURCES.SEGMENT_COLOR_TABLE)
  }
}

// 网络异常的错误
export class NetWorkResourceError extends BasicResourceError {
  constructor(
    type: TYPES,
    originalError?: Error,
    message?: string
  ) {
    super(RESOURCES.NET_WORK, type, originalError, message)
    this.name = getErrorName(type, RESOURCES.NET_WORK)
  }
}

// 资源下载的错误
export class DownloadResourceError extends BasicResourceError {
  constructor(
    type: TYPES,
    message?: string,
    originalError?: Error
  ) {
    super(RESOURCES.DOWNLOAD, type, originalError, message)
    this.name = getErrorName(type, RESOURCES.NET_WORK)
  }
}
/** 图片资源(照片库/重建图/测产分析)加载失败 */
export class OriginalPhotoError extends BasicResourceError {
  constructor(
    type: TYPES,
    originalError?: Error,
    message?: string
  ) {
    super(RESOURCES.MAPPING, type, originalError, message)
    this.name = getErrorName(type, RESOURCES.MAPPING)
  }
}

