/**
 * @file 遥控器重建相关gql
 * @author lian.duan 2023-10-18
 */
import graphqlRequest from '@/services/graphqlRequest'
import queryRcProcessingJobsGql from './gql/queryRcProcessingJobs.gql'
import updateRcProcessingJobGql from './gql/updateRcProcessingJob.gql'
import queryEditRcProcessingJobInfoGql from './gql/queryEditRcProcessingJobInfo.gql'
import deleteRcProcessingJobGql from './gql/deleteRcProcessingJob.gql'
import queryRcProcessingJobDetailGql from './gql/queryRcProcessingJobDetail.gql'
import queryRcCommonJobFileGql from './gql/queryRcCommonJobFile.gql'

import {
  QueryRcProcessingJobsQueryVariables,
  QueryRcProcessingJobsQuery,
  UpdateRcProcessingJobMutationVariables,
  UpdateRcProcessingJobMutation,
  QueryEditRcProcessingJobInfoQuery,
  QueryEditRcProcessingJobInfoQueryVariables,
  DeleteRcProcessingJobMutation,
  DeleteRcProcessingJobMutationVariables,
  QueryRcProcessingJobDetailQuery,
  QueryRcProcessingJobDetailQueryVariables,
  QueryRcCommonJobFileQuery,
  QueryRcCommonJobFileQueryVariables
} from '@/types/graphql-types.d'

// 获取重建任务列表
export function queryRcProcessingJobs(variables: QueryRcProcessingJobsQueryVariables) {
  return graphqlRequest<QueryRcProcessingJobsQuery, QueryRcProcessingJobsQueryVariables>(queryRcProcessingJobsGql, variables)
}

// 获取重建任务列表
export function updateRcProcessingJob(variables: UpdateRcProcessingJobMutationVariables) {
  return graphqlRequest<UpdateRcProcessingJobMutation, UpdateRcProcessingJobMutationVariables>(updateRcProcessingJobGql, variables)
}

// 获取重建任务
export function queryEditRcProcessingJobInfo(variables: QueryEditRcProcessingJobInfoQueryVariables) {
  return graphqlRequest<QueryEditRcProcessingJobInfoQuery, QueryEditRcProcessingJobInfoQueryVariables>(queryEditRcProcessingJobInfoGql, variables)
}

// 删除重建任务
export function deleteRcProcessingJob(variables: DeleteRcProcessingJobMutationVariables) {
  return graphqlRequest<DeleteRcProcessingJobMutation, DeleteRcProcessingJobMutationVariables>(deleteRcProcessingJobGql, variables)
}

// 详情
export function queryRcProcessingJobDetail(variables: QueryRcProcessingJobDetailQueryVariables) {
  return graphqlRequest<QueryRcProcessingJobDetailQuery, QueryRcProcessingJobDetailQueryVariables>(queryRcProcessingJobDetailGql, variables)
}

// 下载
export function queryRcCommonJobFile(variables: QueryRcCommonJobFileQueryVariables) {
  return graphqlRequest<QueryRcCommonJobFileQuery, QueryRcCommonJobFileQueryVariables>(queryRcCommonJobFileGql, variables)
}