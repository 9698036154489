import store from '/builds/dji-smartfarm/agro-cloud-fe/websites/smart-agro-pc/src/pages/Task/store';

import PageComponent from '/builds/dji-smartfarm/agro-cloud-fe/websites/smart-agro-pc/src/pages/Task/index';

const PageComponentName = PageComponent;

const PageProvider = store.Provider;

const StoreWrappedPage = (props) => {
  return (
    <PageProvider>
      <PageComponentName {...props} />
    </PageProvider>
  );
};

(StoreWrappedPage as any).pageConfig =
  (PageComponentName as any).pageConfig || ({} as any);
(StoreWrappedPage as any).getInitialProps = (
  PageComponentName as any
).getInitialProps;

export default StoreWrappedPage;
export { store };
