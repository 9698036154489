/**
 * @file 公共变量类型声明
 * @author super.yang 2022-09-02
*/
// 日期格式化的format格式
export enum DateFormat {
  // 日期格式化为年月日 即 (2022/09/01) 形式
  YearMonthDay = 'YYYY/MM/DD',
  // 日期格式化为年月日 即 (2022/9/1) 形式
  YearMonthDayShort = 'YYYY/M/D',
  // 日期格式化为年/月/日 小时:分钟:秒 即 (2022/09/01 11:11:11) 形式
  YearMonthDayAndSecond = 'YYYY/MM/DD HH:mm:ss',
  // 日期格式化为年月日 即 (2022年9月1日) 形式
  ChineseYearMonthDay = 'YYYY年MM月DD日',
  // 日期格式化为年.月.日 即 (2022.9.1) 形式
  PointYearMonthDay = 'YYYY.MM.DD',
  // 日期格式化为年/月/日 小时:分钟 即 (2022/09/01 11:11) 的形式
  YearMonthDayAndMin = 'YYYY/MM/DD HH:mm'
}

// 处理任务状态0：待开始，1：等待中，2：准备中，3：执行中，4：处理结果中，5：已停止，6：执行完成，7：执行失败
export enum TASK_STATUS_ITEM {
  TO_START = 0,
  WAITING = 1,
  PREPARING = 2,
  RUNNING = 3,
  PROCESSING_RESULTS = 4,
  STOP_IT = 5,
  FINISHED = 6,
  FAILED = 7,
}

export enum TaskStatus {
  // 成功
  SUCCESS,
  // 失败
  FAILURE,
  // 正在进行
  PROCESSING
}

export enum LoadingState {
  LOADING = 0,
  FAIL = 1,
  FETCHED = 2,
  EMPTY = 3
}