/**
 * @file 部门树形选择器
 * @author Yangholmes 2022-07-26
 */

import { FC, useEffect, useMemo, useState } from 'react'
import appStore from '@/store'
import { TreeSelect, TreeSelectProps } from 'antd'
import { Icon } from '../Icon'
import Loading from '../AgLoading'
import style from './index.module.less'
import { genDepartmentData } from './utils'
import { useTranslation } from 'react-i18next'

export { genDepartmentData } from './utils'

// DepartmentTreeSelect 候选项节点类型
export interface DepartmentTreeNode {
  id: string;
  uuid: string;
  name: string;
  children?: DepartmentTreeNode[];
  loading?: boolean;
}

// DepartmentTreeSelect props 类型
export interface DepartmentTreeSelectProps extends TreeSelectProps {
  insertDefault?: boolean;
  valuePropName?: string;
  mount2body?: Boolean;
  loadingSize?: 'small' | 'medium' | 'huge';
}

// 组件声明
export const DepartmentTreeSelect: FC<DepartmentTreeSelectProps> = ({
  insertDefault,
  children,
  valuePropName = 'uuid',
  mount2body = false,
  onDropdownVisibleChange,
  loadingSize = 'medium',
  ...props
}) => {
  const { t } = useTranslation()
  // 获取状态
  const [departmentTree] = appStore.useModel('departmentTree')

  const [dropdown, setDropdown] = useState<boolean>(false)
  const [departmentTreeData, setDepartmentTreeData] = useState<DepartmentTreeNode[]>()

  const isLoading = useMemo(() => {
    return !departmentTree.isLoaded
  }, [departmentTree])

  // 过滤初始状态中的部门树
  useEffect(() => {
    const { children = [] } = departmentTree.department
    const _departmentTreeData = genDepartmentData(children)
    // 插入一条数据，表示全选
    setDepartmentTreeData(insertDefault ? [
      {
        id: '',
        uuid: '',
        name: t('common.form.defaultAllLabel'),
        children: _departmentTreeData
      }
    ] : _departmentTreeData)
  }, [departmentTree, insertDefault])

  // 点击展开下拉框事件处理
  const _onDropdownVisibleChange = (val) => {
    setDropdown(val)
    onDropdownVisibleChange?.(val)
  }

  return (
    <div className={style['department-tree-select']}>
      <TreeSelect
        {...props}
        loading={isLoading}
        style={{ width: '100%' }}
        listHeight={400}
        dropdownClassName={style.dropdown}
        suffixIcon={isLoading && dropdown ? <Loading size={loadingSize} /> : <Icon className={dropdown ? 'dropdown' : ''} iconName='down' />}
        switcherIcon={<Icon iconName='down' />}
        onDropdownVisibleChange={_onDropdownVisibleChange}
        fieldNames={{
          value: valuePropName,
          label: 'name'
        }}
        treeData={departmentTreeData}
        virtual={false}
        getPopupContainer={(triggerNode) =>
          mount2body ? document.body : triggerNode.parentNode}
      >
        {children}
      </TreeSelect>
    </div>
  )
}
