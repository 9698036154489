/**
* @file 设备类型
* @author wei.shangguan 2022-10-14
*/

export interface DeviceType {
  readonly label: string;
  readonly value: string;
  readonly children?: DeviceType[];
}

export default {
  state: {
    deviceTypes: []
  },

  reducers: {
    updateDeviceType(prevState, payload: DeviceType[]) {
      return {
        ...prevState,
        deviceTypes: payload
      }
    }
  }
}
