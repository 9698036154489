/**
 * @file 应用入口
 * @author Yangholmes 2022-05-10
 */
import 'virtual:svg-icons-register'
import Defer from 'agras-defer'
import { message } from 'antd'
import { Locale } from 'antd/es/locale-provider/index.d'
import { enableMapSet } from 'immer'
import { IAppConfig, runApp, getHistory } from 'ice'

import { fetchUserProfile } from '@/services/apis/user'
import '@/monitoring'
// NOTICE: 这个要放到下面不然覆盖不了样式，勿动
import { getConfigRequest, ConfigInfo } from '@/services/apis/config'
import { UserProfileQuery } from '@/types/graphql-types.d'
import {
  changePersonalTitle,
  changeTitleAndFavicon,
  checkApplyStatus,
  promiseAllSettled
} from '@/utils/toolMethod'

import '@/styles/reactViewer.less'
import '@/styles/leafletMap.less'
// NOTICE: 这个要放到下面不然覆盖不了样式，勿动
import '@/styles/ant-theme/index.less'

import Permission from '@/services/permission'
import { getSystemEdition } from '@/helpers/system'
import { region } from '@/constants/routes'

import defineI18n, { currentLocaleConfig } from './i18n'
import { init, UnitCode } from 'agras-i22t'
import { getISOLocaleByLang, loadDayjsLocaleByLang } from './utils/i18n'
import { unitCodeMap } from './constants/unitCodeMap'
import dayjs from 'dayjs'

import { AppProvider } from './appProvider'
import { setCookieByIp } from '@/helpers/cookie'
import { correctPersonalRoutePrefix } from '@/helpers/route'

window.loadDefer = new Defer<void>()
window.addEventListener('load', (e) => {
  const { name } = (e?.currentTarget || {}) as Window

  if (name && name === 'v2AgroCloudIframe') {
    return
  }
  /**
   * 在其他地方也需要注册load的回调，但是可能load事件已经触发，所以用promise替换
   */
  window.loadDefer.resolve()

  // 个人版按需加载 dji feedback 模块，企业版不加载这个模块
  import.meta.env.VITE_IS_PERSONAL
    && String.prototype.toUpperCase.call(import.meta.env.VITE_IS_PERSONAL) === 'TRUE'
    && import('./monitoring/djiFeedback').then((res) => {
      const { feedback } = res
      feedback()
    })
})

// 使store支持map，set数据结构
enableMapSet()

// 全局配置 messgae 组件
message.config({
  duration: 3, // 持续时间
  top: 24// 到页面顶部距离
})

function defineAppConfig(locale: Locale) {
  const appConfig: IAppConfig = {
    app: {
      rootId: 'ice-container',
      strict: true,
      getInitialData: async () => {
        const history = getHistory()
        let userProfile: UserProfileQuery = {
          userProfile: undefined
        }
        let config: ConfigInfo | undefined = undefined

        try {
          const [configRes, userProfileRes] = await promiseAllSettled([getConfigRequest(), fetchUserProfile()])

          if (configRes?.status === 'fulfilled') {
            config = configRes.value.data.data
            changeTitleAndFavicon(config)
          }

          if (userProfileRes?.status === 'fulfilled') {
            userProfile = userProfileRes.value
            // 检查用户申请状态
            await checkApplyStatus(userProfile, history)
          }
        } catch (e) {
          console.warn('e', e)
        }

        Permission.init(
          // 政府版以 is_h 标识，主要是避免安全审计，特意使用了一个无关的名字
          getSystemEdition(config?.meta_info?.is_h || false),
          userProfile.userProfile?.member?.RoleMemberDepartment?.role || 0,
          userProfile.userProfile?.member?.organizationRoleId || 0
        )

        return {
          // initialStates 是约定好的字段，会透传给 store 的初始状态
          initialStates: {
            config,
            profile: {
              userProfile: userProfile?.userProfile || {}
            }
          }
        }
      },
      addProvider: ({ children }: { children: React.ReactNode }) => {
        return <AppProvider locale={locale}>{children}</AppProvider>
      }
    },
    router: {
      type: 'browser',
      basename: region.REGION_PREFIX,
      /**
       * @param routes
       */
      modifyRoutes(routes) {
        // 劫持路由
        return routes
      },
      // 当懒加载组件渲染不出来时显示的内容，默认会有个loading，传入一个空div标签覆盖
      fallback: <div />
    }
  }

  return appConfig
}

setCookieByIp().finally(() => {
  // 修正个人版的路由前缀
  correctPersonalRoutePrefix()
  const { lang: locale, region } = currentLocaleConfig
  // 提供给 packages-agras-leaflet -drawer 多语言使用
  window.agrasLang = locale
  // 将cookie中的lang字段转为antd需要的locale
  const antLang = getISOLocaleByLang(locale)
  // 加载语言包
  defineI18n(locale).then(() => {
    return Promise.all([
      import(`../node_modules/antd/es/locale/${antLang}.js`),
      /**
       * NOTICE:
       * 这里本来应该是按照antd的方式加载的，但是采用上述方式的加载无法正常设置语言
       */
      loadDayjsLocaleByLang(locale)
    ]).then(([antRes, dayjsRes]) => {
      dayjs.locale(dayjsRes.default)
      return antRes.default as Locale
    })
  }).then((antLocale) => {
    const unitCode = unitCodeMap[region] ?? UnitCode.Std
    init({
      // 初始化单位制
      unitCode: unitCode,
      // 后端接口数据的单位制(亩)
      baseUnitCode: UnitCode.Cn,
      // 单位的template
      template: 'agrasUnit.unit.{code}.{quantity}'
    })
    changePersonalTitle()
    runApp(defineAppConfig(antLocale))
  }).catch((err) => {
    // TODO: 找不到对应语言包时应该报错，需求还未出
    console.error(err)
  })
})
