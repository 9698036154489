/**
 * @file  顶部菜单配置
 * @author xinqiang.wei 2022/6/30
 */
import { t } from 'i18next'

// helpers
import { displayUserName } from '@/helpers/userInfo'
import { regionCountryMap } from '@/constants/region'
import { currentLocaleConfig } from '@/i18n'

// 底部按钮的类型
export enum BottomMenuType {
  Exit, // 回到旧版
  Setting, // 设置
  PersonInfo, // 个人信息
  Region, // 切换区域
  Collapsed // 收起的图标
}

export interface BottomListOptions {
  // 图标名
  icon: string;
  // 菜单项文案，收起按钮的菜单项没有文字描述
  title?: string | (() => string);
  type: BottomMenuType;
  showCollapsedBtn?: Boolean;
}

export const bottomList = () => {
  const { isInChina, region } = currentLocaleConfig
  const baseList: BottomListOptions[] = [
    {
      icon: 'setting',
      title: t('menu.setting.title') as string,
      type: BottomMenuType.Setting
    },
    {
      icon: 'user',
      title: () => {
        return displayUserName()
      },
      type: BottomMenuType.PersonInfo
    }
  ]

  // 在海外需要增加语言切换modal
  if (!isInChina) {
    baseList.push({
      icon: 'earth',
      title: regionCountryMap[region],
      type: BottomMenuType.Region
    })
  }

  // 最后一项 需要加上展开收起的按钮
  baseList[baseList.length - 1].showCollapsedBtn = true

  return baseList
}
