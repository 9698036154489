/**
 * @file
 * @author super.yang 2022-07-11
 */
import { SKELETON_NUMBER } from '@/constants/common'
import { IMediaDispatch, IMediaState } from '@/pages/Media/store'
import { fetchPhotoHomeList } from '@/services/apis/photo'
import { PHOTO_TYPES, PhotoTableRecordType } from '@/types/photo.d'
import { getInitTableData } from '@/utils/table'
import { getEndSecond, getStartSecond, page2FirstAfter } from '@/utils/toolMethod'
import { INIT_PAGESIZE, INIT_PAGE } from '@/constants/common'
import { IFormOptions, IFormParams, PHOTO_TYPES_ALL } from '@/pages/Media/models/media'

// 表格数据
export interface ITableData {
  table: PhotoTableRecordType[] | [];
  totalCount: number;
}

// 初始化表格数据
const iniTableData: ITableData = {
  table: [],
  totalCount: 0
}


// 组装查询参数
const getParams = (form: IFormOptions): IFormParams => {
  const paramsTemp: IFormParams = {}
  if (form.startTime) {
    paramsTemp.startTime = getStartSecond(form.startTime)
  }
  if (form.endTime) {
    paramsTemp.endTime = getEndSecond(form.endTime)
  }

  const formName = form.name?.trim()
  if (formName) {
    paramsTemp.name = formName
  }

  /**
   * 如果是查询所有类型，则不传type，
   */
  if (form.type !== PHOTO_TYPES_ALL) {
    if (form.type === PHOTO_TYPES.FARM) {
      paramsTemp.type = [PHOTO_TYPES.FARM, PHOTO_TYPES.CITY]
    } else {
      form.type && (paramsTemp.type = [form.type])
    }
  }

  if (form.departmentUuids) {
    paramsTemp.departmentUuids = form.departmentUuids
  }

  return paramsTemp
}

export default {
  state: iniTableData,
  reducers: {
    // 更新表格数据
    updateTableData(prevState: IMediaState, payload) {
      prevState.table = payload
    },
    // 更新表格数据
    updateTableDataByUuid(prevState: IMediaState, payload) {
      const index = prevState.table.findIndex((item) => item.uuid === payload.uuid)
      prevState.table[index] = {
        ...prevState.table[index],
        ...payload
      }
    },
    // 表格骨架屏数据
    resetTableData(prevState: IMediaState, payload) {
      prevState.table = getInitTableData(payload.pageSize)
    },
    // 更新表格数据数量
    updateTableDataCount(prevState: IMediaState, payload) {
      prevState.totalCount = payload.totalCount
    }
  },
  effects: (dispatch: IMediaDispatch) => ({
    async getTableData(payload: { pageNumber?: number; pageSize?: number; params?: any }, mediaState: IMediaState) {
      dispatch.photoTable.resetTableData({ pageSize: SKELETON_NUMBER })
      try {

        // 不需要默认值，如果没传就重置初始化值
        const { pageNumber = INIT_PAGE, pageSize = INIT_PAGESIZE, params = {} } = payload
        // 更新当前页数以及分页数量
        dispatch.media.updatePageNumber(pageNumber)
        dispatch.media.updatePageSize(pageSize)

        dispatch.media.setParams(params)

        const queryPageParams = page2FirstAfter(pageNumber, pageSize)

        const query = {
          filter: { ...getParams(params) },
          ...queryPageParams
        }

        const res = await fetchPhotoHomeList(query)
        const totalCount = res.mappings?.totalCount

        /**
         * 如果没有数据，也需要更新页面总数
         */
        if ((totalCount && totalCount !== mediaState.photoTable.totalCount) || totalCount === 0) {
          dispatch.photoTable.updateTableDataCount({ totalCount })
        }

        // 更新表格数据
        dispatch.photoTable.updateTableData(res.mappings?.nodes)

      } catch (error) {
        // 错误处理
        // 更新表格数据
        dispatch.photoTable.updateTableData([])
        dispatch.photoTable.updateTableDataCount({ totalCount: 0 })
      }
    }
  })
}
