export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  EmptyHash: any;
  /**
   * 传入一个字符串格式(非完整的geojson)，字符串格式如下:
   * `{"type": "Polygon","coordinates": [[[62.57812500000001,63.704722429433225],[62.9296875,60.75915950226991],[69.9609375,61.10078883158897],[62.57812500000001,63.704722429433225]]]}`
   */
  GeoJSON: any;
  /** int64 */
  Int64: any;
  /**
   * 传入一个字符串格式(非完整的geojson)，字符串格式如下:
   * `{
   *    "type": "LineString",
   *    "coordinates": [
   *        [30, 10], [10, 30], [40, 40]
   *    ]
   * }`
   */
  LineStringGeoJSON: any;
  MetaInfo: any;
  /**
   * 这个时间格式支持两种形式：如果传字符串，则传入符合RFC3339标准的时间字符串，如"2020-08-18T14:10:53.840941+08:00"
   * 如果传入int或者float，请传标准秒级时间戳，如1599865568
   */
  Time: any;
};

export type AccurateWeather = {
  /** 体感温度 摄氏度 */
  apparentTemperature?: Maybe<Scalars['Float']>;
  /** 云量 0-1 */
  cloudRate?: Maybe<Scalars['Float']>;
  /** 时间点 */
  datetime?: Maybe<Scalars['Time']>;
  /** 向下短波辐射通量 */
  dswrf?: Maybe<Scalars['Float']>;
  /** 湿度 0-1 */
  humidity?: Maybe<Scalars['Float']>;
  /** 降水量 mm/h */
  precipitation?: Maybe<Scalars['Float']>;
  /** 气压 Pa */
  pressure?: Maybe<Scalars['Float']>;
  /** 天气现象 */
  skyCondition?: Maybe<SkyCond>;
  /** 温度（摄氏度） */
  temperature?: Maybe<Scalars['Float']>;
  /** 能见度 km */
  visibility?: Maybe<Scalars['Float']>;
  /** 风向 （北起顺时针的度数） */
  windDirection?: Maybe<Scalars['Float']>;
  /** 风速 km/h */
  windSpeed?: Maybe<Scalars['Float']>;
};


export type AccurateWeatherApparentTemperatureArgs = {
  unit?: InputMaybe<TemperatureUnit>;
};


export type AccurateWeatherPressureArgs = {
  unit?: InputMaybe<PressureUnit>;
};


export type AccurateWeatherTemperatureArgs = {
  unit?: InputMaybe<TemperatureUnit>;
};


export type AccurateWeatherVisibilityArgs = {
  unit?: InputMaybe<LenghtUnit>;
};


export type AccurateWeatherWindSpeedArgs = {
  unit?: InputMaybe<SpeedUnit>;
};

export type AccurateWeathersFilter = {
  /** sql 片段方式（仅占位,暂不开启） */
  cond?: InputMaybe<Scalars['String']>;
  /** 数据统计间隔 */
  internal?: InputMaybe<Internal>;
  /** 气象观测点的uuid */
  positionOfInterestUuid?: InputMaybe<Scalars['String']>;
};

export type AddTagInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 加到那个资源上去 */
  taggableType: TaggableType;
  /** 相应的资源uuid */
  taggableUuid: Scalars['String'];
  /** 标签的uuid */
  uuid: Scalars['String'];
};

export type AddTagPayload = {
  __typename?: 'AddTagPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  tag?: Maybe<Tag>;
};

export type AddressComponent = {
  __typename?: 'AddressComponent';
  /** 请注意：当城市是省直辖县时返回为空，以及城市为北京、上海、天津、重庆四个直辖市时，该字段返回为空字符串 */
  city: Scalars['String'];
  country: Scalars['String'];
  district: Scalars['String'];
  province: Scalars['String'];
  seaArea: Scalars['String'];
  streetNumber: StreetNumber;
  township: Scalars['String'];
};

export type AiPlot = {
  __typename?: 'AiPlot';
  area?: Maybe<Scalars['Float']>;
  createdAt: Scalars['Time'];
  geo: Scalars['GeoJSON'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};


export type AiPlotAreaArgs = {
  unit: AreaUnit;
};

export type AiPlotEdge = {
  __typename?: 'AiPlotEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AiPlot>;
};

export type AiPlotJob = {
  __typename?: 'AiPlotJob';
  aiPlots?: Maybe<AiPlotsConnection>;
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  outputResourceUuid?: Maybe<Scalars['String']>;
  resourceUuid?: Maybe<Scalars['String']>;
  /** 处理任务状态0：待开始，1：等待中，2：准备中，3：执行中，4：处理结果中，5：已停止，6：执行完成，7：执行失败 */
  status: Scalars['Int'];
  updatedAt: Scalars['Time'];
  uuid: Scalars['String'];
};


export type AiPlotJobAiPlotsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type AiPlotJobEdge = {
  __typename?: 'AiPlotJobEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AiPlotJob>;
};

export type AiPlotJobsConnection = {
  __typename?: 'AiPlotJobsConnection';
  edges: Array<Maybe<AiPlotJobEdge>>;
  nodes: Array<Maybe<AiPlotJob>>;
  pageInfo?: Maybe<PageInfoNew>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type AiPlotsConnection = {
  __typename?: 'AiPlotsConnection';
  edges: Array<Maybe<AiPlotEdge>>;
  nodes: Array<Maybe<AiPlot>>;
  pageInfo?: Maybe<PageInfoNew>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type AiPlotsFilter = {
  aiPlotJobUuids?: InputMaybe<Array<Scalars['String']>>;
  bbox?: InputMaybe<BoundingBoxAttributes>;
  /** 该部门及其子部门下的所有任务的ai地块 */
  departmentUuids?: InputMaybe<Array<Scalars['String']>>;
  processingJobUuids?: InputMaybe<Array<Scalars['String']>>;
  uuids?: InputMaybe<Array<Scalars['String']>>;
};

export type AnalysisResult = {
  __typename?: 'AnalysisResult';
  averageDensity: Scalars['Float'];
  /** 五等分位 */
  fiveSeg: Array<Scalars['Float']>;
  gaussianDis: Array<Scalars['Float']>;
  sourceImages: Array<AnalysisSourceImage>;
  totalCount: Scalars['Int'];
};


export type AnalysisResultSourceImagesArgs = {
  sort?: InputMaybe<Sort>;
};

export type AnalysisSourceImage = SeedlingSourceImage | SourceImage;

/** 申请使用 */
export type ApplicationForm = {
  __typename?: 'ApplicationForm';
  /** 联系方式 */
  contact?: Maybe<Scalars['String']>;
  /** 创建时间 */
  createdAt?: Maybe<Scalars['Time']>;
  /** 系统中的id */
  id?: Maybe<Scalars['ID']>;
  /** 申请理由 */
  reason?: Maybe<Scalars['String']>;
  /** 0-待处理，1-通过，2-拒绝 */
  status?: Maybe<Scalars['Int']>;
  /** 更新时间 */
  updatedAt?: Maybe<Scalars['Time']>;
  /** 用户id */
  userId?: Maybe<Scalars['ID']>;
  /** 系统中的uuid */
  uuid?: Maybe<Scalars['String']>;
};

/** 申请的属性 */
export type ApplicationFormAttributes = {
  /** 联系方式 */
  contact?: InputMaybe<Scalars['String']>;
  /** 申请理由 */
  reason?: InputMaybe<Scalars['String']>;
};

/** ApplicationForm connection */
export type ApplicationFormConnection = {
  __typename?: 'ApplicationFormConnection';
  /** ApplicationForm数组片段 */
  edges: Array<Maybe<ApplicationFormEdge>>;
  /** 直接获取元素 */
  nodes: Array<Maybe<ApplicationForm>>;
  /** 分页/connection信息 */
  pageInfo: PageInfo;
  /** 总数量 */
  totalCount: Scalars['Int'];
};

/** ApplicationForm edge */
export type ApplicationFormEdge = {
  __typename?: 'ApplicationFormEdge';
  /** 分页/connection中的游标 */
  cursor?: Maybe<Scalars['String']>;
  /** 记录 */
  node?: Maybe<ApplicationForm>;
};

export type ApplicationFormsFilter = {
  cond?: InputMaybe<Scalars['String']>;
};

export type AreaInfo = {
  __typename?: 'AreaInfo';
  /** 作业亩数，0.01亩为单位 */
  area: Scalars['Int'];
  /** 日期，格式为年月，如20200501 */
  date: Scalars['Int'];
};

/** 面积单位 */
export const enum AreaUnit {
  /** 英亩 (1英亩=4,046.856平方米) */
  Acre = 'ACRE',
  /** 公亩 (1公亩=100平方米) */
  Are = 'ARE',
  /** 0.01 亩（6.666平方米） */
  CentiMu = 'CENTI_MU',
  /** 公顷 (1公顷=10,000平方米) */
  Hectare = 'HECTARE',
  /** 亩 （1亩=666.666平方米） */
  Mu = 'MU',
  /** 平方米 */
  SquareMeter = 'SQUARE_METER'
};

export type AttachTagsInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 加到那个资源上去 */
  taggableType: TaggableType;
  /** 相应的资源uuid */
  taggableUuid: Scalars['String'];
  /** 标签的uuid */
  uuids: Array<Scalars['String']>;
};

export type AttachTagsPayload = {
  __typename?: 'AttachTagsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
};

export type Authorization = {
  __typename?: 'Authorization';
  ak: Scalars['String'];
  createTime: Scalars['String'];
  sessionToken: Scalars['String'];
  sk: Scalars['String'];
};

export type AuxImg = {
  __typename?: 'AuxImg';
  blue: Scalars['String'];
  green: Scalars['String'];
  nir: Scalars['String'];
  red: Scalars['String'];
  redEdge: Scalars['String'];
};

/** 多光谱重建原图中的tif频段 */
export const enum BandName {
  Blue = 'Blue',
  Green = 'Green',
  Nir = 'NIR',
  Red = 'Red',
  RedEdge = 'RedEdge'
};

export type BatchAttribute = {
  name: Scalars['String'];
  /**
   * 对于外部厂商则是外部厂商的sn
   * 对于dji则是飞控或机身
   */
  sn: Scalars['String'];
};

export type BoundingBox = {
  __typename?: 'BoundingBox';
  downLeft: Position;
  upperRight: Position;
};

export type BoundingBoxAttributes = {
  downLeft: PositionAttributes;
  upperRight: PositionAttributes;
};

export type Bucket = {
  __typename?: 'Bucket';
  centroid: Position;
  count: Scalars['Int'];
  key: Scalars['String'];
  viewPort: ViewPort;
  /** 单位0.01亩 */
  workArea: Scalars['Float'];
};

/** 可以选择坐标系 */
export const enum Cs {
  Gcj02 = 'GCJ02',
  Wgs84 = 'WGS84'
};

export type CompressionJobForDjitile = {
  __typename?: 'CompressionJobForDjitile';
  createdAt: Scalars['Time'];
  /** 上传到农服后农服给的uuid */
  fertilizerUuid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 这个压缩任务是否是需要上传农服的 */
  isUpload: Scalars['Boolean'];
  outputResourceUuid?: Maybe<Scalars['String']>;
  processingJobUuid: Scalars['String'];
  resourceUuid?: Maybe<Scalars['String']>;
  /** 上传到农服桶中的路径 */
  resourcesDir?: Maybe<Scalars['String']>;
  /** 0：待开始，1：等待中，2：准备中，3：执行中，4：处理结果中，5：已停止，6：执行完成，7：执行失败 */
  status: Scalars['Int'];
  /** 上传给的农服用户uid */
  uid?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Time'];
  /** 下载链接 */
  url?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type Configuration = {
  __typename?: 'Configuration';
  MaxDepth?: Maybe<Scalars['Float']>;
  MaxHeight?: Maybe<Scalars['Float']>;
  MaxPto?: Maybe<Scalars['Int']>;
  /** 毫米/小时 */
  MaxRainfall?: Maybe<Scalars['Float']>;
  MaxSowUsagePerMu?: Maybe<Scalars['Float']>;
  MaxSpeed?: Maybe<Scalars['Float']>;
  MaxSprayUsagePerMu?: Maybe<Scalars['Float']>;
  MaxSprayWidth?: Maybe<Scalars['Float']>;
  /** 摄氏度 */
  MaxTemperature?: Maybe<Scalars['Float']>;
  MaxWidth?: Maybe<Scalars['Float']>;
  /** 米/秒 */
  MaxWindSpeed?: Maybe<Scalars['Float']>;
  MinDepth?: Maybe<Scalars['Float']>;
  MinHeight?: Maybe<Scalars['Float']>;
  MinPto?: Maybe<Scalars['Int']>;
  MinSowUsagePerMu?: Maybe<Scalars['Float']>;
  MinSpeed?: Maybe<Scalars['Float']>;
  MinSprayUsagePerMu?: Maybe<Scalars['Float']>;
  MinSprayWidth?: Maybe<Scalars['Float']>;
  /** 摄氏度 */
  MinTemperature?: Maybe<Scalars['Float']>;
  MinWidth?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  localTimes?: Maybe<Array<LocalTimeRange>>;
};

/** 参数的属性 */
export type ConfigurationAttributes = {
  MaxDepth?: InputMaybe<Scalars['Float']>;
  MaxPto?: InputMaybe<Scalars['Int']>;
  MaxWidth?: InputMaybe<Scalars['Float']>;
  MinDepth?: InputMaybe<Scalars['Float']>;
  MinPto?: InputMaybe<Scalars['Int']>;
  MinWidth?: InputMaybe<Scalars['Float']>;
  localTimes?: InputMaybe<Array<LocalTimeRangeInput>>;
  maxHeight?: InputMaybe<Scalars['Float']>;
  maxRainfall?: InputMaybe<Scalars['Float']>;
  maxSowUsagePerMu?: InputMaybe<Scalars['Float']>;
  maxSpeed?: InputMaybe<Scalars['Float']>;
  maxSprayUsagePerMu?: InputMaybe<Scalars['Float']>;
  maxSprayWidth?: InputMaybe<Scalars['Float']>;
  maxTemperature?: InputMaybe<Scalars['Float']>;
  maxWindSpeed?: InputMaybe<Scalars['Float']>;
  minHeight?: InputMaybe<Scalars['Float']>;
  minSowUsagePerMu?: InputMaybe<Scalars['Float']>;
  minSpeed?: InputMaybe<Scalars['Float']>;
  minSprayUsagePerMu?: InputMaybe<Scalars['Float']>;
  minSprayWidth?: InputMaybe<Scalars['Float']>;
  minTemperature?: InputMaybe<Scalars['Float']>;
};

export type ConfigurationConnection = {
  __typename?: 'ConfigurationConnection';
  edges: Array<Maybe<ConfigurationEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ConfigurationEdge = {
  __typename?: 'ConfigurationEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Configuration>;
};

export type CreateAndStartNdviJobInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  ndviJobAttributes: NdviJobAttributes;
};

export type CreateAndStartNdviJobPayload = {
  __typename?: 'CreateAndStartNdviJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  ndviJob?: Maybe<NdviJob>;
};

/** 创建申请的请求 */
export type CreateApplicationFormInput = {
  /** 申请 */
  ApplicationFormAttributes: ApplicationFormAttributes;
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** 创建申请的响应 */
export type CreateApplicationFormPayload = {
  __typename?: 'CreateApplicationFormPayload';
  /** 申请 */
  ApplicationForm: ApplicationForm;
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateAutoProcessingJobInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  notify?: InputMaybe<Scalars['Boolean']>;
  processingJobAttributes: ProcessingJobAttributes;
};

export type CreateAutoProcessingJobPayload = {
  __typename?: 'CreateAutoProcessingJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateEstimationJobInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 照片库的uuid */
  mappingUuid: Scalars['String'];
  /** 名字 */
  name: Scalars['String'];
  /** 作物类型 */
  plant?: InputMaybe<PlantType>;
  /** 关联高程图重建任务的uuid */
  processingJobUuid?: InputMaybe<Scalars['String']>;
};

export type CreateEstimationJobPayload = {
  __typename?: 'CreateEstimationJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  estimationJob?: Maybe<EstimationJob>;
};

export type CreateFlyWaypointListInput = {
  flyWaypointList: Array<PointFtInput>;
  modifiedFlyWaypointListUuid: Scalars['String'];
  workArea: Scalars['Float'];
};

export type CreateFlyWaypointListPayload = {
  __typename?: 'CreateFlyWaypointListPayload';
  uuid: Scalars['String'];
};

export type CreateFreePlotInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 自由航线规划属性 */
  freePlotAttributes: FreePlotAttributes;
};

export type CreateFreePlotPayload = {
  __typename?: 'CreateFreePlotPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  freePlot?: Maybe<FreePlot>;
};

export type CreateInaccurateAiPlotInput = {
  aiPlotUuid: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type CreateInaccurateAiPlotPayload = {
  __typename?: 'CreateInaccurateAiPlotPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  inaccurateAiPlot?: Maybe<InaccurateAiPlot>;
};

export type CreateMapInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 瓦片合并任务属性 */
  mapAttributes: MapAttributes;
};

export type CreateMapPayload = {
  __typename?: 'CreateMapPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  map?: Maybe<Map>;
};

export type CreateMappingInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  mappingAttributes: MappingAttributes;
};

export type CreateMergeJobInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 瓦片合并任务属性 */
  mergeJobAttributes: MergeJobAttributes;
};

export type CreateMergeJobPayload = {
  __typename?: 'CreateMergeJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  mergeJob?: Maybe<MergeJob>;
};

export type CreatePermissionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 授权属性 */
  permissionAttributes: PermissionAttributes;
};

export type CreatePermissionPayload = {
  __typename?: 'CreatePermissionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  permission?: Maybe<Permission>;
};

export type CreatePlotInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 地块属性 */
  plotAttributes: PlotAttributes;
};

export type CreatePlotPatrolInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  plotPatrolAttributes: PlotPatrolAttributes;
};

export type CreatePlotPatrolPayload = {
  __typename?: 'CreatePlotPatrolPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  plotPatrol: PlotPatrol;
};

export type CreatePlotPatrolsSourceImageInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  comment: Scalars['String'];
  plotPatrolUuid: Scalars['ID'];
  sourceImageUuid: Scalars['ID'];
  tagUuids?: InputMaybe<Array<Scalars['String']>>;
};

export type CreatePlotPatrolsSourceImagePayload = {
  __typename?: 'CreatePlotPatrolsSourceImagePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  plotPatrolsSourceImage: PlotPatrolsSourceImage;
};

export type CreatePlotPayload = {
  __typename?: 'CreatePlotPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 地块 */
  plot?: Maybe<Plot>;
};

/** 创建高清地图授权记录 */
export type CreateProcessingJobAuthInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 高清地图授权输入属性 */
  processingJobAuthAttributes: ProcessingJobAuthAttributes;
};

export type CreateProcessingJobAuthPayload = {
  __typename?: 'CreateProcessingJobAuthPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 高清地图授权 */
  processingJobAuth?: Maybe<ProcessingJobAuth>;
};

export type CreateProcessingJobInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  processingJobAttributes: ProcessingJobAttributes;
};

export type CreateProcessingJobPayload = {
  __typename?: 'CreateProcessingJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  processingJob?: Maybe<ProcessingJob>;
};

export type CreateProcessingJobSourceImageInput = {
  processingJobUuid: Scalars['String'];
  sourceImageUuid: Scalars['String'];
  tagUuids: Array<Scalars['String']>;
};

export type CreateProcessingJobSourceImagePayload = {
  __typename?: 'CreateProcessingJobSourceImagePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  processingJobSourceImage?: Maybe<ProcessingJobSourceImage>;
};

export type CreateRemoteControllerProcessingJob = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  externalId: Scalars['String'];
  files: Array<StsAttributes>;
  /** 遥控器本地的uuid */
  jobType?: InputMaybe<JobType>;
  name: Scalars['String'];
  /**
   * 这个任务的参数，是个json字符串，json的形式如下
   *
   *    "parameter":{
   *
   *        "map_mode":5,                      //可选，拼图模式 0代表农田模式 1代表城市模式 2代表果树模式 4代表多光谱模式
   *
   *        "quality_level": 1,                //可选，重建等级 1:高分辨率 2:中分辨率 3:低分辨率               默认为1
   *
   *        "use_gcj02":true                   //可选，是否使用国测局坐标系                                    默认true
   *
   *        "aux_band_list":["Blue","Green","Red","RedEdge","NIR"],            //可选，多光谱模式中算法需要处理的波段名称列表
   *
   *        "index_list":["NDVI","GNDVI","NDRE","LCI","OSAVI","MCARI"]    //可选，多光谱模式中算法需要生成的指数图列表
   *
   *        // 以下为两种输出地理坐标系的选择，二选一：
   *
   *        // 模式1,WKT字符串，往往从PRJ文件来
   *
   *        "output_CS_from_WKT": "",          //可选，用户定义的输出坐标系                                    默认为空字符串
   *
   *        // 模式2，给坐标系字符串和可选的高程重载
   *
   *        // 地理坐标系：
   *
   *        "output_geo_CS": "",               //可选，地理坐标系，用户选择，格式样例:"EPSG:4326"              默认为空字符串
   *
   *        // 高程坐标系重载，如果不需要修改高程则可忽略这个
   *
   *        "output_override_vertical_CS": ""  //可选，重载高程坐标系，用户选择，格式样例:"EPSG:4326"          默认为空字符串
   *
   *    }
   */
  params: Scalars['String'];
};

export type CreateSeedlingJobInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 部门 */
  departmentUuid: Scalars['String'];
  /** 照片库的uuid */
  mappingUuid: Scalars['String'];
  /** 名字 */
  name: Scalars['String'];
  /** 某个重建任务的uuid */
  processingJobUuid?: InputMaybe<Scalars['String']>;
};

export type CreateSeedlingJobPayload = {
  __typename?: 'CreateSeedlingJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  seedlingJob?: Maybe<SeedlingJob>;
};

export type CreateSourceImagesInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  sourceImagesAttributes: SourceImagesAttributes;
};

export type CreateSourceImagesPayload = {
  __typename?: 'CreateSourceImagesPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  sourceImages?: Maybe<Array<Maybe<SourceImage>>>;
};

/** 创建storage参数 */
export type CreateStsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  fileInfo: StsAttributes;
};

/** 创建sts的响应 */
export type CreateStsPayload = {
  __typename?: 'CreateStsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  sts: Sts;
};

export type CreateTagInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  /** 集团uuid，普通用户没必要传 */
  organizationId?: InputMaybe<Scalars['Int']>;
};

export type CreateTagPayload = {
  __typename?: 'CreateTagPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  tag?: Maybe<Tag>;
};

/** 创建技术反馈的请求 */
export type CreateTechFeedbackInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 反馈属性 */
  techFeedbackAttributes: TechFeedbackAttributes;
};

export type CreateTechFeedbackPayload = {
  __typename?: 'CreateTechFeedbackPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  techFeedback?: Maybe<TechFeedback>;
};

export type CreateTreeInfoInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  treeInfoAttributes: TreeInfoAttributes;
};

export type CreateTreeInfoPayload = {
  __typename?: 'CreateTreeInfoPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  treeInfo?: Maybe<TreeInfo>;
};

export type Credential = {
  __typename?: 'Credential';
  accessKeyId?: Maybe<Scalars['String']>;
  callback?: Maybe<Scalars['String']>;
  /**
   * 请参考https://help.aliyun.com/document_detail/31989.html?spm=a2c4g.11186623.2.9.73a82da1j5Eg5A
   * fileType作为额外标示上传的文件的类型字段，用于回调后台时候确定文件类型的自定义变量
   * 表单域中增加："x:file-type"  =>  该值
   */
  fileType?: Maybe<Scalars['String']>;
  policy?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
};

export type Credentials = {
  __typename?: 'Credentials';
  accessKeyId?: Maybe<Scalars['String']>;
  accessKeySecret?: Maybe<Scalars['String']>;
  expire?: Maybe<Scalars['Int']>;
  securityToken?: Maybe<Scalars['String']>;
};

export type DailyForecastWeather = SummaryWeather & {
  __typename?: 'DailyForecastWeather';
  /** 体感温度 摄氏度 */
  apparentTemperature?: Maybe<MaxMinAvg>;
  /** 云量 0-1 */
  cloudRate?: Maybe<MaxMinAvg>;
  /** 时间点 */
  datetime?: Maybe<Scalars['Time']>;
  /** 向下短波辐射通量 */
  dswrf?: Maybe<MaxMinAvg>;
  /** 湿度 0-1 */
  humidity?: Maybe<MaxMinAvg>;
  /** 降水量 mm/h */
  precipitation?: Maybe<MaxMinAvg>;
  /** 气压 Pa */
  pressure?: Maybe<MaxMinAvg>;
  /** 天气现象 */
  skyCondition?: Maybe<Array<Maybe<SkyCond>>>;
  /** 温度（摄氏度） */
  temperature?: Maybe<MaxMinAvg>;
  /** 能见度 km */
  visibility?: Maybe<MaxMinAvg>;
  /** 风向 （北起顺时针的度数） */
  windDirection?: Maybe<MaxMinAvg>;
  /** 风速 km/h */
  windSpeed?: Maybe<MaxMinAvg>;
};


export type DailyForecastWeatherApparentTemperatureArgs = {
  unit?: InputMaybe<TemperatureUnit>;
};


export type DailyForecastWeatherPressureArgs = {
  unit?: InputMaybe<PressureUnit>;
};


export type DailyForecastWeatherTemperatureArgs = {
  unit?: InputMaybe<TemperatureUnit>;
};


export type DailyForecastWeatherVisibilityArgs = {
  unit?: InputMaybe<LenghtUnit>;
};


export type DailyForecastWeatherWindSpeedArgs = {
  unit?: InputMaybe<SpeedUnit>;
};

export type DailyForecastWeatherConnection = {
  __typename?: 'DailyForecastWeatherConnection';
  /** DailyForecastWeather 数组片段 */
  edges: Array<Maybe<DailyForecastWeatherEdge>>;
  /** 直接获取元素 */
  nodes: Array<Maybe<DailyForecastWeather>>;
  /** 分页/connection信息 */
  pageInfo: PageInfo;
  /** 总数量 */
  totalCount: Scalars['Int'];
};

/** edge */
export type DailyForecastWeatherEdge = {
  __typename?: 'DailyForecastWeatherEdge';
  /** 分页/connection中的游标 */
  cursor?: Maybe<Scalars['String']>;
  /** 记录 */
  node?: Maybe<DailyForecastWeather>;
};

export type DailyHistoryWeather = SummaryWeather & {
  __typename?: 'DailyHistoryWeather';
  /** 体感温度 摄氏度 */
  apparentTemperature?: Maybe<MaxMinAvg>;
  /** 云量 0-1 */
  cloudRate?: Maybe<MaxMinAvg>;
  /** 时间点 */
  datetime?: Maybe<Scalars['Time']>;
  /** 向下短波辐射通量 */
  dswrf?: Maybe<MaxMinAvg>;
  /** 湿度 0-1 */
  humidity?: Maybe<MaxMinAvg>;
  /** 系统id */
  id?: Maybe<Scalars['ID']>;
  /** 降水量 mm/h */
  precipitation?: Maybe<MaxMinAvg>;
  /** 气压 Pa */
  pressure?: Maybe<MaxMinAvg>;
  /** 天气现象 */
  skyCondition?: Maybe<Array<Maybe<SkyCond>>>;
  /** 温度（摄氏度） */
  temperature?: Maybe<MaxMinAvg>;
  /** 能见度 km */
  visibility?: Maybe<MaxMinAvg>;
  /** 风向 （北起顺时针的度数） */
  windDirection?: Maybe<MaxMinAvg>;
  /** 风速 km/h */
  windSpeed?: Maybe<MaxMinAvg>;
};


export type DailyHistoryWeatherApparentTemperatureArgs = {
  unit?: InputMaybe<TemperatureUnit>;
};


export type DailyHistoryWeatherPressureArgs = {
  unit?: InputMaybe<PressureUnit>;
};


export type DailyHistoryWeatherTemperatureArgs = {
  unit?: InputMaybe<TemperatureUnit>;
};


export type DailyHistoryWeatherVisibilityArgs = {
  unit?: InputMaybe<LenghtUnit>;
};


export type DailyHistoryWeatherWindSpeedArgs = {
  unit?: InputMaybe<SpeedUnit>;
};

export type DailyHistoryWeatherConnection = {
  __typename?: 'DailyHistoryWeatherConnection';
  /** DailyHistoryWeather 数组片段 */
  edges: Array<Maybe<DailyHistoryWeatherEdge>>;
  /** 直接获取元素 */
  nodes: Array<Maybe<DailyHistoryWeather>>;
  /** 分页/connection信息 */
  pageInfo: PageInfo;
  /** 总数量 */
  totalCount: Scalars['Int'];
};

/** edge */
export type DailyHistoryWeatherEdge = {
  __typename?: 'DailyHistoryWeatherEdge';
  /** 分页/connection中的游标 */
  cursor?: Maybe<Scalars['String']>;
  /** 记录 */
  node?: Maybe<DailyHistoryWeather>;
};

export type DailyHistoryWeathersFilter = {
  /** 气象观测点的uuid */
  PositionOfInterestUuid?: InputMaybe<Scalars['String']>;
  /** sql 片段方式（仅占位,暂不开启） */
  cond?: InputMaybe<Scalars['String']>;
};

export type DayInfo = {
  __typename?: 'DayInfo';
  /** 当天的活跃植保机数量 */
  activeDroneCount: Scalars['Int'];
  /** 日期，格式为年月，如20200501 */
  createDate: Scalars['Int'];
  /** 当天的飞行次数 */
  flightRecordCount: Scalars['Int'];
  /** 当天的飞行时长（单位秒） */
  flightRecordTime: Scalars['Int'];
  /** 当天违规面积（单位0.01亩） */
  invalidWorkArea: Scalars['Int'];
  /** 当天合法面积（单位0.01亩） */
  validWorkArea: Scalars['Int'];
};

export type DeleteEstimationJobInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type DeleteEstimationJobPayload = {
  __typename?: 'DeleteEstimationJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  estimationJob?: Maybe<EstimationJob>;
};

export type DeleteFreePlotInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type DeleteFreePlotPayload = {
  __typename?: 'DeleteFreePlotPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 地块 */
  freePlot?: Maybe<FreePlot>;
};

export type DeleteMappingInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type DeleteMappingPayload = {
  __typename?: 'DeleteMappingPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteMergeJobInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type DeleteMergeJobPayload = {
  __typename?: 'DeleteMergeJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  mergeJob?: Maybe<MergeJob>;
};

export type DeletePermissionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type DeletePermissionPayload = {
  __typename?: 'DeletePermissionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  permission?: Maybe<Permission>;
};

export type DeletePlotInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 地块uuid */
  uuid: Scalars['String'];
};

export type DeletePlotPatrolInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type DeletePlotPatrolPayload = {
  __typename?: 'DeletePlotPatrolPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePlotPatrolsSourceImageInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  plotPatrolUuid: Scalars['ID'];
  sourceImageUuid: Scalars['ID'];
};

export type DeletePlotPatrolsSourceImagePayload = {
  __typename?: 'DeletePlotPatrolsSourceImagePayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePlotPayload = {
  __typename?: 'DeletePlotPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 地块 */
  plot?: Maybe<Plot>;
};

export type DeleteProcessingJobAuthInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 高清地图授权的Uuid */
  uuid: Scalars['String'];
};

export type DeleteProcessingJobAuthPayload = {
  __typename?: 'DeleteProcessingJobAuthPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 高清地图授权记录 */
  processingJobAuth?: Maybe<ProcessingJobAuth>;
};

export type DeleteProcessingJobInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type DeleteProcessingJobPayload = {
  __typename?: 'DeleteProcessingJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteProcessingJobSourceImageInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  processingJobUuid: Scalars['ID'];
  sourceImageUuid: Scalars['ID'];
};

export type DeleteProcessingJobSourceImagePayload = {
  __typename?: 'DeleteProcessingJobSourceImagePayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteSeedlingJobInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type DeleteSeedlingJobPayload = {
  __typename?: 'DeleteSeedlingJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  seedlingJob?: Maybe<SeedlingJob>;
};

export type DeleteSourceImagesInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  uuids: Array<Scalars['String']>;
};

export type DeleteSourceImagesPayload = {
  __typename?: 'DeleteSourceImagesPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  sourceImages?: Maybe<Array<Maybe<SourceImage>>>;
};

export type DeleteTagInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type DeleteTagPayload = {
  __typename?: 'DeleteTagPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  tag?: Maybe<Tag>;
};

/** 部门；组织关系模块中业务模型 */
export type Department = {
  __typename?: 'Department';
  /** 违规通知人 */
  NotifyReceiverName?: Maybe<Scalars['String']>;
  /** 违规通知电话 */
  NotifyReceiverPhone?: Maybe<Scalars['String']>;
  /** 气象观测点 */
  PositionOfInterests?: Maybe<PositionOfInterestConnection>;
  /** 该部门及其子部门的地块列表 */
  accessibleLands?: Maybe<LandConnection>;
  /** 设备列表 */
  devices?: Maybe<DeviceConnection>;
  /** 部门外部系统中的id */
  externalId?: Maybe<Scalars['ID']>;
  /** 农场边界/电子围栏 */
  fences?: Maybe<FenceConnection>;
  /** 系统中的成员id */
  id?: Maybe<Scalars['ID']>;
  /** 所属部门 */
  members?: Maybe<MemberConnection>;
  /** 部门名称(256字符以内) */
  name?: Maybe<Scalars['String']>;
  /** 所属机构 */
  organization?: Maybe<Organization>;
  /** 所属机构的ID */
  organizationId?: Maybe<Scalars['ID']>;
  /** 上一级部门 */
  parentDepartment?: Maybe<Department>;
  /** 上一级部门ID */
  parentDepartmentId?: Maybe<Scalars['ID']>;
  /** 部门外部系统中上一级的id */
  parentExternalId?: Maybe<Scalars['ID']>;
  /** 子部门列表 */
  subDepartments?: Maybe<DepartmentConnection>;
  /** 系统中的成员uuid */
  uuid?: Maybe<Scalars['String']>;
};


/** 部门；组织关系模块中业务模型 */
export type DepartmentPositionOfInterestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


/** 部门；组织关系模块中业务模型 */
export type DepartmentAccessibleLandsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


/** 部门；组织关系模块中业务模型 */
export type DepartmentDevicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DevicesFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


/** 部门；组织关系模块中业务模型 */
export type DepartmentFencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


/** 部门；组织关系模块中业务模型 */
export type DepartmentMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<MembersFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


/** 部门；组织关系模块中业务模型 */
export type DepartmentSubDepartmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DepartmentsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};

/** 部门的属性 */
export type DepartmentAttributes = {
  /** 违规通知人 */
  NotifyReceiverName?: InputMaybe<Scalars['String']>;
  /** 违规通知电话 */
  NotifyReceiverPhone?: InputMaybe<Scalars['String']>;
  /** 外部系统的部门id */
  externalId?: InputMaybe<Scalars['String']>;
  /**
   * 部门名称
   *
   * 创建部门的时候请务必传入此参数
   */
  name?: InputMaybe<Scalars['String']>;
  /**
   * 所属组织/集团id
   *
   * 创建部门的时候请务必传入此参数
   */
  organizationId?: InputMaybe<Scalars['ID']>;
  /**
   * 上级部门的id
   *
   * 创建部门的时候请务必传入此参数，如果没有父部门则传"0"
   */
  parentDepartmentId?: InputMaybe<Scalars['ID']>;
  /** 部门的id */
  parentExternalId?: InputMaybe<Scalars['String']>;
};

/** Department connection */
export type DepartmentConnection = {
  __typename?: 'DepartmentConnection';
  /** Department 数组片段 */
  edges: Array<Maybe<DepartmentEdge>>;
  /** 直接获取元素 */
  nodes: Array<Maybe<Department>>;
  /** 分页/connection信息 */
  pageInfo: PageInfo;
  /** 总数量 */
  totalCount: Scalars['Int'];
};

/** Department edge */
export type DepartmentEdge = {
  __typename?: 'DepartmentEdge';
  /** 分页/connection中的游标 */
  cursor?: Maybe<Scalars['String']>;
  /** 记录 */
  node?: Maybe<Department>;
};

export type DepartmentInfo = {
  __typename?: 'DepartmentInfo';
  /** 违规作业部门 */
  department: Department;
  /** 面积（0.01亩） */
  workArea: Scalars['Int'];
};

export type DepartmentNode = {
  __typename?: 'DepartmentNode';
  children?: Maybe<Array<Maybe<DepartmentNode>>>;
  department?: Maybe<Department>;
};

export type DepartmentRole = {
  /** 部门id */
  departmentId?: InputMaybe<Scalars['ID']>;
  /** 角色id */
  role?: InputMaybe<Scalars['Int']>;
};

export type DepartmentTree = {
  __typename?: 'DepartmentTree';
  children?: Maybe<Array<Maybe<DepartmentNode>>>;
  depth: Scalars['Int'];
};

export type DepartmentsFilter = {
  /** sql 片段方式（仅占位,暂不开启） */
  cond?: InputMaybe<Scalars['String']>;
  /** 下面是否有任务。true代表有，false代表没有，不传代表不根据此条件筛选 */
  hasTask?: InputMaybe<Scalars['Boolean']>;
  /** 组织/集团uuid */
  organizationUuId?: InputMaybe<Scalars['String']>;
  /** 直属上级部门id */
  parentDepartmentId?: InputMaybe<Scalars['ID']>;
  /** user的name或者phone */
  userKeyword?: InputMaybe<Scalars['String']>;
};

export type DestroyNdviJobInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type DestroyNdviJobPayload = {
  __typename?: 'DestroyNdviJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DestroyRemoteControllerProcessingJobPayload = {
  __typename?: 'DestroyRemoteControllerProcessingJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Device = {
  __typename?: 'Device';
  admin?: Maybe<Scalars['String']>;
  authorized: Scalars['Boolean'];
  authorizedAt?: Maybe<Scalars['Time']>;
  bodyCode: Scalars['String'];
  department?: Maybe<Department>;
  /** 部门链，顺序从底层到顶层 */
  departmentLinks?: Maybe<Array<Maybe<Department>>>;
  deviceModel?: Maybe<DeviceModel>;
  flightRecords?: Maybe<FlightRecordConnection>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  /** 备注信息 */
  remark?: Maybe<Scalars['String']>;
  sn?: Maybe<Scalars['String']>;
};


export type DeviceFlightRecordsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type DeviceConnection = {
  __typename?: 'DeviceConnection';
  edges: Array<Maybe<DeviceEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DeviceEdge = {
  __typename?: 'DeviceEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Device>;
};

export type DeviceModel = {
  __typename?: 'DeviceModel';
  /** 该设备型号的类型 */
  deviceType?: Maybe<DeviceType>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  vendor?: Maybe<Vendor>;
};

export type DeviceModelsFilter = {
  vendorId: Scalars['ID'];
};

export type DeviceType = {
  __typename?: 'DeviceType';
  id: Scalars['ID'];
  /**
   * 标签，可确保全局唯一，用于前端筛选
   * Drone-植保机 Tractor-拖拉机
   */
  label?: Maybe<Scalars['String']>;
  /** 植保机 深松机 拖拉机 */
  name: Scalars['String'];
  /** 作业类型 */
  taskTypes?: Maybe<Array<TaskType>>;
  /** 哪些厂商是这个DeviceType的 */
  vendors?: Maybe<Array<Vendor>>;
};

export type DeviceWithWaypoint = {
  __typename?: 'DeviceWithWaypoint';
  device: Device;
  waypoint: Waypoint;
};

export type DevicesFilter = {
  departmentId?: InputMaybe<Scalars['ID']>;
  /** 根据型号搜索 */
  deviceModelId?: InputMaybe<Scalars['ID']>;
  /** 根据设备类型搜索 */
  deviceTypeId?: InputMaybe<Scalars['ID']>;
  /**
   * 筛选是否授权
   * 空则是不做该筛选,true则为筛选已经授权的,false为筛选没授权的
   */
  isAuthorized?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
};

/** 域名模型 */
export type Domain = {
  __typename?: 'Domain';
  /** 域名 */
  Host?: Maybe<Scalars['String']>;
  /** 系统中的id */
  id?: Maybe<Scalars['ID']>;
  /** 所属机构 */
  organization?: Maybe<Organization>;
  /** 所属机构的ID */
  organizationId?: Maybe<Scalars['ID']>;
  /** 状态 */
  status?: Maybe<Scalars['Int']>;
  /** 系统中的uuid */
  uuid?: Maybe<Scalars['String']>;
};

/** 域名的属性 */
export type DomainAttributes = {
  /** 外部域名类型 */
  externalId?: InputMaybe<Scalars['String']>;
  /** 所属组织/集团ID */
  organizationId?: InputMaybe<Scalars['ID']>;
  /** 组织/集团权限 */
  organizationRoleId?: InputMaybe<Scalars['Int']>;
  /** 域名类型 */
  type?: InputMaybe<Scalars['String']>;
};

/** 域名connection */
export type DomainConnection = {
  __typename?: 'DomainConnection';
  /** Domain数组片段 */
  edges: Array<Maybe<DomainEdge>>;
  /** 直接获取元素 */
  nodes: Array<Maybe<Domain>>;
  /** 分页/connection信息 */
  pageInfo: PageInfo;
  /** 总数量 */
  totalCount: Scalars['Int'];
};

/** 域名edge */
export type DomainEdge = {
  __typename?: 'DomainEdge';
  /** 分页/connection中的游标 */
  cursor?: Maybe<Scalars['String']>;
  /** 记录 */
  node?: Maybe<Domain>;
};

export type DomainsFilter = {
  /** sql 片段方式（仅占位,暂不开启） */
  cond?: InputMaybe<Scalars['String']>;
};

export type DownloadNdviFertilizerInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 处方图uuid */
  ndviJobUuid: Scalars['String'];
};

export type DownloadNdviFertilizerPayload = {
  __typename?: 'DownloadNdviFertilizerPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export const enum DownloadNdviFertilizerStatus {
  Failed = 'Failed',
  Handling = 'Handling',
  Success = 'Success'
};

export type DroneInfo = {
  __typename?: 'DroneInfo';
  /** 飞机名字 */
  name: Scalars['String'];
  /** 飞机作业面积（单位0.01亩） */
  workArea: Scalars['Int'];
};

export const enum Edition {
  /** 企业版 */
  Enterprise = 'Enterprise',
  /** 个人版 */
  Personal = 'Personal'
};

export type EstimationImage = {
  __typename?: 'EstimationImage';
  /** 面积(平方米) */
  area: Scalars['Float'];
  /** 数穗结果密度 */
  density: Scalars['Float'];
  /** 去畸变图 */
  dewarpImgSignURL?: Maybe<Scalars['String']>;
  /** 数穗结果（个数） */
  estimationCount: Scalars['Int'];
  /** 位置 */
  position: Position;
  /** ai识别结果图 */
  senseImgSignURL?: Maybe<Scalars['String']>;
  /** 原图uuid */
  sourceImageUuid?: Maybe<Scalars['String']>;
  /** 航拍原图 */
  srcImgSignURL?: Maybe<Scalars['String']>;
  /** 状态(0-waiting（瞬间）；1-pending; 2-ok;3-failed；4-unknown（代码bug）) */
  status: Scalars['Int'];
};


export type EstimationImageAreaArgs = {
  unit?: InputMaybe<AreaUnit>;
};

export type EstimationJob = {
  __typename?: 'EstimationJob';
  /** 拍摄时间，如果没照片那就是空 */
  captureTime?: Maybe<Scalars['Time']>;
  createdAt: Scalars['Time'];
  department?: Maybe<Department>;
  /** 部门链，顺序从底层到顶层 */
  departmentLinks?: Maybe<Array<Maybe<Department>>>;
  downLeft?: Maybe<Position>;
  /**
   * 出苗分析结果
   * 如果传边界则是边界内的分析结果
   * 如果不传边界则是整个的出苗分析结果
   * 分析结果不分页数
   * 注意就算分析任务成功了，也有可能结果是空，因为有可能圈的范围内没有图片，那就是空结果
   */
  estimationResult?: Maybe<EstimationResult>;
  /** 失败的图片数量 */
  failureCount: Scalars['Int'];
  id: Scalars['ID'];
  mapping?: Maybe<Mapping>;
  mappingUuid: Scalars['String'];
  name: Scalars['String'];
  plantType?: Maybe<PlantType>;
  position?: Maybe<Position>;
  processingJob?: Maybe<ProcessingJob>;
  /** 进度百分比(0-100) */
  progressPercent: Scalars['Float'];
  senseAgroJobUuid?: Maybe<Scalars['String']>;
  /** 0：待开始，1：等待中，2：准备中，3：执行中，4：处理结果中，5：已停止，6：执行完成，7：执行失败 */
  status: Scalars['Int'];
  updatedAt: Scalars['Time'];
  upperRight?: Maybe<Position>;
  uuid: Scalars['String'];
};


export type EstimationJobEstimationResultArgs = {
  geo?: InputMaybe<Scalars['GeoJSON']>;
};

export type EstimationJobEdge = {
  __typename?: 'EstimationJobEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<EstimationJob>;
};

export type EstimationJobsConnection = {
  __typename?: 'EstimationJobsConnection';
  edges: Array<Maybe<EstimationJobEdge>>;
  nodes: Array<Maybe<EstimationJob>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars['Int'];
};

export type EstimationJobsFilter = {
  bbox?: InputMaybe<BoundingBoxAttributes>;
  captureAfter?: InputMaybe<Scalars['Time']>;
  captureBefore?: InputMaybe<Scalars['Time']>;
  createdAfter?: InputMaybe<Scalars['Time']>;
  createdBefore?: InputMaybe<Scalars['Time']>;
  departmentUuids?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<Scalars['Int']>>;
  uuids?: InputMaybe<Array<Scalars['String']>>;
};

export type EstimationResult = {
  __typename?: 'EstimationResult';
  /** 单位：穗/亩 */
  averageDensity: Scalars['Float'];
  /** 五等分位, 单位：穗/亩 */
  fiveSeg: Array<Scalars['Float']>;
  gaussianDis: Array<Scalars['Float']>;
  sourceImages: Array<EstimationSourceImage>;
  /** 照片总数 */
  totalCount: Scalars['Int'];
};


export type EstimationResultSourceImagesArgs = {
  sort?: InputMaybe<Sort>;
};

export type EstimationSourceImage = EstimationImage | SourceImage;

export const enum ExportStatus {
  /** 生成中，不能下载 */
  Busy = 'Busy',
  /** 导出失败 */
  Err = 'Err',
  /** 初始状态，不能下载 */
  Initial = 'Initial',
  /** 生成完毕，等待下载 */
  Waiting = 'Waiting'
};

export const enum ExternalType {
  /** 表示该地块是某个department的边界 */
  EdgeOfDepartment = 'EdgeOfDepartment',
  /** 表示该地块是某个organization的边界 */
  EdgeOfOrganization = 'EdgeOfOrganization',
  /** 表示该地块是属于某个department的地块 */
  LandOfDepartment = 'LandOfDepartment',
  /** 表示该地块属于某个organization的地块 */
  LandOfOrganization = 'LandOfOrganization'
};

export const enum Format {
  Png = 'PNG',
  Tif = 'TIF'
};

export type FastUploadInput = {
  ext?: InputMaybe<MediaFile>;
  fingerprint?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
};

/** 电子围栏/农场边界 */
export type Fence = {
  __typename?: 'Fence';
  /** 部门id */
  departmentId?: Maybe<Scalars['ID']>;
  /** 电子围栏/边界 */
  geo?: Maybe<Scalars['GeoJSON']>;
  /** 系统中的id */
  id?: Maybe<Scalars['ID']>;
  /** 系统中的uuid */
  uuid?: Maybe<Scalars['String']>;
};

/** 边界/电子围栏的属性 */
export type FenceAttributes = {
  /** 部门id */
  departmentId?: InputMaybe<Scalars['ID']>;
  /** 电子围栏/边界 */
  geo?: InputMaybe<Scalars['GeoJSON']>;
};

/** fence connection */
export type FenceConnection = {
  __typename?: 'FenceConnection';
  /** 所有边界组成的bbox */
  bbox?: Maybe<BoundingBox>;
  /** 所有边界的中心点 */
  centroid?: Maybe<Position>;
  /** Fence数组片段 */
  edges: Array<Maybe<FenceEdge>>;
  /** 直接获取元素 */
  nodes: Array<Maybe<Fence>>;
  /** 分页/connection信息 */
  pageInfo: PageInfo;
  /** 总数量 */
  totalCount: Scalars['Int'];
};

/** Fence edge */
export type FenceEdge = {
  __typename?: 'FenceEdge';
  /** 分页/connection中的游标 */
  cursor?: Maybe<Scalars['String']>;
  /** 记录 */
  node?: Maybe<Fence>;
};

export type FencesFilter = {
  /** sql 片段方式（仅占位,暂不开启） */
  cond?: InputMaybe<Scalars['String']>;
};

/** 第三方处方图 */
export type Fertilizer = {
  __typename?: 'Fertilizer';
  /** bouding box */
  bbox?: Maybe<BoundingBox>;
  /** 服务器创建时间 */
  createdAt?: Maybe<Scalars['Time']>;
  /** 部门链 */
  departmentLinks?: Maybe<Array<Maybe<Department>>>;
  /** fertilizer 地址 */
  fertilizerUrl?: Maybe<Scalars['String']>;
  /** 系统中的id */
  id?: Maybe<Scalars['ID']>;
  /** 用户指定的日期 */
  localCreatedAt?: Maybe<Scalars['Time']>;
  /** 处方图最大值 */
  maxValue?: Maybe<Scalars['Float']>;
  /** tiff图文件原信息 */
  metaInfo?: Maybe<Scalars['MetaInfo']>;
  /** 处方图最小值 */
  minValue?: Maybe<Scalars['Float']>;
  /** 名称 */
  name?: Maybe<Scalars['String']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 处方图来源 */
  source?: Maybe<FertilizerSource>;
  /** tfw 地址 */
  tfwUrl?: Maybe<Scalars['String']>;
  /** 服务器更新时间 */
  updatedAt?: Maybe<Scalars['Time']>;
  /** 系统中的uuid */
  uuid?: Maybe<Scalars['String']>;
};


/** 第三方处方图 */
export type FertilizerFertilizerUrlArgs = {
  format?: InputMaybe<Format>;
};

/** Fertilizer的属性 */
export type FertilizerAttributes = {
  /** 部门链 */
  departmentUuid?: InputMaybe<Scalars['String']>;
  /** fertilizer 地址 */
  fertilizerStorageUuid?: InputMaybe<Scalars['String']>;
  /** 用户指定的日期 */
  localCreatedAt?: InputMaybe<Scalars['Time']>;
  /** 名称 */
  name?: InputMaybe<Scalars['String']>;
  /** 备注 */
  note?: InputMaybe<Scalars['String']>;
  /** 处方图来源 */
  source?: InputMaybe<FertilizerSource>;
  /** tfw 地址 */
  tfwStorageUuid?: InputMaybe<Scalars['String']>;
};

/** Fertilizer connection */
export type FertilizerConnection = {
  __typename?: 'FertilizerConnection';
  /** Fertilizer数组片段 */
  edges: Array<Maybe<FertilizerEdge>>;
  /** 直接获取元素 */
  nodes: Array<Maybe<Fertilizer>>;
  /** 分页/connection信息 */
  pageInfo: PageInfo;
  /** 总数量 */
  totalCount: Scalars['Int'];
};

/** Fertilizer edge */
export type FertilizerEdge = {
  __typename?: 'FertilizerEdge';
  /** 分页/connection中的游标 */
  cursor?: Maybe<Scalars['String']>;
  /** 记录 */
  node?: Maybe<Fertilizer>;
};

export type FertilizerShare = {
  __typename?: 'FertilizerShare';
  /** 创建时间 */
  createdAt?: Maybe<Scalars['Time']>;
  /** 第三方处方图 */
  fertilizerUuid?: Maybe<Scalars['String']>;
  /** 系统中的id */
  id?: Maybe<Scalars['ID']>;
  /** ndvi重建任务 */
  ndviJob?: Maybe<NdviJob>;
  /** 备注 */
  remark?: Maybe<Scalars['String']>;
  /** 分享状态 */
  status?: Maybe<Scalars['Int']>;
  /** 分享目标 */
  target?: Maybe<UserOfExternalSystem>;
  /** 最后更新时间 */
  updatedAt?: Maybe<Scalars['Time']>;
  /** 分享创建者 */
  user?: Maybe<User>;
  /** 系统中的uuid */
  uuid?: Maybe<Scalars['String']>;
};

export type FertilizerShareAttributes = {
  /** 大疆用户账号（目前仅限大疆农服） */
  account?: InputMaybe<Scalars['String']>;
  /** 第三方处方图uuid */
  fertilizerUuid?: InputMaybe<Scalars['String']>;
  /** ndvi重建任务uuid */
  ndviJobUuid?: InputMaybe<Scalars['String']>;
};

/** FertilizerShare connection */
export type FertilizerShareConnection = {
  __typename?: 'FertilizerShareConnection';
  /** FertilizerShare数组片段 */
  edges: Array<Maybe<FertilizerShareEdge>>;
  /** 直接获取元素 */
  nodes: Array<Maybe<FertilizerShare>>;
  /** 分页/connection信息 */
  pageInfo: PageInfo;
  /** 总数量 */
  totalCount: Scalars['Int'];
};

/** UserOfExternalSystem edge */
export type FertilizerShareEdge = {
  __typename?: 'FertilizerShareEdge';
  /** 分页/connection中的游标 */
  cursor?: Maybe<Scalars['String']>;
  /** 记录 */
  node?: Maybe<FertilizerShare>;
};

export const enum FertilizerSource {
  /** 来自OPEN API上传 */
  ByApi = 'BY_API',
  /** 智农云平台；来自重建 */
  ByDji = 'BY_DJI',
  /** 用户手工上传 */
  ByManual = 'BY_MANUAL'
};

export type FertilizersFilter = {
  DepartmentUuid?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  contentMd5?: Maybe<Scalars['String']>;
  createdAt: Scalars['Time'];
  name?: Maybe<Scalars['String']>;
  signUrl?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Int']>;
};

/** file details是前端上传文件时候解析并传给后端，后端原封不动返回 */
export type FileDetails = {
  __typename?: 'FileDetails';
  auxImg: AuxImg;
  cameraModel: Scalars['String'];
  captureTime: Scalars['Time'];
  dewarpFlag: Scalars['Boolean'];
  focalLengthIn35mm: Scalars['Int'];
  geoDesc: GeoDesc;
  /** 坐标 */
  gps: Gps;
  height: Scalars['Int'];
  id: Scalars['String'];
  isImported: Scalars['Boolean'];
  /** 角度，有三个值，分别是Omega/Yaw、Phi/Pitch、Kappa/Roll */
  orientation: Array<Scalars['Float']>;
  orientationType: Scalars['String'];
  originPath: Scalars['String'];
  path: Scalars['String'];
  posInfo: PosInfo;
  /** 有三个值，分别是经度的精度、纬度的精度、高度的精度。前两者即水平精度，值一般都一样 */
  posSigma: Array<Scalars['Float']>;
  position: Array<Scalars['Float']>;
  preCalibParam: Array<Scalars['Float']>;
  relativeHeight: Scalars['Float'];
  rtkFlag: Scalars['Int'];
  velocity: Array<Scalars['Float']>;
  width: Scalars['Int'];
  xmp: Xmp;
};

export type FileInfo = {
  __typename?: 'FileInfo';
  fileName: Scalars['String'];
  /** 文件大小，单位b */
  fileSize: Scalars['Int'];
  /** 哈希值 */
  sha1: Scalars['String'];
  type: Scalars['String'];
};

export type FileUrl = {
  __typename?: 'FileUrl';
  name: Scalars['String'];
  url: Scalars['String'];
};

export type FinishRemoteControllerProcessingJobPayload = {
  __typename?: 'FinishRemoteControllerProcessingJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type FlightData = {
  __typename?: 'FlightData';
  /** 播撒绞龙模式，单位转/分钟 */
  deliverSpeed: Scalars['Int'];
  /** 深度，单位厘米 */
  depth: Scalars['Float'];
  flowSpeed: Scalars['Float'];
  /** 舱口大小，手动挡模式下有效。单位: 百分比 */
  hatchSize: Scalars['Int'];
  height: Scalars['Float'];
  /** 是否在喷洒 */
  isSowing: Scalars['Boolean'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  /** 喷洒的工作模式 */
  mode: Scalars['Int'];
  /** 动力输出转速，单位转/分钟 */
  pto: Scalars['Int'];
  /** 播撒速度，单位克/分 */
  sowSpeed: Scalars['Float'];
  /** 瞬时速度，单位米/秒 */
  speed: Scalars['Float'];
  sprayWidth: Scalars['Float'];
  /**
   * 毫秒级时间戳
   * 需要使用int64
   */
  timestamp: Scalars['Int64'];
  /** 转盘速度，单位：rpm */
  turntableSpeed: Scalars['Int'];
  xSpeed: Scalars['Float'];
  ySpeed: Scalars['Float'];
};

export type FlightRecord = {
  __typename?: 'FlightRecord';
  airline?: Maybe<Array<Maybe<FlightData>>>;
  beginTimestamp?: Maybe<Scalars['Int']>;
  detail?: Maybe<Array<Maybe<FlightData>>>;
  device?: Maybe<Device>;
  endTimestamp?: Maybe<Scalars['Int']>;
  flightRecordAttribute?: Maybe<FlightRecordAttribute>;
  flyerName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  invalidAttributes: Array<FlightRecordAttributeEnum>;
  land?: Maybe<Land>;
  /** 作业长度，单位厘米 */
  length?: Maybe<Scalars['Float']>;
  location?: Maybe<Scalars['String']>;
  no?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  /** 飞行记录的质量，如果未知就是空 */
  quality?: Maybe<QualityType>;
  sn?: Maybe<Scalars['String']>;
  /** 播撒量，单位克 */
  sowUsage?: Maybe<Scalars['Int']>;
  sprayUsage?: Maybe<Scalars['Int']>;
  sprayWidth?: Maybe<Scalars['Float']>;
  taskType?: Maybe<TaskType>;
  /** 作业记录类型 */
  taskTypeId?: Maybe<Scalars['ID']>;
  /** 幅宽，单位厘米 */
  width?: Maybe<Scalars['Float']>;
  workArea?: Maybe<Scalars['Int']>;
};

export type FlightRecordAttribute = {
  __typename?: 'FlightRecordAttribute';
  /**
   *    MinDepth: Float
   *    MaxDepth: Float
   */
  AvgDepth?: Maybe<Scalars['Float']>;
  /**
   *    MinPto: Int
   *    MaxPto: Int
   */
  AvgPto?: Maybe<Scalars['Int']>;
  MaxHeight?: Maybe<Scalars['Float']>;
  MaxSpeed?: Maybe<Scalars['Float']>;
  MinHeight?: Maybe<Scalars['Float']>;
  /** 毫米/小时 */
  Rainfall?: Maybe<Scalars['Float']>;
  SowUsagePerMu: Scalars['Float'];
  SprayUsagePerMu: Scalars['Float'];
  SprayWidth: Scalars['Float'];
  /** 摄氏度 */
  Temperature?: Maybe<Scalars['Float']>;
  /** 米/秒 */
  WindSpeed?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
};

export const enum FlightRecordAttributeEnum {
  LocalTime = 'LocalTime',
  MaxDepth = 'MaxDepth',
  MaxHeight = 'MaxHeight',
  MaxPto = 'MaxPto',
  MaxSpeed = 'MaxSpeed',
  MinDepth = 'MinDepth',
  MinHeight = 'MinHeight',
  MinPto = 'MinPto',
  Rainfall = 'Rainfall',
  SowUsagePerMu = 'SowUsagePerMu',
  SprayUsagePerMu = 'SprayUsagePerMu',
  SprayWidth = 'SprayWidth',
  Temperature = 'Temperature',
  WindSpeed = 'WindSpeed'
};

export type FlightRecordConnection = {
  __typename?: 'FlightRecordConnection';
  edges: Array<Maybe<FlightRecordEdge>>;
  /**
   * 该飞行记录集合所归属的地块集合
   * 不允许传地块筛选条件，只允许传分页参数，最多一页1000个
   */
  lands?: Maybe<LandConnection>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};


export type FlightRecordConnectionLandsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type FlightRecordEdge = {
  __typename?: 'FlightRecordEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<FlightRecord>;
};

export type FlightRecordFilter = {
  /** 开始时间，传数字，如20200501 */
  beginDate?: InputMaybe<Scalars['Int']>;
  /** 部门的uuid，根据该部门下的设备筛选飞行记录，注意该条件不是根据地块 */
  departmentUuidForDrones?: InputMaybe<Scalars['String']>;
  /** 部门的uuid，根据部门uuid查该部门下的地块下的飞行记录 */
  departmentUuids?: InputMaybe<Array<Scalars['String']>>;
  /** 设备型号，请传设备型号id，int */
  deviceModelId?: InputMaybe<Scalars['Int']>;
  /** 设备名称 */
  deviceName?: InputMaybe<Scalars['String']>;
  /** 设备sn */
  deviceSns?: InputMaybe<Array<Scalars['String']>>;
  /** 设备厂商，请传设备厂商id，int */
  deviceVendorId?: InputMaybe<Scalars['Int']>;
  /** 结束时间，传数字，如20200501 */
  endDate?: InputMaybe<Scalars['Int']>;
  /** 飞行记录所属地块uuid */
  landUuids?: InputMaybe<Array<Scalars['String']>>;
  /** 飞行记录的location字段 */
  location?: InputMaybe<Scalars['String']>;
  /** 作业质量 */
  quality?: InputMaybe<QualityType>;
  /**
   * 飞行记录的非合规项。
   * 要注意2点。1、与quality字段的优先级顺序为：valid > items > invalid
   * 2、各个条件之间是"或"关系，而非"且"关系。例如，qualityItems传入[InvalidSprayUsagePerMu, InvalidMaxSpeed]，指的是"亩用药量违规或飞行速度违规的飞行记录"，而不是"亩用药量违规且飞行速度违规的飞行记录"
   */
  qualityItems?: InputMaybe<Array<QualityItem>>;
};

/** 用于筛选农机记录 */
export type FlightRecordsFilter = {
  /** 作业类型的id */
  taskTypeIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type FlyWaypointList = {
  __typename?: 'FlyWaypointList';
  isModified: Scalars['Boolean'];
  pathPlanningResult?: Maybe<PathPlanningResult>;
  ppeParams?: Maybe<PpeParams>;
  uuid: Scalars['String'];
};

export type FreePlot = {
  __typename?: 'FreePlot';
  caliPoints?: Maybe<Array<Array<Scalars['Float']>>>;
  createdAt: Scalars['Time'];
  edgesPointsSource?: Maybe<Scalars['String']>;
  geo: Scalars['LineStringGeoJSON'];
  id: Scalars['ID'];
  name: Scalars['String'];
  newestFlyWaypointList?: Maybe<FlyWaypointList>;
  ppe?: Maybe<Scalars['String']>;
  ppeParams?: Maybe<PpeParams>;
  startPoint?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Time'];
  uuid: Scalars['String'];
};

export type FreePlotAttributes = {
  /**
   * 地块的标定点
   * 请依次传入经度、纬度和高度，如没有高度则传入0
   * 如[[122.75609, 35.376234, 0], [122.75667, 35.376245, 0]]
   */
  caliPoints?: InputMaybe<Array<Array<Scalars['Float']>>>;
  edgesPointsSource?: InputMaybe<Scalars['String']>;
  /** LineString的geojson */
  geo: Scalars['LineStringGeoJSON'];
  name?: InputMaybe<Scalars['String']>;
  ppeParams?: InputMaybe<PpeParamsInput>;
  /** 所属job的uuid */
  processingJobUuid: Scalars['String'];
  startPoint?: InputMaybe<Scalars['String']>;
};

export type FreePlotEdge = {
  __typename?: 'FreePlotEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<FreePlot>;
};

export type FreePlotsConnection = {
  __typename?: 'FreePlotsConnection';
  edges: Array<Maybe<FreePlotEdge>>;
  nodes: Array<Maybe<FreePlot>>;
  pageInfo?: Maybe<PageInfoNew>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type GenerateSegmentCoverageInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  processingJobUuid: Scalars['String'];
};

export type GenerateSegmentCoveragePayload = {
  __typename?: 'GenerateSegmentCoveragePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  urlFormat: Scalars['String'];
};

export type GeoDesc = {
  __typename?: 'GeoDesc';
  csType: Scalars['String'];
  geoCs: Scalars['String'];
};

export type GetAgentInfoCodeInput = {
  agentPhone: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type GetAgentInfoCodePayload = {
  __typename?: 'GetAgentInfoCodePayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Gps = {
  __typename?: 'Gps';
  altitude: Scalars['Float'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type GpsCorner = {
  __typename?: 'GpsCorner';
  BottomRightLat: Scalars['Float'];
  BottomRightLon: Scalars['Float'];
  TopLeftLat: Scalars['Float'];
  TopLeftLon: Scalars['Float'];
};

export type Gsddsm = {
  __typename?: 'Gsddsm';
  /** 平均海拔，单位米 */
  averageAltitude: Scalars['Float'];
  /** 挖方量，单位立方米 */
  excavation: Scalars['Float'];
  /** 分析了哪些地块 */
  landUuids: Array<Scalars['String']>;
  /** 地块列表，无分页 */
  lands: Array<Land>;
  /**
   * 五分位值
   * 会给出四个分割点
   */
  quantile: Array<Scalars['Float']>;
  /**
   * 新的五分位值
   * 会给出四个分割点以及最大最小值
   */
  quantileNew: Array<Scalars['Float']>;
  /** 平均落差（标准差），单位米 */
  standardDeviation: Scalars['Float'];
};

/** 历史天气记录 */
export type HistoryWeather = AccurateWeather & {
  __typename?: 'HistoryWeather';
  /** 体感温度（摄氏度） */
  apparentTemperature?: Maybe<Scalars['Float']>;
  /** 云量（0-1） */
  cloudRate?: Maybe<Scalars['Float']>;
  /** 上游系统数据时间 */
  datetime?: Maybe<Scalars['Time']>;
  /** 短波向下反射率 */
  dswrf?: Maybe<Scalars['Float']>;
  /** 湿度（0-1） */
  humidity?: Maybe<Scalars['Float']>;
  /** 系统中的天气观测点id */
  id?: Maybe<Scalars['ID']>;
  /** 气象观察点 */
  positionOfInterest?: Maybe<PositionOfInterest>;
  /** 气象观察点id */
  positionOfInterestId?: Maybe<Scalars['ID']>;
  /** 降雨量 mm/h */
  precipitation?: Maybe<Scalars['Float']>;
  /** 气压Pa */
  pressure?: Maybe<Scalars['Float']>;
  /** 记录时间 */
  recordAt?: Maybe<Scalars['Time']>;
  /** 天气现象 */
  skyCondition?: Maybe<SkyCond>;
  /** 温度（摄氏度） */
  temperature?: Maybe<Scalars['Float']>;
  /** 能见度（km） */
  visibility?: Maybe<Scalars['Float']>;
  /** 风向（北起顺时针、度） */
  windDirection?: Maybe<Scalars['Float']>;
  /** 风速（km/h) */
  windSpeed?: Maybe<Scalars['Float']>;
};


/** 历史天气记录 */
export type HistoryWeatherApparentTemperatureArgs = {
  unit?: InputMaybe<TemperatureUnit>;
};


/** 历史天气记录 */
export type HistoryWeatherPressureArgs = {
  unit?: InputMaybe<PressureUnit>;
};


/** 历史天气记录 */
export type HistoryWeatherTemperatureArgs = {
  unit?: InputMaybe<TemperatureUnit>;
};


/** 历史天气记录 */
export type HistoryWeatherVisibilityArgs = {
  unit?: InputMaybe<LenghtUnit>;
};


/** 历史天气记录 */
export type HistoryWeatherWindSpeedArgs = {
  unit?: InputMaybe<SpeedUnit>;
};

/** 历史天气记录connection */
export type HistoryWeatherConnection = {
  __typename?: 'HistoryWeatherConnection';
  /** 历史天气记录数组片段 */
  edges: Array<Maybe<HistoryWeatherEdge>>;
  /** 直接获取元素 */
  nodes: Array<Maybe<HistoryWeather>>;
  /** 分页/connection信息 */
  pageInfo: PageInfo;
  /** 总数量 */
  totalCount: Scalars['Int'];
};

/** 历史天气记录edge */
export type HistoryWeatherEdge = {
  __typename?: 'HistoryWeatherEdge';
  /** 分页/connection中的游标 */
  cursor?: Maybe<Scalars['String']>;
  /** 记录 */
  node?: Maybe<HistoryWeather>;
};

export type HistoryWeathersFilter = {
  /** 气象观测点的uuid */
  PositionOfInterestUuid?: InputMaybe<Scalars['String']>;
  /** sql 片段方式（仅占位,暂不开启） */
  cond?: InputMaybe<Scalars['String']>;
};

export type HourlyForecastWeather = AccurateWeather & {
  __typename?: 'HourlyForecastWeather';
  /** 体感温度 摄氏度 */
  apparentTemperature?: Maybe<Scalars['Float']>;
  /** 云量 0-1 */
  cloudRate?: Maybe<Scalars['Float']>;
  /** 时间点 */
  datetime?: Maybe<Scalars['Time']>;
  /** 向下短波辐射通量 */
  dswrf?: Maybe<Scalars['Float']>;
  /** 湿度 0-1 */
  humidity?: Maybe<Scalars['Float']>;
  /** 降水量 mm/h */
  precipitation?: Maybe<Scalars['Float']>;
  /** 气压 Pa */
  pressure?: Maybe<Scalars['Float']>;
  /** 天气现象 */
  skyCondition?: Maybe<SkyCond>;
  /** 温度（摄氏度） */
  temperature?: Maybe<Scalars['Float']>;
  /** 能见度 km */
  visibility?: Maybe<Scalars['Float']>;
  /** 风向 （北起顺时针的度数） */
  windDirection?: Maybe<Scalars['Float']>;
  /** 风速 km/h */
  windSpeed?: Maybe<Scalars['Float']>;
};


export type HourlyForecastWeatherApparentTemperatureArgs = {
  unit?: InputMaybe<TemperatureUnit>;
};


export type HourlyForecastWeatherPressureArgs = {
  unit?: InputMaybe<PressureUnit>;
};


export type HourlyForecastWeatherTemperatureArgs = {
  unit?: InputMaybe<TemperatureUnit>;
};


export type HourlyForecastWeatherVisibilityArgs = {
  unit?: InputMaybe<LenghtUnit>;
};


export type HourlyForecastWeatherWindSpeedArgs = {
  unit?: InputMaybe<SpeedUnit>;
};

export type HourlyForecastWeatherConnection = {
  __typename?: 'HourlyForecastWeatherConnection';
  /** HourlyForecastWeather 数组片段 */
  edges: Array<Maybe<HourlyForecastWeatherEdge>>;
  /** 直接获取元素 */
  nodes: Array<Maybe<HourlyForecastWeather>>;
  /** 分页/connection信息 */
  pageInfo: PageInfo;
  /** 总数量 */
  totalCount: Scalars['Int'];
};

/** edge */
export type HourlyForecastWeatherEdge = {
  __typename?: 'HourlyForecastWeatherEdge';
  /** 分页/connection中的游标 */
  cursor?: Maybe<Scalars['String']>;
  /** 记录 */
  node?: Maybe<HourlyForecastWeather>;
};

export type InaccurateAiPlot = {
  __typename?: 'InaccurateAiPlot';
  aiPlot?: Maybe<AiPlot>;
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  uuid: Scalars['String'];
};

export const enum Index {
  Gndvi = 'GNDVI',
  Lci = 'LCI',
  Ndre = 'NDRE',
  Ndvi = 'NDVI',
  Osavi = 'OSAVI'
};

/** 渐进显示 */
export type Interlace = {
  value?: InputMaybe<Scalars['Int']>;
};

export const enum Internal {
  /** 24小时 */
  Day = 'DAY',
  /** 一小时 */
  Hour = 'HOUR'
};

export type InvalidInfo = {
  __typename?: 'InvalidInfo';
  /** 飞行高度未达标的面积（单位0.01亩） */
  invalidFusionHeightWorkArea: Scalars['Int'];
  /** 时间段不对的面积（单位0.01亩） */
  invalidLocalTimeWorkArea: Scalars['Int'];
  /** 飞行速度未达标的面积（单位0.01亩） */
  invalidMaxSpeedWorkArea: Scalars['Int'];
  /** 降雨超标的面积（单位0.01亩） */
  invalidRailfallWorkArea: Scalars['Int'];
  /** 亩播撒量未达标的面积（单位0.01亩） */
  invalidSowUsagePerMuWorkArea: Scalars['Int'];
  /** 亩用药量未达标的面积（单位0.01亩） */
  invalidSprayUsagePerMuWorkArea: Scalars['Int'];
  /** 行间距未达标的面积（单位0.01亩） */
  invalidSprayWidthWorkArea: Scalars['Int'];
  /** 温度超标的面积（单位0.01亩） */
  invalidTemperatureWorkArea: Scalars['Int'];
  /** 风力超标的面积（单位0.01亩） */
  invalidWindSpeedWorkArea: Scalars['Int'];
};

export const enum JobType {
  Agras = 'Agras',
  AgroCloud = 'AgroCloud',
  Terra = 'Terra'
};

export type JoinSharingCoveragesPlanInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type JoinSharingCoveragesPlanPayload = {
  __typename?: 'JoinSharingCoveragesPlanPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Land = {
  __typename?: 'Land';
  area?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Time']>;
  /** 部门链，顺序从底层到顶层 */
  departmentLinks?: Maybe<Array<Maybe<Department>>>;
  externalId?: Maybe<Scalars['String']>;
  externalType?: Maybe<ExternalType>;
  flightRecords?: Maybe<FlightRecordConnection>;
  geo?: Maybe<Scalars['GeoJSON']>;
  id?: Maybe<Scalars['ID']>;
  /**
   * 该地块是否有违规飞行记录。有则为true，没有则为false，该地块没有飞行记录、找不到任务、任务没有配置的情况下则为空。
   * 必须指定地块用哪个任务的配置来进行比较
   */
  invalid?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Position>;
  realArea?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Time']>;
  uuid?: Maybe<Scalars['String']>;
};


export type LandAreaArgs = {
  unit: AreaUnit;
};


export type LandFlightRecordsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FlightRecordsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type LandInvalidArgs = {
  taskId: Scalars['ID'];
};


export type LandRealAreaArgs = {
  unit: AreaUnit;
};

export type LandAttributes = {
  /** 地块面积 */
  area: Scalars['Float'];
  /** 地块面积单位 */
  areaUnit: AreaUnit;
  /** 地块所属的外部系统的id（非uuid） */
  externalId: Scalars['String'];
  /** 地块所属的外部系统的类型 */
  externalType: ExternalType;
  /** 地块的地理位置信息GeoJSON字符串，目前只支持无空洞多边形 */
  geo: Scalars['GeoJSON'];
  /** 地块名字 */
  name: Scalars['String'];
  /** 地块的位置 */
  position: PositionAttributes;
};

export type LandCluster = {
  __typename?: 'LandCluster';
  /** 聚集的 bounding box */
  bbox?: Maybe<BoundingBox>;
  /** 聚集中心点 */
  center?: Maybe<Position>;
  /** 地块数量 */
  count: Scalars['Int'];
};

export type LandClusterEdge = {
  __typename?: 'LandClusterEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<LandCluster>;
};

export type LandConnection = {
  __typename?: 'LandConnection';
  /** 所有地块组成的bbox */
  bbox?: Maybe<BoundingBox>;
  /**
   * 对地块的聚合(k分布)
   * zoomLevel默认为5，基本是中国地图的层级
   */
  cluster?: Maybe<LandClusterConnection>;
  edges: Array<Maybe<LandEdge>>;
  nodes: Array<Maybe<Land>>;
  pageInfo: PageInfo;
  totalArea: Scalars['Float'];
  totalCount: Scalars['Int'];
};


export type LandConnectionClusterArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  zoomLevel?: InputMaybe<Scalars['Int']>;
};


export type LandConnectionTotalAreaArgs = {
  unit: AreaUnit;
};

export type LandEdge = {
  __typename?: 'LandEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Land>;
};

export const enum LandField {
  Area = 'area',
  ExternalId = 'externalId',
  ExternalType = 'externalType',
  Geo = 'geo',
  Name = 'name',
  Position = 'position',
  RealArea = 'realArea'
};

export type LandsFilter = {
  /** 查地理位置被该boundingbox相交的地块 */
  bbox?: InputMaybe<BoundingBoxAttributes>;
  /** 地块直接挂靠的部门的uuid */
  directDepartmentUuid?: InputMaybe<Scalars['String']>;
  /** 查该地块关联的实体id列表（非uuid） */
  externalIds?: InputMaybe<Array<Scalars['String']>>;
  externalType?: InputMaybe<ExternalType>;
  /** 是否直属本部门(null或者true都表示会查询本部门，当前仅当为false时是查询包括子部门；兼容线上要求) */
  isDirectUnderDepart?: InputMaybe<Scalars['Boolean']>;
  /** 地块名字，模糊查询 */
  name?: InputMaybe<Scalars['String']>;
  /** 查询某个任务下的地块，任务和地块没有直接关联关系，只是通过任务的视口去查 */
  processingJobUuid?: InputMaybe<Scalars['String']>;
  /** 查地理位置被该boundingbox包含的地块 */
  within?: InputMaybe<BoundingBoxAttributes>;
};

export const enum LandsSort {
  /** 距离顺序 */
  DistanceAsc = 'DISTANCE_ASC',
  /** 距离逆序 */
  DistanceDesc = 'DISTANCE_DESC',
  /** 更新时间顺序 */
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  /** 更新时间逆序 */
  UpdatedAtDesc = 'UPDATED_AT_DESC'
};

/** 长度单位 */
export const enum LenghtUnit {
  /** 千米 */
  Kilometre = 'KILOMETRE',
  /** 米 */
  Metre = 'METRE',
  /** 英里 */
  Mile = 'MILE'
};

export type LocalTime = {
  __typename?: 'LocalTime';
  /**
   * 时间字符串，格式为HH:mm[:ss[.SSS]]，如14:25、14:25:06、14:25:06.123
   * 注意请遵循RFC3339标准，不允许传24:00
   */
  timeString: Scalars['String'];
  /** 协调世界时UTC相对于当前时区的时间差值，单位为分钟 */
  timezoneOffset: Scalars['Int'];
};

export type LocalTimeInput = {
  /**
   * 时间字符串，格式为HH:mm[:ss]，如14:25、14:25:06
   * 一般就是传时分
   * 注意请遵循RFC3339标准，不允许传24:00
   */
  timeString: Scalars['String'];
  /**
   * 协调世界时UTC相对于当前时区的时间差值，单位为分钟
   * 例如对于东八区时间，该值为-480
   */
  timezoneOffset: Scalars['Int'];
};

export type LocalTimeRange = {
  __typename?: 'LocalTimeRange';
  beginLocalTime: LocalTime;
  endLocalTime: LocalTime;
};

export type LocalTimeRangeInput = {
  beginLocalTime: LocalTimeInput;
  endLocalTime: LocalTimeInput;
};

export type Map = {
  __typename?: 'Map';
  createdAt?: Maybe<Scalars['Time']>;
  /** bounding box左下角坐标 */
  downLeft?: Maybe<Position>;
  /** 处理任务id */
  id?: Maybe<Scalars['ID']>;
  /** 最大缩放层级 (null表示没有初始值) */
  maxZoomLevel?: Maybe<Scalars['Int']>;
  /** 最小缩放层级 (null表示没有初始值) */
  minZoomLevel?: Maybe<Scalars['Int']>;
  /** 拥有者ID */
  ownerId?: Maybe<Scalars['ID']>;
  /** resource uuid */
  resourceUuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Time']>;
  /** bounding box右上角坐标 */
  upperRight?: Maybe<Position>;
  uuid?: Maybe<Scalars['String']>;
};

export type MapAttributes = {
  departmentUuid?: InputMaybe<Scalars['String']>;
  organizationUuid?: InputMaybe<Scalars['String']>;
};

export type MapEdge = {
  __typename?: 'MapEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Map>;
};

export type MapReport = {
  __typename?: 'MapReport';
  CoveredArea: Scalars['Float'];
  DensifyLevel: Scalars['Float'];
  DensifyTime: Scalars['Float'];
  DomScreennailSize: Array<Scalars['Int']>;
  DsmScreennailSize: Array<Scalars['Int']>;
  EnableCUDA: Scalars['Int'];
  FlyAltitude: Scalars['Float'];
  GCJTiles: Scalars['Boolean'];
  GpsCorner: GpsCorner;
  MapMode: Scalars['String'];
  MapTile: MapTile;
  OverlapRenderSize: Array<Scalars['Int']>;
  QualityLevel: Scalars['Int'];
  TDOMGSD: Scalars['Float'];
  TDOMGenerateTime: Scalars['Float'];
};

export type MapTile = {
  __typename?: 'MapTile';
  MaximumLevel: Scalars['Int'];
  MinimumLevel: Scalars['Int'];
};

export type Mapping = {
  __typename?: 'Mapping';
  /** 是否是用来做自动重建的照片库 */
  auto: Scalars['Boolean'];
  /** 该照片库下的自动重建任务 */
  autoProcessingJob?: Maybe<ProcessingJob>;
  /** 拍摄时间，如果没照片那就是空 */
  captureTime?: Maybe<Scalars['Time']>;
  createdAt?: Maybe<Scalars['Time']>;
  /** 该图片库所属的部门 */
  department?: Maybe<Department>;
  /** 部门链，顺序从底层到顶层 */
  departmentLinks?: Maybe<Array<Maybe<Department>>>;
  /** 左下角坐标 */
  downLeft?: Maybe<Position>;
  /** 测绘在外部系统中的id */
  externalId?: Maybe<Scalars['String']>;
  /** 测绘所属文件夹的Uuid */
  folderInfos?: Maybe<Scalars['String']>;
  /** 这个照片库是否只需要存多光谱重建NDVI波段所需照片 */
  forNDVI: Scalars['Boolean'];
  /** 测绘ID */
  id?: Maybe<Scalars['ID']>;
  /** 该照片库允许重建的多光谱indexlist */
  indexList: Array<Index>;
  /**
   * 该测绘用于创建什么任务，0代表农田模式 1代表城市模式（即大田模式） 2代表果树模式 4代表多光谱模式 5代表巡田模式 默认为0
   *
   * 该状态其实只是为了让前端限制创建任务的类型以及校验哪些文件，这是前端创建mapping时候传入的值
   */
  jobType?: Maybe<Scalars['Int']>;
  /** 测绘名字 */
  name?: Maybe<Scalars['String']>;
  /** 测绘所属的owner的id */
  ownerId?: Maybe<Scalars['ID']>;
  /** 中心位置坐标, wgs84坐标系 */
  position?: Maybe<Position>;
  /** 备注 */
  remark?: Maybe<Scalars['String']>;
  /** 测绘拥有的源文件。可以选择是jpg还是tif，如果不传则默认是所有 */
  sourceImages?: Maybe<SourceImagesConnection>;
  /** 开V2接口，用于专门筛选分组的jpg，必须传图片类型 */
  sourceImagesV2?: Maybe<SourceImagesConnectionV2>;
  updatedAt?: Maybe<Scalars['Time']>;
  /**
   * 是否需要把重建状态通知给用户
   * 上传图片的瞬时状态
   */
  uploadStatus: UploadStatus;
  /** 右上角坐标, wgs84坐标系 */
  upperRight?: Maybe<Position>;
  /** 测绘在terra-cloud中的uuid */
  uuid?: Maybe<Scalars['String']>;
  /**
   * 检查某个mapping下的图片是否可以重建
   * 类型只支持0, 1, 2, 4
   * 如果传入的是多光谱类型（4），则可传入想重建的indexList，只会校验这些list
   */
  validStartProcessingJob?: Maybe<ValidStartProcessingJobResult>;
};


export type MappingSourceImagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SourceImagesFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
};


export type MappingSourceImagesV2Args = {
  after?: InputMaybe<Scalars['String']>;
  filter: SourceImagesFilterV2;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
};


export type MappingValidStartProcessingJobArgs = {
  indexList?: InputMaybe<Array<Index>>;
  jobType: Scalars['Int'];
};

export type MappingAttributes = {
  /** 所属部门的uuid */
  departmentUuid: Scalars['String'];
  forNDVI?: Scalars['Boolean'];
  /** 这次测绘是用于什么job类型的, 0代表农田模式 1代表城市模式 2代表果树模式 4代表多光谱模式 5为重建处方图 默认为0 */
  jobType: Scalars['Int'];
  name: Scalars['String'];
  /** 备注 */
  remark?: InputMaybe<Scalars['String']>;
};

export type MappingEdge = {
  __typename?: 'MappingEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Mapping>;
};

export type MappingPayload = {
  __typename?: 'MappingPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  mapping?: Maybe<Mapping>;
};

export type MappingStatus = {
  __typename?: 'MappingStatus';
  /** 是否是用来做自动重建的照片库 */
  auto: Scalars['Boolean'];
  /** 上传图片的瞬时状态 */
  uploadStatus: UploadStatus;
};

export type MappingsConnection = {
  __typename?: 'MappingsConnection';
  nodes: Array<Maybe<Mapping>>;
  pageInfo?: Maybe<PageInfoNew>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MappingsFilter = {
  /** 根据视口查询，请传入wgs84坐标系的视口 */
  bbox?: InputMaybe<BoundingBoxAttributes>;
  /** 根据部门的uuid列表进行筛选 */
  departmentUuids?: InputMaybe<Array<Scalars['String']>>;
  endTime?: InputMaybe<Scalars['Time']>;
  /** 根据名字筛选 */
  name?: InputMaybe<Scalars['String']>;
  /** 根据备注筛选 */
  remark?: InputMaybe<Scalars['String']>;
  /** 根据创建时间范围筛选 */
  startTime?: InputMaybe<Scalars['Time']>;
  /**
   * 根据类型筛选，可以传多个
   *
   * 0代表农田模式 1代表城市模式（即大田模式） 2代表果树模式 4代表多光谱模式
   */
  type?: InputMaybe<Array<Scalars['Int']>>;
};

export type MapsConnection = {
  __typename?: 'MapsConnection';
  edges: Array<Maybe<MapEdge>>;
  /** 直接取现元素 */
  nodes: Array<Maybe<Map>>;
  pageInfo?: Maybe<PageInfoNew>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MaxMinAvg = {
  __typename?: 'MaxMinAvg';
  avg?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type MediaCreateStsInput = {
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MediaFastUploadInput = {
  fastUploadInput?: InputMaybe<FastUploadInput>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MediaFile = {
  droneModelKey?: InputMaybe<Scalars['String']>;
  isOriginal?: InputMaybe<Scalars['Boolean']>;
  missionId?: InputMaybe<Scalars['String']>;
  payloadModelKey?: InputMaybe<Scalars['String']>;
  sn?: InputMaybe<Scalars['String']>;
  tinnyFingerprint?: InputMaybe<Scalars['String']>;
  waylineTaskId?: InputMaybe<Scalars['String']>;
};

export type MediaFingerprints = {
  __typename?: 'MediaFingerprints';
  tinyFingerprints: Array<Scalars['String']>;
};

export type MediaFingerprintsInput = {
  tinyFingerprints?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MediaFolderFinishUploadAttributes = {
  ext?: InputMaybe<MediaFile>;
  fileCount?: InputMaybe<Scalars['Int']>;
  fileUploadedCount?: InputMaybe<Scalars['Int']>;
  path?: InputMaybe<Scalars['String']>;
  waylineTaskId?: InputMaybe<Scalars['String']>;
};

export type MediaFolderFinishUploadInput = {
  folderFinishUpload?: InputMaybe<MediaFolderFinishUploadAttributes>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MediaSts = {
  __typename?: 'MediaSts';
  bucket?: Maybe<Scalars['String']>;
  credentials?: Maybe<Credentials>;
  endpoint?: Maybe<Scalars['String']>;
  objectKeyPrefix?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type MediaUploadCallbackInput = {
  uploadCallbackInput?: InputMaybe<UploadCallbackAttributes>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

/** 成员；组织关系模块中业务模型 */
export type Member = {
  __typename?: 'Member';
  /** !!优化路径；权限生效所在部门（苏垦需求是一级部门） */
  RoleMemberDepartment?: Maybe<MemberDepartment>;
  /** !!优化路径；可以访问的部门 */
  accessibleDepartments?: Maybe<DepartmentConnection>;
  /** !!优化路径；所在部门 */
  departments?: Maybe<DepartmentConnection>;
  /** 成员外部系统中的openid */
  externalId?: Maybe<Scalars['String']>;
  /** 外部系统/模块中的成员type */
  externalType?: Maybe<Scalars['String']>;
  /** 系统中的成员id */
  id?: Maybe<Scalars['ID']>;
  /** 所属部门 */
  membersDepartments?: Maybe<MemberDepartmentConnection>;
  /** 所属机构 */
  organization?: Maybe<Organization>;
  /** 所属机构的ID */
  organizationId?: Maybe<Scalars['ID']>;
  /** 组织角色权限（0-普通；1-超管） */
  organizationRoleId: Scalars['Int'];
  /** 账号 */
  user?: Maybe<User>;
  /** 系统中的成员uuid */
  uuid?: Maybe<Scalars['String']>;
};


/** 成员；组织关系模块中业务模型 */
export type MemberAccessibleDepartmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DepartmentsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


/** 成员；组织关系模块中业务模型 */
export type MemberDepartmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


/** 成员；组织关系模块中业务模型 */
export type MemberMembersDepartmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

/** 成员的属性 */
export type MemberAttributes = {
  /** 外部成员类型 */
  externalId?: InputMaybe<Scalars['String']>;
  /** 所属组织/集团ID */
  organizationId?: InputMaybe<Scalars['ID']>;
  /** 组织/集团权限 */
  organizationRoleId?: InputMaybe<Scalars['Int']>;
  /** 成员类型 */
  type?: InputMaybe<Scalars['String']>;
};

/** 组织机构connection */
export type MemberConnection = {
  __typename?: 'MemberConnection';
  /** Member数组片段 */
  edges: Array<Maybe<MemberEdge>>;
  /** 直接获取元素 */
  nodes: Array<Maybe<Member>>;
  /** 分页/connection信息 */
  pageInfo: PageInfo;
  /** 总数量 */
  totalCount: Scalars['Int'];
};

/** 成员/组织关系模块中业务模型 */
export type MemberDepartment = {
  __typename?: 'MemberDepartment';
  /** 部门 */
  department?: Maybe<Department>;
  /** 所属部门的ID */
  departmentId: Scalars['ID'];
  /** 系统中的id */
  id?: Maybe<Scalars['ID']>;
  /** 成员 */
  member?: Maybe<Member>;
  /** 成员id */
  memberId: Scalars['ID'];
  /** 角色(0-普通；1-管理员) */
  role: Scalars['Int'];
  /** uuid */
  uuid?: Maybe<Scalars['String']>;
};

/** 成员/部门属性 */
export type MemberDepartmentAttributes = {
  /** 部门id */
  departmentId?: InputMaybe<Scalars['ID']>;
  /** 成员id */
  memberId?: InputMaybe<Scalars['ID']>;
  /** 角色id */
  role?: InputMaybe<Scalars['Int']>;
};

/** 成员/组织机构connection */
export type MemberDepartmentConnection = {
  __typename?: 'MemberDepartmentConnection';
  /** MemberDepartment数组片段 */
  edges: Array<Maybe<MemberDepartmentEdge>>;
  /** 直接获取元素 */
  nodes: Array<Maybe<MemberDepartment>>;
  /** 分页/connection信息 */
  pageInfo: PageInfo;
  /** 总数量 */
  totalCount: Scalars['Int'];
};

/** 成员/组织机构edge */
export type MemberDepartmentEdge = {
  __typename?: 'MemberDepartmentEdge';
  /** 分页/connection中的游标 */
  cursor?: Maybe<Scalars['String']>;
  /** 记录 */
  node?: Maybe<MemberDepartment>;
};

/** 创建member/Department选项 */
export type MemberDepartmentOption = {
  /** 是否自动link上一级部门关系 */
  autoLinkParentDepartment?: InputMaybe<Scalars['Boolean']>;
  /** 末端部门id */
  departmentId?: InputMaybe<Scalars['ID']>;
  /** 归属集团的ID */
  organizationId?: InputMaybe<Scalars['ID']>;
  /** 超管权限设置（0-普通， 1-超管） */
  organizationRoleId?: InputMaybe<Scalars['Int']>;
  /** 各级部门的role（大小限制<10） */
  roles?: InputMaybe<Array<InputMaybe<DepartmentRole>>>;
};

/** 组织机构edge */
export type MemberEdge = {
  __typename?: 'MemberEdge';
  /** 分页/connection中的游标 */
  cursor?: Maybe<Scalars['String']>;
  /** 记录 */
  node?: Maybe<Member>;
};

export type MembersFilter = {
  /** sql 片段方式（仅占位,暂不开启） */
  cond?: InputMaybe<Scalars['String']>;
  /** 是否直属集团的成员 */
  isDirectUnderOrg?: InputMaybe<Scalars['Boolean']>;
  organizationUuId?: InputMaybe<Scalars['String']>;
  /** user的name或者phone */
  userKeyword?: InputMaybe<Scalars['String']>;
};

export type MergeJob = {
  __typename?: 'MergeJob';
  createdAt?: Maybe<Scalars['Time']>;
  djiServiceUuid?: Maybe<Scalars['String']>;
  /** 处理任务id */
  id?: Maybe<Scalars['ID']>;
  mapId?: Maybe<Scalars['ID']>;
  planMergeAt?: Maybe<Scalars['Time']>;
  processingJob?: Maybe<ProcessingJob>;
  processingJobId?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Time']>;
  uuid?: Maybe<Scalars['String']>;
};

export type MergeJobAttributes = {
  /** 大地图的uuid */
  mapUuid?: InputMaybe<Scalars['String']>;
  /** 重建任务的uuid */
  processingJobUuid?: InputMaybe<Scalars['String']>;
};

export type MergeJobEdge = {
  __typename?: 'MergeJobEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<MergeJob>;
};

export type MergeJobsConnection = {
  __typename?: 'MergeJobsConnection';
  edges: Array<Maybe<MergeJobEdge>>;
  /** 直接取现元素 */
  nodes: Array<Maybe<MergeJob>>;
  pageInfo?: Maybe<PageInfoNew>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MetaData = {
  absoluteAltitude?: InputMaybe<Scalars['Float']>;
  createdTime?: InputMaybe<Scalars['String']>;
  gimbalYawDegree?: InputMaybe<Scalars['Float']>;
  photoedPosition?: InputMaybe<Position2>;
  relativeAltitude?: InputMaybe<Scalars['Float']>;
  shootPosition?: InputMaybe<Position2>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** 把标签加到某个资源中 */
  addTag?: Maybe<AddTagPayload>;
  /** 更新一个资源的标签 */
  attachTags?: Maybe<AttachTagsPayload>;
  /** 开启重建处方图任务 */
  createAndStartNdviJob?: Maybe<CreateAndStartNdviJobPayload>;
  /** 创建申请 */
  createApplicationForm?: Maybe<CreateApplicationFormPayload>;
  /** 创建自动重建任务 */
  createAutoProcessingJob?: Maybe<CreateAutoProcessingJobPayload>;
  /** 创建部门 */
  createDepartment: CreateDepartmentPayload;
  /** 添加设备 */
  createDevice: CreateDevicePayload;
  /** 创建估产任务 */
  createEstimationJob?: Maybe<CreateEstimationJobPayload>;
  /** 增加农场边界/电子围栏 */
  createFence: CreateFencePayload;
  /** 上传第三方处方图 */
  createFertilizer: CreateFertilizerPayload;
  /** 分享处方图 */
  createFertilizerShare: CreateFertilizerSharePayload;
  /** 创建航线 */
  createFlyWaypointList?: Maybe<CreateFlyWaypointListPayload>;
  /** 创建自由航线的手动规划线 */
  createFreePlot?: Maybe<CreateFreePlotPayload>;
  /** 标注不准确的ai地块 */
  createInaccurateAiPlot?: Maybe<CreateInaccurateAiPlotPayload>;
  /** 创建地块 */
  createLand: CreateLandPayload;
  /** 创建大地图 */
  createMap?: Maybe<CreateMapPayload>;
  /** 创建照片库 */
  createMapping?: Maybe<MappingPayload>;
  /** 创建成员 */
  createMember: CreateMemberPayload;
  /** 创建成员/部门 */
  createMemberDepartment: CreateMemberDepartmentPayload;
  /** 创建合并瓦片请求 */
  createMergeJob?: Maybe<CreateMergeJobPayload>;
  /** 创建或修改作业参数 */
  createOrUpdateConfiguration: CreateOrUpdateConfigurationPayload;
  /** 创建组织 */
  createOrganization: CreateOrganizationPayload;
  createPermission?: Maybe<CreatePermissionPayload>;
  /** 创建测绘地块 */
  createPlot?: Maybe<CreatePlotPayload>;
  /** 创建巡田 */
  createPlotPatrol?: Maybe<CreatePlotPatrolPayload>;
  /** 创建巡田备注 */
  createPlotPatrolsSourceImage?: Maybe<CreatePlotPatrolsSourceImagePayload>;
  /** 增加气象观测点 */
  createPositionOfInterest: CreatePositionOfInterestPayload;
  /** 创建任务，可以是农田模式、城市模式（巡田、大田）、多光谱模式、果树模式。不同模式体现在params中的map_mode（map mode即job type） */
  createProcessingJob?: Maybe<CreateProcessingJobPayload>;
  /** 创建瓦片授权 */
  createProcessingJobAuth?: Maybe<CreateProcessingJobAuthPayload>;
  /** 创建一个重建任务图片 */
  createProcessingJobSourceImage?: Maybe<CreateProcessingJobSourceImagePayload>;
  /** 创建出苗分析任务 */
  createSeedlingJob?: Maybe<CreateSeedlingJobPayload>;
  /** 创建图片，可一次创建多张 */
  createSourceImages?: Maybe<CreateSourceImagesPayload>;
  /** 创建sts token */
  createSts?: Maybe<CreateStsPayload>;
  createTag?: Maybe<CreateTagPayload>;
  /** 创建作业 */
  createTask: CreateTaskPayload;
  /** 技术支持 */
  createTechFeedback?: Maybe<CreateTechFeedbackPayload>;
  /** 更新果树的树心点 */
  createTreeInfo?: Maybe<CreateTreeInfoPayload>;
  /** 创建用户 */
  createUser: CreateUserPayload;
  /** 删除设备 */
  deleteDevice: DeleteDevicePayload;
  /** 删除估产任务 */
  deleteEstimationJob?: Maybe<DeleteEstimationJobPayload>;
  /** 删除自由航线的手动规划线 */
  deleteFreePlot?: Maybe<DeleteFreePlotPayload>;
  /** 删除照片库 */
  deleteMapping?: Maybe<DeleteMappingPayload>;
  /** 删除瓦片合并请求 */
  deleteMergeJob?: Maybe<DeleteMergeJobPayload>;
  deletePermission?: Maybe<DeletePermissionPayload>;
  /** 删除测绘地块 */
  deletePlot?: Maybe<DeletePlotPayload>;
  /** 删除巡田 */
  deletePlotPatrol?: Maybe<DeletePlotPatrolPayload>;
  /** 删除巡田备注 */
  deletePlotPatrolsSourceImage?: Maybe<DeletePlotPatrolsSourceImagePayload>;
  /** 删除任务 */
  deleteProcessingJob?: Maybe<DeleteProcessingJobPayload>;
  deleteProcessingJobSourceImage?: Maybe<DeleteProcessingJobSourceImagePayload>;
  /** 删除出苗分析任务 */
  deleteSeedlingJob?: Maybe<DeleteSeedlingJobPayload>;
  /** 批量删除原图，请提供文件夹（mapping）的uuid */
  deleteSourceImages?: Maybe<DeleteSourceImagesPayload>;
  deleteTag?: Maybe<DeleteTagPayload>;
  /** 删除作业 */
  deleteTask: DeleteTaskPayload;
  /** 删除部门 */
  destroyDepartment: DestroyDepartmentPayload;
  /** 删除农场边界/电子围栏 */
  destroyFence: DestroyFencePayload;
  /** 删除第三方处方图 */
  destroyFertilizer: DestroyFertilizerPayload;
  /** 删除地块 */
  destroyLand: DestroyLandPayload;
  /** 删除成员 */
  destroyMember: DestroyMemberPayload;
  /** 删除成员/部门 */
  destroyMemberDepartment: DestroyMemberDepartmentPayload;
  /** 删除处方图任务 */
  destroyNdviJob?: Maybe<DestroyNdviJobPayload>;
  /** 删除组织 */
  destroyOrganization: DestroyOrganizationPayload;
  /** 删除气象观测点 */
  destroyPositionOfInterest: DestroyPositionOfInterestPayload;
  /** 删除瓦片授权 */
  destroyProcessingJobAuth?: Maybe<DeleteProcessingJobAuthPayload>;
  destroyRemoteControllerProcessingJob?: Maybe<DestroyRemoteControllerProcessingJobPayload>;
  /** 删除用户 */
  destroyUser: DestroyUserPayload;
  /** 下载处方图,是个zip包 */
  downloadNdviFertilizer?: Maybe<DownloadNdviFertilizerPayload>;
  /** 导出作业 */
  exportTask: ExportTaskPayload;
  finishRemoteControllerProcessingJob?: Maybe<FinishRemoteControllerProcessingJobPayload>;
  /**
   * 把语义图生成一个geoserver图层
   * 会根据segment.tif的md5做去重处理
   */
  generateSegmentCoverage?: Maybe<GenerateSegmentCoveragePayload>;
  /** 请求设置代理商姓名和电话的验证码 */
  getAgentInfoCode?: Maybe<GetAgentInfoCodePayload>;
  /** 加入高清地图共享计划 */
  joinSharingCoveragesPlan?: Maybe<JoinSharingCoveragesPlanPayload>;
  /** pilot media 相关API */
  mediaCreateSts?: Maybe<MediaSts>;
  mediaFastUpload?: Maybe<Scalars['EmptyHash']>;
  mediaFingerprints?: Maybe<MediaFingerprints>;
  mediaFolderFinishUpload?: Maybe<Scalars['EmptyHash']>;
  mediaUploadCallback?: Maybe<Scalars['String']>;
  notifyPermission?: Maybe<NotifyPermissionPayload>;
  /** 创建pilot app token */
  pilotUserCreateAuthToken?: Maybe<Scalars['String']>;
  /** 赠送个人版权限 */
  pilotUserCreatePersonalVersionLicense?: Maybe<PersonalLicense>;
  /** 分享重建任务上的地块给农服用户 */
  plotsShare?: Maybe<PlotsSharePayload>;
  /** 退出高清地图共享计划 */
  quitSharingCoveragesPlan?: Maybe<QuitSharingCoveragesPlanPayload>;
  /** 把标签从某个资源中移除 */
  removeTag?: Maybe<RemoveTagPayload>;
  /**
   * 运行航线生成算法
   * 该uuid可以传地块或者自由规划的航线的uuid
   * 既可以传plot也可以传freePlot
   */
  runPathPlanning?: Maybe<RunPathPlanningPayload>;
  /** 对重建任务的NDVI.tif使用最新的语义图进行一次降采样 */
  sampleNDVI?: Maybe<SampleNdviPayload>;
  /** 设置代理商姓名和电话 */
  setAgentInfo?: Maybe<SetAgentInfoPayload>;
  /** 开启ai地块识别 */
  startAiPlotJob?: Maybe<StartAiPlotJobPayload>;
  /** 开启自动重建任务 */
  startAutoProcessingJob?: Maybe<StartAutoProcessingJobPayload>;
  /** 对某个任务开启djitile的打包 */
  startCompressionJobForDjitile?: Maybe<StartCompressionJobForDjitilePayload>;
  /** 开启估产任务 */
  startEstimationJob?: Maybe<StartEstimationJobPayload>;
  startMediaFileUpload?: Maybe<Scalars['EmptyHash']>;
  /** 开始瓦片合并任务 */
  startMergeJob?: Maybe<StartMergeJobPayload>;
  /** 开启任务 */
  startProcessingJob?: Maybe<StartProcessingJobPayload>;
  /** 开启出苗分析任务 */
  startSeedlingJob?: Maybe<StartSeedlingJobPayload>;
  syncCallBack?: Maybe<SyncCallbackPayload>;
  /** 把条田land同步为plot */
  syncLandsToPlots?: Maybe<SyncLandsToPlotsPayload>;
  /** 遥控器同步本地重建 （个人版专用） */
  syncRemoteControllerProcessingJob?: Maybe<SyncRemoteControllerProcessingJobPayload>;
  /** 更新部门 */
  updateDepartment: UpdateDepartmentPayload;
  /** 修改设备 */
  updateDevice: UpdateDevicePayload;
  /** 修改估产任务 */
  updateEstimationJob?: Maybe<UpdateEstimationJobPayload>;
  /** 更新农场边界/电子围栏 */
  updateFence: UpdateFencePayload;
  /** 更新第三方处方图 */
  updateFertilizer: UpdateFertilizerPayload;
  /** 更新自由航线的手动规划线 */
  updateFreePlot?: Maybe<UpdateFreePlotPayload>;
  /** 更新地块 */
  updateLand: UpdateLandPayload;
  /** 修改照片库 */
  updateMapping?: Maybe<MappingPayload>;
  /** 更新成员 */
  updateMember: UpdateMemberPayload;
  /** 更新成员/部门 */
  updateMemberDepartment: UpdateMemberDepartmentPayload;
  /** 更新瓦片顺序请求 */
  updateMergeJob?: Maybe<UpdateMergeJobPayload>;
  /** 更新组织 */
  updateOrganization: UpdateOrganizationPayload;
  updatePermission?: Maybe<UpdatePermissionPayload>;
  /** 更新测绘地块 */
  updatePlot?: Maybe<UpdatePlotPayload>;
  /** 修改巡田 */
  updatePlotPatrol?: Maybe<UpdatePlotPatrolPayload>;
  /** 修改巡田备注 */
  updatePlotPatrolsSourceImage?: Maybe<UpdatePlotPatrolsSourceImagePayload>;
  /** 更新气象观测点 */
  updatePositionOfInterest: UpdatePositionOfInterestPayload;
  /** 更新任务 */
  updateProcessingJob?: Maybe<UpdateProcessingJobPayload>;
  updateProcessingJobSourceImage?: Maybe<UpdateProcessingJobSourceImagePayload>;
  updateRemoteControllerProcessingJob?: Maybe<UpdateRemoteControllerProcessingJobPayload>;
  /** 修改出苗分析任务 */
  updateSeedlingJob?: Maybe<UpdateSeedlingJobPayload>;
  /** 更新语义图(异步) */
  updateSegment?: Maybe<UpdateSegmentPayload>;
  updateTag?: Maybe<UpdateTagPayload>;
  /** 创建作业 */
  updateTask: UpdateTaskPayload;
  /** 更新用户 */
  updateUser: UpdateUserPayload;
  /** 发送照片上传失败短信通知 */
  uploadFailedMessage?: Maybe<UploadFailedMessagePayload>;
  /** 客户端主动回调 */
  uploadStorageCallback?: Maybe<UploadStorageCallbackPayload>;
  validatePilotUser?: Maybe<PilotUser>;
};


export type MutationAddTagArgs = {
  input: AddTagInput;
};


export type MutationAttachTagsArgs = {
  input: AttachTagsInput;
};


export type MutationCreateAndStartNdviJobArgs = {
  input: CreateAndStartNdviJobInput;
};


export type MutationCreateApplicationFormArgs = {
  input: CreateApplicationFormInput;
};


export type MutationCreateAutoProcessingJobArgs = {
  input: CreateAutoProcessingJobInput;
};


export type MutationCreateDepartmentArgs = {
  input: CreateDepartmentInput;
};


export type MutationCreateDeviceArgs = {
  input: CreateDeviceInput;
};


export type MutationCreateEstimationJobArgs = {
  input: CreateEstimationJobInput;
};


export type MutationCreateFenceArgs = {
  input: CreateFenceInput;
};


export type MutationCreateFertilizerArgs = {
  input: CreateFertilizerInput;
};


export type MutationCreateFertilizerShareArgs = {
  input: CreateFertilizerShareInput;
};


export type MutationCreateFlyWaypointListArgs = {
  input: CreateFlyWaypointListInput;
};


export type MutationCreateFreePlotArgs = {
  input: CreateFreePlotInput;
};


export type MutationCreateInaccurateAiPlotArgs = {
  input: CreateInaccurateAiPlotInput;
};


export type MutationCreateLandArgs = {
  input: CreateLandInput;
};


export type MutationCreateMapArgs = {
  input: CreateMapInput;
};


export type MutationCreateMappingArgs = {
  input: CreateMappingInput;
};


export type MutationCreateMemberArgs = {
  input: CreateMemberInput;
};


export type MutationCreateMemberDepartmentArgs = {
  input: CreateMemberDepartmentInput;
};


export type MutationCreateMergeJobArgs = {
  input: CreateMergeJobInput;
};


export type MutationCreateOrUpdateConfigurationArgs = {
  input: CreateOrUpdateConfigurationInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationCreatePermissionArgs = {
  input: CreatePermissionInput;
};


export type MutationCreatePlotArgs = {
  input: CreatePlotInput;
};


export type MutationCreatePlotPatrolArgs = {
  input: CreatePlotPatrolInput;
};


export type MutationCreatePlotPatrolsSourceImageArgs = {
  input: CreatePlotPatrolsSourceImageInput;
};


export type MutationCreatePositionOfInterestArgs = {
  input: CreatePositionOfInterestInput;
};


export type MutationCreateProcessingJobArgs = {
  input: CreateProcessingJobInput;
};


export type MutationCreateProcessingJobAuthArgs = {
  input: CreateProcessingJobAuthInput;
};


export type MutationCreateProcessingJobSourceImageArgs = {
  input: CreateProcessingJobSourceImageInput;
};


export type MutationCreateSeedlingJobArgs = {
  input: CreateSeedlingJobInput;
};


export type MutationCreateSourceImagesArgs = {
  input: CreateSourceImagesInput;
};


export type MutationCreateStsArgs = {
  input: CreateStsInput;
};


export type MutationCreateTagArgs = {
  input: CreateTagInput;
};


export type MutationCreateTaskArgs = {
  input: CreateTaskInput;
};


export type MutationCreateTechFeedbackArgs = {
  input: CreateTechFeedbackInput;
};


export type MutationCreateTreeInfoArgs = {
  input: CreateTreeInfoInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteDeviceArgs = {
  input: DeleteDeviceInput;
};


export type MutationDeleteEstimationJobArgs = {
  input: DeleteEstimationJobInput;
};


export type MutationDeleteFreePlotArgs = {
  input: DeleteFreePlotInput;
};


export type MutationDeleteMappingArgs = {
  input: DeleteMappingInput;
};


export type MutationDeleteMergeJobArgs = {
  input: DeleteMergeJobInput;
};


export type MutationDeletePermissionArgs = {
  input: DeletePermissionInput;
};


export type MutationDeletePlotArgs = {
  input: DeletePlotInput;
};


export type MutationDeletePlotPatrolArgs = {
  input: DeletePlotPatrolInput;
};


export type MutationDeletePlotPatrolsSourceImageArgs = {
  input: DeletePlotPatrolsSourceImageInput;
};


export type MutationDeleteProcessingJobArgs = {
  input: DeleteProcessingJobInput;
};


export type MutationDeleteProcessingJobSourceImageArgs = {
  input: DeleteProcessingJobSourceImageInput;
};


export type MutationDeleteSeedlingJobArgs = {
  input: DeleteSeedlingJobInput;
};


export type MutationDeleteSourceImagesArgs = {
  input: DeleteSourceImagesInput;
};


export type MutationDeleteTagArgs = {
  input: DeleteTagInput;
};


export type MutationDeleteTaskArgs = {
  input: DeleteTaskInput;
};


export type MutationDestroyDepartmentArgs = {
  input: DestroyDepartmentInput;
};


export type MutationDestroyFenceArgs = {
  input: DestroyFenceInput;
};


export type MutationDestroyFertilizerArgs = {
  input: DestroyFenceInput;
};


export type MutationDestroyLandArgs = {
  input: DestroyLandInput;
};


export type MutationDestroyMemberArgs = {
  input: DestroyMemberInput;
};


export type MutationDestroyMemberDepartmentArgs = {
  input: DestroyMemberDepartmentInput;
};


export type MutationDestroyNdviJobArgs = {
  input: DestroyNdviJobInput;
};


export type MutationDestroyOrganizationArgs = {
  input: DestroyOrganizationInput;
};


export type MutationDestroyPositionOfInterestArgs = {
  input: DestroyPositionOfInterestInput;
};


export type MutationDestroyProcessingJobAuthArgs = {
  input: DeleteProcessingJobAuthInput;
};


export type MutationDestroyRemoteControllerProcessingJobArgs = {
  input: RemoteControllerProcessingJobExternalIdInput;
};


export type MutationDestroyUserArgs = {
  input: DestroyUserInput;
};


export type MutationDownloadNdviFertilizerArgs = {
  input: DownloadNdviFertilizerInput;
};


export type MutationExportTaskArgs = {
  input: ExportTaskInput;
};


export type MutationFinishRemoteControllerProcessingJobArgs = {
  input: RemoteControllerProcessingJobExternalIdInput;
};


export type MutationGenerateSegmentCoverageArgs = {
  input: GenerateSegmentCoverageInput;
};


export type MutationGetAgentInfoCodeArgs = {
  input: GetAgentInfoCodeInput;
};


export type MutationJoinSharingCoveragesPlanArgs = {
  input?: InputMaybe<JoinSharingCoveragesPlanInput>;
};


export type MutationMediaCreateStsArgs = {
  input: MediaCreateStsInput;
};


export type MutationMediaFastUploadArgs = {
  input: MediaFastUploadInput;
};


export type MutationMediaFingerprintsArgs = {
  input: MediaFingerprintsInput;
};


export type MutationMediaFolderFinishUploadArgs = {
  input: MediaFolderFinishUploadInput;
};


export type MutationMediaUploadCallbackArgs = {
  input: MediaUploadCallbackInput;
};


export type MutationNotifyPermissionArgs = {
  input: NotifyPermissionInput;
};


export type MutationPilotUserCreateAuthTokenArgs = {
  input: PilotUserCreateAuthTokenInput;
};


export type MutationPilotUserCreatePersonalVersionLicenseArgs = {
  input: PilotUserCreatePersonalVersionLicenseInput;
};


export type MutationPlotsShareArgs = {
  input: PlotsShareInput;
};


export type MutationQuitSharingCoveragesPlanArgs = {
  input?: InputMaybe<QuitSharingCoveragesPlanInput>;
};


export type MutationRemoveTagArgs = {
  input: RemoveTagInput;
};


export type MutationRunPathPlanningArgs = {
  async?: InputMaybe<Scalars['Boolean']>;
  input: RunPathPlanningInput;
};


export type MutationSampleNdviArgs = {
  input: SampleNdviInput;
};


export type MutationSetAgentInfoArgs = {
  input: SetAgentInfoInput;
};


export type MutationStartAiPlotJobArgs = {
  input: StartAiPlotJobInput;
};


export type MutationStartAutoProcessingJobArgs = {
  input: StartAutoProcessingJobInput;
};


export type MutationStartCompressionJobForDjitileArgs = {
  input: StartCompressionJobForDjitileInput;
};


export type MutationStartEstimationJobArgs = {
  input: StartEstimationJobInput;
};


export type MutationStartMediaFileUploadArgs = {
  input: StartMediaUploadInput;
};


export type MutationStartMergeJobArgs = {
  input: StartMergeJobInput;
};


export type MutationStartProcessingJobArgs = {
  input: StartProcessingJobInput;
};


export type MutationStartSeedlingJobArgs = {
  input: StartSeedlingJobInput;
};


export type MutationSyncCallBackArgs = {
  input: SyncCallbackInput;
};


export type MutationSyncLandsToPlotsArgs = {
  input: SyncLandsToPlots;
};


export type MutationSyncRemoteControllerProcessingJobArgs = {
  input: CreateRemoteControllerProcessingJob;
};


export type MutationUpdateDepartmentArgs = {
  input: UpdateDepartmentInput;
};


export type MutationUpdateDeviceArgs = {
  input: UpdateDeviceInput;
};


export type MutationUpdateEstimationJobArgs = {
  input: UpdateEstimationJobInput;
};


export type MutationUpdateFenceArgs = {
  input: UpdateFenceInput;
};


export type MutationUpdateFertilizerArgs = {
  input: UpdateFertilizerInput;
};


export type MutationUpdateFreePlotArgs = {
  input: UpdateFreePlotInput;
};


export type MutationUpdateLandArgs = {
  input: UpdateLandInput;
};


export type MutationUpdateMappingArgs = {
  input: UpdateMappingInput;
};


export type MutationUpdateMemberArgs = {
  input: UpdateMemberInput;
};


export type MutationUpdateMemberDepartmentArgs = {
  input: UpdateMemberDepartmentInput;
};


export type MutationUpdateMergeJobArgs = {
  input: UpdateMergeJobInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


export type MutationUpdatePermissionArgs = {
  input: UpdatePermissionInput;
};


export type MutationUpdatePlotArgs = {
  input: UpdatePlotInput;
};


export type MutationUpdatePlotPatrolArgs = {
  input: UpdatePlotPatrolInput;
};


export type MutationUpdatePlotPatrolsSourceImageArgs = {
  input: UpdatePlotPatrolsSourceImageInput;
};


export type MutationUpdatePositionOfInterestArgs = {
  input: UpdatePositionOfInterestInput;
};


export type MutationUpdateProcessingJobArgs = {
  input: UpdateProcessingJobInput;
};


export type MutationUpdateProcessingJobSourceImageArgs = {
  input: UpdateProcessingJobSourceImageInput;
};


export type MutationUpdateRemoteControllerProcessingJobArgs = {
  input: UpdateRemoteControllerProcessingJobInput;
};


export type MutationUpdateSeedlingJobArgs = {
  input: UpdateSeedlingJobInput;
};


export type MutationUpdateSegmentArgs = {
  input: UpdateSegmentInput;
};


export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
};


export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUploadFailedMessageArgs = {
  input: UploadFailedMessageInput;
};


export type MutationUploadStorageCallbackArgs = {
  input: UploadStorageCallbackInput;
};


export type MutationValidatePilotUserArgs = {
  account: Scalars['String'];
};

export type NdviJob = Patrol & TaggableI & {
  __typename?: 'NdviJob';
  /** 也就是createdAt */
  captureTime?: Maybe<Scalars['Time']>;
  createdAt?: Maybe<Scalars['Time']>;
  /** 在dji service云端重建中的uuid */
  djiServiceUuid?: Maybe<Scalars['String']>;
  /** bounding box左下角坐标 */
  downLeft?: Maybe<Position>;
  fertilizerMaxValue?: Maybe<Scalars['Float']>;
  fertilizerMinValue?: Maybe<Scalars['Float']>;
  /**
   * 取这个job完成后的某些文件的url，传入文件名
   * 只允许segment.tif segment.tfw segmentAPI.json fertilizer_detailed.tfw fertilizer_detailed.tif fertilizer.tfw fertilizer.tif gsddsm.tif gsddsm.tfw
   */
  fileUrls: Array<FileUrl>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  outputResourceUuid?: Maybe<Scalars['String']>;
  /** 生成处方图的参数 */
  params?: Maybe<Scalars['String']>;
  /** 原图照片数量：一直是0 */
  pictureCount?: Maybe<Scalars['Int']>;
  /** 位置 */
  position?: Maybe<Position>;
  /** 所属重建任务 */
  processingJob?: Maybe<ProcessingJob>;
  resourceUuid?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  taggableId: Scalars['ID'];
  taggableType: TaggableType;
  taggableUuid: Scalars['String'];
  /** 该语义图的标签 */
  tags?: Maybe<TagConnection>;
  /** 缩列图URL */
  thumbnailUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Time']>;
  /** bounding box右上角坐标 */
  upperRight?: Maybe<Position>;
  uuid?: Maybe<Scalars['String']>;
  /** 点巡、面巡：一直是null */
  viewType?: Maybe<Scalars['Int']>;
};


export type NdviJobDownLeftArgs = {
  cs?: InputMaybe<Cs>;
};


export type NdviJobFileUrlsArgs = {
  fileNames: Array<Scalars['String']>;
};


export type NdviJobPositionArgs = {
  cs?: InputMaybe<Cs>;
};


export type NdviJobTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TagsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type NdviJobUpperRightArgs = {
  cs?: InputMaybe<Cs>;
};

export type NdviJobAttributes = {
  /** 生成处方图的模式，0-平衡法 1-分级法 */
  mode: Scalars['Int'];
  /** 处方图名字 */
  name: Scalars['String'];
  /**
   * 处方图的参数
   * 例：{"grid_gsd": 1.0}
   */
  params: Scalars['String'];
  /** 地块uuid */
  plotUuids?: InputMaybe<Array<Scalars['String']>>;
  /** 处方图所属processing job的uuid */
  processingJobUuid: Scalars['String'];
};

export type NdviJobCluster = {
  __typename?: 'NdviJobCluster';
  /** 聚集的 bounding box */
  bbox?: Maybe<BoundingBox>;
  /** 聚集中心点 */
  center?: Maybe<Position>;
  /** 重建数量 */
  count: Scalars['Int'];
};

export type NdviJobClusterEdge = {
  __typename?: 'NdviJobClusterEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<NdviJobCluster>;
};

export type NdviJobClustersConnection = {
  __typename?: 'NdviJobClustersConnection';
  edges: Array<Maybe<NdviJobClusterEdge>>;
  nodes: Array<Maybe<NdviJobCluster>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type NdviJobEdge = {
  __typename?: 'NdviJobEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<NdviJob>;
};

export type NdviJobsConnection = {
  __typename?: 'NdviJobsConnection';
  edges: Array<Maybe<NdviJobEdge>>;
  pageInfo?: Maybe<PageInfoNew>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type NdviJobsFilter = {
  bbox?: InputMaybe<BoundingBoxAttributes>;
  departmentUuids?: InputMaybe<Array<Scalars['String']>>;
  endTime?: InputMaybe<Scalars['Time']>;
  mode?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  /** 是否只取每个重建的最新处方图任务，默认否 */
  newest?: InputMaybe<Scalars['Boolean']>;
  /** 地块uuid筛选 */
  plotUuids?: InputMaybe<Array<Scalars['String']>>;
  /** 检索captureTime开关 */
  searchCaptureTime?: InputMaybe<Scalars['Boolean']>;
  /** 根据创建时间范围筛选 */
  startTime?: InputMaybe<Scalars['Time']>;
  statuses?: InputMaybe<Array<Scalars['Int']>>;
  uuids?: InputMaybe<Array<Scalars['String']>>;
  /** 筛选没有地块的处方图 */
  withoutPlot?: InputMaybe<Scalars['Boolean']>;
  /** 地图层级(默认是5) */
  zoomLevel?: InputMaybe<Scalars['Int']>;
};

export type NewDepartmentInfo = {
  __typename?: 'NewDepartmentInfo';
  /** 违规作业部门 */
  department: Department;
  /** 部门不合规作业面积 */
  invalidWorkArea: Scalars['Int'];
  /** 部门合规作业面积（单位0.01亩，下同） */
  validWorkArea: Scalars['Int'];
};

export type NewDeviceInfo = {
  __typename?: 'NewDeviceInfo';
  /** 设备 */
  device: Device;
  /** 设备不合规作业面积 */
  invalidWorkArea: Scalars['Int'];
  /** 设备合规作业面积（单位0.01亩，下同） */
  validWorkArea: Scalars['Int'];
};

export type NotifyPermissionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type NotifyPermissionPayload = {
  __typename?: 'NotifyPermissionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  permission?: Maybe<Permission>;
};

/** 组织机构（组织关系模块中业务模型） */
export type Organization = {
  __typename?: 'Organization';
  /** 地块列表 */
  accessibleLands?: Maybe<LandConnection>;
  /** 下属部门 */
  departments?: Maybe<DepartmentConnection>;
  /** 外部系统ID(256字符以内) */
  externalId?: Maybe<Scalars['String']>;
  flightRecords?: Maybe<FlightRecordConnection>;
  /** 系统中的机构id */
  id?: Maybe<Scalars['ID']>;
  /** 所属机构的最大层次结构 */
  maxDepartmentLevel?: Maybe<Scalars['Int']>;
  /** 成员 */
  members?: Maybe<MemberConnection>;
  /** 机构称呼(256字符以内) */
  name?: Maybe<Scalars['String']>;
  /** 系统中的机构uuid */
  uuid?: Maybe<Scalars['String']>;
};


/** 组织机构（组织关系模块中业务模型） */
export type OrganizationAccessibleLandsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


/** 组织机构（组织关系模块中业务模型） */
export type OrganizationDepartmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DepartmentsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


/** 组织机构（组织关系模块中业务模型） */
export type OrganizationFlightRecordsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


/** 组织机构（组织关系模块中业务模型） */
export type OrganizationMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<MembersFilter>;
  first?: InputMaybe<Scalars['Int']>;
};

/** 组织/集团属性 */
export type OrganizationAttributes = {
  /** 授权账号 */
  account?: InputMaybe<Scalars['String']>;
  /** 外部系统id */
  externalId?: InputMaybe<Scalars['ID']>;
  /** 下属部门最深层级 */
  maxDepartmentDeepLevel?: InputMaybe<Scalars['Int']>;
  /** 名称 */
  name?: InputMaybe<Scalars['String']>;
};

/** 组织机构connection */
export type OrganizationConnection = {
  __typename?: 'OrganizationConnection';
  /** Organization数组片段 */
  edges: Array<Maybe<OrganizationEdge>>;
  /** 直接获取元素 */
  nodes: Array<Maybe<Organization>>;
  /** 分页/connection信息 */
  pageInfo: PageInfo;
  /** 总数量 */
  totalCount: Scalars['Int'];
};

/** 组织机构edge */
export type OrganizationEdge = {
  __typename?: 'OrganizationEdge';
  /** 分页/connection中的游标 */
  cursor?: Maybe<Scalars['String']>;
  /** 记录 */
  node?: Maybe<Organization>;
};

export type OrganizationsFilter = {
  /** sql 片段方式（仅占位,暂不开启） */
  cond?: InputMaybe<Scalars['String']>;
};

export type OutputFileAttributes = {
  contentMd5: Scalars['String'];
  fileExtension: Scalars['String'];
  fileName: Scalars['String'];
  /** 单位为b */
  fileSize: Scalars['Int'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PageInfoNew = {
  __typename?: 'PageInfoNew';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  startCursor?: Maybe<Scalars['String']>;
};

export type PathPlanningResult = {
  __typename?: 'PathPlanningResult';
  blockArea: Scalars['Float'];
  errCode: Scalars['Int'];
  obsArea: Scalars['Float'];
  path: Array<PointFt>;
  pathLen: Scalars['Float'];
  sprayArea: Scalars['Float'];
};

/** 点巡、面巡的interface */
export type Patrol = {
  /** 拍摄时间 */
  captureTime?: Maybe<Scalars['Time']>;
  /** 记录的id */
  id?: Maybe<Scalars['ID']>;
  /** 巡田名称 */
  name?: Maybe<Scalars['String']>;
  /** 原图数量 */
  pictureCount?: Maybe<Scalars['Int']>;
  /** 缩列图url */
  thumbnailUrl?: Maybe<Scalars['String']>;
  /** 记录的uuid */
  uuid?: Maybe<Scalars['String']>;
  /** 俯瞰=0 鸟瞰=1 */
  viewType?: Maybe<Scalars['Int']>;
};

export type PatrolConnection = {
  __typename?: 'PatrolConnection';
  edges: Array<Maybe<PatrolEdge>>;
  nodes: Array<Maybe<Patrol>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PatrolEdge = {
  __typename?: 'PatrolEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Patrol>;
};

export type PatrolsFilter = {
  /** 视图的边界 */
  bbox?: InputMaybe<BoundingBoxAttributes>;
  /** 部门Uuid */
  departmentUuids?: InputMaybe<Array<Scalars['String']>>;
  /** 结束时间 （拍摄时间） */
  endTime?: InputMaybe<Scalars['Time']>;
  /** 面巡类型 */
  jobType?: InputMaybe<Scalars['Int']>;
  /** 检索类型: PlotPatrol/ProcessingJob/NdviJob */
  patrolTypes?: InputMaybe<Array<Scalars['String']>>;
  /** 开始时间 （拍摄时间） */
  startTime?: InputMaybe<Scalars['Time']>;
  /** 俯瞰 /鸟瞰 */
  viewType?: InputMaybe<Scalars['Int']>;
};

export type Permission = {
  __typename?: 'Permission';
  departCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  notifyPhone?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['Int']>;
  orgName?: Maybe<Scalars['String']>;
  orgSource?: Maybe<Scalars['String']>;
  user?: Maybe<PilotUser>;
  uuid?: Maybe<Scalars['String']>;
};

export type PermissionAttributes = {
  account?: InputMaybe<Scalars['String']>;
  /** 集团管理员场景下选择具体部门 */
  departmentUuid?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  notifyPhone?: InputMaybe<Scalars['String']>;
};

export type PermissionConnection = {
  __typename?: 'PermissionConnection';
  edges: Array<Maybe<PermissionEdge>>;
  nodes: Array<Maybe<Permission>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PermissionEdge = {
  __typename?: 'PermissionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Permission>;
};

/** 个人版证书 */
export type PersonalLicense = {
  __typename?: 'PersonalLicense';
  /** 开始时间 */
  BeginAt?: Maybe<Scalars['Time']>;
  /** 结束时间 */
  EndAt?: Maybe<Scalars['Time']>;
  createdAt?: Maybe<Scalars['Time']>;
  /** 系统中的id */
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['Time']>;
  /** 用户id */
  userId?: Maybe<Scalars['ID']>;
  /** 系统中的uuid */
  uuid?: Maybe<Scalars['String']>;
};

/** PersonalLicense connection */
export type PersonalLicenseConnection = {
  __typename?: 'PersonalLicenseConnection';
  /** PersonalLicense数组片段 */
  edges: Array<Maybe<PersonalLicenseEdge>>;
  /** 直接获取元素 */
  nodes: Array<Maybe<PersonalLicense>>;
  /** 分页/connection信息 */
  pageInfo: PageInfo;
  /** 总数量 */
  totalCount: Scalars['Int'];
};

/** PersonalLicense edge */
export type PersonalLicenseEdge = {
  __typename?: 'PersonalLicenseEdge';
  /** 分页/connection中的游标 */
  cursor?: Maybe<Scalars['String']>;
  /** 记录 */
  node?: Maybe<PersonalLicense>;
};

export type PersonalLicensesFilter = {
  cond?: InputMaybe<Scalars['String']>;
};

export type PilotUser = {
  __typename?: 'PilotUser';
  createdAt?: Maybe<Scalars['Time']>;
  email?: Maybe<Scalars['String']>;
  hasPersonalVersionPermission: Scalars['Boolean'];
  id?: Maybe<Scalars['ID']>;
  licenseBeginAt?: Maybe<Scalars['Time']>;
  licenseEndAt?: Maybe<Scalars['Time']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Time']>;
};

export type PilotUserCreateAuthTokenInput = {
  autoRebuild?: InputMaybe<Scalars['Boolean']>;
  mappingType?: InputMaybe<Scalars['String']>;
  permissionUuid?: InputMaybe<Scalars['String']>;
};

export type PilotUserCreatePersonalVersionLicenseInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 用户提交请求的类型,默认为pilot */
  sourceType?: InputMaybe<SourceType>;
};

export const enum PlantType {
  Rice = 'RICE',
  Wheat = 'WHEAT'
};

export type Plot = {
  __typename?: 'Plot';
  area?: Maybe<Scalars['Float']>;
  caliPoints?: Maybe<Array<Array<Scalars['Float']>>>;
  createdAt?: Maybe<Scalars['Time']>;
  edgesPointsSource?: Maybe<Scalars['String']>;
  fertilizerParams?: Maybe<Scalars['String']>;
  geo?: Maybe<Scalars['GeoJSON']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  /** 该地块下的处方图 */
  ndviJobs?: Maybe<NdviJobsConnection>;
  newestFlyWaypointList?: Maybe<FlyWaypointList>;
  ppe?: Maybe<Scalars['String']>;
  /** 生成航线时候的参数 */
  ppeParams?: Maybe<PpeParams>;
  startPoint?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Time']>;
  uuid?: Maybe<Scalars['String']>;
};


export type PlotAreaArgs = {
  unit: AreaUnit;
};


export type PlotNdviJobsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<NdviJobsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};

export type PlotAttributes = {
  /**
   * 地块的标定点
   * 请依次传入经度、纬度和高度，如没有高度则传入0
   * 如[[122.75609, 35.376234, 0], [122.75667, 35.376245, 0]]
   */
  caliPoints?: InputMaybe<Array<Array<Scalars['Float']>>>;
  edgesPointsSource?: InputMaybe<Scalars['String']>;
  /** 地块的地理位置信息GeoJSON字符串，目前只支持无空洞多边形 */
  geo: Scalars['GeoJSON'];
  /** 地块名字 */
  name: Scalars['String'];
  ppeParams?: InputMaybe<PpeParamsInput>;
  /** 所属job的uuid */
  processingJobUuid: Scalars['String'];
  startPoint?: InputMaybe<Scalars['String']>;
};

export type PlotEdge = {
  __typename?: 'PlotEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Plot>;
};

export type PlotPatrol = Patrol & TaggableI & {
  __typename?: 'PlotPatrol';
  beginDate?: Maybe<Scalars['Time']>;
  /** 拍摄时间 */
  captureTime?: Maybe<Scalars['Time']>;
  commentCount: Scalars['Int'];
  createdAt?: Maybe<Scalars['Time']>;
  /** 所属组织 */
  department?: Maybe<Department>;
  /** 部门链，顺序从底层到顶层 */
  departmentLinks?: Maybe<Array<Maybe<Department>>>;
  endDate?: Maybe<Scalars['Time']>;
  /** 巡田ID */
  id?: Maybe<Scalars['ID']>;
  /** 图片库 */
  mapping?: Maybe<Mapping>;
  /** 巡田名字 */
  name?: Maybe<Scalars['String']>;
  pictureCount?: Maybe<Scalars['Int']>;
  plotPatrolsSourceImages?: Maybe<Array<PlotPatrolsSourceImage>>;
  taggableId: Scalars['ID'];
  taggableType: TaggableType;
  taggableUuid: Scalars['String'];
  tags?: Maybe<TagConnection>;
  /** 缩列图url */
  thumbnailUrl?: Maybe<Scalars['String']>;
  /** 巡田UUID */
  uuid?: Maybe<Scalars['String']>;
  /** 俯瞰 鸟瞰 */
  viewType?: Maybe<Scalars['Int']>;
};


export type PlotPatrolTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TagsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};

export type PlotPatrolAttributes = {
  beginDate?: Scalars['Time'];
  endDate?: Scalars['Time'];
  mappingUuid: Scalars['String'];
  name: Scalars['String'];
  viewType?: InputMaybe<Scalars['Int']>;
};

export type PlotPatrolCluster = {
  __typename?: 'PlotPatrolCluster';
  /** 聚集的 bounding box */
  bbox?: Maybe<BoundingBox>;
  /** 聚集中心点 */
  center?: Maybe<Position>;
  /** 巡田数量 */
  count: Scalars['Int'];
};

export type PlotPatrolClusterEdge = {
  __typename?: 'PlotPatrolClusterEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<PlotPatrolCluster>;
};

export type PlotPatrolClustersConnection = {
  __typename?: 'PlotPatrolClustersConnection';
  edges: Array<Maybe<PlotPatrolClusterEdge>>;
  nodes: Array<Maybe<PlotPatrolCluster>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PlotPatrolEdge = {
  __typename?: 'PlotPatrolEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<PlotPatrol>;
};

export type PlotPatrolsConnection = {
  __typename?: 'PlotPatrolsConnection';
  edges: Array<Maybe<PlotPatrolEdge>>;
  nodes: Array<Maybe<PlotPatrol>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PlotPatrolsFilter = {
  /** 根据视口查询，请传入wgs84坐标系的视口 */
  bbox?: InputMaybe<BoundingBoxAttributes>;
  /** 根据部门的uuid列表进行筛选 */
  departmentUuids?: InputMaybe<Array<Scalars['String']>>;
  /** 根据创建时间筛选 */
  endAt?: InputMaybe<Scalars['Time']>;
  /** 根据名字筛选 */
  name?: InputMaybe<Scalars['String']>;
  /** 检索captureTime开关 */
  searchCaptureTime?: InputMaybe<Scalars['Boolean']>;
  /** 根据创建时间筛选 */
  startAt?: InputMaybe<Scalars['Time']>;
  /** 鸟瞰 俯瞰 */
  viewType?: InputMaybe<Scalars['Int']>;
  /** 默认层级5 */
  zoomLevel?: InputMaybe<Scalars['Int']>;
};

export type PlotPatrolsSourceImage = TaggableI & {
  __typename?: 'PlotPatrolsSourceImage';
  SourceImage?: Maybe<SourceImage>;
  SourceImageUuid: Scalars['String'];
  comment: Scalars['String'];
  id: Scalars['ID'];
  plotPatrolUuid: Scalars['String'];
  taggableId: Scalars['ID'];
  taggableType: TaggableType;
  taggableUuid: Scalars['String'];
  tags?: Maybe<TagConnection>;
  uuid: Scalars['String'];
};


export type PlotPatrolsSourceImageTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TagsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};

export type PlotsConnection = {
  __typename?: 'PlotsConnection';
  edges: Array<Maybe<PlotEdge>>;
  pageInfo?: Maybe<PageInfoNew>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PlotsShareInput = {
  /** 农服账号 */
  account: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 自由航线uuid */
  freePlotUuids?: InputMaybe<Array<Scalars['String']>>;
  /** 是否只分享单个地块 */
  isSingle?: InputMaybe<Scalars['Boolean']>;
  /** 处方图uuid */
  ndviJobUuids?: InputMaybe<Array<Scalars['String']>>;
  /** 地块uuid */
  plotUuids?: InputMaybe<Array<Scalars['String']>>;
  /** 重建任务的uuid */
  processingJobUuid: Scalars['String'];
};

export type PlotsSharePayload = {
  __typename?: 'PlotsSharePayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Point3D = {
  __typename?: 'Point3D';
  /** 离地高度 */
  altitude: Scalars['Float'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type Point3DInput = {
  altitude: Scalars['Float'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type PointFt = {
  __typename?: 'PointFT';
  /** 开关的状态 -1: null action, 0: 开启, 1: 关闭 */
  action: Scalars['Int'];
  /** 开关的动作，13: 开启，14: 关闭, 0: 无动作 */
  actionType: Scalars['Int'];
  id: Scalars['Int'];
  point3D: Point3D;
  /** POINTFT_TYPE(INVALID_POINT: -2, NULL_POINT: -1, NORMAL_POINT: 0, TURN_POINT: 1, OBS_AVOID_POINT: 2, TREE_CENTER_POINT: 3, MANU_POINT: 4 */
  pointType: Scalars['Int'];
  semanticType: Scalars['Int'];
};

export type PointFtInput = {
  /** 序号,从0开始 */
  action: Scalars['Int'];
  point3D: Point3DInput;
  pointType: Scalars['Int'];
  semanticType: Scalars['Int'];
};

export type PosInfo = {
  __typename?: 'PosInfo';
  id: Scalars['String'];
  orientation: Array<Scalars['Float']>;
  pos: Array<Scalars['Float']>;
  posSigma: Array<Scalars['Float']>;
};

export type Position = {
  __typename?: 'Position';
  /** 纬度 */
  lat?: Maybe<Scalars['Float']>;
  /** 经度 */
  lng?: Maybe<Scalars['Float']>;
};

export type Position2 = {
  /** 纬度 */
  lat?: InputMaybe<Scalars['Float']>;
  /** 经度 */
  lng?: InputMaybe<Scalars['Float']>;
};

export type PositionAttributes = {
  /** 纬度 */
  lat: Scalars['Float'];
  /** 经度 */
  lng: Scalars['Float'];
};

/** 天气观测点 */
export type PositionOfInterest = {
  __typename?: 'PositionOfInterest';
  /** 小时级别精确天气（服务根据时间自动返回历史/未来天气，推荐使用）after 是时间字符串 */
  AccurateWeathers: Array<Maybe<AccurateWeather>>;
  /** 天级别概要天气（服务根据时间自动返回历史/未来天气, 推荐使用）after 是时间字符串 */
  SummaryWeathers: Array<Maybe<SummaryWeather>>;
  /** 天级别未来天气预测(如果是需要用历史+未来数据，建议用优化接口：SummaryWeathers） */
  dailyForecastWeathers?: Maybe<DailyForecastWeatherConnection>;
  /** 所属机构的ID */
  departmentId?: Maybe<Scalars['ID']>;
  /** 历史天气记录 */
  historyWeathers?: Maybe<HistoryWeatherConnection>;
  /** 小时级别未来天气预测(如果是需要用历史+未来数据，建议用优化接口：AccurateWeathers) */
  hourlyForecastWeathers?: Maybe<HourlyForecastWeatherConnection>;
  /** 系统中的天气观测点id */
  id?: Maybe<Scalars['ID']>;
  /** 观测点名称 */
  name?: Maybe<Scalars['String']>;
  /** 经纬度 */
  position?: Maybe<Position>;
  /** 实时天气（如果需要实时更新，推荐使用subscription） */
  realtimeWeather?: Maybe<RealtimeWeather>;
  /** 气象观测点的数据聚合时区 */
  userTimezone?: Maybe<Scalars['String']>;
  /** 系统中的天气观测点uuid */
  uuid?: Maybe<Scalars['String']>;
};


/** 天气观测点 */
export type PositionOfInterestAccurateWeathersArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AccurateWeathersFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


/** 天气观测点 */
export type PositionOfInterestSummaryWeathersArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SummaryWeathersFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


/** 天气观测点 */
export type PositionOfInterestDailyForecastWeathersArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


/** 天气观测点 */
export type PositionOfInterestHistoryWeathersArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


/** 天气观测点 */
export type PositionOfInterestHourlyForecastWeathersArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

/** 气象观测点的属性 */
export type PositionOfInterestAttributes = {
  /** 所属部门ID */
  departmentId?: InputMaybe<Scalars['ID']>;
  /** 组织/集团权限 */
  position?: InputMaybe<PositionAttributes>;
  /** 所属时区 */
  userTimezone?: InputMaybe<Scalars['String']>;
};

/** 组织机构connection */
export type PositionOfInterestConnection = {
  __typename?: 'PositionOfInterestConnection';
  /** PositionOfInterest数组片段 */
  edges: Array<Maybe<PositionOfInterestEdge>>;
  /** 直接获取元素 */
  nodes: Array<Maybe<PositionOfInterest>>;
  /** 分页/connection信息 */
  pageInfo: PageInfo;
  /** 总数量 */
  totalCount: Scalars['Int'];
};

/** 组织机构edge */
export type PositionOfInterestEdge = {
  __typename?: 'PositionOfInterestEdge';
  /** 分页/connection中的游标 */
  cursor?: Maybe<Scalars['String']>;
  /** 记录 */
  node?: Maybe<PositionOfInterest>;
};

export type PositionOfInterestsFilter = {
  /** sql 片段方式（仅占位,暂不开启） */
  cond?: InputMaybe<Scalars['String']>;
  /** 部门uuid */
  departmentUuid?: InputMaybe<Scalars['String']>;
};

export type PpeParams = {
  __typename?: 'PpeParams';
  /** 是否绕过障碍物 */
  avoidObstacle: Scalars['Boolean'];
  /** 航线平滑度 */
  followAccuracy: Scalars['Float'];
  /** 是否实时航线 */
  isRealTime: Scalars['Boolean'];
  /** 航线角度，度 */
  sprayDirection: Scalars['Float'];
  /** 航线高度，米 */
  sprayHeight: Scalars['Float'];
  /**
   * 航线类型
   * 连续喷洒-自动等距（区域规划-连续喷洒) 0
   * 定点喷洒-自动树心（区域规划-树心定点喷洒）1
   * 连续喷洒-自动树心（区域规划-树心连续喷洒）	2
   * 连续喷洒-手动（自由航线规划-连续喷洒）3
   * 定点喷洒-半自动（自由航线规划-树心定点喷洒）4
   * 连续喷洒-半自动（自由航线规划-树心连续喷洒）5
   */
  sprayType: Scalars['Float'];
  /** 航线宽度，米 */
  sprayWidth: Scalars['Float'];
};

export type PpeParamsInput = {
  /** 是否绕过障碍物 */
  avoidObstacle: Scalars['Boolean'];
  /** 航线平滑度 */
  followAccuracy: Scalars['Float'];
  /** 是否实时航线 */
  isRealTime: Scalars['Boolean'];
  /** 航线角度，度 */
  sprayDirection: Scalars['Float'];
  /** 航线高度，米 */
  sprayHeight: Scalars['Float'];
  /**
   * 航线类型
   * 连续喷洒-自动等距（区域规划-连续喷洒) 0
   * 定点喷洒-自动树心（区域规划-树心定点喷洒）1
   * 连续喷洒-自动树心（区域规划-树心连续喷洒）	2
   * 连续喷洒-手动（自由航线规划-连续喷洒）3
   * 定点喷洒-半自动（自由航线规划-树心定点喷洒）4
   * 连续喷洒-半自动（自由航线规划-树心连续喷洒）5
   */
  sprayType: Scalars['Float'];
  /** 航线宽度，米 */
  sprayWidth: Scalars['Float'];
};

/** 气压单位 */
export const enum PressureUnit {
  /** 巴 */
  Bar = 'BAR',
  /** 千帕 */
  Kpa = 'KPA',
  /** 兆帕 */
  Mpa = 'MPA',
  /** 帕 */
  Pa = 'PA'
};

export type ProcessingJob = Patrol & TaggableI & {
  __typename?: 'ProcessingJob';
  /** 该job的图片库 */
  Mapping?: Maybe<Mapping>;
  /** 名下生成的ai地块任务 */
  aiPlotJobs?: Maybe<AiPlotJobsConnection>;
  /** 面积，若不传单位则给亩 */
  area?: Maybe<Scalars['Float']>;
  /** 拍摄时间 */
  captureTime?: Maybe<Scalars['Time']>;
  /** lzw压缩过的高程图的链接 */
  compressionGsddsm?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Time']>;
  /** 所属部门 */
  department?: Maybe<Department>;
  /** 部门链，顺序从底层到顶层 */
  departmentLinks?: Maybe<Array<Maybe<Department>>>;
  /** 在dji service中的uuid */
  djiServiceUuid?: Maybe<Scalars['String']>;
  /** bounding box左下角坐标 */
  downLeft?: Maybe<Position>;
  /** 错误码 */
  errCode: Scalars['String'];
  /**
   * 取这个job完成后的某些文件的url
   * 只允许segment.tif segment.tfw segmentAPI.json fertilizer_detailed.tfw fertilizer_detailed.tif fertilizer.tfw fertilizer.tif gsddsm.tif gsddsm.tfw
   */
  fileUrls: Array<FileUrl>;
  fileUrlsV2: Array<FileUrl>;
  /** 自由航线规划 */
  freePlots?: Maybe<FreePlotsConnection>;
  /** 全图的高程信息 */
  fullGsddsm?: Maybe<Gsddsm>;
  /**
   * 高程信息
   * 不传地块信息则由后台指定相应的计算地块
   * 如果后台还是找不到地块，则会返回整张高程图的信息
   * 传入地块信息则给出对相应地块的高程分析
   */
  gsddsm?: Maybe<Gsddsm>;
  /** 处理任务id */
  id?: Maybe<Scalars['ID']>;
  /** 是否是demo数据 */
  isDemo: Scalars['Boolean'];
  /** 该任务是否高程精准，有可能为空 */
  isPreciseGsd?: Maybe<Scalars['Boolean']>;
  /** 这个重建任务的一些指标 */
  mapReport?: Maybe<MapReport>;
  name?: Maybe<Scalars['String']>;
  /** 名下的ndvi jos */
  ndviJobs?: Maybe<NdviJobsConnection>;
  /**
   * 取最新的对djitile的压缩job
   * 如果传入uuid则取该uuid的压缩
   */
  newestCompressionJobForTile?: Maybe<CompressionJobForDjitile>;
  /** 最新的语义图文件的dji service uuid */
  newestSegment?: Maybe<Scalars['String']>;
  notify: Scalars['Boolean'];
  /** 输出资源的uuid */
  outputResourceUuid?: Maybe<Scalars['String']>;
  /** 该任务的参数，是一个json字符串 */
  params?: Maybe<Scalars['String']>;
  /** 该接口是请求dji service查当前任务的百分比，由于要通过网络请求比较慢 */
  percentRemote?: Maybe<Scalars['Float']>;
  /** 照片数量 */
  pictureCount?: Maybe<Scalars['Int']>;
  /** 地块 */
  plots?: Maybe<PlotsConnection>;
  /** 位置 */
  position?: Maybe<Position>;
  /** 任务中添加过 */
  processingJobSourceImages?: Maybe<Array<Maybe<ProcessingJobSourceImage>>>;
  /** 输入资源的uuid */
  resourceUuid?: Maybe<Scalars['String']>;
  /** 降采样成1m为一个栅格的NDVI.tif */
  sampleNDVI?: Maybe<Array<Scalars['String']>>;
  /** 用来生成降采样的处方图的语义图文件的dji service uuid */
  segmentForSampleNDVI?: Maybe<Scalars['String']>;
  /** 处理任务状态0：待开始，1：等待中，2：准备中，3：执行中，4：处理结果中，5：已停止，6：执行完成，7：执行失败 */
  status?: Maybe<Scalars['Int']>;
  /** 该接口是请求dji service查当前任务的状态，由于要通过网络请求比较慢 */
  statusRemote?: Maybe<Scalars['Int']>;
  taggableId: Scalars['ID'];
  taggableType: TaggableType;
  taggableUuid: Scalars['String'];
  tags?: Maybe<TagConnection>;
  /** 缩列图 */
  thumbnailUrl?: Maybe<Scalars['String']>;
  /** 树心点信息 */
  treeInfo?: Maybe<TreeInfo>;
  /** 这个job类型，0代表农田模式 1代表城市模式 2代表果树模式 4代表多光谱模式 默认为0 */
  type?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Time']>;
  /** bounding box右上角坐标 */
  upperRight?: Maybe<Position>;
  uuid?: Maybe<Scalars['String']>;
  /** 点巡/面巡 */
  viewType?: Maybe<Scalars['Int']>;
  /** 生成xyz的高清地图瓦片模板 */
  xyzTileFormat: Scalars['String'];
};


export type ProcessingJobAiPlotJobsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type ProcessingJobAreaArgs = {
  unit?: InputMaybe<AreaUnit>;
};


export type ProcessingJobDownLeftArgs = {
  cs?: InputMaybe<Cs>;
};


export type ProcessingJobFileUrlsArgs = {
  fileNames: Array<Scalars['String']>;
};


export type ProcessingJobFileUrlsV2Args = {
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rows?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type ProcessingJobFreePlotsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProcessingJobFreePlotsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type ProcessingJobGsddsmArgs = {
  landUuids?: InputMaybe<Array<Scalars['String']>>;
};


export type ProcessingJobNdviJobsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<NdviJobsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type ProcessingJobNewestCompressionJobForTileArgs = {
  uuid?: InputMaybe<Scalars['String']>;
};


export type ProcessingJobPlotsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProcessingJobPlotsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type ProcessingJobPositionArgs = {
  cs?: InputMaybe<Cs>;
};


export type ProcessingJobTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TagsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type ProcessingJobUpperRightArgs = {
  cs?: InputMaybe<Cs>;
};

export type ProcessingJobAttributes = {
  /**
   * 任务所用图片库的uuid
   * 将来有可能支持多个图片库
   */
  mappingUuid: Scalars['String'];
  /** 这个任务的名字 */
  name: Scalars['String'];
  notify?: Scalars['Boolean'];
  /**
   * 这个任务的参数，是个json字符串，json的形式如下
   *
   *    "parameter":{
   *
   *        "map_mode":5,                      //可选，拼图模式 0代表农田模式 1代表城市模式 2代表果树模式 4代表多光谱模式
   *
   *        "quality_level": 1,                //可选，重建等级 1:高分辨率 2:中分辨率 3:低分辨率               默认为1
   *
   *        "use_gcj02":true                   //可选，是否使用国测局坐标系                                    默认true
   *
   *        "aux_band_list":["Blue","Green","Red","RedEdge","NIR"],            //可选，多光谱模式中算法需要处理的波段名称列表
   *
   *        "index_list":["NDVI","GNDVI","NDRE","LCI","OSAVI","MCARI"]    //可选，多光谱模式中算法需要生成的指数图列表
   *
   *        // 以下为两种输出地理坐标系的选择，二选一：
   *
   *        // 模式1,WKT字符串，往往从PRJ文件来
   *
   *        "output_CS_from_WKT": "",          //可选，用户定义的输出坐标系                                    默认为空字符串
   *
   *        // 模式2，给坐标系字符串和可选的高程重载
   *
   *        // 地理坐标系：
   *
   *        "output_geo_CS": "",               //可选，地理坐标系，用户选择，格式样例:"EPSG:4326"              默认为空字符串
   *
   *        // 高程坐标系重载，如果不需要修改高程则可忽略这个
   *
   *        "output_override_vertical_CS": ""  //可选，重载高程坐标系，用户选择，格式样例:"EPSG:4326"          默认为空字符串
   *
   *    }
   */
  params: Scalars['String'];
};

export type ProcessingJobAuth = {
  __typename?: 'ProcessingJobAuth';
  /** 创建时间 */
  createdAt?: Maybe<Scalars['Time']>;
  /** 授权的部门 */
  department?: Maybe<Department>;
  /** 系统中的id */
  id?: Maybe<Scalars['ID']>;
  /** 授权的集团 */
  organization?: Maybe<Organization>;
  /** 分享目标 */
  target?: Maybe<UserOfExternalSystem>;
  /** 最后更新时间 */
  updatedAt?: Maybe<Scalars['Time']>;
  /** 授权创建者 */
  user?: Maybe<User>;
  /** 系统中的uuid */
  uuid?: Maybe<Scalars['String']>;
};

export type ProcessingJobAuthAttributes = {
  /** 大疆用户账号（目前仅限大疆农服） */
  account?: InputMaybe<Scalars['String']>;
  /** 要授权的部门Uuid */
  departmentUuid?: InputMaybe<Scalars['String']>;
  /** 要授权的集团Uuid */
  organizationUuid?: InputMaybe<Scalars['String']>;
};

/** UserOfExternalSystem connection */
export type ProcessingJobAuthConnection = {
  __typename?: 'ProcessingJobAuthConnection';
  /** UserOfExternalSystem数组片段 */
  edges: Array<Maybe<ProcessingJobAuthEdge>>;
  /** 直接获取元素 */
  nodes: Array<Maybe<ProcessingJobAuth>>;
  /** 分页/connection信息 */
  pageInfo: PageInfo;
  /** 总数量 */
  totalCount: Scalars['Int'];
};

/** UserOfExternalSystem edge */
export type ProcessingJobAuthEdge = {
  __typename?: 'ProcessingJobAuthEdge';
  /** 分页/connection中的游标 */
  cursor?: Maybe<Scalars['String']>;
  /** 记录 */
  node?: Maybe<ProcessingJobAuth>;
};

export type ProcessingJobAuthsFilter = {
  account?: InputMaybe<Scalars['String']>;
};

export type ProcessingJobCluster = {
  __typename?: 'ProcessingJobCluster';
  /** 聚集的 bounding box */
  bbox?: Maybe<BoundingBox>;
  /** 聚集中心点 */
  center?: Maybe<Position>;
  /** 重建数量 */
  count: Scalars['Int'];
};

export type ProcessingJobClusterEdge = {
  __typename?: 'ProcessingJobClusterEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ProcessingJobCluster>;
};

export type ProcessingJobClustersConnection = {
  __typename?: 'ProcessingJobClustersConnection';
  edges: Array<Maybe<ProcessingJobClusterEdge>>;
  nodes: Array<Maybe<ProcessingJobCluster>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ProcessingJobEdge = {
  __typename?: 'ProcessingJobEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ProcessingJob>;
};

export type ProcessingJobFreePlotsFilter = {
  uuids?: InputMaybe<Array<Scalars['String']>>;
};

export type ProcessingJobPlotsFilter = {
  uuids?: InputMaybe<Array<Scalars['String']>>;
};

export type ProcessingJobSourceImage = SourceImage | Taggable;

export type ProcessingJobsConnection = {
  __typename?: 'ProcessingJobsConnection';
  edges: Array<Maybe<ProcessingJobEdge>>;
  nodes: Array<Maybe<ProcessingJob>>;
  pageInfo?: Maybe<PageInfoNew>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ProcessingJobsFilter = {
  bbox?: InputMaybe<BoundingBoxAttributes>;
  departmentUuids?: InputMaybe<Array<Scalars['String']>>;
  endTime?: InputMaybe<Scalars['Time']>;
  indexList?: InputMaybe<Array<Index>>;
  jobType?: InputMaybe<Scalars['Int']>;
  jobTypes?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  /** 检索captureTime开关 */
  searchCaptureTime?: InputMaybe<Scalars['Boolean']>;
  /** 根据创建时间范围筛选 */
  startTime?: InputMaybe<Scalars['Time']>;
  status?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<Scalars['Int']>>;
  uuids?: InputMaybe<Array<Scalars['String']>>;
  zoomLevel?: InputMaybe<Scalars['Int']>;
};

export type Quality = {
  __typename?: 'Quality';
  /** 部门总作业面积，从大到小排序 */
  departmentRank: Array<DepartmentInfo>;
  /** 植保机总作业面积排名 */
  droneRank: Array<DroneInfo>;
  /** 总的飞行次数 */
  flightRecordCount: Scalars['Int'];
  /** 该作业下的飞行记录 */
  flightRecords?: Maybe<FlightRecordConnection>;
  /** 飞行记录聚合 */
  flightRecordsAggr: Array<Bucket>;
  /** 部门违规面积，从大到小排序 */
  invalidDepartmentRank: Array<DepartmentInfo>;
  /** 植保机违规面积排名 */
  invalidDroneRank: Array<DroneInfo>;
  /** 违规作业信息 */
  invalidInfo: InvalidInfo;
  /** 该任务下累计违规作业（单位0.01亩） */
  invalidWorkArea: Scalars['Int'];
  /** 新排行，可以根据多种排序方式来排行，区域排行 */
  newDepartmentRank: Array<NewDepartmentInfo>;
  /** 根据部门的所属设备sn来进行筛选 */
  newDepartmentRankForDevices: Array<NewDepartmentInfo>;
  /** 新排行，可以根据多种排序方式来排行，设备排行 */
  newDeviceRank: Array<NewDeviceInfo>;
  /** 总用药量（毫升） */
  totalSprayUsage: Scalars['Int'];
  /** 总的飞行时长（秒） */
  totalWorkTime: Scalars['Int'];
  /** 作业趋势 */
  trend: Array<DayInfo>;
  /** 该任务下累计合规作业（单位0.01亩） */
  validWorkArea: Scalars['Int'];
};


export type QualityFlightRecordsArgs = {
  after?: InputMaybe<Scalars['String']>;
  bottomRight?: InputMaybe<PositionAttributes>;
  first?: InputMaybe<Scalars['Int']>;
  topLeft?: InputMaybe<PositionAttributes>;
};


export type QualityFlightRecordsAggrArgs = {
  bottomRight?: InputMaybe<PositionAttributes>;
  precision?: InputMaybe<Scalars['Int']>;
  topLeft?: InputMaybe<PositionAttributes>;
};


export type QualityNewDepartmentRankArgs = {
  rank?: InputMaybe<Rank>;
};


export type QualityNewDepartmentRankForDevicesArgs = {
  rank?: InputMaybe<Rank>;
};


export type QualityNewDeviceRankArgs = {
  rank?: InputMaybe<Rank>;
};

export const enum QualityItem {
  /** 飞行高度违规 */
  InvalidHeight = 'InvalidHeight',
  /** 时间段不对 */
  InvalidLocalTime = 'InvalidLocalTime',
  /** 飞行速度违规 */
  InvalidMaxSpeed = 'InvalidMaxSpeed',
  /** 降雨超标 */
  InvalidRainfall = 'InvalidRainfall',
  /** 亩播撒量违规 */
  InvalidSowUsagePerMu = 'InvalidSowUsagePerMu',
  /** 亩用药量违规 */
  InvalidSprayUsagePerMu = 'InvalidSprayUsagePerMu',
  /** 行间距违规 */
  InvalidSprayWidth = 'InvalidSprayWidth',
  /** 温度超标 */
  InvalidTemperature = 'InvalidTemperature',
  /** 风力超标 */
  InvalidWind = 'InvalidWind'
};

export type QualityParams = {
  /** 图片绝对质量 */
  Q?: InputMaybe<Scalars['Int']>;
};

export const enum QualityType {
  /** 不合规 */
  Invalid = 'Invalid',
  /** 合规 */
  Valid = 'Valid'
};

export type Query = {
  __typename?: 'Query';
  /** 查询ai地块 */
  aiPlot?: Maybe<AiPlot>;
  /** 查询ai地块 */
  aiPlots?: Maybe<AiPlotsConnection>;
  /** 查询申请列表 */
  applicationForms?: Maybe<ApplicationFormConnection>;
  /**
   * 查询一个地理位置信息的面积
   * 地块的地理位置信息GeoJSON字符串，目前只支持无空洞多边形
   * geo传入需要计算的GeoJSON字符串，unit传入计算的面积想要的单位
   */
  calculateArea: Scalars['Float'];
  /** （24小时级）历史天气 */
  dailyHistoryWeathers?: Maybe<DailyHistoryWeatherConnection>;
  /** 单个部门详情 */
  department?: Maybe<Department>;
  /** 不分页的部门树 */
  departmentTree?: Maybe<DepartmentTree>;
  /**
   * 部门列表
   * filter和devicesFilter不能同时传，只能传一个
   */
  departments?: Maybe<DepartmentConnection>;
  /** 单个设备 */
  device?: Maybe<Device>;
  /**
   * 设备类型，从设备类型查厂商和型号
   * 由于逻辑简单，数据量很少，不需要分页
   */
  deviceTypes?: Maybe<Array<DeviceType>>;
  /** 设备列表 */
  devices?: Maybe<DeviceConnection>;
  /** 单个域名详情 */
  domain?: Maybe<Domain>;
  /** 域名列表 */
  domains?: Maybe<DomainConnection>;
  /** 估产分析任务 */
  estimationJob?: Maybe<EstimationJob>;
  estimationJobs?: Maybe<EstimationJobsConnection>;
  /** faq一级标题 */
  faqTitles?: Maybe<FaqTitleConnection>;
  /** 单个电子围栏/农场边界 */
  fence?: Maybe<Fence>;
  /** 电子围栏/农场边界 列表 */
  fences?: Maybe<FenceConnection>;
  /** 单个第三方处方图 */
  fertilizer?: Maybe<Fertilizer>;
  /** 第三方处方图 列表 */
  fertilizers?: Maybe<FertilizerConnection>;
  /** 飞行记录详情 */
  flightRecord?: Maybe<FlightRecord>;
  /**
   * 查找航线
   * useNewPpe 是否使用新的ppe返回action(旧: 13, 14；新: 0, 1)
   */
  flyWaypointList?: Maybe<FlyWaypointList>;
  /** 请求segment图层状态 */
  getSegmentCoverageStatus?: Maybe<GenerateSegmentCoveragePayload>;
  /** （分钟级）历史天气 */
  historyWeathers?: Maybe<HistoryWeatherConnection>;
  /** 地块详情 */
  land?: Maybe<Land>;
  /**
   * 地块列表
   * 顺序不传则按照更新时间倒叙排序
   */
  lands?: Maybe<LandConnection>;
  /** 地图 */
  map?: Maybe<Map>;
  /** 照片库详情 */
  mapping?: Maybe<Mapping>;
  /** 测绘列表。可以传入条件和排序，如果不传排序则按照创建时间顺序 */
  mappings?: Maybe<MappingsConnection>;
  /** 地图列表 */
  maps?: Maybe<MapsConnection>;
  /** 单个成员 */
  member?: Maybe<Member>;
  /** 成员列表 */
  members?: Maybe<MemberConnection>;
  /** 合并任务 */
  mergeJobs?: Maybe<MergeJobsConnection>;
  /** 单个处方图 */
  ndviJob?: Maybe<NdviJob>;
  /** 重建任务聚合 */
  ndviJobClusters?: Maybe<NdviJobClustersConnection>;
  /** 处方图任务 */
  ndviJobs?: Maybe<NdviJobsConnection>;
  /** 单个集团组织详情 */
  organization?: Maybe<Organization>;
  /** 集团组织列表 */
  organizations?: Maybe<OrganizationConnection>;
  /** 点巡/面巡查询 */
  patrols?: Maybe<PatrolConnection>;
  permission?: Maybe<Permission>;
  permissions?: Maybe<PermissionConnection>;
  /** 查询个人版的license */
  personalLicenses?: Maybe<PersonalLicenseConnection>;
  pilotUserPermissions?: Maybe<PermissionConnection>;
  /** pilot app 用户信息 */
  pilotUserProfile?: Maybe<PilotUser>;
  /** 巡田详情 */
  plotPatrol?: Maybe<PlotPatrol>;
  /** 巡田分布 */
  plotPatrolClusters?: Maybe<PlotPatrolClustersConnection>;
  /** 巡田列表。可以传入条件，按照创建时间逆序 */
  plotPatrols?: Maybe<PlotPatrolsConnection>;
  /** 巡田详情 */
  plotPatrolsSourceImage?: Maybe<PlotPatrolsSourceImage>;
  /** 天气观测点 */
  positionOfInterest?: Maybe<PositionOfInterest>;
  /** 天气观测点列表 */
  positionOfInterests?: Maybe<PositionOfInterestConnection>;
  /** 重建任务 */
  processingJob?: Maybe<ProcessingJob>;
  /** 查看单个瓦片授权列表 */
  processingJobAuth?: Maybe<ProcessingJobAuth>;
  /** 瓦片授权列表 */
  processingJobAuths?: Maybe<ProcessingJobAuthConnection>;
  /** 重建任务聚合 */
  processingJobClusters?: Maybe<ProcessingJobClustersConnection>;
  /** 巡田详情 */
  processingJobSourceImage?: Maybe<ProcessingJobSourceImage>;
  /** 重建任务列表 */
  processingJobs?: Maybe<ProcessingJobsConnection>;
  /** 获取下载处方图的状态 */
  queryDownloadNdviFertilizer?: Maybe<QueryDownloadNdviFertilizerPayload>;
  /**
   * 地理位置信息反解析，使用了高德的api
   * 请传入wgs84坐标系
   * 响应与高德逆地理编码API的字段完全一致，请看文档 https://lbs.amap.com/api/webservice/guide/api/georegeo#instructions
   */
  regeo?: Maybe<Regeo>;
  /** 请求版本信息列表 */
  releaseNotes?: Maybe<ReleaseNoteConnection>;
  remoteControllerProcessingJob?: Maybe<RemoteControllerProcessingJob>;
  /** 遥控器重建列表 */
  remoteControllerProcessingJobs?: Maybe<RemoteControllerProcessingJobConnection>;
  /** 出苗分析任务 */
  seedlingJob?: Maybe<SeedlingJob>;
  seedlingJobs?: Maybe<SeedlingJobsConnection>;
  /** 查询segment状态 */
  segmentStatus: SegmentStatus;
  /** 共享高清地图列表 */
  sharingCoverages?: Maybe<SharingCoverageConnection>;
  /** 图片 */
  sourceImage?: Maybe<SourceImage>;
  /** 数据总览（传入农场uuid列表作为条件） */
  statistics: Statistics;
  tag?: Maybe<Tag>;
  /** 可通过uuid查询可以打tag的 */
  taggable?: Maybe<TaggableI>;
  /** 标签 */
  tags?: Maybe<TagConnection>;
  /** 单个任务 */
  task?: Maybe<Task>;
  /** 作业类型列表，不分页 */
  taskTypes?: Maybe<Array<TaskType>>;
  /** 任务列表 */
  tasks?: Maybe<TaskConnection>;
  /** 处方图用药量(默认单位是 公斤/亩) */
  usageOfPrescription?: Maybe<Scalars['Float']>;
  /** 用户 */
  userProfile?: Maybe<User>;
  /** 用户列表 */
  users?: Maybe<UserConnection>;
  /** 设备厂商 */
  vendors?: Maybe<Array<Maybe<Vendor>>>;
};


export type QueryAiPlotArgs = {
  uuid: Scalars['String'];
};


export type QueryAiPlotsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AiPlotsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryApplicationFormsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ApplicationFormsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryCalculateAreaArgs = {
  geo: Scalars['GeoJSON'];
  unit: AreaUnit;
};


export type QueryDailyHistoryWeathersArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DailyHistoryWeathersFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryDepartmentArgs = {
  uuid: Scalars['String'];
};


export type QueryDepartmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  devicesFilter?: InputMaybe<DevicesFilter>;
  filter?: InputMaybe<DepartmentsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryDeviceArgs = {
  id: Scalars['ID'];
};


export type QueryDevicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DevicesFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryDomainArgs = {
  uuid: Scalars['String'];
};


export type QueryDomainsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DomainsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryEstimationJobArgs = {
  uuid: Scalars['String'];
};


export type QueryEstimationJobsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EstimationJobsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
};


export type QueryFaqTitlesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryFenceArgs = {
  uuid: Scalars['String'];
};


export type QueryFencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FencesFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryFertilizerArgs = {
  uuid: Scalars['String'];
};


export type QueryFertilizersArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FertilizersFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryFlightRecordArgs = {
  id: Scalars['ID'];
};


export type QueryFlyWaypointListArgs = {
  useNewPpe?: InputMaybe<Scalars['Boolean']>;
  uuid: Scalars['String'];
};


export type QueryGetSegmentCoverageStatusArgs = {
  uuid: Scalars['String'];
};


export type QueryHistoryWeathersArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<HistoryWeathersFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryLandArgs = {
  uuid: Scalars['String'];
};


export type QueryLandsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter: LandsFilter;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
};


export type QueryMapArgs = {
  uuid: Scalars['String'];
};


export type QueryMappingArgs = {
  uuid: Scalars['String'];
};


export type QueryMappingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<MappingsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
};


export type QueryMapsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryMemberArgs = {
  uuid: Scalars['String'];
};


export type QueryMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<MembersFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryMergeJobsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryNdviJobArgs = {
  uuid: Scalars['String'];
};


export type QueryNdviJobClustersArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<NdviJobsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryNdviJobsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<NdviJobsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryOrganizationArgs = {
  uuid: Scalars['String'];
};


export type QueryOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrganizationsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryPatrolsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PatrolsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryPermissionArgs = {
  uuid: Scalars['String'];
};


export type QueryPermissionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryPersonalLicensesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PersonalLicensesFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryPilotUserPermissionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryPlotPatrolArgs = {
  uuid: Scalars['String'];
};


export type QueryPlotPatrolClustersArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PlotPatrolsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryPlotPatrolsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PlotPatrolsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryPlotPatrolsSourceImageArgs = {
  plotPatrolUuid: Scalars['String'];
  sourceImageUuid: Scalars['String'];
};


export type QueryPositionOfInterestArgs = {
  uuid: Scalars['String'];
};


export type QueryPositionOfInterestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PositionOfInterestsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryProcessingJobArgs = {
  uuid: Scalars['String'];
};


export type QueryProcessingJobAuthArgs = {
  uuid: Scalars['String'];
};


export type QueryProcessingJobAuthsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProcessingJobAuthsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryProcessingJobClustersArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProcessingJobsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryProcessingJobSourceImageArgs = {
  processingJobUuid: Scalars['String'];
  sourceImageUuid: Scalars['String'];
};


export type QueryProcessingJobsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProcessingJobsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
};


export type QueryQueryDownloadNdviFertilizerArgs = {
  ndviJobuuid: Scalars['String'];
};


export type QueryRegeoArgs = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};


export type QueryReleaseNotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryRemoteControllerProcessingJobArgs = {
  externalId: Scalars['String'];
};


export type QueryRemoteControllerProcessingJobsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RemoteControllerProcessingJobsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  gps?: InputMaybe<PositionAttributes>;
  sort?: InputMaybe<LandsSort>;
};


export type QuerySeedlingJobArgs = {
  uuid: Scalars['String'];
};


export type QuerySeedlingJobsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SeedlingJobsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
};


export type QuerySegmentStatusArgs = {
  uuid: Scalars['String'];
};


export type QuerySharingCoveragesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SharingCoveragesFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
};


export type QuerySourceImageArgs = {
  uuid: Scalars['String'];
};


export type QueryStatisticsArgs = {
  departmentUuids?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryTagArgs = {
  uuid: Scalars['String'];
};


export type QueryTaggableArgs = {
  taggableType: TaggableType;
  taggableUuid: Scalars['String'];
};


export type QueryTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TagsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
};


export type QueryTaskArgs = {
  id: Scalars['ID'];
};


export type QueryTasksArgs = {
  after: Scalars['String'];
  departmentUuid?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
};


export type QueryUsageOfPrescriptionArgs = {
  shapes?: InputMaybe<Array<Scalars['GeoJSON']>>;
  uuid?: InputMaybe<Scalars['String']>;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UsersFilter>;
  first?: InputMaybe<Scalars['Int']>;
};

export type QueryDownloadNdviFertilizerPayload = {
  __typename?: 'QueryDownloadNdviFertilizerPayload';
  downloadUrl: Scalars['String'];
  /**
   * 只有四种状态: NotExist-不存在 Handling-处理中 Failed-失败 Success-成功
   * 由于规定了一个最长的处理时间，超过了这个时间后就会清除，因此前端需规定一个最长的轮询时间，建议一分钟
   * 如果查到NotExist和Failed状态即直接失败
   */
  status: DownloadNdviFertilizerStatus;
};

export type QuitSharingCoveragesPlanInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type QuitSharingCoveragesPlanPayload = {
  __typename?: 'QuitSharingCoveragesPlanPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export const enum Rank {
  /** 根据违规面积升序排序 */
  InvalidAreaAsc = 'InvalidAreaAsc',
  /** 根据违规面积降序排序 */
  InvalidAreaDesc = 'InvalidAreaDesc',
  /** 根据违规比例升序排序 */
  InvalidRatioAsc = 'InvalidRatioAsc',
  /** 根据违规比例降序排序 */
  InvalidRatioDesc = 'InvalidRatioDesc',
  /** 根据总面积升序排序 */
  TotalAreaAsc = 'TotalAreaAsc',
  /** 根据总面积降序排序（默认） */
  TotalAreaDesc = 'TotalAreaDesc',
  /** 根据合规面积升序排序 */
  ValidAreaAsc = 'ValidAreaAsc',
  /** 根据合规面积降序排序 */
  ValidAreaDesc = 'ValidAreaDesc',
  /** 根据合规比例升序排序 */
  ValidRatioAsc = 'ValidRatioAsc',
  /** 根据合规比例降序排序 */
  ValidRatioDesc = 'ValidRatioDesc'
};

export type RealtimeWeather = {
  __typename?: 'RealtimeWeather';
  /** 体感温度 摄氏度 */
  apparentTemperature?: Maybe<Scalars['Float']>;
  /** 云量 0-1 */
  cloudrate?: Maybe<Scalars['Float']>;
  /** 时间点 */
  datetime?: Maybe<Scalars['Time']>;
  /** 湿度 0-1 */
  humidity?: Maybe<Scalars['Float']>;
  /** 降水量 mm/h */
  precipitation?: Maybe<Scalars['Float']>;
  /** 气压 Pa */
  pressure?: Maybe<Scalars['Float']>;
  /** 天气情况 */
  skyCondition?: Maybe<Scalars['String']>;
  /** 温度（摄氏度） */
  temperature?: Maybe<Scalars['Float']>;
  /** 能见度 km */
  visibility?: Maybe<Scalars['Float']>;
  /** 风向 （北起顺时针的度数） */
  windDirection?: Maybe<Scalars['Float']>;
  /** 风速 km/h */
  windSpeed?: Maybe<Scalars['Float']>;
};

export type Regeo = {
  __typename?: 'Regeo';
  regeocode: Regeocode;
  /** 具体错误码见https://lbs.amap.com/api/webservice/guide/tools/info */
  status: Status;
};

export type Regeocode = {
  __typename?: 'Regeocode';
  addressComponent: AddressComponent;
  formattedAddress: Scalars['String'];
};

export type ReleaseNote = {
  __typename?: 'ReleaseNote';
  /** 内容 */
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  /** 发布时间 */
  publishedAt?: Maybe<Scalars['Time']>;
  /** 版本号 */
  version?: Maybe<Scalars['String']>;
};

export type ReleaseNoteConnection = {
  __typename?: 'ReleaseNoteConnection';
  edges: Array<Maybe<ReleaseNoteEdge>>;
  nodes: Array<Maybe<ReleaseNote>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ReleaseNoteEdge = {
  __typename?: 'ReleaseNoteEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ReleaseNote>;
};

export type RemoteControllerProcessingJob = {
  __typename?: 'RemoteControllerProcessingJob';
  /** 面积,不传单位则默认为亩 */
  area?: Maybe<Scalars['Float']>;
  /** 拍摄时间 */
  captureTime?: Maybe<Scalars['Time']>;
  createdAt?: Maybe<Scalars['Time']>;
  /** bounding box左下角坐标 */
  downLeft?: Maybe<Position>;
  droneType: Scalars['String'];
  /** 上传时候带过来的id,用于标记本地 */
  externalId: Scalars['String'];
  /**
   * 遥控器重建结果；在可预见的时间段内；重建结果文件不会超过5个；所以设计成Array
   * 如果withContentDisposition设置了,则生成的url会把Content-Disposition头设置为attachment; filename="filename.xxx"，便于浏览器下载
   */
  files?: Maybe<Array<File>>;
  /** 是否切割完瓦片 */
  hasTiles: Scalars['Boolean'];
  id?: Maybe<Scalars['ID']>;
  /** 是否已经上传完 */
  isUploaded: Scalars['Boolean'];
  jobType: Scalars['String'];
  location: Scalars['String'];
  mappingMode: Scalars['Int'];
  /** 最大瓦片层级 */
  maximumLevel: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  params?: Maybe<Scalars['String']>;
  /** 位置 */
  position?: Maybe<Position>;
  /** 处理任务状态0：待开始，1：等待中，2：准备中，3：执行中，4：处理结果中，5：已停止，6：执行完成，7：执行失败 */
  status?: Maybe<Scalars['Int']>;
  /** 0代表农田模式 1代表城市模式 2代表果树模式 4代表多光谱模式 默认为0 */
  type?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Time']>;
  /** bounding box右上角坐标 */
  upperRight?: Maybe<Position>;
  uuid?: Maybe<Scalars['String']>;
  /** 生成xyz的高清地图瓦片模板 */
  xyzTileFormat: Scalars['String'];
};


export type RemoteControllerProcessingJobAreaArgs = {
  unit?: InputMaybe<AreaUnit>;
};


export type RemoteControllerProcessingJobDownLeftArgs = {
  cs?: InputMaybe<Cs>;
};


export type RemoteControllerProcessingJobFilesArgs = {
  fileNames?: InputMaybe<Array<Scalars['String']>>;
  withContentDisposition?: InputMaybe<Scalars['Boolean']>;
};


export type RemoteControllerProcessingJobPositionArgs = {
  cs?: InputMaybe<Cs>;
};


export type RemoteControllerProcessingJobUpperRightArgs = {
  cs?: InputMaybe<Cs>;
};

export type RemoteControllerProcessingJobConnection = {
  __typename?: 'RemoteControllerProcessingJobConnection';
  edges: Array<Maybe<RemoteControllerProcessingJobEdge>>;
  nodes: Array<Maybe<RemoteControllerProcessingJob>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type RemoteControllerProcessingJobEdge = {
  __typename?: 'RemoteControllerProcessingJobEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<RemoteControllerProcessingJob>;
};

export type RemoteControllerProcessingJobExternalIdInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  externalId: Scalars['String'];
};

export type RemoteControllerProcessingJobsFilter = {
  endTime?: InputMaybe<Scalars['Time']>;
  /** 外部id */
  externalId?: InputMaybe<Scalars['String']>;
  /** 是否已经上传完成 */
  isUploaded?: InputMaybe<Scalars['Boolean']>;
  /** 支持Agras-遥控器,Terra-智图PC */
  jobType?: InputMaybe<JobType>;
  /** 筛选类型,这里传入的是重建任务的jobtype */
  jobTypes?: InputMaybe<Array<Scalars['Int']>>;
  /** 名字或地址搜索 */
  keyword?: InputMaybe<Scalars['String']>;
  /** 任务类型,8-航测面模式,9-航测圈模式,10-航测果树模式 */
  mappingMode?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['Time']>;
  /** 状态 */
  statuses?: InputMaybe<Array<Scalars['Int']>>;
};

export type RemoveTagInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 哪个资源 */
  taggableType: TaggableType;
  /** 相应的资源uuid */
  taggableUuid: Scalars['String'];
  /** 标签的uuid */
  uuid: Scalars['String'];
};

export type RemoveTagPayload = {
  __typename?: 'RemoveTagPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  tag?: Maybe<Tag>;
};

export type ResizeParams = {
  /** 指定目标缩略图的高度 */
  h?: InputMaybe<Scalars['Int']>;
  /** 指定缩放的模式,请传lfit或mfit */
  m?: InputMaybe<Scalars['String']>;
  /** 指定目标缩略图的宽度 */
  w?: InputMaybe<Scalars['Int']>;
};

export const enum Role {
  DepartmentAdmin = 'DepartmentAdmin',
  DepartmentUser = 'DepartmentUser',
  OrgAdmin = 'OrgAdmin',
  OrgUser = 'OrgUser',
  SystemAdmin = 'SystemAdmin',
  Unknown = 'Unknown'
};

/** 更详细文档请查看 https://doc.djicorp.com/view/64e6e6c9-54f3-4510-ab98-1eef909f868a */
export type RunPathPlanningInput = {
  /** 是否绕过障碍物 */
  avoidObstacle: Scalars['Boolean'];
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 航线平滑度 */
  followAccuracy: Scalars['Float'];
  /** 是否实时航线 */
  isRealTime: Scalars['Boolean'];
  /** 地块的uuid */
  plotUuid: Scalars['String'];
  /** 航线角度，度 */
  sprayDirection: Scalars['Float'];
  /** 航线高度，米 */
  sprayHeight: Scalars['Float'];
  /**
   * 航线类型
   * 连续喷洒-自动等距（区域规划-连续喷洒) 0
   * 定点喷洒-自动树心（区域规划-树心定点喷洒）1
   * 连续喷洒-自动树心（区域规划-树心连续喷洒）	6
   * 连续喷洒-手动（自由航线规划-连续喷洒）3
   * 定点喷洒-半自动（自由航线规划-树心定点喷洒）4
   * 连续喷洒-半自动（自由航线规划-树心连续喷洒）5
   */
  sprayType: Scalars['Float'];
  /** 航线宽度，米 */
  sprayWidth: Scalars['Float'];
};

export type RunPathPlanningPayload = {
  __typename?: 'RunPathPlanningPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  flyWaypointList?: Maybe<FlyWaypointList>;
  result?: Maybe<PathPlanningResult>;
};

export type S3StsInit = {
  __typename?: 'S3StsInit';
  authorization: Authorization;
  cloudBucketName: Scalars['String'];
  cloudName: Scalars['String'];
  endPoint: Scalars['String'];
  objectKey: Scalars['String'];
  region: Scalars['String'];
};

export type SampleNdviInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 任务的uuid */
  processingJobUuid: Scalars['String'];
};

export type SampleNdviPayload = {
  __typename?: 'SampleNDVIPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SeedlingJob = {
  __typename?: 'SeedlingJob';
  /**
   * 出苗分析结果
   * 如果传边界则是边界内的分析结果
   * 如果不传边界则是整个的出苗分析结果
   * 分析结果不分页数
   * 注意就算分析任务成功了，也有可能结果是空，因为有可能圈的范围内没有图片，那就是空结果
   */
  analysisResult?: Maybe<AnalysisResult>;
  /** 拍摄时间，如果没照片那就是空 */
  captureTime?: Maybe<Scalars['Time']>;
  createdAt: Scalars['Time'];
  department?: Maybe<Department>;
  /** 部门链，顺序从底层到顶层 */
  departmentLinks?: Maybe<Array<Maybe<Department>>>;
  djiServiceUuid?: Maybe<Scalars['String']>;
  downLeft?: Maybe<Position>;
  id: Scalars['ID'];
  mappingUuid: Scalars['String'];
  name: Scalars['String'];
  outputResourceUuid?: Maybe<Scalars['String']>;
  percentRemote: Scalars['Float'];
  position?: Maybe<Position>;
  processingJobUuid?: Maybe<Scalars['String']>;
  resourceUuid?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
  updatedAt: Scalars['Time'];
  upperRight?: Maybe<Position>;
  uuid: Scalars['String'];
};


export type SeedlingJobAnalysisResultArgs = {
  geo?: InputMaybe<Scalars['GeoJSON']>;
};

export type SeedlingJobEdge = {
  __typename?: 'SeedlingJobEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<SeedlingJob>;
};

export type SeedlingJobsConnection = {
  __typename?: 'SeedlingJobsConnection';
  edges: Array<Maybe<SeedlingJobEdge>>;
  nodes: Array<Maybe<SeedlingJob>>;
  pageInfo?: Maybe<PageInfoNew>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SeedlingJobsFilter = {
  bbox?: InputMaybe<BoundingBoxAttributes>;
  captureAfter?: InputMaybe<Scalars['Time']>;
  captureBefore?: InputMaybe<Scalars['Time']>;
  createdAfter?: InputMaybe<Scalars['Time']>;
  createdBefore?: InputMaybe<Scalars['Time']>;
  departmentUuids?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<Scalars['Int']>>;
  uuids?: InputMaybe<Array<Scalars['String']>>;
};

export type SeedlingSourceImage = {
  __typename?: 'SeedlingSourceImage';
  /** 苗数量 */
  seedlingCount: Scalars['Int'];
  /** 苗密度，个/平方米 */
  seedlingDensity: Scalars['Float'];
};

export type SegmentPartial = {
  area: Scalars['GeoJSON'];
  value: Scalars['Int'];
};

export const enum SegmentStatus {
  Failed = 'FAILED',
  Ok = 'OK',
  Processing = 'PROCESSING',
  Unknown = 'UNKNOWN'
};

export type SetAgentInfoInput = {
  agentName: Scalars['String'];
  agentPhone: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
};

export type SetAgentInfoPayload = {
  __typename?: 'SetAgentInfoPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SharingCoverage = {
  __typename?: 'SharingCoverage';
  captureTime: Scalars['Time'];
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  position: Position;
  user?: Maybe<User>;
  uuid: Scalars['String'];
};

export type SharingCoverageConnection = {
  __typename?: 'SharingCoverageConnection';
  edges: Array<Maybe<SharingCoverageEdge>>;
  nodes: Array<Maybe<SharingCoverage>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SharingCoverageEdge = {
  __typename?: 'SharingCoverageEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<SharingCoverage>;
};

export type SharingCoveragesFilter = {
  /** 查地理位置被该boundingbox相交的共享地块 */
  bbox?: InputMaybe<BoundingBoxAttributes>;
};

export const enum SkyCond {
  /** 晴（白天） */
  ClearDay = 'CLEAR_DAY',
  /** 晴（夜间） */
  ClearNight = 'CLEAR_NIGHT',
  /** 阴 */
  Cloudy = 'CLOUDY',
  /** 浮尘 */
  Dust = 'DUST',
  /** 雾 */
  Fog = 'FOG',
  /** 重度雾霾 */
  HeavyHaze = 'HEAVY_HAZE',
  /** 大雨 */
  HeavyRain = 'HEAVY_RAIN',
  /** 大雪 */
  HeavySnow = 'HEAVY_SNOW',
  /** 轻度雾霾 */
  LightHaze = 'LIGHT_HAZE',
  /** 小雨 */
  LightRain = 'LIGHT_RAIN',
  /** 小雪 */
  LightSnow = 'LIGHT_SNOW',
  /** 中度雾霾 */
  ModerateHaze = 'MODERATE_HAZE',
  /** 中雨 */
  ModerateRain = 'MODERATE_RAIN',
  /** 中雪 */
  ModerateSnow = 'MODERATE_SNOW',
  /** 多云（白天） */
  PartlyCloudyDay = 'PARTLY_CLOUDY_DAY',
  /** 多云（夜间） */
  PartlyCloudyNight = 'PARTLY_CLOUDY_NIGHT',
  /** 沙尘 */
  Sand = 'SAND',
  /** 暴雨 */
  StormRain = 'STORM_RAIN',
  /** 暴雪 */
  StormSnow = 'STORM_SNOW',
  /** 大风 */
  Wind = 'WIND'
};

export const enum Sort {
  /** 顺序 */
  Asc = 'ASC',
  /** 按照拍摄时间顺序 */
  CaptureTimeAsc = 'CAPTURE_TIME_ASC',
  /** 按照拍摄时间逆序 */
  CaptureTimeDesc = 'CAPTURE_TIME_DESC',
  /** 倒序 */
  Desc = 'DESC'
};

export type SourceImage = TaggableI & {
  __typename?: 'SourceImage';
  /** 原图的contentMd5 */
  contentMd5?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Time']>;
  /** oss鉴权 */
  credential?: Maybe<Credential>;
  /** 图片详细信息 */
  fileDetails?: Maybe<FileDetails>;
  /** 原图扩展名 */
  fileExtension?: Maybe<Scalars['String']>;
  /** 原图名 */
  fileName?: Maybe<Scalars['String']>;
  /** 原图大小 */
  fileSize?: Maybe<Scalars['Int']>;
  /** 原图id */
  id?: Maybe<Scalars['ID']>;
  /** 原图所属测绘id */
  mappingId?: Maybe<Scalars['ID']>;
  /** 原文件的文件名 */
  originalFileName?: Maybe<Scalars['String']>;
  /** 使用s3的sts上传信息 */
  s3StsInit?: Maybe<S3StsInit>;
  /**
   * 图片访问的url，目前只支持传质量参数和缩放参数，并且tif也会转为jpg。将来可能会扩展
   *
   * 如果不传质量参数则直接给原图，并且tif不会转为jpg
   *
   * 传质量参数则会进行压缩
   *
   * 可以对图片做处理，详细文档请见
   *
   * https://help.aliyun.com/document_detail/44705.html?spm=a2c4g.11186623.6.1673.1050e849KQYUNi
   *
   * https://help.aliyun.com/document_detail/44688.html?spm=a2c4g.11186623.2.10.4d98697f9Bpsf7
   *
   * https://help.aliyun.com/document_detail/44703.html?spm=a2c4g.11186623.6.1671.335cc1f6DYLiBr
   *
   * https://help.aliyun.com/document_detail/44704.html?spm=a2c4g.11186623.6.742.43414fdeO1BzZE
   */
  signURL?: Maybe<Scalars['String']>;
  /** 原图状态，0-未上传oss，1-已上传oss且同步成功，2-同步失败 */
  status?: Maybe<Scalars['Int']>;
  /** 存储信息 */
  storageService?: Maybe<StorageService>;
  taggableId: Scalars['ID'];
  taggableType: TaggableType;
  taggableUuid: Scalars['String'];
  tags?: Maybe<TagConnection>;
  /** 如果是jpg，则可以取到相对应的tiff */
  tiffs: Array<Maybe<SourceImage>>;
  updatedAt?: Maybe<Scalars['Time']>;
  /** 原图uuid */
  uuid?: Maybe<Scalars['String']>;
};


export type SourceImageSignUrlArgs = {
  format?: InputMaybe<Scalars['String']>;
  interlace?: InputMaybe<Interlace>;
  quality?: InputMaybe<QualityParams>;
  resize?: InputMaybe<ResizeParams>;
};


export type SourceImageTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TagsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};

export type SourceImageAttributes = {
  contentMd5: Scalars['String'];
  fileDetails: Scalars['String'];
  fileExtension: Scalars['String'];
  fileName: Scalars['String'];
  /** 单位为b */
  fileSize: Scalars['Int'];
  originalFileName?: InputMaybe<Scalars['String']>;
};

export type SourceImageEdge = {
  __typename?: 'SourceImageEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<SourceImage>;
};

/** 图片类型 */
export const enum SourceImageType {
  Jpg = 'JPG',
  Tif = 'TIF'
};

export type SourceImagesAttributes = {
  mappingUuid: Scalars['String'];
  sourceImages: Array<SourceImageAttributes>;
};

export type SourceImagesConnection = {
  __typename?: 'SourceImagesConnection';
  edges: Array<Maybe<SourceImageEdge>>;
  nodes: Array<Maybe<SourceImage>>;
  pageInfo?: Maybe<PageInfoNew>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SourceImagesConnectionV2 = {
  __typename?: 'SourceImagesConnectionV2';
  nodes: Array<Maybe<SourceImage>>;
  /**
   * 用于兼容原智慧云中的page info和云重建中的page info
   *
   * 只要是云重建中的schema都用这个分页PageInfoNew
   */
  pageInfo?: Maybe<PageInfoNew>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SourceImagesFilter = {
  /** 图片状态，0为未上传成功，1为上传成功 */
  status?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<SourceImageType>;
};

export type SourceImagesFilterV2 = {
  /** 图片状态，0为未上传成功，1为上传成功 */
  status?: InputMaybe<Scalars['Int']>;
  type: SourceImageType;
};

export const enum SourceType {
  /** 农服app */
  DjiApp = 'DJIApp',
  /** pilot h5 */
  PilotH5 = 'PilotH5'
};

/** 速度单位 */
export const enum SpeedUnit {
  /** ft/m */
  FeetPerSecond = 'FEET_PER_SECOND',
  /** km/h */
  KilometrePerHour = 'KILOMETRE_PER_HOUR',
  /** knot */
  Knot = 'KNOT',
  /** m/s */
  MeterPerSecond = 'METER_PER_SECOND',
  /** mi/h */
  MilesPerHour = 'MILES_PER_HOUR'
};

export type StartAiPlotJobInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** processing job uuid，要开启哪个job的ai地块识别 */
  processingJobUuid: Scalars['String'];
};

export type StartAiPlotJobPayload = {
  __typename?: 'StartAiPlotJobPayload';
  aiPlotJob?: Maybe<AiPlotJob>;
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type StartAutoProcessingJobInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  mappingUuid: Scalars['String'];
};

export type StartAutoProcessingJobPayload = {
  __typename?: 'StartAutoProcessingJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type StartCompressionJobForDjitileInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 自由航线uuid列表 */
  freePlotUuids?: InputMaybe<Array<Scalars['String']>>;
  /** 处方图uuid列表 */
  ndviJobUuids?: InputMaybe<Array<Scalars['String']>>;
  /** 地块uuid列表 */
  plotUuids?: InputMaybe<Array<Scalars['String']>>;
  /** 要开启的 processing job 的uuid */
  uuid: Scalars['String'];
};

export type StartCompressionJobForDjitilePayload = {
  __typename?: 'StartCompressionJobForDjitilePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  compressionJobForDjitile?: Maybe<CompressionJobForDjitile>;
};

export type StartEstimationJobInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type StartEstimationJobPayload = {
  __typename?: 'StartEstimationJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  estimationJob?: Maybe<EstimationJob>;
};

export type StartMediaUploadInput = {
  fileGroupId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  sn?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  /**    mediaMissionType: Int */
  useType?: InputMaybe<UseType>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type StartMergeJobInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type StartMergeJobPayload = {
  __typename?: 'StartMergeJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  mergeJob?: Maybe<MergeJob>;
};

export type StartProcessingJobInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 要开启的任务的uuid */
  uuid: Scalars['String'];
};

export type StartProcessingJobPayload = {
  __typename?: 'StartProcessingJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  processingJob?: Maybe<ProcessingJob>;
};

export type StartSeedlingJobInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type StartSeedlingJobPayload = {
  __typename?: 'StartSeedlingJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  seedlingJob?: Maybe<SeedlingJob>;
};

export type Statistics = {
  __typename?: 'Statistics';
  /** 当前活跃的植保机数量 */
  activeDroneCount: Scalars['Int'];
  /** 今年累计作业亩数排行，是农场的排行 */
  areaRank: Array<DepartmentInfo>;
  /** 作业亩数趋势 */
  areaTrend: Array<AreaInfo>;
  /** 今天的作业面积，单位0.01亩 */
  todayArea: Scalars['Int'];
  /** 今天作业地块数量 */
  todayLandCount: Scalars['Int'];
  /** 今年累计作业面积 */
  yearArea: Scalars['Int'];
};

export type Status = {
  __typename?: 'Status';
  info: Scalars['String'];
  infocode: Scalars['String'];
  status: Scalars['String'];
};

export type StorageService = {
  __typename?: 'StorageService';
  /** 桶名称 */
  bucketId?: Maybe<Scalars['String']>;
  /** 上传域名 */
  endpoint?: Maybe<Scalars['String']>;
  /** uuid格式 */
  objectId?: Maybe<Scalars['String']>;
  /**
   * 路径前缀，目前上传重建原图不需要前缀，上传树心点文件需要tree_infos
   * key请组合成prefix/objectId
   * 如：prefix="tree_infos", objectId="abc"
   * 则上传的key为"tree_infos/abc"
   */
  prefix?: Maybe<Scalars['String']>;
};

export type StreetNumber = {
  __typename?: 'StreetNumber';
  direction: Scalars['String'];
  distance: Scalars['String'];
  location: Scalars['String'];
  number: Scalars['String'];
  street: Scalars['String'];
};

/** STS 资源 */
export type Sts = {
  __typename?: 'Sts';
  credential?: Maybe<Credential>;
  isExisted: Scalars['Boolean'];
  s3StsInit?: Maybe<S3StsInit>;
  storageService?: Maybe<StorageService>;
};

/** sts 创建入参 */
export type StsAttributes = {
  /** 上传文件MD5 */
  contentMd5: Scalars['String'];
  /** 上传文件大小（单位bytes） */
  fileSize: Scalars['Int'];
  /** 文件名称 */
  name?: InputMaybe<Scalars['String']>;
  /**
   * 由于int32的溢出考虑,使用新的fileSize
   * 传了该字段则用该字段,否则用fileSize.请优先使用这个字段,废弃fileSize
   */
  newFileSize?: InputMaybe<Scalars['Float']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  arrivedRealtimeWeather?: Maybe<RealtimeWeather>;
  estimationJobsStatus: Array<Maybe<EstimationJob>>;
  mappingStatus: MappingStatus;
  processingJobsStatus: Array<Maybe<ProcessingJob>>;
  waypoints: Array<DeviceWithWaypoint>;
};


export type SubscriptionArrivedRealtimeWeatherArgs = {
  positionOfInterestUuid: Scalars['String'];
};


export type SubscriptionEstimationJobsStatusArgs = {
  uuids: Array<Scalars['String']>;
};


export type SubscriptionMappingStatusArgs = {
  uuid: Scalars['String'];
};


export type SubscriptionProcessingJobsStatusArgs = {
  uuids: Array<Scalars['String']>;
};

export type SummaryWeather = {
  /** 体感温度 摄氏度 */
  apparentTemperature?: Maybe<MaxMinAvg>;
  /** 云量 0-1 */
  cloudRate?: Maybe<MaxMinAvg>;
  /** 时间点 */
  datetime?: Maybe<Scalars['Time']>;
  /** 向下短波辐射通量 */
  dswrf?: Maybe<MaxMinAvg>;
  /** 湿度 0-1 */
  humidity?: Maybe<MaxMinAvg>;
  /** 降水量 mm/h */
  precipitation?: Maybe<MaxMinAvg>;
  /** 气压 Pa */
  pressure?: Maybe<MaxMinAvg>;
  /** 天气现象 */
  skyCondition?: Maybe<Array<Maybe<SkyCond>>>;
  /** 温度（摄氏度） */
  temperature?: Maybe<MaxMinAvg>;
  /** 能见度 km */
  visibility?: Maybe<MaxMinAvg>;
  /** 风向 （北起顺时针的度数） */
  windDirection?: Maybe<MaxMinAvg>;
  /** 风速 km/h */
  windSpeed?: Maybe<MaxMinAvg>;
};


export type SummaryWeatherApparentTemperatureArgs = {
  unit?: InputMaybe<TemperatureUnit>;
};


export type SummaryWeatherPressureArgs = {
  unit?: InputMaybe<PressureUnit>;
};


export type SummaryWeatherTemperatureArgs = {
  unit?: InputMaybe<TemperatureUnit>;
};


export type SummaryWeatherVisibilityArgs = {
  unit?: InputMaybe<LenghtUnit>;
};


export type SummaryWeatherWindSpeedArgs = {
  unit?: InputMaybe<SpeedUnit>;
};

export type SummaryWeathersFilter = {
  /** sql 片段方式（仅占位,暂不开启） */
  cond?: InputMaybe<Scalars['String']>;
  /** 数据统计间隔 */
  internal?: InputMaybe<Internal>;
  /** 气象观测点的uuid */
  positionOfInterestUuid?: InputMaybe<Scalars['String']>;
};

export type SyncCallbackInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  externalId: Scalars['String'];
  /** 任务的本地id */
  storageUuids: Array<Scalars['String']>;
};

export type SyncCallbackPayload = {
  __typename?: 'SyncCallbackPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SyncLandsToPlots = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 条田uuid列表 */
  landUuids: Array<Scalars['String']>;
  /** 重建任务uuid */
  processingJobUuid: Scalars['String'];
};

export type SyncLandsToPlotsPayload = {
  __typename?: 'SyncLandsToPlotsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  plots?: Maybe<Array<Maybe<Plot>>>;
};

export type SyncRemoteControllerProcessingJobPayload = {
  __typename?: 'SyncRemoteControllerProcessingJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<Sts>>>;
};

export type Tag = {
  __typename?: 'Tag';
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  name: Scalars['String'];
  /**
   * TODO
   * 其上一级标签
   */
  parentTag?: Maybe<Tag>;
  /**
   * TODO
   * 其下一级标签
   */
  subTags?: Maybe<TagConnection>;
  updatedAt: Scalars['Time'];
  uuid: Scalars['String'];
};


export type TagSubTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TagsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};

export type TagConnection = {
  __typename?: 'TagConnection';
  edges: Array<Maybe<TagEdge>>;
  nodes: Array<Maybe<Tag>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TagEdge = {
  __typename?: 'TagEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Tag>;
};

export type Taggable = TaggableI & {
  __typename?: 'Taggable';
  taggableId: Scalars['ID'];
  taggableType: TaggableType;
  taggableUuid: Scalars['String'];
  tags?: Maybe<TagConnection>;
};


export type TaggableTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TagsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};

/** 可打标签的类型的interface */
export type TaggableI = {
  taggableId: Scalars['ID'];
  taggableType: TaggableType;
  taggableUuid: Scalars['String'];
  tags?: Maybe<TagConnection>;
};


/** 可打标签的类型的interface */
export type TaggableITagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TagsFilter>;
  first?: InputMaybe<Scalars['Int']>;
};

/** 标签要加在什么上面 */
export const enum TaggableType {
  NdviJob = 'NdviJob',
  PlotPatrol = 'PlotPatrol',
  PlotPatrolsSourceImage = 'PlotPatrolsSourceImage',
  ProcessingJob = 'ProcessingJob',
  ProcessingJobSourceImage = 'ProcessingJobSourceImage',
  SourceImage = 'SourceImage'
};

export type TagsFilter = {
  /** 名字的模糊查询 */
  name?: InputMaybe<Scalars['String']>;
};

export type Task = {
  __typename?: 'Task';
  /** 开始时间，格式如20200501 */
  beginDate?: Maybe<Scalars['Int']>;
  /** 任务的配置 */
  configuration?: Maybe<Configuration>;
  /** 任务所属的组织，是个农场（一级部门） */
  department?: Maybe<Department>;
  /** 部门链，顺序从底层到顶层 */
  departmentLinks?: Maybe<Array<Maybe<Department>>>;
  /**
   * 设备列表，是在该任务的时间区间内，有产生过飞行记录的飞机的列表
   *
   * 如果某台飞机已经被删除了，在飞行记录中是存在的，但是这里不筛选出来
   *
   * 如果单纯只是想拿到该任务下的飞行记录中所有的飞机，那就不要传filter
   */
  devices?: Maybe<DeviceConnection>;
  /** 是否需要违规通知 */
  enableNotify: Scalars['Boolean'];
  /** 是否监管遥控器 */
  enablePush: Scalars['Boolean'];
  /** 是否开启质量监管 */
  enableQuality: Scalars['Boolean'];
  /** 结束，格式如20200501 */
  endDate?: Maybe<Scalars['Int']>;
  /** 导出文件URL */
  exportFileUrl?: Maybe<Scalars['String']>;
  /** 作业导出状态 */
  exportStatus: ExportStatus;
  id?: Maybe<Scalars['ID']>;
  /** 任务名称 */
  name?: Maybe<Scalars['String']>;
  /** 该作业下的质量信息，这里可以筛选飞行记录 */
  quality: Quality;
  /** 作业类型 */
  taskType?: Maybe<TaskType>;
  /** 该任务总的作业面积 */
  totalWorkArea?: Maybe<Scalars['Int']>;
};


export type TaskDevicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DevicesFilter>;
  first?: InputMaybe<Scalars['Int']>;
};


export type TaskQualityArgs = {
  filter?: InputMaybe<FlightRecordFilter>;
};

export type TaskConnection = {
  __typename?: 'TaskConnection';
  edges: Array<Maybe<TaskEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TaskEdge = {
  __typename?: 'TaskEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Task>;
};

export type TaskType = {
  __typename?: 'TaskType';
  /** 该作业类型所属的 */
  deviceType?: Maybe<DeviceType>;
  id: Scalars['ID'];
  /**
   * 标签，可确保全局唯一，用于前端筛选
   * Spraying-植保无人机作业 Subsoiling-深松作业 Tractoring-拖拉机作业
   */
  label?: Maybe<Scalars['String']>;
  /** Spraying-植保无人机作业 Subsoiling-深松作业 Tractoring-拖拉机作业 */
  name: Scalars['String'];
};

export type TechFeedback = {
  __typename?: 'TechFeedback';
  createdAt: Scalars['Time'];
  description: Scalars['String'];
  id: Scalars['ID'];
  picUUID: Scalars['String'];
  sentryUUID: Scalars['String'];
  updatedAt: Scalars['Time'];
};

export type TechFeedbackAttributes = {
  description?: InputMaybe<Scalars['String']>;
  /** 图片内容 base64 编码 */
  picContent?: InputMaybe<Scalars['String']>;
  sentryUUID?: InputMaybe<Scalars['String']>;
};

/** 温度单位 */
export const enum TemperatureUnit {
  /** 摄氏 */
  Celsius = 'CELSIUS',
  /** 华氏 */
  Fahrenheit = 'FAHRENHEIT',
  /** 开尔文 */
  Kelvin = 'KELVIN'
};

export type TreeInfo = {
  __typename?: 'TreeInfo';
  contentMd5: Scalars['String'];
  createdAt: Scalars['Time'];
  /** oss鉴权 */
  credential?: Maybe<Credential>;
  id: Scalars['ID'];
  /** 存在桶中的前缀 */
  prefix: Scalars['String'];
  /** 使用s3的sts上传信息 */
  s3StsInit?: Maybe<S3StsInit>;
  signURL?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
  /** 存储信息 */
  storageService?: Maybe<StorageService>;
  uuid: Scalars['String'];
};

export type TreeInfoAttributes = {
  contentMd5: Scalars['String'];
  /** 文件大小，单位b */
  fileSize: Scalars['Int'];
  processingJobUuid: Scalars['String'];
};

export type UpdateEstimationJobInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 名字 */
  name?: InputMaybe<Scalars['String']>;
  /** 作物类型 */
  plant?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type UpdateEstimationJobPayload = {
  __typename?: 'UpdateEstimationJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  estimationJob?: Maybe<EstimationJob>;
};

export type UpdateFreePlotAttributes = {
  /** 地块的标定点 */
  caliPoints?: InputMaybe<Array<Array<Scalars['Float']>>>;
  edgesPointsSource?: InputMaybe<Scalars['String']>;
  /** LineString */
  geo?: InputMaybe<Scalars['LineStringGeoJSON']>;
  name?: InputMaybe<Scalars['String']>;
  ppeParams?: InputMaybe<PpeParamsInput>;
  removeStartPoint?: InputMaybe<Scalars['Boolean']>;
  startPoint?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type UpdateFreePlotInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  updateFreePlotAttributes: UpdateFreePlotAttributes;
};

export type UpdateFreePlotPayload = {
  __typename?: 'UpdateFreePlotPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  freePlot?: Maybe<FreePlot>;
};

export type UpdateMappingInput = {
  auto?: InputMaybe<Scalars['Boolean']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 更新名字 */
  name?: InputMaybe<Scalars['String']>;
  /** 更新备注 */
  remark?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type UpdateMergeJobAttributes = {
  planMergeAt?: InputMaybe<Scalars['Time']>;
};

export type UpdateMergeJobInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 更新排序 */
  updateMergeJobAttributes: UpdateMergeJobAttributes;
  uuid: Scalars['String'];
};

export type UpdateMergeJobPayload = {
  __typename?: 'UpdateMergeJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  mergeJob?: Maybe<MergeJob>;
};

export type UpdatePermissionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 授权属性 */
  permissionAttributes: PermissionAttributes;
  uuid: Scalars['String'];
};

export type UpdatePermissionPayload = {
  __typename?: 'UpdatePermissionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  permission?: Maybe<Permission>;
};

/** 更新地块参数 */
export type UpdatePlotAttributes = {
  /**
   * 地块的标定点
   * 请依次传入经度、纬度和高度，如没有高度则传入0
   * 如[[122.75609, 35.376234, 0], [122.75667, 35.376245, 0]]
   */
  caliPoints?: InputMaybe<Array<Array<Scalars['Float']>>>;
  edgesPointsSource?: InputMaybe<Scalars['String']>;
  /**
   * 地块用于生成处方图的参数
   * 由于有可能会变化，因此使用string表示，传入合适的json字符串
   * 例子：平衡法 - {"averageAmountPerUnit": 1.0, "maxAmountPerUnit": 2.0, "minAmountPerUnit": 3.0}
   * 分级法 - {"zonation": 3, "ndviMap": [-0.3, 0.5], "amountPerUnit": [3, 5]}
   */
  fertilizerParams?: InputMaybe<Scalars['String']>;
  /** 地块的地理位置信息GeoJSON字符串，目前只支持无空洞多边形 */
  geo?: InputMaybe<Scalars['GeoJSON']>;
  /** 地块名字 */
  name?: InputMaybe<Scalars['String']>;
  /**
   * 地块用于生成航线的参数
   * 由于有可能会变化，因此使用string表示，传入合适的json字符串
   */
  ppeParams?: InputMaybe<PpeParamsInput>;
  removeStartPoint?: InputMaybe<Scalars['Boolean']>;
  startPoint?: InputMaybe<Scalars['String']>;
  /** 地块的uuid */
  uuid: Scalars['String'];
};

export type UpdatePlotInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 地块属性 */
  updatePlotAttributes: UpdatePlotAttributes;
};

export type UpdatePlotPatrolInput = {
  /** 更新任务开始时间 */
  beginDate?: InputMaybe<Scalars['Time']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 更新任务结束时间 */
  endDate?: InputMaybe<Scalars['Time']>;
  /** 更新名字 */
  name?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
  /** 鸟瞰 俯瞰 */
  viewType?: InputMaybe<Scalars['Int']>;
};

export type UpdatePlotPatrolPayload = {
  __typename?: 'UpdatePlotPatrolPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  plotPatrol: PlotPatrol;
};

export type UpdatePlotPatrolsSourceImageInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  comment: Scalars['String'];
  plotPatrolUuid: Scalars['ID'];
  sourceImageUuid: Scalars['ID'];
  tagUuids?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdatePlotPatrolsSourceImagePayload = {
  __typename?: 'UpdatePlotPatrolsSourceImagePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  plotPatrolsSourceImage: PlotPatrolsSourceImage;
};

export type UpdatePlotPayload = {
  __typename?: 'UpdatePlotPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 地块 */
  plot?: Maybe<Plot>;
};

export type UpdateProcessingJobInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  /** 标签的uuid，注意会全量更新 */
  tagUuids?: InputMaybe<Array<Scalars['String']>>;
  uuid: Scalars['String'];
};

export type UpdateProcessingJobPayload = {
  __typename?: 'UpdateProcessingJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  processingJob?: Maybe<ProcessingJob>;
};

export type UpdateProcessingJobSourceImageInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  processingJobUuid: Scalars['ID'];
  sourceImageUuid: Scalars['ID'];
  tagUuids?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateProcessingJobSourceImagePayload = {
  __typename?: 'UpdateProcessingJobSourceImagePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  processingJobSourceImage: ProcessingJobSourceImage;
};

export type UpdateRemoteControllerProcessingJobInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  externalId: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateRemoteControllerProcessingJobPayload = {
  __typename?: 'UpdateRemoteControllerProcessingJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateSeedlingJobInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 名字 */
  name?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type UpdateSeedlingJobPayload = {
  __typename?: 'UpdateSeedlingJobPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  seedlingJob?: Maybe<SeedlingJob>;
};

export type UpdateSegmentInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 数组顺序越靠后代表图层z-index越大 */
  segmentPartials: Array<SegmentPartial>;
  /** segment uuid */
  uuid: Scalars['String'];
};

export type UpdateSegmentPayload = {
  __typename?: 'UpdateSegmentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  processingJobUuid: Scalars['String'];
};

export type UpdateTagInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type UpdateTagPayload = {
  __typename?: 'UpdateTagPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  tag?: Maybe<Tag>;
};

export type UpdateUserAttributes = {
  /** 名称 */
  name?: InputMaybe<Scalars['String']>;
  /** 手机号 */
  phone?: InputMaybe<Scalars['String']>;
  /** 类型，目前只提供苏垦 */
  source?: InputMaybe<Scalars['String']>;
};

export type UploadCallbackAttributes = {
  ext?: InputMaybe<MediaFile>;
  fingerprint?: InputMaybe<Scalars['String']>;
  metaData?: InputMaybe<MetaData>;
  name?: InputMaybe<Scalars['String']>;
  objectKey?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<Scalars['Int']>;
  subFileType?: InputMaybe<Scalars['String']>;
};

export type UploadFailedMessageInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 照片库的uuid */
  uuid: Scalars['String'];
};

export type UploadFailedMessagePayload = {
  __typename?: 'UploadFailedMessagePayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export const enum UploadStatus {
  /** 此时的照片库存在上传或同步失败的照片 */
  Failed = 'Failed',
  /** 此时的照片库有图片且图片已经全部传完 */
  Finished = 'Finished',
  /** 此时的照片库是没有照片的空状态 */
  Pending = 'Pending',
  /** 此时的照片库是有图片正在上传但是没传完 */
  Uploading = 'Uploading'
};

export type UploadStorageCallbackInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type UploadStorageCallbackPayload = {
  __typename?: 'UploadStorageCallbackPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UseType = {
  id?: InputMaybe<Scalars['Float']>;
  text?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  /** 用户填入的代理商名字 */
  agentName: Scalars['String'];
  /** 用户填入的代理商电话 */
  agentPhone: Scalars['String'];
  /** 查看合并图层组的key */
  authKey?: Maybe<Scalars['String']>;
  /** 是否允许查看地块真实面积 */
  canViewRealArea: Scalars['Boolean'];
  /** members字段包含该用户的角色，组织关系等字段，由组织关系模块开发 */
  createdAt?: Maybe<Scalars['Time']>;
  /** 部门链，顺序从底层到顶层 */
  departmentLinks?: Maybe<Array<Maybe<Department>>>;
  /** 该用户是个人版用户还是企业版用户 */
  edition: Edition;
  /** 邮箱（个人版需求引入） */
  email?: Maybe<Scalars['String']>;
  /** 个人版用户过期时间 */
  endAt: Scalars['Time'];
  /** 是否首次登陆 */
  firstLogin: Scalars['Boolean'];
  /**
   * 用户查询模块，需要查询用户账户（手机号还是openid？两个都给出去），姓名，组织架构，所属农场（从组织架构中取，组织架构给出了所有层级的组织架构），角色
   *
   * 用户本系统中的id
   */
  id?: Maybe<Scalars['ID']>;
  /** 是否已加入共享计划 */
  isSharingCoverage: Scalars['Boolean'];
  /** 合并图层组的名字 */
  layerGroup?: Maybe<Scalars['String']>;
  /** !! user <- 1:1 -> member 情况下的优化路径 */
  member?: Maybe<Member>;
  members?: Maybe<MemberConnection>;
  /** 用户外部系统中的名字 */
  name?: Maybe<Scalars['String']>;
  /** 是否有权使用本系统 */
  permissionFlag: Scalars['Boolean'];
  /** 用户电话号码 */
  phone?: Maybe<Scalars['String']>;
  /** 用户权限 */
  role: Role;
  /** 来源，目前只有苏垦 */
  source?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Time']>;
  /** 是否使用合并图层组 */
  useLayerGroup?: Maybe<Scalars['Boolean']>;
  /** 用户本系统中的uuid */
  uuid?: Maybe<Scalars['String']>;
};

export type UserAttributes = {
  /** 名称 */
  name: Scalars['String'];
  /** 手机号 */
  phone: Scalars['String'];
  /** 类型，目前只提供苏垦 */
  source?: InputMaybe<Scalars['String']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Array<Maybe<UserEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<User>;
};

export const enum UserField {
  /** 名字 */
  Name = 'name',
  /** 手机号 */
  Phone = 'phone'
};

export type UserOfExternalSystem = {
  __typename?: 'UserOfExternalSystem';
  /** 账号（比如：手机、邮箱） */
  account?: Maybe<Scalars['String']>;
  /** 创建时间 */
  createdAt?: Maybe<Scalars['Time']>;
  /** 外部系统id（比如大疆农服的uid） */
  external_id?: Maybe<Scalars['String']>;
  /** 系统ID */
  id?: Maybe<Scalars['ID']>;
  /** 昵称 */
  name?: Maybe<Scalars['String']>;
  /** 更新时间 */
  updatedAt?: Maybe<Scalars['Time']>;
};

/** UserOfExternalSystem connection */
export type UserOfExternalSystemConnection = {
  __typename?: 'UserOfExternalSystemConnection';
  /** UserOfExternalSystem数组片段 */
  edges: Array<Maybe<UserOfExternalSystemEdge>>;
  /** 直接获取元素 */
  nodes: Array<Maybe<UserOfExternalSystem>>;
  /** 分页/connection信息 */
  pageInfo: PageInfo;
  /** 总数量 */
  totalCount: Scalars['Int'];
};

/** UserOfExternalSystem edge */
export type UserOfExternalSystemEdge = {
  __typename?: 'UserOfExternalSystemEdge';
  /** 分页/connection中的游标 */
  cursor?: Maybe<Scalars['String']>;
  /** 记录 */
  node?: Maybe<UserOfExternalSystem>;
};

export type UsersFilter = {
  /** 部门的uuid */
  departmentUuid?: InputMaybe<Scalars['String']>;
  /** 集团的uuid (这个参数表示查询直属集团的uuid) */
  organizationUuid?: InputMaybe<Scalars['String']>;
  /** 可以根据用户名字或者电话号码搜索，只能精确搜索 */
  search?: InputMaybe<Scalars['String']>;
};

export type ValidStartProcessingJobResult = {
  __typename?: 'ValidStartProcessingJobResult';
  /** 只有多光谱模式需要这个字段，缺失的bandName */
  lacks: Array<BandName>;
  /** 0表示不允许重建，1表示允许重建 */
  ok: Scalars['Int'];
};

export type Vendor = {
  __typename?: 'Vendor';
  appId?: Maybe<Scalars['String']>;
  appSecret?: Maybe<Scalars['String']>;
  deviceModels?: Maybe<Array<Maybe<DeviceModel>>>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type VendorConnection = {
  __typename?: 'VendorConnection';
  edges: Array<Maybe<VendorEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type VendorEdge = {
  __typename?: 'VendorEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Vendor>;
};

export type ViewPort = {
  __typename?: 'ViewPort';
  bottomRight: Position;
  topLeft: Position;
};

export type Waypoint = {
  __typename?: 'Waypoint';
  Depth: Scalars['Float'];
  /** 喷洒速度，单位0.01升每分钟 */
  FlowSpeed: Scalars['Float'];
  /** 仓口大小，单位百分比 */
  HatchPercent: Scalars['Int'];
  Height: Scalars['Float'];
  Lat: Scalars['Float'];
  Lng: Scalars['Float'];
  Pto: Scalars['Int'];
  Sn: Scalars['String'];
  /** 播撒速度，单位克/分钟 */
  SowSpeed: Scalars['Float'];
  Speed: Scalars['Float'];
  /** 转盘速度，单位rpm */
  TurnSpeed: Scalars['Int'];
  Type: Scalars['Int'];
  XSpeed: Scalars['Float'];
  YSpeed: Scalars['Float'];
};

export type WaypointsFilter = {
  /** 根据设备类型筛选 */
  deviceTypeId?: InputMaybe<Scalars['ID']>;
};

export type Xmp = {
  __typename?: 'Xmp';
  bandName: Scalars['String'];
  captureUUID: Scalars['String'];
  droneID: Scalars['String'];
};

export type _Service = {
  __typename?: '_Service';
  sdl: Scalars['String'];
};

/** 创建部门的输入 */
export type CreateDepartmentInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 部门属性 */
  departmentAttributes: DepartmentAttributes;
};

export type CreateDepartmentPayload = {
  __typename?: 'createDepartmentPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 部门 */
  department?: Maybe<Department>;
};

/** 添加设备请求 */
export type CreateDeviceInput = {
  admin: Scalars['String'];
  batchAttributes: Array<BatchAttribute>;
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  departmentId: Scalars['ID'];
  deviceModelId: Scalars['ID'];
  phone: Scalars['String'];
  remark?: InputMaybe<Scalars['String']>;
};

export type CreateDevicePayload = {
  __typename?: 'createDevicePayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 设备 */
  devices: Array<Maybe<Device>>;
};

/** 创建域名的请求 */
export type CreateDomainInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 域名属性 */
  domainAttributes: DomainAttributes;
};

/** 创建域名的响应 */
export type CreateDomainPayload = {
  __typename?: 'createDomainPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 域名 */
  domain: Domain;
};

/** 创建边界/电子围栏的请求 */
export type CreateFenceInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 边界/电子围栏属性 */
  fenceAttributes: FenceAttributes;
};

/** 创建边界/电子围栏的响应 */
export type CreateFencePayload = {
  __typename?: 'createFencePayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 边界/电子围栏 */
  fence: Fence;
};

/** 创建Fertilizer的请求 */
export type CreateFertilizerInput = {
  /** Fertilizer属性 */
  FertilizerAttributes: FertilizerAttributes;
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** 创建Fertilizer的响应 */
export type CreateFertilizerPayload = {
  __typename?: 'createFertilizerPayload';
  /** Fertilizer */
  Fertilizer: Fertilizer;
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** 创建处方图分享记录 */
export type CreateFertilizerShareInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 处方图分享输入属性 */
  fertilizerShareAttributes: FertilizerShareAttributes;
};

/** 分享处方图 */
export type CreateFertilizerSharePayload = {
  __typename?: 'createFertilizerSharePayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 边界/电子围栏 */
  fertilizerShare: FertilizerShare;
};

export type CreateLandInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 地块属性 */
  landAttributes: LandAttributes;
};

export type CreateLandPayload = {
  __typename?: 'createLandPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 地块 */
  land?: Maybe<Land>;
};

/** 创建成员/部门请求 */
export type CreateMemberDepartmentInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 成员/部门 属性 */
  memberDepartmentAttributes: MemberDepartmentAttributes;
};

/** 创建成员/部门响应 */
export type CreateMemberDepartmentPayload = {
  __typename?: 'createMemberDepartmentPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 成员/部门 */
  memberDepartment?: Maybe<MemberDepartment>;
};

/** 创建成员的请求 */
export type CreateMemberInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 成员属性 */
  memberAttributes: MemberAttributes;
};

/** 创建成员的响应 */
export type CreateMemberPayload = {
  __typename?: 'createMemberPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 成员 */
  member: Member;
};

/** 创建或修改作业参数请求 */
export type CreateOrUpdateConfigurationInput = {
  attributes: ConfigurationAttributes;
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  taskId: Scalars['ID'];
};

export type CreateOrUpdateConfigurationPayload = {
  __typename?: 'createOrUpdateConfigurationPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 设备 */
  configuration: Configuration;
};

/** 创建组织/集团请求 */
export type CreateOrganizationInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 组织/集团属性 */
  organizationAttributes: OrganizationAttributes;
};

/** 创建组织/集团响应 */
export type CreateOrganizationPayload = {
  __typename?: 'createOrganizationPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 组织/集团 */
  organization?: Maybe<Organization>;
};

/** 创建气象观测点的请求 */
export type CreatePositionOfInterestInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 气象观测点属性 */
  positionOfInterestAttributes: PositionOfInterestAttributes;
};

/** 创建气象观测点的响应 */
export type CreatePositionOfInterestPayload = {
  __typename?: 'createPositionOfInterestPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 气象观测点 */
  positionOfInterest: PositionOfInterest;
};

/** 创建任务请求 */
export type CreateTaskInput = {
  /** 开始日期(20200601) */
  beginDate: Scalars['Int'];
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<ConfigurationAttributes>;
  departmentId: Scalars['ID'];
  enableNotify: Scalars['Boolean'];
  enablePush: Scalars['Boolean'];
  enableQuality: Scalars['Boolean'];
  /** 结束日期(20200630) */
  endDate: Scalars['Int'];
  name: Scalars['String'];
  /** 作业类型的id */
  taskTypeId?: InputMaybe<Scalars['ID']>;
};

export type CreateTaskPayload = {
  __typename?: 'createTaskPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 任务 */
  task: Task;
};

/** 创建用户请求 */
export type CreateUserInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** member 创建选项 */
  option?: InputMaybe<MemberDepartmentOption>;
  /** 用户 */
  userAttributes: UserAttributes;
};

export type CreateUserPayload = {
  __typename?: 'createUserPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 用户 */
  user?: Maybe<User>;
};

export type DeleteDeviceInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteDevicePayload = {
  __typename?: 'deleteDevicePayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 设备 */
  device: Device;
};

export type DeleteTaskInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  taskId: Scalars['ID'];
};

export type DeleteTaskPayload = {
  __typename?: 'deleteTaskPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 任务 */
  task: Task;
};

/** 删除部门的请求 */
export type DestroyDepartmentInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 部门的uuid */
  uuid: Scalars['String'];
};

/** 删除部门的响应 */
export type DestroyDepartmentPayload = {
  __typename?: 'destroyDepartmentPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 部门 */
  department?: Maybe<Department>;
};

/** 删除域名的请求 */
export type DestroyDomainInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 域名的uuid */
  uuid: Scalars['String'];
};

/** 删除域名的响应 */
export type DestroyDomainPayload = {
  __typename?: 'destroyDomainPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 域名 */
  domain: Domain;
};

/** 删除边界/电子围栏的请求 */
export type DestroyFenceInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 边界/电子围栏的uuid */
  uuid: Scalars['String'];
};

/** 删除边界/电子围栏的响应 */
export type DestroyFencePayload = {
  __typename?: 'destroyFencePayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 边界/电子围栏 */
  fence: Fence;
};

/** 删除Fertilizer的请求 */
export type DestroyFertilizerInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Fertilizer的uuid */
  uuid: Scalars['String'];
};

/** 删除Fertilizer的响应 */
export type DestroyFertilizerPayload = {
  __typename?: 'destroyFertilizerPayload';
  /** Fertilizer */
  Fertilizer: Fertilizer;
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DestroyLandInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 地块uuid */
  uuid: Scalars['String'];
};

export type DestroyLandPayload = {
  __typename?: 'destroyLandPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 地块 */
  land?: Maybe<Land>;
};

/** 删除成员/部门输入 */
export type DestroyMemberDepartmentInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** uuid */
  uuid: Scalars['String'];
};

/** 删除成员/部门响应 */
export type DestroyMemberDepartmentPayload = {
  __typename?: 'destroyMemberDepartmentPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 成员/部门 */
  memberDepartment?: Maybe<MemberDepartment>;
};

/** 删除成员的请求 */
export type DestroyMemberInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 成员的uuid */
  uuid: Scalars['String'];
};

/** 删除成员的响应 */
export type DestroyMemberPayload = {
  __typename?: 'destroyMemberPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 成员 */
  member: Member;
};

/** 删除组织/集团请求 */
export type DestroyOrganizationInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 组织/集团uuid */
  uuid: Scalars['String'];
};

/** 删除组织/集团响应 */
export type DestroyOrganizationPayload = {
  __typename?: 'destroyOrganizationPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 组织/集团属性 */
  organization?: Maybe<Organization>;
};

/** 删除气象观测点的请求 */
export type DestroyPositionOfInterestInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 气象观测点的uuid */
  uuid: Scalars['String'];
};

/** 删除气象观测点的响应 */
export type DestroyPositionOfInterestPayload = {
  __typename?: 'destroyPositionOfInterestPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 气象观测点 */
  positionOfInterest: PositionOfInterest;
};

export type DestroyUserInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 用户的uuid */
  uuid: Scalars['String'];
};

export type DestroyUserPayload = {
  __typename?: 'destroyUserPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 用户 */
  user?: Maybe<User>;
};

export type ExportTaskInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  taskId: Scalars['ID'];
};

export type ExportTaskPayload = {
  __typename?: 'exportTaskPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 任务 */
  task: Task;
};

export type FaqContent = {
  __typename?: 'faqContent';
  content: Scalars['String'];
  name: Scalars['String'];
};

export type FaqContentConnection = {
  __typename?: 'faqContentConnection';
  edges: Array<Maybe<FaqContentEdge>>;
  nodes: Array<Maybe<FaqContent>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type FaqContentEdge = {
  __typename?: 'faqContentEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<FaqContent>;
};

export type FaqTitle = {
  __typename?: 'faqTitle';
  faqContents?: Maybe<FaqContentConnection>;
  name: Scalars['String'];
};


export type FaqTitleFaqContentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type FaqTitleConnection = {
  __typename?: 'faqTitleConnection';
  edges: Array<Maybe<FaqTitleEdge>>;
  nodes: Array<Maybe<FaqTitle>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type FaqTitleEdge = {
  __typename?: 'faqTitleEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<FaqTitle>;
};

export type LandClusterConnection = {
  __typename?: 'landClusterConnection';
  edges: Array<Maybe<LandClusterEdge>>;
  nodes: Array<Maybe<LandCluster>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** 更新部门的输入 */
export type UpdateDepartmentInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 部门属性 */
  departmentAttributes: DepartmentAttributes;
  /** 部门的uuid */
  uuid: Scalars['String'];
};

/** 更新部门的响应 */
export type UpdateDepartmentPayload = {
  __typename?: 'updateDepartmentPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 部门 */
  department?: Maybe<Department>;
};

export type UpdateDeviceInput = {
  admin?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  departmentId?: InputMaybe<Scalars['ID']>;
  deviceModelId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
};

export type UpdateDevicePayload = {
  __typename?: 'updateDevicePayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 设备 */
  device: Device;
};

/** 更新域名的请求 */
export type UpdateDomainInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 域名属性 */
  domainAttributes: DomainAttributes;
  /** 域名的uuid */
  uuid: Scalars['String'];
};

/** 更新域名的响应 */
export type UpdateDomainPayload = {
  __typename?: 'updateDomainPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 域名 */
  domain: Domain;
};

/** 更新边界/电子围栏的请求 */
export type UpdateFenceInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 边界/电子围栏属性 */
  fenceAttributes: FenceAttributes;
  /** 边界/电子围栏的uuid */
  uuid: Scalars['String'];
};

/** 更新边界/电子围栏的响应 */
export type UpdateFencePayload = {
  __typename?: 'updateFencePayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 边界/电子围栏 */
  fence: Fence;
};

/** 更新Fertilizer的请求 */
export type UpdateFertilizerInput = {
  /** Fertilizer属性 */
  FertilizerAttributes: FertilizerAttributes;
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Fertilizer的uuid */
  uuid: Scalars['String'];
};

/** 更新Fertilizer的响应 */
export type UpdateFertilizerPayload = {
  __typename?: 'updateFertilizerPayload';
  /** Fertilizer */
  Fertilizer: Fertilizer;
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateLandInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /**
   * 需要更新哪些字段必须给出，不给出的字段不更新
   *
   * 这是为了处理部分更新的情况
   */
  fields: Array<LandField>;
  /** 地块属性 */
  landAttributes: LandAttributes;
  /** 地块uuid */
  uuid: Scalars['String'];
};

export type UpdateLandPayload = {
  __typename?: 'updateLandPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 地块 */
  land?: Maybe<Land>;
};

/** 更新成员/部门请求 */
export type UpdateMemberDepartmentInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 成员/部门 属性 */
  memberDepartmentAttributes: MemberDepartmentAttributes;
  /** uuid */
  uuid: Scalars['String'];
};

/** 更新成员/部门请求 */
export type UpdateMemberDepartmentPayload = {
  __typename?: 'updateMemberDepartmentPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 成员/部门 属性 */
  memberDepartment?: Maybe<MemberDepartment>;
};

/** 更新成员的请求 */
export type UpdateMemberInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 成员属性 */
  memberAttributes: MemberAttributes;
  /** 成员的uuid */
  uuid: Scalars['String'];
};

/** 更新成员的响应 */
export type UpdateMemberPayload = {
  __typename?: 'updateMemberPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 成员 */
  member: Member;
};

/** 更新组织/集团请求 */
export type UpdateOrganizationInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 组织/集团属性 */
  organizationAttributes: OrganizationAttributes;
  /** 组织/集团uuid */
  uuid: Scalars['String'];
};

/** 更新组织/集团响应 */
export type UpdateOrganizationPayload = {
  __typename?: 'updateOrganizationPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 组织/集团 */
  organization?: Maybe<Organization>;
};

/** 更新气象观测点的请求 */
export type UpdatePositionOfInterestInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** 气象观测点属性 */
  positionOfInterestAttributes: PositionOfInterestAttributes;
  /** 气象观测点的uuid */
  uuid: Scalars['String'];
};

/** 更新气象观测点的响应 */
export type UpdatePositionOfInterestPayload = {
  __typename?: 'updatePositionOfInterestPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 气象观测点 */
  positionOfInterest: PositionOfInterest;
};

/** 修改任务请求 */
export type UpdateTaskInput = {
  beginDate?: InputMaybe<Scalars['Int']>;
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  departmentId?: InputMaybe<Scalars['ID']>;
  enableNotify?: InputMaybe<Scalars['Boolean']>;
  enablePush?: InputMaybe<Scalars['Boolean']>;
  enableQuality?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  taskId: Scalars['ID'];
};

export type UpdateTaskPayload = {
  __typename?: 'updateTaskPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 任务 */
  task: Task;
};

export type UpdateUserInput = {
  /** 客户端请求id */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** member */
  option?: InputMaybe<MemberDepartmentOption>;
  /** 用户属性 */
  userAttributes: UpdateUserAttributes;
  /** 用户的uuid */
  uuid: Scalars['String'];
};

export type UpdateUserPayload = {
  __typename?: 'updateUserPayload';
  /** 客户端请求id */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 用户 */
  user?: Maybe<User>;
};

export type CalculateAreaQueryVariables = Exact<{
  geo: Scalars['GeoJSON'];
}>;


export type CalculateAreaQuery = { __typename?: 'Query', calculateArea: number };

export type ImageUrlQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type ImageUrlQuery = { __typename?: 'Query', sourceImage?: { __typename?: 'SourceImage', signURL?: string | null } | null };

export type LatlntRegeoQueryVariables = Exact<{
  lng: Scalars['Float'];
  lat: Scalars['Float'];
}>;


export type LatlntRegeoQuery = { __typename?: 'Query', regeo?: { __typename?: 'Regeo', regeocode: { __typename?: 'Regeocode', formattedAddress: string, addressComponent: { __typename?: 'AddressComponent', country: string, province: string, city: string, township: string, district: string, streetNumber: { __typename?: 'StreetNumber', street: string } } } } | null };

export type CreateEstimationJobMutationVariables = Exact<{
  mappingUuid: Scalars['String'];
  name: Scalars['String'];
  plant?: InputMaybe<PlantType>;
  processingJobUuid?: InputMaybe<Scalars['String']>;
}>;


export type CreateEstimationJobMutation = { __typename?: 'Mutation', createEstimationJob?: { __typename?: 'CreateEstimationJobPayload', estimationJob?: { __typename?: 'EstimationJob', uuid: string, name: string } | null } | null };

export type QueryCropEstimationLandPopupDetailQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type QueryCropEstimationLandPopupDetailQuery = { __typename?: 'Query', land?: { __typename?: 'Land', id?: string | null, uuid?: string | null, name?: string | null, area?: number | null, geo?: any | null, departmentLinks?: Array<{ __typename?: 'Department', name?: string | null } | null> | null } | null };

export type DeleteEstimationJobMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type DeleteEstimationJobMutation = { __typename?: 'Mutation', deleteEstimationJob?: { __typename?: 'DeleteEstimationJobPayload', clientMutationId?: string | null } | null };

export type CropEstimationGeoResultQueryVariables = Exact<{
  uuid: Scalars['String'];
  geo?: InputMaybe<Scalars['GeoJSON']>;
}>;


export type CropEstimationGeoResultQuery = { __typename?: 'Query', estimationJob?: { __typename?: 'EstimationJob', estimationResult?: { __typename?: 'EstimationResult', totalCount: number, averageDensity: number } | null } | null };

export type QueryCropEstimationTaskResultQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type QueryCropEstimationTaskResultQuery = { __typename?: 'Query', estimationJob?: { __typename?: 'EstimationJob', estimationResult?: { __typename?: 'EstimationResult', fiveSeg: Array<number>, sourceImages: Array<{ __typename?: 'EstimationImage', density: number, status: number, dewarpImgSignURL?: string | null, senseImgSignURL?: string | null, position: { __typename?: 'Position', lat?: number | null, lng?: number | null } } | { __typename?: 'SourceImage' }> } | null } | null };

export type CropEstimationResultInfoFragment = { __typename?: 'EstimationImage', density: number, status: number, dewarpImgSignURL?: string | null, senseImgSignURL?: string | null, position: { __typename?: 'Position', lat?: number | null, lng?: number | null } };

export type CropEstimationTasksTotalCountQueryVariables = Exact<{
  filter?: InputMaybe<EstimationJobsFilter>;
}>;


export type CropEstimationTasksTotalCountQuery = { __typename?: 'Query', estimationJobs?: { __typename?: 'EstimationJobsConnection', totalCount: number } | null };

export type CropEstimationTasksQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EstimationJobsFilter>;
}>;


export type CropEstimationTasksQuery = { __typename?: 'Query', estimationJobs?: { __typename?: 'EstimationJobsConnection', totalCount: number, nodes: Array<{ __typename?: 'EstimationJob', name: string, createdAt: any, status: number, uuid: string, plantType?: PlantType | null, progressPercent: number, failureCount: number, departmentLinks?: Array<{ __typename?: 'Department', uuid?: string | null, name?: string | null } | null> | null } | null>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean } | null } | null };

export type CropEstimationTaskNodeFragment = { __typename?: 'EstimationJob', name: string, createdAt: any, status: number, uuid: string, plantType?: PlantType | null, progressPercent: number, failureCount: number, departmentLinks?: Array<{ __typename?: 'Department', uuid?: string | null, name?: string | null } | null> | null };

export type FetchEditEstimationQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type FetchEditEstimationQuery = { __typename?: 'Query', estimationJob?: { __typename?: 'EstimationJob', name: string, processingJob?: { __typename?: 'ProcessingJob', name?: string | null } | null, mapping?: { __typename?: 'Mapping', name?: string | null } | null } | null };

export type FetchEditEstimationFragmentFragment = { __typename?: 'EstimationJob', name: string, processingJob?: { __typename?: 'ProcessingJob', name?: string | null } | null, mapping?: { __typename?: 'Mapping', name?: string | null } | null };

export type QueryEstimationCaculateCardLandDetailQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type QueryEstimationCaculateCardLandDetailQuery = { __typename?: 'Query', land?: { __typename?: 'Land', uuid?: string | null, name?: string | null, area?: number | null, geo?: any | null } | null };

export type QueryEstimationCaculateCardLandResultQueryVariables = Exact<{
  jobUuid: Scalars['String'];
  geo?: InputMaybe<Scalars['GeoJSON']>;
}>;


export type QueryEstimationCaculateCardLandResultQuery = { __typename?: 'Query', estimationJob?: { __typename?: 'EstimationJob', estimationResult?: { __typename?: 'EstimationResult', averageDensity: number, totalCount: number } | null } | null };

export type QueryEstimationJobBoundingBoxQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type QueryEstimationJobBoundingBoxQuery = { __typename?: 'Query', estimationJob?: { __typename?: 'EstimationJob', upperRight?: { __typename?: 'Position', lat?: number | null, lng?: number | null } | null, downLeft?: { __typename?: 'Position', lat?: number | null, lng?: number | null } | null } | null };

export type QueryEstimationJobMapDetailInfoQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type QueryEstimationJobMapDetailInfoQuery = { __typename?: 'Query', estimationJob?: { __typename?: 'EstimationJob', uuid: string, status: number, estimationResult?: { __typename?: 'EstimationResult', fiveSeg: Array<number> } | null, upperRight?: { __typename?: 'Position', lat?: number | null, lng?: number | null } | null, downLeft?: { __typename?: 'Position', lat?: number | null, lng?: number | null } | null, processingJob?: { __typename?: 'ProcessingJob', uuid?: string | null, mapReport?: { __typename?: 'MapReport', MapTile: { __typename?: 'MapTile', MaximumLevel: number } } | null } | null } | null };

export type QueryEstimationPlotCountQueryVariables = Exact<{
  bbox?: InputMaybe<BoundingBoxAttributes>;
}>;


export type QueryEstimationPlotCountQuery = { __typename?: 'Query', lands?: { __typename?: 'LandConnection', totalCount: number } | null };

export type StartEstimationJobMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type StartEstimationJobMutation = { __typename?: 'Mutation', startEstimationJob?: { __typename?: 'StartEstimationJobPayload', clientMutationId?: string | null, estimationJob?: { __typename?: 'EstimationJob', progressPercent: number } | null } | null };

export type UpdateEstimationTaskMutationVariables = Exact<{
  uuid: Scalars['String'];
  name: Scalars['String'];
}>;


export type UpdateEstimationTaskMutation = { __typename?: 'Mutation', updateEstimationJob?: { __typename?: 'UpdateEstimationJobPayload', clientMutationId?: string | null } | null };

export type DepartmentFragmentFragment = { __typename?: 'Department', id?: string | null, uuid?: string | null, parentDepartmentId?: string | null, name?: string | null, NotifyReceiverName?: string | null, NotifyReceiverPhone?: string | null };

export type DepartmentFencesFragmentFragment = { __typename?: 'Department', id?: string | null, name?: string | null, fences?: { __typename?: 'FenceConnection', nodes: Array<{ __typename?: 'Fence', geo?: any | null } | null>, bbox?: { __typename?: 'BoundingBox', upperRight: { __typename?: 'Position', lat?: number | null, lng?: number | null }, downLeft: { __typename?: 'Position', lat?: number | null, lng?: number | null } } | null } | null, accessibleLands?: { __typename?: 'LandConnection', bbox?: { __typename?: 'BoundingBox', upperRight: { __typename?: 'Position', lat?: number | null, lng?: number | null }, downLeft: { __typename?: 'Position', lat?: number | null, lng?: number | null } } | null } | null };

export type DepartmentFenceQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type DepartmentFenceQuery = { __typename?: 'Query', department?: { __typename?: 'Department', id?: string | null, name?: string | null, fences?: { __typename?: 'FenceConnection', nodes: Array<{ __typename?: 'Fence', geo?: any | null } | null>, bbox?: { __typename?: 'BoundingBox', upperRight: { __typename?: 'Position', lat?: number | null, lng?: number | null }, downLeft: { __typename?: 'Position', lat?: number | null, lng?: number | null } } | null } | null, accessibleLands?: { __typename?: 'LandConnection', bbox?: { __typename?: 'BoundingBox', upperRight: { __typename?: 'Position', lat?: number | null, lng?: number | null }, downLeft: { __typename?: 'Position', lat?: number | null, lng?: number | null } } | null } | null } | null };

export type SubDepartmentsFenceQueryVariables = Exact<{
  parentDepartmentId: Scalars['ID'];
}>;


export type SubDepartmentsFenceQuery = { __typename?: 'Query', departments?: { __typename?: 'DepartmentConnection', nodes: Array<{ __typename?: 'Department', id?: string | null, name?: string | null, fences?: { __typename?: 'FenceConnection', nodes: Array<{ __typename?: 'Fence', geo?: any | null } | null>, bbox?: { __typename?: 'BoundingBox', upperRight: { __typename?: 'Position', lat?: number | null, lng?: number | null }, downLeft: { __typename?: 'Position', lat?: number | null, lng?: number | null } } | null } | null, accessibleLands?: { __typename?: 'LandConnection', bbox?: { __typename?: 'BoundingBox', upperRight: { __typename?: 'Position', lat?: number | null, lng?: number | null }, downLeft: { __typename?: 'Position', lat?: number | null, lng?: number | null } } | null } | null } | null> } | null };

export type QueryFaqTitlesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type QueryFaqTitlesQuery = { __typename?: 'Query', faqTitles?: { __typename?: 'faqTitleConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean }, nodes: Array<{ __typename?: 'faqTitle', name: string, faqContents?: { __typename?: 'faqContentConnection', nodes: Array<{ __typename?: 'faqContent', name: string, content: string } | null> } | null } | null> } | null };

export type QueryFaqNodesFragment = { __typename?: 'faqTitle', name: string, faqContents?: { __typename?: 'faqContentConnection', nodes: Array<{ __typename?: 'faqContent', name: string, content: string } | null> } | null };

export type QueryFaqContentsFragment = { __typename?: 'faqContent', name: string, content: string };

export type ReportFeedbackMutationVariables = Exact<{
  description?: InputMaybe<Scalars['String']>;
  picContent?: InputMaybe<Scalars['String']>;
  sentryUUID?: InputMaybe<Scalars['String']>;
}>;


export type ReportFeedbackMutation = { __typename?: 'Mutation', createTechFeedback?: { __typename?: 'CreateTechFeedbackPayload', clientMutationId?: string | null } | null };

export type FragmentNdviJobFragment = { __typename?: 'NdviJob', uuid?: string | null, params?: string | null, name?: string | null, status?: number | null, fileUrls: Array<{ __typename?: 'FileUrl', url: string }>, upperRight?: { __typename?: 'Position', lng?: number | null, lat?: number | null } | null, downLeft?: { __typename?: 'Position', lng?: number | null, lat?: number | null } | null };

export type QueryFertilizerQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type QueryFertilizerQuery = { __typename?: 'Query', ndviJob?: { __typename?: 'NdviJob', uuid?: string | null, params?: string | null, name?: string | null, status?: number | null, fileUrls: Array<{ __typename?: 'FileUrl', url: string }>, upperRight?: { __typename?: 'Position', lng?: number | null, lat?: number | null } | null, downLeft?: { __typename?: 'Position', lng?: number | null, lat?: number | null } | null } | null };

export type QueryNdivJobStatusQueryVariables = Exact<{
  processingJobUuid: Scalars['String'];
  filter?: InputMaybe<NdviJobsFilter>;
}>;


export type QueryNdivJobStatusQuery = { __typename?: 'Query', processingJob?: { __typename?: 'ProcessingJob', ndviJobs?: { __typename?: 'NdviJobsConnection', edges: Array<{ __typename?: 'NdviJobEdge', node?: { __typename?: 'NdviJob', uuid?: string | null, params?: string | null, name?: string | null, status?: number | null, fileUrls: Array<{ __typename?: 'FileUrl', url: string }>, upperRight?: { __typename?: 'Position', lng?: number | null, lat?: number | null } | null, downLeft?: { __typename?: 'Position', lng?: number | null, lat?: number | null } | null } | null } | null> } | null } | null };

export type QuerySampleNdviQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type QuerySampleNdviQuery = { __typename?: 'Query', processingJob?: { __typename?: 'ProcessingJob', sampleNDVI?: Array<string> | null, id?: string | null, uuid?: string | null, segmentForSampleNDVI?: string | null, newestSegment?: string | null } | null };

export type QueryTotalUsageQueryVariables = Exact<{
  uuid: Scalars['String'];
  shapes?: InputMaybe<Array<Scalars['GeoJSON']> | Scalars['GeoJSON']>;
}>;


export type QueryTotalUsageQuery = { __typename?: 'Query', usageOfPrescription?: number | null };

export type QureyPlotByProcessingJobQueryVariables = Exact<{
  processingJobUuid: Scalars['String'];
  plotUuid: Scalars['String'];
}>;


export type QureyPlotByProcessingJobQuery = { __typename?: 'Query', processingJob?: { __typename?: 'ProcessingJob', name?: string | null, fileUrls: Array<{ __typename?: 'FileUrl', name: string }>, plots?: { __typename?: 'PlotsConnection', edges: Array<{ __typename?: 'PlotEdge', node?: { __typename?: 'Plot', id?: string | null, uuid?: string | null, name?: string | null, area?: number | null, geo?: any | null, fertilizerParams?: string | null } | null } | null> } | null } | null };

export type StartFertilizerMapJobMutationVariables = Exact<{
  processingJobUuid: Scalars['String'];
  plotUuid: Scalars['String'];
  name: Scalars['String'];
  params: Scalars['String'];
  mode: Scalars['Int'];
}>;


export type StartFertilizerMapJobMutation = { __typename?: 'Mutation', createAndStartNdviJob?: { __typename?: 'CreateAndStartNdviJobPayload', ndviJob?: { __typename?: 'NdviJob', uuid?: string | null, params?: string | null, name?: string | null, status?: number | null, fileUrls: Array<{ __typename?: 'FileUrl', url: string }>, upperRight?: { __typename?: 'Position', lng?: number | null, lat?: number | null } | null, downLeft?: { __typename?: 'Position', lng?: number | null, lat?: number | null } | null } | null } | null };

export type EnforceSampleNdviTaskMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type EnforceSampleNdviTaskMutation = { __typename?: 'Mutation', sampleNDVI?: { __typename?: 'SampleNDVIPayload', clientMutationId?: string | null } | null };

export type UpdatePlotMutationVariables = Exact<{
  uuid: Scalars['String'];
  fertilizerParams?: InputMaybe<Scalars['String']>;
}>;


export type UpdatePlotMutation = { __typename?: 'Mutation', updatePlot?: { __typename?: 'UpdatePlotPayload', plot?: { __typename?: 'Plot', uuid?: string | null } | null } | null };

export type ProcessingJobGsdQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type ProcessingJobGsdQuery = { __typename?: 'Query', processingJob?: { __typename?: 'ProcessingJob', fileUrls: Array<{ __typename?: 'FileUrl', name: string, url: string }> } | null };

export type LandPopupDetailQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type LandPopupDetailQuery = { __typename?: 'Query', land?: { __typename?: 'Land', uuid?: string | null, name?: string | null, area?: number | null, departmentLinks?: Array<{ __typename?: 'Department', name?: string | null } | null> | null } | null };

export type ApplyInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type ApplyInfoQuery = { __typename?: 'Query', applicationForms?: { __typename?: 'ApplicationFormConnection', nodes: Array<{ __typename?: 'ApplicationForm', status?: number | null } | null> } | null };

export type CreateApplyInfoMutationVariables = Exact<{
  contact: Scalars['String'];
  reason: Scalars['String'];
}>;


export type CreateApplyInfoMutation = { __typename?: 'Mutation', createApplicationForm?: { __typename?: 'CreateApplicationFormPayload', ApplicationForm: { __typename?: 'ApplicationForm', status?: number | null } } | null };

export type CreateAutoProcessingJobMutationVariables = Exact<{
  mappingUuid: Scalars['String'];
  name: Scalars['String'];
  params: Scalars['String'];
  notify?: InputMaybe<Scalars['Boolean']>;
}>;


export type CreateAutoProcessingJobMutation = { __typename?: 'Mutation', createAutoProcessingJob?: { __typename?: 'CreateAutoProcessingJobPayload', clientMutationId?: string | null } | null };

export type CreateMappingMutationVariables = Exact<{
  name: Scalars['String'];
  jobType: Scalars['Int'];
  departmentUuid: Scalars['String'];
  remark?: InputMaybe<Scalars['String']>;
  forNDVI: Scalars['Boolean'];
}>;


export type CreateMappingMutation = { __typename?: 'Mutation', createMapping?: { __typename?: 'MappingPayload', mapping?: { __typename?: 'Mapping', uuid?: string | null, name?: string | null, jobType?: number | null, remark?: string | null, forNDVI: boolean, auto: boolean, department?: { __typename?: 'Department', uuid?: string | null } | null } | null } | null };

export type CreatePlotPatrolMutationVariables = Exact<{
  name: Scalars['String'];
  mappingUuid: Scalars['String'];
  viewType: Scalars['Int'];
}>;


export type CreatePlotPatrolMutation = { __typename?: 'Mutation', createPlotPatrol?: { __typename?: 'CreatePlotPatrolPayload', plotPatrol: { __typename?: 'PlotPatrol', name?: string | null, uuid?: string | null } } | null };

export type CreateSourceImagesMutationVariables = Exact<{
  mappingUuid: Scalars['String'];
  sourceImages: Array<SourceImageAttributes> | SourceImageAttributes;
}>;


export type CreateSourceImagesMutation = { __typename?: 'Mutation', createSourceImages?: { __typename?: 'CreateSourceImagesPayload', sourceImages?: Array<{ __typename?: 'SourceImage', id?: string | null, status?: number | null, credential?: { __typename?: 'Credential', accessKeyId?: string | null, signature?: string | null, callback?: string | null, policy?: string | null } | null, storageService?: { __typename?: 'StorageService', endpoint?: string | null, bucketId?: string | null, prefix?: string | null, objectId?: string | null } | null, s3StsInit?: { __typename?: 'S3StsInit', cloudName: string, objectKey: string, region: string, cloudBucketName: string, endPoint: string, authorization: { __typename?: 'Authorization', ak: string, sk: string, sessionToken: string, createTime: string } } | null } | null> | null } | null };

export type CreateSourceImagesPayloadFragment = { __typename?: 'SourceImage', id?: string | null, status?: number | null, credential?: { __typename?: 'Credential', accessKeyId?: string | null, signature?: string | null, callback?: string | null, policy?: string | null } | null, storageService?: { __typename?: 'StorageService', endpoint?: string | null, bucketId?: string | null, prefix?: string | null, objectId?: string | null } | null, s3StsInit?: { __typename?: 'S3StsInit', cloudName: string, objectKey: string, region: string, cloudBucketName: string, endPoint: string, authorization: { __typename?: 'Authorization', ak: string, sk: string, sessionToken: string, createTime: string } } | null };

export type DeletePhotoHomeMappingMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type DeletePhotoHomeMappingMutation = { __typename?: 'Mutation', deleteMapping?: { __typename?: 'DeleteMappingPayload', clientMutationId?: string | null } | null };

export type DeleteSourcePhotosMutationVariables = Exact<{
  uuids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteSourcePhotosMutation = { __typename?: 'Mutation', deleteSourceImages?: { __typename?: 'DeleteSourceImagesPayload', clientMutationId?: string | null } | null };

export type FetchPhotoHomeListQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<MappingsFilter>;
  sort?: InputMaybe<Sort>;
}>;


export type FetchPhotoHomeListQuery = { __typename?: 'Query', mappings?: { __typename?: 'MappingsConnection', totalCount?: number | null, nodes: Array<{ __typename?: 'Mapping', uuid?: string | null, name?: string | null, jobType?: number | null, remark?: string | null, createdAt?: any | null, forNDVI: boolean, sourceImages?: { __typename?: 'SourceImagesConnection', totalCount?: number | null } | null, department?: { __typename?: 'Department', uuid?: string | null } | null, departmentLinks?: Array<{ __typename?: 'Department', uuid?: string | null, name?: string | null } | null> | null } | null> } | null };

export type QueryMappingBoundingBoxQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type QueryMappingBoundingBoxQuery = { __typename?: 'Query', mapping?: { __typename?: 'Mapping', upperRight?: { __typename?: 'Position', lat?: number | null, lng?: number | null } | null, downLeft?: { __typename?: 'Position', lat?: number | null, lng?: number | null } | null } | null };

export type QueryMappingDetailQueryVariables = Exact<{
  mappingUuid: Scalars['String'];
}>;


export type QueryMappingDetailQuery = { __typename?: 'Query', mapping?: { __typename?: 'Mapping', uuid?: string | null, name?: string | null, jobType?: number | null, auto: boolean, forNDVI: boolean, remark?: string | null, createdAt?: any | null, department?: { __typename?: 'Department', uuid?: string | null } | null, departmentLinks?: Array<{ __typename?: 'Department', name?: string | null } | null> | null, autoProcessingJob?: { __typename?: 'ProcessingJob', name?: string | null, params?: string | null, notify: boolean } | null } | null };

export type QueryMappingRelativeProcessingJobsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProcessingJobsFilter>;
}>;


export type QueryMappingRelativeProcessingJobsQuery = { __typename?: 'Query', processingJobs?: { __typename?: 'ProcessingJobsConnection', totalCount?: number | null, nodes: Array<{ __typename?: 'ProcessingJob', uuid?: string | null, name?: string | null, area?: number | null, createdAt?: any | null } | null>, pageInfo?: { __typename?: 'PageInfoNew', hasNextPage?: boolean | null } | null } | null };

export type QueryPhotoDetailQueryVariables = Exact<{
  mappingUuid: Scalars['String'];
}>;


export type QueryPhotoDetailQuery = { __typename?: 'Query', mapping?: { __typename?: 'Mapping', name?: string | null, jobType?: number | null, forNDVI: boolean, remark?: string | null, createdAt?: any | null, departmentLinks?: Array<{ __typename?: 'Department', name?: string | null, uuid?: string | null } | null> | null } | null };

export type QueryPhotoListQueryVariables = Exact<{
  mappingUuid: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  filter: SourceImagesFilterV2;
}>;


export type QueryPhotoListQuery = { __typename?: 'Query', mapping?: { __typename?: 'Mapping', name?: string | null, jobType?: number | null, upperRight?: { __typename?: 'Position', lng?: number | null, lat?: number | null } | null, downLeft?: { __typename?: 'Position', lng?: number | null, lat?: number | null } | null, sourceImagesV2?: { __typename?: 'SourceImagesConnectionV2', pageInfo?: { __typename?: 'PageInfoNew', hasNextPage?: boolean | null } | null, nodes: Array<{ __typename?: 'SourceImage', uuid?: string | null, fileName?: string | null, originalFileName?: string | null, fileExtension?: string | null, fileSize?: number | null, fileDetails?: { __typename?: 'FileDetails', position: Array<number>, orientation: Array<number>, posSigma: Array<number>, captureTime: any } | null, tiffs: Array<{ __typename?: 'SourceImage', uuid?: string | null, fileName?: string | null, originalFileName?: string | null, fileExtension?: string | null, fileDetails?: { __typename?: 'FileDetails', xmp: { __typename?: 'Xmp', bandName: string } } | null } | null> } | null> } | null } | null };

export type QueryPhotoTotalCountQueryVariables = Exact<{
  mappingUuid: Scalars['String'];
}>;


export type QueryPhotoTotalCountQuery = { __typename?: 'Query', mapping?: { __typename?: 'Mapping', uuid?: string | null, name?: string | null, sourceImages?: { __typename?: 'SourceImagesConnection', totalCount?: number | null } | null } | null };

export type SendUploadFailedMessageMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type SendUploadFailedMessageMutation = { __typename?: 'Mutation', uploadFailedMessage?: { __typename?: 'UploadFailedMessagePayload', clientMutationId?: string | null } | null };

export type StartAutoProcessingJobMutationVariables = Exact<{
  mappingUuid: Scalars['String'];
}>;


export type StartAutoProcessingJobMutation = { __typename?: 'Mutation', startAutoProcessingJob?: { __typename?: 'StartAutoProcessingJobPayload', clientMutationId?: string | null } | null };

export type UpdateMappingMutationVariables = Exact<{
  uuid: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
  auto?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateMappingMutation = { __typename?: 'Mutation', updateMapping?: { __typename?: 'MappingPayload', mapping?: { __typename?: 'Mapping', uuid?: string | null, name?: string | null, remark?: string | null, auto: boolean } | null } | null };

export type CreateProcessingJobMutationVariables = Exact<{
  mappingUuid: Scalars['String'];
  name: Scalars['String'];
  params: Scalars['String'];
  notify: Scalars['Boolean'];
}>;


export type CreateProcessingJobMutation = { __typename?: 'Mutation', createProcessingJob?: { __typename?: 'CreateProcessingJobPayload', processingJob?: { __typename?: 'ProcessingJob', uuid?: string | null } | null } | null };

export type DeleteProcessingJobMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type DeleteProcessingJobMutation = { __typename?: 'Mutation', deleteProcessingJob?: { __typename?: 'DeleteProcessingJobPayload', clientMutationId?: string | null } | null };

export type QueryCommonJobFileQueryVariables = Exact<{
  uuid: Scalars['String'];
  names: Array<Scalars['String']> | Scalars['String'];
}>;


export type QueryCommonJobFileQuery = { __typename?: 'Query', processingJob?: { __typename?: 'ProcessingJob', fileUrls: Array<{ __typename?: 'FileUrl', name: string, url: string }> } | null };

export type QueryEditProcessingJobInfoQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type QueryEditProcessingJobInfoQuery = { __typename?: 'Query', processingJob?: { __typename?: 'ProcessingJob', id?: string | null, uuid?: string | null, status?: number | null, name?: string | null, type?: number | null, area?: number | null, Mapping?: { __typename?: 'Mapping', name?: string | null } | null, tags?: { __typename?: 'TagConnection', nodes: Array<{ __typename?: 'Tag', uuid: string, name: string } | null> } | null } | null };

export type QueryMappingJpgCountQueryVariables = Exact<{
  mappingUuid: Scalars['String'];
}>;


export type QueryMappingJpgCountQuery = { __typename?: 'Query', mapping?: { __typename?: 'Mapping', uuid?: string | null, name?: string | null, sourceImages?: { __typename?: 'SourceImagesConnection', totalCount?: number | null } | null } | null };

export type QueryProcessingJobsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProcessingJobsFilter>;
}>;


export type QueryProcessingJobsQuery = { __typename?: 'Query', processingJobs?: { __typename?: 'ProcessingJobsConnection', totalCount?: number | null, nodes: Array<{ __typename?: 'ProcessingJob', id?: string | null, uuid?: string | null, status?: number | null, errCode: string, name?: string | null, type?: number | null, area?: number | null, percentRemote?: number | null, createdAt?: any | null, departmentLinks?: Array<{ __typename?: 'Department', uuid?: string | null, name?: string | null } | null> | null, Mapping?: { __typename?: 'Mapping', forNDVI: boolean } | null } | null>, pageInfo?: { __typename?: 'PageInfoNew', hasNextPage?: boolean | null } | null } | null };

export type ProcessingJobFragmentFragment = { __typename?: 'ProcessingJob', id?: string | null, uuid?: string | null, status?: number | null, errCode: string, name?: string | null, type?: number | null, area?: number | null, percentRemote?: number | null, createdAt?: any | null, departmentLinks?: Array<{ __typename?: 'Department', uuid?: string | null, name?: string | null } | null> | null, Mapping?: { __typename?: 'Mapping', forNDVI: boolean } | null };

export type QueryProcessingPhotoListQueryVariables = Exact<{
  processingJobUuid: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type QueryProcessingPhotoListQuery = { __typename?: 'Query', processingJob?: { __typename?: 'ProcessingJob', Mapping?: { __typename?: 'Mapping', sourceImagesV2?: { __typename?: 'SourceImagesConnectionV2', pageInfo?: { __typename?: 'PageInfoNew', hasNextPage?: boolean | null } | null, nodes: Array<{ __typename?: 'SourceImage', uuid?: string | null, fileName?: string | null, originalFileName?: string | null, fileSize?: number | null, fileDetails?: { __typename?: 'FileDetails', position: Array<number>, orientation: Array<number>, posSigma: Array<number>, captureTime: any } | null } | null> } | null } | null } | null };

export type ProcessingJobPhotoNodeFragment = { __typename?: 'SourceImage', uuid?: string | null, fileName?: string | null, originalFileName?: string | null, fileSize?: number | null, fileDetails?: { __typename?: 'FileDetails', position: Array<number>, orientation: Array<number>, posSigma: Array<number>, captureTime: any } | null };

export type QueryRebuildDetailsByProcessingJobUuidQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type QueryRebuildDetailsByProcessingJobUuidQuery = { __typename?: 'Query', processingJob?: { __typename?: 'ProcessingJob', name?: string | null, type?: number | null, area?: number | null, captureTime?: any | null, createdAt?: any | null, params?: string | null, xyzTileFormat: string, upperRight?: { __typename?: 'Position', lat?: number | null, lng?: number | null } | null, downLeft?: { __typename?: 'Position', lat?: number | null, lng?: number | null } | null, mapReport?: { __typename?: 'MapReport', MapTile: { __typename?: 'MapTile', MaximumLevel: number } } | null, Mapping?: { __typename?: 'Mapping', forNDVI: boolean } | null, department?: { __typename?: 'Department', name?: string | null, uuid?: string | null } | null, tags?: { __typename?: 'TagConnection', nodes: Array<{ __typename?: 'Tag', uuid: string, name: string } | null> } | null } | null };

export type QueryRebuildIndexListQueryVariables = Exact<{
  mappingUuid: Scalars['String'];
}>;


export type QueryRebuildIndexListQuery = { __typename?: 'Query', mapping?: { __typename?: 'Mapping', indexList: Array<Index> } | null };

export type QuerySelectMappingListQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<MappingsFilter>;
  sort?: InputMaybe<Sort>;
}>;


export type QuerySelectMappingListQuery = { __typename?: 'Query', mappings?: { __typename?: 'MappingsConnection', nodes: Array<{ __typename?: 'Mapping', uuid?: string | null, name?: string | null, jobType?: number | null, remark?: string | null, createdAt?: any | null, forNDVI: boolean, sourceImages?: { __typename?: 'SourceImagesConnection', totalCount?: number | null } | null, departmentLinks?: Array<{ __typename?: 'Department', name?: string | null } | null> | null } | null>, pageInfo?: { __typename?: 'PageInfoNew', hasNextPage?: boolean | null } | null } | null };

export type SelectMappingListFragmentFragment = { __typename?: 'Mapping', uuid?: string | null, name?: string | null, jobType?: number | null, remark?: string | null, createdAt?: any | null, forNDVI: boolean, sourceImages?: { __typename?: 'SourceImagesConnection', totalCount?: number | null } | null, departmentLinks?: Array<{ __typename?: 'Department', name?: string | null } | null> | null };

export type StartProcessingJobMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type StartProcessingJobMutation = { __typename?: 'Mutation', startProcessingJob?: { __typename?: 'StartProcessingJobPayload', processingJob?: { __typename?: 'ProcessingJob', uuid?: string | null } | null } | null };

export type UpdateProcessingJobMutationVariables = Exact<{
  uuid: Scalars['String'];
  name: Scalars['String'];
  tagUuids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type UpdateProcessingJobMutation = { __typename?: 'Mutation', updateProcessingJob?: { __typename?: 'UpdateProcessingJobPayload', processingJob?: { __typename?: 'ProcessingJob', tags?: { __typename?: 'TagConnection', nodes: Array<{ __typename?: 'Tag', uuid: string, name: string } | null> } | null } | null } | null };

export type ValidStartProcessingJobQueryVariables = Exact<{
  uuid: Scalars['String'];
  jobType: Scalars['Int'];
  indexList?: InputMaybe<Array<Index> | Index>;
}>;


export type ValidStartProcessingJobQuery = { __typename?: 'Query', mapping?: { __typename?: 'Mapping', validStartProcessingJob?: { __typename?: 'ValidStartProcessingJobResult', ok: number, lacks: Array<BandName> } | null } | null };

export type CreateFreePlotMutationVariables = Exact<{
  uuid: Scalars['String'];
  name: Scalars['String'];
  geo: Scalars['LineStringGeoJSON'];
  edgesPointsSource?: InputMaybe<Scalars['String']>;
}>;


export type CreateFreePlotMutation = { __typename?: 'Mutation', createFreePlot?: { __typename?: 'CreateFreePlotPayload', freePlot?: { __typename?: 'FreePlot', uuid: string, name: string, geo: any, ppeParams?: { __typename?: 'PpeParams', sprayHeight: number, sprayType: number, sprayWidth: number, sprayDirection: number, followAccuracy: number, avoidObstacle: boolean } | null } | null } | null };

export type CreateProcessingJobPlotMutationVariables = Exact<{
  processingJobUuid: Scalars['String'];
  name: Scalars['String'];
  geo: Scalars['GeoJSON'];
  edgesPointsSource?: InputMaybe<Scalars['String']>;
}>;


export type CreateProcessingJobPlotMutation = { __typename?: 'Mutation', createPlot?: { __typename?: 'CreatePlotPayload', clientMutationId?: string | null, plot?: { __typename?: 'Plot', uuid?: string | null, ndviJobs?: { __typename?: 'NdviJobsConnection', totalCount?: number | null } | null } | null } | null };

export type DeleteFreePlotMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type DeleteFreePlotMutation = { __typename?: 'Mutation', deleteFreePlot?: { __typename?: 'DeleteFreePlotPayload', freePlot?: { __typename?: 'FreePlot', uuid: string } | null } | null };

export type DeleteProcessingJobPlotMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type DeleteProcessingJobPlotMutation = { __typename?: 'Mutation', deletePlot?: { __typename?: 'DeletePlotPayload', clientMutationId?: string | null } | null };

export type LandsInRebuildQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  filter: LandsFilter;
}>;


export type LandsInRebuildQuery = { __typename?: 'Query', lands?: { __typename?: 'LandConnection', totalCount: number, nodes: Array<{ __typename?: 'Land', uuid?: string | null, name?: string | null, area?: number | null, geo?: any | null, departmentLinks?: Array<{ __typename?: 'Department', name?: string | null } | null> | null } | null>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean } } | null };

export type SyncLandsToPlotsMutationVariables = Exact<{
  jobUuid: Scalars['String'];
  landUuids: Array<Scalars['String']> | Scalars['String'];
}>;


export type SyncLandsToPlotsMutation = { __typename?: 'Mutation', syncLandsToPlots?: { __typename?: 'SyncLandsToPlotsPayload', clientMutationId?: string | null } | null };

export type UpdateFreePlotMutationVariables = Exact<{
  uuid: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  geo?: InputMaybe<Scalars['LineStringGeoJSON']>;
  caliPoints?: InputMaybe<Array<Array<Scalars['Float']> | Scalars['Float']> | Array<Scalars['Float']> | Scalars['Float']>;
  ppeParams?: InputMaybe<PpeParamsInput>;
  startPoint?: InputMaybe<Scalars['String']>;
  removeStartPoint?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateFreePlotMutation = { __typename?: 'Mutation', updateFreePlot?: { __typename?: 'UpdateFreePlotPayload', freePlot?: { __typename?: 'FreePlot', uuid: string, name: string, geo: any, ppeParams?: { __typename?: 'PpeParams', sprayHeight: number, sprayType: number, sprayWidth: number, sprayDirection: number, followAccuracy: number, avoidObstacle: boolean } | null } | null } | null };

export type UpdateProcessingJobPlotMutationVariables = Exact<{
  uuid: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  geo?: InputMaybe<Scalars['GeoJSON']>;
  caliPoints?: InputMaybe<Array<Array<Scalars['Float']> | Scalars['Float']> | Array<Scalars['Float']> | Scalars['Float']>;
  fertilizerParams?: InputMaybe<Scalars['String']>;
  ppeParams?: InputMaybe<PpeParamsInput>;
  startPoint?: InputMaybe<Scalars['String']>;
  removeStartPoint?: InputMaybe<Scalars['Boolean']>;
  edgesPointsSource?: InputMaybe<Scalars['String']>;
}>;


export type UpdateProcessingJobPlotMutation = { __typename?: 'Mutation', updatePlot?: { __typename?: 'UpdatePlotPayload', clientMutationId?: string | null } | null };

export type QueryReleaseNotesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type QueryReleaseNotesQuery = { __typename?: 'Query', releaseNotes?: { __typename?: 'ReleaseNoteConnection', totalCount: number, nodes: Array<{ __typename?: 'ReleaseNote', content?: string | null, publishedAt?: any | null, version?: string | null } | null>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean } } | null };

export type DeleteRcProcessingJobMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type DeleteRcProcessingJobMutation = { __typename?: 'Mutation', destroyRemoteControllerProcessingJob?: { __typename?: 'DestroyRemoteControllerProcessingJobPayload', clientMutationId?: string | null } | null };

export type QueryEditRcProcessingJobInfoQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type QueryEditRcProcessingJobInfoQuery = { __typename?: 'Query', remoteControllerProcessingJob?: { __typename?: 'RemoteControllerProcessingJob', id?: string | null, name?: string | null, uuid: string } | null };

export type QueryRcCommonJobFileQueryVariables = Exact<{
  uuid: Scalars['String'];
  names: Array<Scalars['String']> | Scalars['String'];
}>;


export type QueryRcCommonJobFileQuery = { __typename?: 'Query', remoteControllerProcessingJob?: { __typename?: 'RemoteControllerProcessingJob', fileUrls?: Array<{ __typename?: 'File', name?: string | null, url?: string | null }> | null } | null };

export type QueryRcProcessingJobDetailQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type QueryRcProcessingJobDetailQuery = { __typename?: 'Query', remoteControllerProcessingJob?: { __typename?: 'RemoteControllerProcessingJob', name?: string | null, type?: number | null, area?: number | null, captureTime?: any | null, createdAt?: any | null, maximumLevel: number, xyzTileFormat: string, tileUuid?: string | null, uuid: string, upperRight?: { __typename?: 'Position', lat?: number | null, lng?: number | null } | null, downLeft?: { __typename?: 'Position', lat?: number | null, lng?: number | null } | null } | null };

export type QueryRcProcessingJobsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<RemoteControllerProcessingJobsFilter>;
}>;


export type QueryRcProcessingJobsQuery = { __typename?: 'Query', remoteControllerProcessingJobs?: { __typename?: 'RemoteControllerProcessingJobConnection', totalCount: number, nodes: Array<{ __typename?: 'RemoteControllerProcessingJob', id?: string | null, status?: number | null, name?: string | null, type?: number | null, area?: number | null, createdAt?: any | null, uuid: string } | null>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean } } | null };

export type RemoteControllerProcessingJobFragmentFragment = { __typename?: 'RemoteControllerProcessingJob', id?: string | null, status?: number | null, name?: string | null, type?: number | null, area?: number | null, createdAt?: any | null, uuid: string };

export type UpdateRcProcessingJobMutationVariables = Exact<{
  uuid: Scalars['String'];
  name: Scalars['String'];
}>;


export type UpdateRcProcessingJobMutation = { __typename?: 'Mutation', updateRemoteControllerProcessingJob?: { __typename?: 'UpdateRemoteControllerProcessingJobPayload', clientMutationId?: string | null } | null };

export type AreaPlotDetailQueryVariables = Exact<{
  uuid: Scalars['String'];
  plotUuid: Scalars['String'];
}>;


export type AreaPlotDetailQuery = { __typename?: 'Query', processingJob?: { __typename?: 'ProcessingJob', plots?: { __typename?: 'PlotsConnection', edges: Array<{ __typename?: 'PlotEdge', node?: { __typename?: 'Plot', uuid?: string | null, name?: string | null, geo?: any | null, area?: number | null, createdAt?: any | null, startPoint?: string | null, ppeParams?: { __typename?: 'PpeParams', sprayHeight: number, sprayWidth: number, sprayDirection: number, followAccuracy: number, sprayType: number, avoidObstacle: boolean, isRealTime: boolean } | null, newestFlyWaypointList?: { __typename?: 'FlyWaypointList', uuid: string, isModified: boolean, pathPlanningResult?: { __typename?: 'PathPlanningResult', path: Array<{ __typename?: 'PointFT', id: number, action: number, pointType: number, semanticType: number, point3D: { __typename?: 'Point3D', latitude: number, longitude: number, altitude: number } }> } | null } | null } | null } | null> } | null } | null };

export type CreateFlyWaypointListMutationVariables = Exact<{
  input: CreateFlyWaypointListInput;
}>;


export type CreateFlyWaypointListMutation = { __typename?: 'Mutation', createFlyWaypointList?: { __typename?: 'CreateFlyWaypointListPayload', uuid: string } | null };

export type FlyWayPointFtFragment = { __typename?: 'PointFT', id: number, action: number, pointType: number, semanticType: number, point3D: { __typename?: 'Point3D', latitude: number, longitude: number, altitude: number } };

export type FlyWaypointListQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type FlyWaypointListQuery = { __typename?: 'Query', flyWaypointList?: { __typename?: 'FlyWaypointList', uuid: string, pathPlanningResult?: { __typename?: 'PathPlanningResult', path: Array<{ __typename?: 'PointFT', id: number, action: number, pointType: number, semanticType: number, point3D: { __typename?: 'Point3D', latitude: number, longitude: number, altitude: number } }> } | null } | null };

export type FreePlotDetailQueryVariables = Exact<{
  uuid: Scalars['String'];
  plotUuid: Scalars['String'];
}>;


export type FreePlotDetailQuery = { __typename?: 'Query', processingJob?: { __typename?: 'ProcessingJob', freePlots?: { __typename?: 'FreePlotsConnection', edges: Array<{ __typename?: 'FreePlotEdge', node?: { __typename?: 'FreePlot', uuid: string, name: string, geo: any, createdAt: any, startPoint?: string | null, ppeParams?: { __typename?: 'PpeParams', sprayHeight: number, sprayWidth: number, sprayDirection: number, followAccuracy: number, sprayType: number, avoidObstacle: boolean, isRealTime: boolean } | null, newestFlyWaypointList?: { __typename?: 'FlyWaypointList', uuid: string, isModified: boolean, pathPlanningResult?: { __typename?: 'PathPlanningResult', path: Array<{ __typename?: 'PointFT', id: number, action: number, pointType: number, semanticType: number, point3D: { __typename?: 'Point3D', latitude: number, longitude: number, altitude: number } }> } | null } | null } | null } | null> } | null } | null };

export type PpeParamsFragmentFragment = { __typename?: 'PpeParams', sprayHeight: number, sprayWidth: number, sprayDirection: number, followAccuracy: number, sprayType: number, avoidObstacle: boolean, isRealTime: boolean };

export type RunPathPlanningMutationVariables = Exact<{
  plotUuid: Scalars['String'];
  sprayType: Scalars['Float'];
  sprayHeight: Scalars['Float'];
  sprayWidth: Scalars['Float'];
  sprayDirection: Scalars['Float'];
  followAccuracy: Scalars['Float'];
  avoidObstacle: Scalars['Boolean'];
}>;


export type RunPathPlanningMutation = { __typename?: 'Mutation', runPathPlanning?: { __typename?: 'RunPathPlanningPayload', flyWaypointList?: { __typename?: 'FlyWaypointList', uuid: string, ppeParams?: { __typename?: 'PpeParams', sprayHeight: number, sprayWidth: number, sprayDirection: number, followAccuracy: number, sprayType: number, avoidObstacle: boolean, isRealTime: boolean } | null } | null } | null };

export type CreateTreeInfoMutationVariables = Exact<{
  processingJobUuid: Scalars['String'];
  contentMd5: Scalars['String'];
  fileSize: Scalars['Int'];
}>;


export type CreateTreeInfoMutation = { __typename?: 'Mutation', createTreeInfo?: { __typename?: 'CreateTreeInfoPayload', treeInfo?: { __typename?: 'TreeInfo', prefix: string, credential?: { __typename?: 'Credential', accessKeyId?: string | null, signature?: string | null, callback?: string | null, policy?: string | null } | null, storageService?: { __typename?: 'StorageService', endpoint?: string | null, bucketId?: string | null, prefix?: string | null, objectId?: string | null } | null, s3StsInit?: { __typename?: 'S3StsInit', cloudName: string, objectKey: string, region: string, cloudBucketName: string, endPoint: string, authorization: { __typename?: 'Authorization', ak: string, sk: string, sessionToken: string, createTime: string } } | null } | null } | null };

export type QuerySegmentFilesQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type QuerySegmentFilesQuery = { __typename?: 'Query', processingJob?: { __typename?: 'ProcessingJob', upperRight?: { __typename?: 'Position', lat?: number | null, lng?: number | null } | null, downLeft?: { __typename?: 'Position', lat?: number | null, lng?: number | null } | null, fileUrls: Array<{ __typename?: 'FileUrl', name: string, url: string }> } | null };

export type QuerySegmentStatusQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type QuerySegmentStatusQuery = { __typename?: 'Query', segmentStatus: SegmentStatus };

export type QueryTreeCenterUrlQueryVariables = Exact<{
  jobUuid: Scalars['String'];
}>;


export type QueryTreeCenterUrlQuery = { __typename?: 'Query', processingJob?: { __typename?: 'ProcessingJob', treeInfo?: { __typename?: 'TreeInfo', signURL?: string | null } | null } | null };

export type SaveSegmentMutationVariables = Exact<{
  uuid: Scalars['String'];
  segmentPartials: Array<SegmentPartial> | SegmentPartial;
}>;


export type SaveSegmentMutation = { __typename?: 'Mutation', updateSegment?: { __typename?: 'UpdateSegmentPayload', processingJobUuid: string } | null };

export type ActiveDronesQueryVariables = Exact<{
  taskId: Scalars['ID'];
  first: Scalars['Int'];
  after: Scalars['String'];
}>;


export type ActiveDronesQuery = { __typename?: 'Query', task?: { __typename?: 'Task', devices?: { __typename?: 'DeviceConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean }, edges: Array<{ __typename?: 'DeviceEdge', node?: { __typename?: 'Device', id?: string | null, name?: string | null, sn?: string | null } | null } | null> } | null } | null };

export type CreateSuperviseTaskMutationVariables = Exact<{
  departmentId: Scalars['ID'];
  name: Scalars['String'];
  beginDate: Scalars['Int'];
  endDate: Scalars['Int'];
  configuration?: InputMaybe<ConfigurationAttributes>;
  taskTypeId?: InputMaybe<Scalars['ID']>;
  enableNotify: Scalars['Boolean'];
  enableQuality: Scalars['Boolean'];
  enablePush: Scalars['Boolean'];
}>;


export type CreateSuperviseTaskMutation = { __typename?: 'Mutation', createTask: { __typename?: 'createTaskPayload', clientMutationId?: string | null } };

export type DeleteSuperviseTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
}>;


export type DeleteSuperviseTaskMutation = { __typename?: 'Mutation', deleteTask: { __typename?: 'deleteTaskPayload', clientMutationId?: string | null } };

export type DepartQualityRankQueryVariables = Exact<{
  id: Scalars['ID'];
  filter?: InputMaybe<FlightRecordFilter>;
  rank?: InputMaybe<Rank>;
}>;


export type DepartQualityRankQuery = { __typename?: 'Query', task?: { __typename?: 'Task', quality: { __typename?: 'Quality', newDepartmentRank: Array<{ __typename?: 'NewDepartmentInfo', validWorkArea: number, invalidWorkArea: number, department: { __typename?: 'Department', id?: string | null, name?: string | null } }> } } | null };

export type DepartRankForDevicesQueryVariables = Exact<{
  id: Scalars['ID'];
  filter?: InputMaybe<FlightRecordFilter>;
  rank?: InputMaybe<Rank>;
}>;


export type DepartRankForDevicesQuery = { __typename?: 'Query', task?: { __typename?: 'Task', quality: { __typename?: 'Quality', newDepartmentRankForDevices: Array<{ __typename?: 'NewDepartmentInfo', validWorkArea: number, invalidWorkArea: number, department: { __typename?: 'Department', id?: string | null, name?: string | null } }> } } | null };

export type DroneQualityRankQueryVariables = Exact<{
  id: Scalars['ID'];
  filter?: InputMaybe<FlightRecordFilter>;
  rank?: InputMaybe<Rank>;
}>;


export type DroneQualityRankQuery = { __typename?: 'Query', task?: { __typename?: 'Task', quality: { __typename?: 'Quality', newDeviceRank: Array<{ __typename?: 'NewDeviceInfo', validWorkArea: number, invalidWorkArea: number, device: { __typename?: 'Device', id?: string | null, sn?: string | null, name?: string | null } }> } } | null };

export type ExportTaskMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ExportTaskMutation = { __typename?: 'Mutation', exportTask: { __typename?: 'exportTaskPayload', task: { __typename?: 'Task', id?: string | null, exportStatus: ExportStatus } } };

export type QualityTrendDataQueryVariables = Exact<{
  id: Scalars['ID'];
  filter?: InputMaybe<FlightRecordFilter>;
}>;


export type QualityTrendDataQuery = { __typename?: 'Query', task?: { __typename?: 'Task', quality: { __typename?: 'Quality', validWorkArea: number, invalidWorkArea: number, trend: Array<{ __typename?: 'DayInfo', createDate: number, validWorkArea: number, invalidWorkArea: number }> } } | null };

export type QualitySwitchQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type QualitySwitchQuery = { __typename?: 'Query', task?: { __typename?: 'Task', enableNotify: boolean, enableQuality: boolean, enablePush: boolean } | null };

export type SuperviseDepartmentQueryVariables = Exact<{
  taskId: Scalars['ID'];
}>;


export type SuperviseDepartmentQuery = { __typename?: 'Query', task?: { __typename?: 'Task', department?: { __typename?: 'Department', id?: string | null, name?: string | null, fences?: { __typename?: 'FenceConnection', nodes: Array<{ __typename?: 'Fence', geo?: any | null } | null>, bbox?: { __typename?: 'BoundingBox', upperRight: { __typename?: 'Position', lat?: number | null, lng?: number | null }, downLeft: { __typename?: 'Position', lat?: number | null, lng?: number | null } } | null } | null, accessibleLands?: { __typename?: 'LandConnection', bbox?: { __typename?: 'BoundingBox', upperRight: { __typename?: 'Position', lat?: number | null, lng?: number | null }, downLeft: { __typename?: 'Position', lat?: number | null, lng?: number | null } } | null } | null } | null } | null };

export type SuperviseDeviceTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type SuperviseDeviceTypesQuery = { __typename?: 'Query', deviceTypes?: Array<{ __typename?: 'DeviceType', id: string, label?: string | null, name: string, vendors?: Array<{ __typename?: 'Vendor', id?: string | null, name?: string | null, deviceModels?: Array<{ __typename?: 'DeviceModel', id?: string | null, name?: string | null } | null> | null }> | null, taskTypes?: Array<{ __typename?: 'TaskType', id: string, name: string, label?: string | null }> | null }> | null };

export type SuperviseFlightRecordClusterQueryVariables = Exact<{
  id: Scalars['ID'];
  filter?: InputMaybe<FlightRecordFilter>;
  topLeft?: InputMaybe<PositionAttributes>;
  bottomRight?: InputMaybe<PositionAttributes>;
  precision?: InputMaybe<Scalars['Int']>;
}>;


export type SuperviseFlightRecordClusterQuery = { __typename?: 'Query', task?: { __typename?: 'Task', quality: { __typename?: 'Quality', flightRecordsAggr: Array<{ __typename?: 'Bucket', count: number, workArea: number, centroid: { __typename?: 'Position', lat?: number | null, lng?: number | null }, viewPort: { __typename?: 'ViewPort', topLeft: { __typename?: 'Position', lat?: number | null, lng?: number | null }, bottomRight: { __typename?: 'Position', lat?: number | null, lng?: number | null } } }> } } | null };

export type SuperviseFlightRecordDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SuperviseFlightRecordDetailQuery = { __typename?: 'Query', flightRecord?: { __typename?: 'FlightRecord', id?: string | null, taskTypeId?: string | null, beginTimestamp?: number | null, endTimestamp?: number | null, workArea?: number | null, sprayWidth?: number | null, sprayUsage?: number | null, sowUsage?: number | null, quality?: QualityType | null, invalidAttributes: Array<FlightRecordAttributeEnum>, device?: { __typename?: 'Device', id?: string | null, name?: string | null, sn?: string | null, deviceModel?: { __typename?: 'DeviceModel', name?: string | null, deviceType?: { __typename?: 'DeviceType', name: string } | null, vendor?: { __typename?: 'Vendor', name?: string | null } | null } | null } | null, airline?: Array<{ __typename?: 'FlightData', lat: number, lng: number } | null> | null, flightRecordAttribute?: { __typename?: 'FlightRecordAttribute', MaxSpeed?: number | null, MaxHeight?: number | null, MinHeight?: number | null, SprayWidth: number, SprayUsagePerMu: number, SowUsagePerMu: number, WindSpeed?: number | null, Temperature?: number | null, Rainfall?: number | null } | null, land?: { __typename?: 'Land', name?: string | null, externalId?: string | null, departmentLinks?: Array<{ __typename?: 'Department', id?: string | null, name?: string | null } | null> | null } | null } | null };

export type QuerySuperviseTaskConfigQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type QuerySuperviseTaskConfigQuery = { __typename?: 'Query', task?: { __typename?: 'Task', configuration?: { __typename?: 'Configuration', MinSprayUsagePerMu?: number | null, MaxSprayUsagePerMu?: number | null, MinSowUsagePerMu?: number | null, MaxSowUsagePerMu?: number | null, MaxSpeed?: number | null, MinHeight?: number | null, MaxHeight?: number | null, MinSprayWidth?: number | null, MaxSprayWidth?: number | null, MaxWindSpeed?: number | null, MinTemperature?: number | null, MaxTemperature?: number | null, MaxRainfall?: number | null, localTimes?: Array<{ __typename?: 'LocalTimeRange', beginLocalTime: { __typename?: 'LocalTime', timeString: string, timezoneOffset: number }, endLocalTime: { __typename?: 'LocalTime', timeString: string, timezoneOffset: number } }> | null } | null } | null };

export type QuerySuperviseTaskListQueryVariables = Exact<{
  first: Scalars['Int'];
  after: Scalars['String'];
  departmentUuid?: InputMaybe<Scalars['String']>;
}>;


export type QuerySuperviseTaskListQuery = { __typename?: 'Query', tasks?: { __typename?: 'TaskConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean }, edges: Array<{ __typename?: 'TaskEdge', node?: { __typename?: 'Task', id?: string | null, name?: string | null, beginDate?: number | null, endDate?: number | null, totalWorkArea?: number | null, taskType?: { __typename?: 'TaskType', id: string, name: string, deviceType?: { __typename?: 'DeviceType', name: string } | null } | null, department?: { __typename?: 'Department', uuid?: string | null } | null, departmentLinks?: Array<{ __typename?: 'Department', id?: string | null, name?: string | null, uuid?: string | null } | null> | null } | null } | null> } | null };

export type TaskExportStatusQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TaskExportStatusQuery = { __typename?: 'Query', task?: { __typename?: 'Task', id?: string | null, exportStatus: ExportStatus, exportFileUrl?: string | null } | null };

export type SuperviseTaskFragment = { __typename?: 'Task', id?: string | null, name?: string | null, beginDate?: number | null, endDate?: number | null, totalWorkArea?: number | null, taskType?: { __typename?: 'TaskType', id: string, name: string, deviceType?: { __typename?: 'DeviceType', name: string } | null } | null, department?: { __typename?: 'Department', uuid?: string | null } | null, departmentLinks?: Array<{ __typename?: 'Department', id?: string | null, name?: string | null, uuid?: string | null } | null> | null };

export type UpdateNotifyReceiverMutationVariables = Exact<{
  uuid: Scalars['String'];
  departmentAttributes: DepartmentAttributes;
}>;


export type UpdateNotifyReceiverMutation = { __typename?: 'Mutation', updateDepartment: { __typename?: 'updateDepartmentPayload', department?: { __typename?: 'Department', id?: string | null, uuid?: string | null, name?: string | null, NotifyReceiverName?: string | null, NotifyReceiverPhone?: string | null } | null } };

export type UpdateSuperviseQualityConfigMutationVariables = Exact<{
  taskId: Scalars['ID'];
  attributes: ConfigurationAttributes;
}>;


export type UpdateSuperviseQualityConfigMutation = { __typename?: 'Mutation', createOrUpdateConfiguration: { __typename?: 'createOrUpdateConfigurationPayload', clientMutationId?: string | null } };

export type UpdateSuperviseTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  beginDate?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['Int']>;
}>;


export type UpdateSuperviseTaskMutation = { __typename?: 'Mutation', updateTask: { __typename?: 'updateTaskPayload', clientMutationId?: string | null, task: { __typename?: 'Task', id?: string | null, name?: string | null, beginDate?: number | null, endDate?: number | null, totalWorkArea?: number | null, taskType?: { __typename?: 'TaskType', id: string, name: string, deviceType?: { __typename?: 'DeviceType', name: string } | null } | null, department?: { __typename?: 'Department', uuid?: string | null } | null, departmentLinks?: Array<{ __typename?: 'Department', id?: string | null, name?: string | null, uuid?: string | null } | null> | null } } };

export type UpdateTaskQualityParamsMutationVariables = Exact<{
  taskId: Scalars['ID'];
  enableNotify: Scalars['Boolean'];
  enableQuality: Scalars['Boolean'];
  enablePush: Scalars['Boolean'];
}>;


export type UpdateTaskQualityParamsMutation = { __typename?: 'Mutation', updateTask: { __typename?: 'updateTaskPayload', task: { __typename?: 'Task', enableNotify: boolean, enableQuality: boolean, enablePush: boolean } } };

export type AttachTagsMutationVariables = Exact<{
  uuids: Array<Scalars['String']> | Scalars['String'];
  taggableType: TaggableType;
  taggableUuid: Scalars['String'];
}>;


export type AttachTagsMutation = { __typename?: 'Mutation', attachTags?: { __typename?: 'AttachTagsPayload', tags?: Array<{ __typename?: 'Tag', uuid: string, name: string } | null> | null } | null };

export type QueryTagsListQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TagsFilter>;
}>;


export type QueryTagsListQuery = { __typename?: 'Query', tags?: { __typename?: 'TagConnection', totalCount: number, nodes: Array<{ __typename?: 'Tag', uuid: string, name: string } | null>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean } } | null };

export type CompressingNdviJobStatusQueryVariables = Exact<{
  ndviUuid: Scalars['String'];
}>;


export type CompressingNdviJobStatusQuery = { __typename?: 'Query', queryDownloadNdviFertilizer?: { __typename?: 'QueryDownloadNdviFertilizerPayload', status: DownloadNdviFertilizerStatus, downloadUrl: string } | null };

export type CompressionJobStatusQueryVariables = Exact<{
  jobUuid: Scalars['String'];
  compressionUUid: Scalars['String'];
}>;


export type CompressionJobStatusQuery = { __typename?: 'Query', processingJob?: { __typename?: 'ProcessingJob', newestCompressionJobForTile?: { __typename?: 'CompressionJobForDjitile', status: number, url?: string | null } | null } | null };

export type FirstNdviJobInPlotQueryVariables = Exact<{
  jobUuid: Scalars['String'];
  plotUuid: Scalars['String'];
}>;


export type FirstNdviJobInPlotQuery = { __typename?: 'Query', processingJob?: { __typename?: 'ProcessingJob', ndviJobs?: { __typename?: 'NdviJobsConnection', edges: Array<{ __typename?: 'NdviJobEdge', node?: { __typename?: 'NdviJob', uuid?: string | null } | null } | null> } | null } | null };

export type QueryMutiSpectualTaskClusterQueryVariables = Exact<{
  filter?: InputMaybe<ProcessingJobsFilter>;
}>;


export type QueryMutiSpectualTaskClusterQuery = { __typename?: 'Query', processingJobClusters?: { __typename?: 'ProcessingJobClustersConnection', nodes: Array<{ __typename?: 'ProcessingJobCluster', count: number, center?: { __typename?: 'Position', lat?: number | null, lng?: number | null } | null, bbox?: { __typename?: 'BoundingBox', downLeft: { __typename?: 'Position', lat?: number | null, lng?: number | null }, upperRight: { __typename?: 'Position', lat?: number | null, lng?: number | null } } | null } | null> } | null };

export type TaskInvalidInfoQueryVariables = Exact<{
  id: Scalars['ID'];
  filter?: InputMaybe<FlightRecordFilter>;
}>;


export type TaskInvalidInfoQuery = { __typename?: 'Query', task?: { __typename?: 'Task', quality: { __typename?: 'Quality', invalidWorkArea: number, invalidInfo: { __typename?: 'InvalidInfo', invalidMaxSpeedWorkArea: number, invalidSprayUsagePerMuWorkArea: number, invalidSowUsagePerMuWorkArea: number, invalidSprayWidthWorkArea: number, invalidTemperatureWorkArea: number, invalidWindSpeedWorkArea: number, invalidRailfallWorkArea: number, invalidFusionHeightWorkArea: number, invalidLocalTimeWorkArea: number } } } | null };

export type QueryTaskProcessingJobsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProcessingJobsFilter>;
}>;


export type QueryTaskProcessingJobsQuery = { __typename?: 'Query', processingJobs?: { __typename?: 'ProcessingJobsConnection', totalCount?: number | null, pageInfo?: { __typename?: 'PageInfoNew', hasNextPage?: boolean | null } | null, nodes: Array<{ __typename?: 'ProcessingJob', uuid?: string | null, name?: string | null, area?: number | null, type?: number | null, createdAt?: any | null, captureTime?: any | null, params?: string | null, tags?: { __typename?: 'TagConnection', nodes: Array<{ __typename?: 'Tag', uuid: string, name: string } | null> } | null, departmentLinks?: Array<{ __typename?: 'Department', uuid?: string | null, name?: string | null } | null> | null, upperRight?: { __typename?: 'Position', lng?: number | null, lat?: number | null } | null, downLeft?: { __typename?: 'Position', lng?: number | null, lat?: number | null } | null } | null> } | null };

export type TaskProcessingJobsFragmentFragment = { __typename?: 'ProcessingJob', uuid?: string | null, name?: string | null, area?: number | null, type?: number | null, createdAt?: any | null, captureTime?: any | null, params?: string | null, tags?: { __typename?: 'TagConnection', nodes: Array<{ __typename?: 'Tag', uuid: string, name: string } | null> } | null, departmentLinks?: Array<{ __typename?: 'Department', uuid?: string | null, name?: string | null } | null> | null, upperRight?: { __typename?: 'Position', lng?: number | null, lat?: number | null } | null, downLeft?: { __typename?: 'Position', lng?: number | null, lat?: number | null } | null };

export type StartCompressionJobMutationVariables = Exact<{
  uuid: Scalars['String'];
  plotUuids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  ndviJobUuids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type StartCompressionJobMutation = { __typename?: 'Mutation', startCompressionJobForDjitile?: { __typename?: 'StartCompressionJobForDjitilePayload', compressionJobForDjitile?: { __typename?: 'CompressionJobForDjitile', uuid: string } | null } | null };

export type StartDownloadNdviFertilizerMutationVariables = Exact<{
  ndviUuid: Scalars['String'];
}>;


export type StartDownloadNdviFertilizerMutation = { __typename?: 'Mutation', downloadNdviFertilizer?: { __typename?: 'DownloadNdviFertilizerPayload', clientMutationId?: string | null } | null };

export type DeleteRebuildDetailPlotMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type DeleteRebuildDetailPlotMutation = { __typename?: 'Mutation', deletePlot?: { __typename?: 'DeletePlotPayload', plot?: { __typename?: 'Plot', uuid?: string | null } | null } | null };

export type DestroyFertilizerMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type DestroyFertilizerMutation = { __typename?: 'Mutation', destroyNdviJob?: { __typename?: 'DestroyNdviJobPayload', clientMutationId?: string | null } | null };

export type QueryAreaPlotsQueryVariables = Exact<{
  uuid: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type QueryAreaPlotsQuery = { __typename?: 'Query', processingJob?: { __typename?: 'ProcessingJob', plots?: { __typename?: 'PlotsConnection', totalCount?: number | null, edges: Array<{ __typename?: 'PlotEdge', node?: { __typename?: 'Plot', uuid?: string | null, name?: string | null, area?: number | null, geo?: any | null, createdAt?: any | null, ppeParams?: { __typename?: 'PpeParams', sprayHeight: number, sprayWidth: number, sprayDirection: number, followAccuracy: number, sprayType: number, avoidObstacle: boolean, isRealTime: boolean } | null } | null } | null>, pageInfo?: { __typename?: 'PageInfoNew', hasNextPage?: boolean | null } | null } | null } | null };

export type AreaPlotNodeFragment = { __typename?: 'Plot', uuid?: string | null, name?: string | null, area?: number | null, geo?: any | null, createdAt?: any | null, ppeParams?: { __typename?: 'PpeParams', sprayHeight: number, sprayWidth: number, sprayDirection: number, followAccuracy: number, sprayType: number, avoidObstacle: boolean, isRealTime: boolean } | null };

export type QueryFreePlotsQueryVariables = Exact<{
  uuid: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type QueryFreePlotsQuery = { __typename?: 'Query', processingJob?: { __typename?: 'ProcessingJob', freePlots?: { __typename?: 'FreePlotsConnection', totalCount?: number | null, edges: Array<{ __typename?: 'FreePlotEdge', node?: { __typename?: 'FreePlot', uuid: string, name: string, geo: any, createdAt: any, ppeParams?: { __typename?: 'PpeParams', sprayHeight: number, sprayWidth: number, sprayDirection: number, followAccuracy: number, sprayType: number, avoidObstacle: boolean, isRealTime: boolean } | null } | null } | null>, pageInfo?: { __typename?: 'PageInfoNew', hasNextPage?: boolean | null } | null } | null } | null };

export type FreePlotNodeFragment = { __typename?: 'FreePlot', uuid: string, name: string, geo: any, createdAt: any, ppeParams?: { __typename?: 'PpeParams', sprayHeight: number, sprayWidth: number, sprayDirection: number, followAccuracy: number, sprayType: number, avoidObstacle: boolean, isRealTime: boolean } | null };

export type NdviJobsQueryVariables = Exact<{
  uuid: Scalars['String'];
  filter?: InputMaybe<NdviJobsFilter>;
}>;


export type NdviJobsQuery = { __typename?: 'Query', processingJob?: { __typename?: 'ProcessingJob', fileUrls: Array<{ __typename?: 'FileUrl', name: string }>, ndviJobs?: { __typename?: 'NdviJobsConnection', edges: Array<{ __typename?: 'NdviJobEdge', node?: { __typename?: 'NdviJob', name?: string | null, uuid?: string | null, params?: string | null, status?: number | null, createdAt?: any | null } | null } | null> } | null } | null };

export type NdviJobWithPlotFragment = { __typename?: 'NdviJob', name?: string | null, uuid?: string | null, params?: string | null, status?: number | null, createdAt?: any | null };

export type NdviRelativePlotsQueryVariables = Exact<{
  uuid: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type NdviRelativePlotsQuery = { __typename?: 'Query', processingJob?: { __typename?: 'ProcessingJob', plots?: { __typename?: 'PlotsConnection', totalCount?: number | null, edges: Array<{ __typename?: 'PlotEdge', node?: { __typename?: 'Plot', uuid?: string | null, name?: string | null, area?: number | null, geo?: any | null, fertilizerParams?: string | null, createdAt?: any | null, ndviJobs?: { __typename?: 'NdviJobsConnection', totalCount?: number | null } | null, ppeParams?: { __typename?: 'PpeParams', sprayHeight: number, sprayWidth: number, sprayDirection: number, followAccuracy: number, sprayType: number, avoidObstacle: boolean, isRealTime: boolean } | null } | null } | null>, pageInfo?: { __typename?: 'PageInfoNew', hasNextPage?: boolean | null } | null } | null } | null };

export type PlotNodeFragment = { __typename?: 'Plot', uuid?: string | null, name?: string | null, area?: number | null, geo?: any | null, fertilizerParams?: string | null, createdAt?: any | null, ndviJobs?: { __typename?: 'NdviJobsConnection', totalCount?: number | null } | null, ppeParams?: { __typename?: 'PpeParams', sprayHeight: number, sprayWidth: number, sprayDirection: number, followAccuracy: number, sprayType: number, avoidObstacle: boolean, isRealTime: boolean } | null };

export type PlotPlanInfoQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type PlotPlanInfoQuery = { __typename?: 'Query', processingJob?: { __typename?: 'ProcessingJob', uuid?: string | null, name?: string | null, type?: number | null, params?: string | null, xyzTileFormat: string, upperRight?: { __typename?: 'Position', lat?: number | null, lng?: number | null } | null, downLeft?: { __typename?: 'Position', lat?: number | null, lng?: number | null } | null, mapReport?: { __typename?: 'MapReport', MapTile: { __typename?: 'MapTile', MaximumLevel: number } } | null } | null };

export type PlotPlanInfoFragmentFragment = { __typename?: 'ProcessingJob', uuid?: string | null, name?: string | null, type?: number | null, params?: string | null, xyzTileFormat: string, upperRight?: { __typename?: 'Position', lat?: number | null, lng?: number | null } | null, downLeft?: { __typename?: 'Position', lat?: number | null, lng?: number | null } | null, mapReport?: { __typename?: 'MapReport', MapTile: { __typename?: 'MapTile', MaximumLevel: number } } | null };

export type ShareFertilizerMutationVariables = Exact<{
  uuid?: InputMaybe<Scalars['String']>;
  account?: InputMaybe<Scalars['String']>;
}>;


export type ShareFertilizerMutation = { __typename?: 'Mutation', createFertilizerShare: { __typename?: 'createFertilizerSharePayload', clientMutationId?: string | null } };

export type ShareFreePlotMutationVariables = Exact<{
  uuid: Scalars['String'];
  account: Scalars['String'];
  plotUuid: Scalars['String'];
}>;


export type ShareFreePlotMutation = { __typename?: 'Mutation', plotsShare?: { __typename?: 'PlotsSharePayload', clientMutationId?: string | null } | null };

export type SharePlotsFertilizerMutationVariables = Exact<{
  uuid: Scalars['String'];
  account: Scalars['String'];
  plotUuid: Scalars['String'];
}>;


export type SharePlotsFertilizerMutation = { __typename?: 'Mutation', plotsShare?: { __typename?: 'PlotsSharePayload', clientMutationId?: string | null } | null };

export type CreatePilotPermissionMutationVariables = Exact<{
  departmentUuid?: InputMaybe<Scalars['String']>;
  account: Scalars['String'];
  notifyPhone?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
}>;


export type CreatePilotPermissionMutation = { __typename?: 'Mutation', createPermission?: { __typename?: 'CreatePermissionPayload', permission?: { __typename?: 'Permission', uuid?: string | null, name?: string | null, orgId?: number | null, orgName?: string | null, note?: string | null, notifyPhone?: string | null, departCode?: string | null, user?: { __typename?: 'PilotUser', name?: string | null, phone?: string | null, email?: string | null } | null } | null } | null };

export type DeletePilotPermissionMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type DeletePilotPermissionMutation = { __typename?: 'Mutation', deletePermission?: { __typename?: 'DeletePermissionPayload', permission?: { __typename?: 'Permission', uuid?: string | null } | null } | null };

export type FragmentPermissionFragment = { __typename?: 'Permission', uuid?: string | null, name?: string | null, orgId?: number | null, orgName?: string | null, note?: string | null, notifyPhone?: string | null, departCode?: string | null, user?: { __typename?: 'PilotUser', name?: string | null, phone?: string | null, email?: string | null } | null };

export type NotifyPilotPermissionMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type NotifyPilotPermissionMutation = { __typename?: 'Mutation', notifyPermission?: { __typename?: 'NotifyPermissionPayload', permission?: { __typename?: 'Permission', uuid?: string | null } | null } | null };

export type PilotPermissionsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type PilotPermissionsQuery = { __typename?: 'Query', permissions?: { __typename?: 'PermissionConnection', nodes: Array<{ __typename?: 'Permission', uuid?: string | null, name?: string | null, orgId?: number | null, orgName?: string | null, note?: string | null, notifyPhone?: string | null, departCode?: string | null, user?: { __typename?: 'PilotUser', name?: string | null, phone?: string | null, email?: string | null } | null } | null> } | null };

export type UpdatePilotPermissionMutationVariables = Exact<{
  uuid: Scalars['String'];
  account: Scalars['String'];
  notifyPhone?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
}>;


export type UpdatePilotPermissionMutation = { __typename?: 'Mutation', updatePermission?: { __typename?: 'UpdatePermissionPayload', permission?: { __typename?: 'Permission', uuid?: string | null, name?: string | null, orgId?: number | null, orgName?: string | null, note?: string | null, notifyPhone?: string | null, departCode?: string | null, user?: { __typename?: 'PilotUser', name?: string | null, phone?: string | null, email?: string | null } | null } | null } | null };

export type UserFragmentFragment = { __typename?: 'User', id?: string | null, uuid?: string | null, name?: string | null, phone?: string | null, email?: string | null, role: Role, canViewRealArea: boolean, permissionFlag: boolean, useLayerGroup?: boolean | null, layerGroup?: string | null, authKey?: string | null, firstLogin: boolean, createdAt?: any | null, endAt: any, departmentLinks?: Array<{ __typename?: 'Department', name?: string | null } | null> | null, member?: { __typename?: 'Member', id?: string | null, uuid?: string | null, organizationRoleId: number, organization?: { __typename?: 'Organization', id?: string | null, uuid?: string | null, name?: string | null, maxDepartmentLevel?: number | null } | null, RoleMemberDepartment?: { __typename?: 'MemberDepartment', id?: string | null, uuid?: string | null, role: number, department?: { __typename?: 'Department', id?: string | null, uuid?: string | null, name?: string | null } | null } | null, departments?: { __typename?: 'DepartmentConnection', totalCount: number, nodes: Array<{ __typename?: 'Department', id?: string | null, uuid?: string | null, name?: string | null, parentDepartmentId?: string | null } | null> } | null } | null };

export type UserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type UserProfileQuery = { __typename?: 'Query', userProfile?: { __typename?: 'User', edition: Edition, id?: string | null, uuid?: string | null, name?: string | null, phone?: string | null, email?: string | null, role: Role, canViewRealArea: boolean, permissionFlag: boolean, useLayerGroup?: boolean | null, layerGroup?: string | null, authKey?: string | null, firstLogin: boolean, createdAt?: any | null, endAt: any, departmentLinks?: Array<{ __typename?: 'Department', name?: string | null } | null> | null, member?: { __typename?: 'Member', id?: string | null, uuid?: string | null, organizationRoleId: number, organization?: { __typename?: 'Organization', id?: string | null, uuid?: string | null, name?: string | null, maxDepartmentLevel?: number | null } | null, RoleMemberDepartment?: { __typename?: 'MemberDepartment', id?: string | null, uuid?: string | null, role: number, department?: { __typename?: 'Department', id?: string | null, uuid?: string | null, name?: string | null } | null } | null, departments?: { __typename?: 'DepartmentConnection', totalCount: number, nodes: Array<{ __typename?: 'Department', id?: string | null, uuid?: string | null, name?: string | null, parentDepartmentId?: string | null } | null> } | null } | null } | null };
