/**
 * @file 重建状态转换
 * @author xinqiang.wei 2022/8/5
 */
import { t } from 'i18next'
import { REBUILD_STATUS } from '@/pages/Media/Rebuild/types.d'
import { TASK_STATUS_ITEM } from '@/types/common.d'
// 前端定义的任务状态，由 REBUILD_TASK_STATUS 映射而来
// REBUILD_STATUS <=> dji service status
// 映射逻辑 https://confluence.djicorp.com/pages/viewpage.action?pageId=178574096


/**
 * dji service 重建状态码映射到 REBUILD_STATUS 常量
 *
 * @param {number} status dji service 重建状态码
 * @return {*}
 */
export function mappingRebuildStatus(status: TASK_STATUS_ITEM) {
  switch (status) {
    case TASK_STATUS_ITEM.WAITING:
    case TASK_STATUS_ITEM.PREPARING:
    case TASK_STATUS_ITEM.RUNNING:
    case TASK_STATUS_ITEM.PROCESSING_RESULTS:
      return {
        status: REBUILD_STATUS.PENDING,
        name: t('pages.media.rebuild.status.pending')
      }
    case TASK_STATUS_ITEM.FINISHED:
      return {
        status: REBUILD_STATUS.SUCCESS,
        name: t('pages.media.rebuild.status.success')
      }
    case TASK_STATUS_ITEM.TO_START:
    case TASK_STATUS_ITEM.STOP_IT:
    case TASK_STATUS_ITEM.FAILED:
      return {
        status: REBUILD_STATUS.FAILED,
        // 翻译有问题，按道理应该取 status.failure，产品 ken.li 新增了一个 key
        name: t('rebuildError.reason')
      }
    default:
      return {
        status: REBUILD_STATUS.FAILED,
        name: t('pages.media.rebuild.status.failure')
      }
  }
}


// 前端定义的任务状态，由 REBUILD_TASK_STATUS 映射而来
export const REBUILD_TASK_STATUS = {
  [REBUILD_STATUS.PENDING]: [
    TASK_STATUS_ITEM.WAITING,
    TASK_STATUS_ITEM.PREPARING,
    TASK_STATUS_ITEM.RUNNING,
    TASK_STATUS_ITEM.PROCESSING_RESULTS
  ],
  [REBUILD_STATUS.SUCCESS]: [
    TASK_STATUS_ITEM.FINISHED
  ],
  [REBUILD_STATUS.FAILED]: [
    TASK_STATUS_ITEM.TO_START,
    TASK_STATUS_ITEM.STOP_IT,
    TASK_STATUS_ITEM.FAILED
  ]
}
