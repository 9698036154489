/**
 * @file 获取用户条款、隐私条款
 * @author lian.duan 2023-04-04
 */
import { currentLocaleConfig } from '@/i18n'

export default class Terms {
  // 用户条款
  static get userTerms() {
    switch (currentLocaleConfig.lang) {
      case 'zh':
        return `${VITE_APP_AG_WEB_HOST}/staticpages/dji-smart-farm-user-terms`
      default:
        return `${VITE_APP_AG_WEB_HOST}/staticpages/dji-smart-farm-user-terms/en`
    }
  }

  // 隐私条款
  static get privacyPolicy() {
    return 'https://www.dji.com/policy'
  }
}