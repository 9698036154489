/**
 * @file 角色常量
 * TODO: 目前只用来做菜单入口的控制，后续的路由，子模块入口的校验还要加
 *
 * @author aron.yao 2022-11-04
 */
import PERMIT_MODULES from './modules'

// 系统角色
export enum ROLES {
  USER = 0, // 普通用户
  ADMIN = 1 // 管理员
}

// 普通用户的功能权限
export const USER_PERMITS = [
  // 数据概览
  PERMIT_MODULES.OVERVIEW.CHECK,
  // 农业沙盘
  PERMIT_MODULES.SANDBOX.CHECK,
  // 实时监管
  PERMIT_MODULES.REALTIME.CHECK,
  // 作业管理
  PERMIT_MODULES.TASK.CHECK,
  // 农田规划
  PERMIT_MODULES.FARM_PLAN.CHECK,
  // 变量作业
  PERMIT_MODULES.MULTI_SPECTRAL.CHECK,
  // 果树规划
  PERMIT_MODULES.FRUIT_PLAN.CHECK,
  // 巡田任务
  PERMIT_MODULES.PATROL_FARM.CHECK,
  // 测产分析
  PERMIT_MODULES.CROP_ESTIMATION.CHECK,
  // 出苗分析
  PERMIT_MODULES.SEEDLING.CHECK,
  // 媒体库-相册
  PERMIT_MODULES.MEDIA_PHOTO.CHECK,
  // 媒体库-重建图
  PERMIT_MODULES.MEDIA_REBUILD.CHECK,
  // 地块管理
  PERMIT_MODULES.PLOT.CHECK,
  // 气象数据
  PERMIT_MODULES.WEATHER.CHECK,
  // 第三方处方图
  PERMIT_MODULES.TP_FERTILIZER.CHECK,
  // 设置-问题反馈
  PERMIT_MODULES.FEEDBACK.CHECK,
  // 设置-更新日志
  PERMIT_MODULES.RELEASE_NOTES.CHECK,
  // 设置-常见问题
  PERMIT_MODULES.FAQ.CHECK
]

// 管理员用户的功能权限
export const ADMIN_PERMITS = [
  // 普通用户权限
  ...USER_PERMITS,
  // 地图授权
  PERMIT_MODULES.MAPPING_AUTH.CHECK,
  // 用户管理
  PERMIT_MODULES.USERS.CHECK,
  // 组织架构
  PERMIT_MODULES.DEPARTMENTS.CHECK,
  // 设备管理
  PERMIT_MODULES.DEVICE.CHECK,
  // 设置-问题反馈
  PERMIT_MODULES.FEEDBACK.CHECK,
  // 设置-更新日志
  PERMIT_MODULES.RELEASE_NOTES.CHECK,
  // 设置-常见问题
  PERMIT_MODULES.FAQ.CHECK
]

// 个人版国内用户的功能权限
export const PERSONAL_PERMITS = [
  // 农业沙盘
  PERMIT_MODULES.SANDBOX.CHECK,
  // 农田规划
  PERMIT_MODULES.FARM_PLAN.CHECK,
  // 变量作业
  PERMIT_MODULES.MULTI_SPECTRAL.CHECK,
  // 果树规划
  PERMIT_MODULES.FRUIT_PLAN.CHECK,
  // 巡田任务
  PERMIT_MODULES.PATROL_FARM.CHECK,
  // 测产分析
  PERMIT_MODULES.CROP_ESTIMATION.CHECK,
  // 地图授权
  PERMIT_MODULES.MAPPING_AUTH.CHECK,
  // 媒体库-相册
  PERMIT_MODULES.MEDIA_PHOTO.CHECK,
  // 媒体库-重建图
  PERMIT_MODULES.MEDIA_REBUILD.CHECK,
  // 地块管理
  PERMIT_MODULES.PLOT.CHECK,
  // 第三方处方图
  PERMIT_MODULES.TP_FERTILIZER.CHECK,
  // 出苗分析
  PERMIT_MODULES.SEEDLING.CHECK,
  // 设置-问题反馈
  PERMIT_MODULES.FEEDBACK.CHECK,
  // 设置-更新日志
  PERMIT_MODULES.RELEASE_NOTES.CHECK,
  // 设置-常见问题
  PERMIT_MODULES.FAQ.CHECK
]

// 个人版海外用户的功能权限
export const PERSONAL_OVERSEA_PERMITS = [
  // 农田规划
  PERMIT_MODULES.FARM_PLAN.CHECK,
  // 变量作业
  PERMIT_MODULES.MULTI_SPECTRAL.CHECK,
  // 果树规划
  PERMIT_MODULES.FRUIT_PLAN.CHECK,
  // 巡田任务
  PERMIT_MODULES.PATROL_FARM.CHECK,
  // 地图授权
  PERMIT_MODULES.MAPPING_AUTH.CHECK,
  // 媒体库-相册
  PERMIT_MODULES.MEDIA_PHOTO.CHECK,
  // 媒体库-重建图
  PERMIT_MODULES.MEDIA_REBUILD.CHECK,
  // 地块管理
  PERMIT_MODULES.PLOT.CHECK,
  // 第三方处方图
  PERMIT_MODULES.TP_FERTILIZER.CHECK,
  // 设置-问题反馈
  PERMIT_MODULES.FEEDBACK.CHECK
]

export const GOVERNMENT_USER_PERMITS = [
  // 数据概览
  PERMIT_MODULES.OVERVIEW.CHECK,
  // 实时监管
  PERMIT_MODULES.REALTIME.CHECK,
  // 作业监管
  PERMIT_MODULES.TASK.CHECK,
  // 设置-问题反馈
  PERMIT_MODULES.FEEDBACK.CHECK,
  // 设置-更新日志
  PERMIT_MODULES.RELEASE_NOTES.CHECK,
  // 设置-更新日志
  PERMIT_MODULES.FAQ.CHECK
]

// 政府版管理员的功能权限
export const GOVERNMENT_ADMIN_PERMITS = [
  ...GOVERNMENT_USER_PERMITS,
  // 地块管理
  PERMIT_MODULES.PLOT.CHECK,
  // 用户管理
  PERMIT_MODULES.USERS.CHECK,
  // 组织架构
  PERMIT_MODULES.DEPARTMENTS.CHECK,
  // 设备管理
  PERMIT_MODULES.DEVICE.CHECK
]

export default {
  ROLES,
  USER_PERMITS,
  ADMIN_PERMITS,
  PERSONAL_PERMITS,
  PERSONAL_OVERSEA_PERMITS,
  GOVERNMENT_USER_PERMITS,
  GOVERNMENT_ADMIN_PERMITS
}
