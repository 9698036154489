/**
 * @file 单位制多语言
 * internationalization unit
 * i22t
 * @author Yangholmes 2023-03-07
 */

/** 支持的单位制，这里截取 国家/地区 码作为单位制 */
export enum UnitCode {
  /** 国际单位制主单位 */
  Si = 'si',
  /** 智慧农业平台标准单位 */
  Std = 'std',
  /** 华制单位 */
  Cn = 'cn',
  /** 英制单位 */
  Uk = 'uk'
}

/** 支持的物理量 */
export const enum Quantity {
  /** 面积 */
  Area = 'area',
  /** 长度 */
  Length = 'length',
  /** 重量 */
  Weight = 'weight',
  /** 体积 */
  Volumn = 'volumn',
  /** 速度 */
  Speed = 'speed'
}

/** 系数类型声明 */
type Coefficient = {
  [code in UnitCode]: {
    [quantity in Quantity]: [
      c: number,
      o: number
    ]
  }
}

/** 系数表 */
const coefficient: Coefficient = {
  [UnitCode.Si]: {
    /** 米 */
    [Quantity.Length]: [1, 0],
    /** 平方米 */
    [Quantity.Area]: [1, 0],
    /** 千克 */
    [Quantity.Weight]: [1, 0],
    /** 立方米 */
    [Quantity.Volumn]: [1, 0],
    /** 米/秒 */
    [Quantity.Speed]: [1, 0]
  },
  [UnitCode.Std]: {
    /** 米 */
    [Quantity.Length]: [1, 0],
    /** 公顷 */
    [Quantity.Area]: [1e-4, 0],
    /** 千克 */
    [Quantity.Weight]: [1, 0],
    /** 升 */
    [Quantity.Volumn]: [1e3, 0],
    /** 米/秒 */
    [Quantity.Speed]: [1, 0]
  },
  [UnitCode.Cn]: {
    /** 米 */
    [Quantity.Length]: [1, 0],
    /** 亩 */
    [Quantity.Area]: [1.5e-3, 0],
    /** 千克 */
    [Quantity.Weight]: [1, 0],
    /** 升 */
    [Quantity.Volumn]: [1e3, 0],
    /** 米/秒 */
    [Quantity.Speed]: [1, 0]
  },
  [UnitCode.Uk]: {
    /** 英尺 */
    [Quantity.Length]: [1 / 3.048e-1, 0],
    /** 英亩 */
    [Quantity.Area]: [1 / 4.0468564224e3, 0],
    /** 磅 */
    [Quantity.Weight]: [1 / 4.5359237e-1, 0],
    /** 美制加仑 */
    [Quantity.Volumn]: [1 / 3.785411784e-3, 0],
    /** 英尺/秒 */
    [Quantity.Speed]: [1 / 3.048e-1, 0]
  }
}

/** 默认 TemplateType ，为空对象 */
export interface TemplateType { } // eslint-disable-line @typescript-eslint/no-empty-interface

/** 模板替换 */
type Replace<
  TString extends string,
  TToReplace extends string,
  TReplacement extends string,
> = TString extends `${infer TPrefix}${TToReplace}${infer TSuffix}`
  ? `${TPrefix}${TReplacement}${TSuffix}`
  : never

/** key 的计算 */
type Key<T> = T extends { template: string }
  ? Replace<Replace<T['template'], '{code}', UnitCode>, '{quantity}', Quantity>
  : Replace<Replace<typeof defaultTemplate, '{code}', UnitCode>, '{quantity}', Quantity>;

/** 默认模板 */
const defaultTemplate = 'common.unit.{code}.{quantity}'
/** 目标单位制 */
let unitCode: UnitCode = UnitCode.Std
/** 数据源单位制，默认是标准单位制 std */
let baseUnitCode: UnitCode = UnitCode.Std
/** i18n key 模板，默认为默认模板 */
let template: string = defaultTemplate
/** 是否初始化 */
let isInitial = false

/**
 * 计算目标单位制值
 *
 * @param value 输入值
 * @param quantity 物理量
 * @param code 目标单位制
 * @return
 */
export function toValue(value: number, quantity: Quantity, code: UnitCode) {
  const co = coefficient[code]
  let [k, b] = co[quantity]
  const [k0, b0] = coefficient[baseUnitCode][quantity]
  b = b - (k * b0) / k0
  k = k / k0
  return value * k + b
}

/**
 * 逆运算
 *
 * @param value 输入值
 * @param quantity 物理量
 * @param code 目标单位制
 * @return
 */
export function reverseValue(value: number, quantity: Quantity, code: UnitCode) {
  const co = coefficient[code]
  let [k, b] = co[quantity]
  const [k0, b0] = coefficient[baseUnitCode][quantity]
  b = b0 - (k0 * b) / k
  k = k0 / k
  return value * k + b
}

export function toUnitKey(code: UnitCode, quantity: Quantity) {
  return template.replace('{code}', code).replace('{quantity}', quantity) as Key<TemplateType>
}

/**
 * i22t 对象
 */
const i22t = {
  /**
   * 设置目标单位制
   * @param unitCode
   */
  setUnit(uc: UnitCode) {
    unitCode = uc
  },

  /**
   * 获取目标单位制 i18n key
   * @param quantity 物理量
   * @return
   */
  getUnitKey(quantity: Quantity) {
    const code = unitCode
    return toUnitKey(code, quantity)
  },

  /**
   * 转换值
   * @param value 源值
   * @param quantity 物理量
   * @return
   */
  convertValue(value: number, quantity: Quantity) {
    const code = unitCode
    return toValue(value, quantity, code)
  },

  /**
   * 转换值，返回一个按指定精度截断的字符串
   * @param value 源值
   * @param quantity 物理量
   * @param digit 精度
   * @return
   */
  toFixedValue(value: number, quantity: Quantity, digit = 2) {
    const code = unitCode
    const val = toValue(value, quantity, code)
    return Number.prototype.toFixed.call(val, digit)
  },

  /**
   * 转换值，返回一个按指定精度截断的字符串，并删除末尾的 0
   * @param value 源值
   * @param quantity 物理量
   * @param digit 精度
   * @return
   */
  toFixedTrimValue(value: number, quantity: Quantity, digit = 2) {
    const trimValue = i22t.toFixedRawValue(value, quantity, digit)
    // eslint-disable-next-line no-magic-numbers
    return Number.prototype.toString.call(trimValue, 10)
  },

  /**
   * 转换值，返回一个按指定精度截断的数字
   * @param value 源值
   * @param quantity 物理量
   * @param digit 精度
   * @return
   */
  toFixedRawValue(value: number, quantity: Quantity, digit = 2) {
    const fixedValue = i22t.toFixedValue(value, quantity, digit)
    return Number.parseFloat(fixedValue)
  },

  /**
   * 逆转换值
   * @param value 值
   * @param quantity 物理量
   * @return
   */
  convertReverseValue(value: number, quantity: Quantity) {
    const code = unitCode
    return reverseValue(value, quantity, code)
  }
}

/** i22t 初始化选项 */
interface I22tOptions {
  unitCode: UnitCode;
  baseUnitCode?: UnitCode;
  template?: string;
}

/**
 * 初始化 i22t
 *
 * @param param I22tOptions
 * @return I22t
 */
export function init(options: I22tOptions) {
  if (!isInitial) {
    unitCode = options.unitCode || UnitCode.Std
    baseUnitCode = options.baseUnitCode || UnitCode.Std
    template = options.template || defaultTemplate
    isInitial = true
  }
  return i22t
}

/**
 * 获取 i22t
 * @param unitCode
 * @returns
 */
export function useI22t(unitCode?: UnitCode) {
  if (!isInitial) {
    const msg = 'i22t 未初始化，请先初始化'
    const err = new Error(msg)
    err.name = 'i22t 未初始化'
    console.error(err)
    throw err
  }
  if (unitCode) {
    i22t.setUnit(unitCode)
  }
  return i22t
}

/**
 * 获取 i22t
 * @param unitCode
 * @return
 */
export function runI22t(unitCode?: UnitCode) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useI22t(unitCode)
}

/**
 * 测试单位码是否支持
 *
 * @param unitCode 单位码
 * @return {boolean}
 */
export function testUnitCode(unitCode: string) {
  return !!UnitCode[unitCode]
}

/**
 * 默认导出
 */
export default {
  init,
  useI22t,
  testUnitCode
}
