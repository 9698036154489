/**
 * @file 企业版会话管理模块
 * @author aron.yao 2022-07-15
 */
// services
import restRequest from '@/services/restRequest'

// types
import type { IForm, VerificationCodeForm } from '@/pages/EnterpriseLogin/LoginCard'
import { ROUTE_PATHS } from '@/constants/routes'
import { ENTERPRISE_LOGIN_API, ENTERPRISE_CAPTCHA_API } from '@/constants/login'
import { region } from '@/constants/routes'
import { getISOLocaleByLang } from '@/utils/i18n'
import { currentLocaleConfig } from '@/i18n'

const BASE_URL = '/api'

/**
 * 企业版登录
 */
export const enterpriseLogin = (formData: IForm) => {
  const postData = {
    phone: formData.phone.trim(),
    code: formData.code.trim(),
    token: formData?.aliyunauth?.token,
    session_id: formData?.aliyunauth?.csessionid,
    sig: formData?.aliyunauth?.sig,
    scene: formData?.aliyunauth?.scene
  }
  return restRequest.post<null>(ENTERPRISE_LOGIN_API, postData)
}

/**
 * 获取验证码接口
 * @param phone
 */
export const enterpriseLoginVerificationCode = (formData: VerificationCodeForm) => {
  return restRequest.post<null>(ENTERPRISE_CAPTCHA_API, {
    phone: formData.phone.trim(),
    token: formData?.aliyunauth?.token,
    session_id: formData?.aliyunauth?.csessionid,
    sig: formData?.aliyunauth?.sig,
    scene: formData?.aliyunauth?.scene
  })
}

/**
 * 跳转到登录页面
 * @param hasBackurl 是否携带backurl，手动登出时不需要这个参数
 */
export const gotoLoginPage = (hasBackurl = true) => {
  // 如果已经在登录页，就不需要再次跳转
  if (location.pathname === `${region.REGION_URL_PREFIX}/login`) {
    return
  }
  location.replace(hasBackurl ? `${region.REGION_URL_PREFIX}/login?url=${encodeURIComponent(window.location.href)}` : `${region.REGION_URL_PREFIX}/login`)
}

/**
 * 登出智农
 */
const logoutAgras = () => restRequest.post(`${BASE_URL}/logout`, {})

/**
 * 获取 terra token
 * @returns
 */
export const queryTerraCloudToken = () => {
  return restRequest.get<{ token: string }>(`${BASE_URL}/terra_cloud_token`, {
    data: {}
  }).then((res) => res.data)
}

/*
 * 登出用户中心
 */
function logoutUserCenter() {
  const backUrl = `${window.location.origin}${region.REGION_URL_PREFIX}/login`
  const locale = getISOLocaleByLang(currentLocaleConfig.lang)
  // 开发环境不添加backUrl query
  window.location.replace(`${VITE_APP_AG_WEB_HOST}/user/logout?locale=${locale}&backUrl=${backUrl}`)
}

/**
 * 登出系统
 */
export async function logout(hasBackurl = true) {
  await logoutAgras()

  if (IS_PERSONAL_VERSION) {
    logoutUserCenter()
  } else {
    gotoLoginPage(hasBackurl)
  }
}
/**
 * 用户登出
*/
export const logoutPersonal = () => restRequest.post(`${BASE_URL}/logout`, {})

/**
 * 跳转403无权限页面
*/
export const gotoForbiddenPage = () => location.replace(ROUTE_PATHS.FORBIDDEN)

// 校验登录回跳地址是否安全
export function isValidLoginBackUrl(url: string) {
  return /^((http|https):\/\/)?[A-Za-z1-9-.]*(\.aasky\.net|\.dbeta\.me|\.dji\.com|\.djiag\.com)(\/.*)?$/.test(url)
}