/**
 * @file 路由名常量，用于区分路由
 * @author aron.yao 2022-08-02
 */
import { currentLocaleConfig } from '@/i18n'

export class Region {
  get region() {
    return currentLocaleConfig.region.toLowerCase()
  }
  // 国家码路由前缀，只有个人版才生效
  get REGION_PREFIX() {
    return (IS_PERSONAL_VERSION && this.region !== 'default') ? this.region : ''
  }
  get REGION_URL_PREFIX() {
    return this.REGION_PREFIX ? `/${this.region}` : ''
  }
  // 新版中旧版模块的路由前缀
  get V3_ROUTE_V2_PATH_PREFIX() {
    return `${region.REGION_PREFIX ? region.REGION_PREFIX + '/' : ''}v2`
  }
}
export const region = new Region()

export const V2_ROUTE_PREFIX_PATH_NAME = '_v2'
// 前端域名下，V2版本的路由前缀
export const V2_ROUTE_PREFIX = `/${V2_ROUTE_PREFIX_PATH_NAME}`

enum ROUTE_NAMES {
  MEDIA_PHOTO = 'photo', // 媒体库-相册
  MEDIA_REBUILD = 'rebuild', // 媒体库-重建图
  MEDIA_FERTILIZER = 'fertilizer', // 媒体库-处方图
  TASK_FARM = 'farm', // 场景作业-农田规划-首页
  TASK_MULTI_SPECTRAL = 'multiSpectral', // 场景作业-变量作业-首页
  TASK_FRUIT = 'fruit', // 场景作业-果树规划-首页
  TASK_REBUILD_DETAIL = 'taskRebuildDetail', // 场景作业-变量作业-详情
  ANALYSIS_CROP_ESTIMATION = 'cropEstimation', // 农业分析-测产分析
}

export enum ROUTE_PATHS {
  HOME = '/',
  FORBIDDEN = '/forbidden',
  MEDIA_ROOT_PATH = '/media',
  MEDIA_PHOTO_PATH = '/media/photo',
  MEDIA_REBUILD_PATH = '/media/rebuild',
  MEDIA_REBUILD_DETAIL_PATH = '/media/rebuild/details',
  MEDIA_PHOTO_PREVIEW_PATH = '/media/photo/preview',
  FAQ_PATH = '/faq',
  USER_PATH = '/user',
  TASK_REBUILD_DETAIL_PATH = '/rebuild-detail',
  TASK_MULTI_SPECTRAL_PATH = '/task/multi-spectral',
  TASK_FRUIT_PATH = '/task/fruit',
  TASK_FARM_PATH = '/task/farm',
  SUPERVISE_PATH = '/supervise',
  CROP_ESTIMATION_PATH = '/analysis/crop-estimation',
  PERSONAL_APPLY = '/personal-apply',
}

// 变量作业-作业规划 路由
export enum REBUILD_DETAIL_ROUTE_PATHS {
  HOME = 'home',
  LAND = 'land',
  SYNC_PLOT = 'sync-plot',
  SEGMENT_EDIT = 'segment-edit',
  ROUTE_PLAN = 'route-plan',
  FERTILIZER = 'fertilizer',
  START_POINT = 'start-point'
}

// 老版本云重建模块在新版中的路由
export enum V2_REBUILD_PATHS {
  FARM_REBUILD = 'rebuild-farm'
}

export default ROUTE_NAMES
