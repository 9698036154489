/**
 * @file url相关的工具库
 * @author aron.yao 2021-11-05
 */
import * as queryString from 'query-string'

/**
 * 解析hash路由的query对象
 * 当初次进入时，形如/#/2d/plot-draw?userId=123的路由，即未通过router跳转的路由，会读取不到query对象，此时需要手动解析
 *
 * @return query参数对象
 */
export function parseHashQuery() {
  const hash = window.location.hash
  if (!hash) {
    return {}
  }

  const queryStr = hash.split('?')[1]
  if (!queryStr) {
    return {}
  }

  return queryString.parse(window.location.hash.split('?')[1])
}

/**
 * 在新Tab打开链接
 *
 * @param {*} url 打开的链接
 */
export function openUrlInNewTab(url: string) {
  window.open(url, '_blank')
}

/**
 * 获取url的最后一个路径
 *
 * @param {*} url 链接
 */
export function getUrlLastPathName() {
  const pathnames = window.location.pathname.split('/')
  if (pathnames && pathnames.length) {
    return pathnames[pathnames.length - 1]
  }
  return ''
}

/**
 * 加载js脚本
 */
export function execSrcScript(
  url: string,
  attributes?: Partial<Pick<HTMLScriptElement, 'async' | 'defer'>>
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const el = document.createElement('script')
    if (attributes) {
      Object.keys(attributes).forEach((key) => {
        el[key] = attributes[key]
      })
    }
    el.onload = () => {
      resolve(true)
    }
    el.onerror = () => {
      reject(false)
    }
    el.src = url
    document.body.appendChild(el)
  })
}
/**
 * 加载样式
 * @param href 样式位置
 * @return Promise<boolean>
 */
export function loadStyle(href: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const link = document.createElement('link')
    link.onload = () => {
      resolve(true)
    }
    link.onerror = () => {
      reject(false)
    }
    link.href = href
    link.rel = 'stylesheet'
    document.head.appendChild(link)
  })
}
