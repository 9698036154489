/**
 * @file 路由
 * @author xinqiang.wei 2022-07-20
 */
import { IRouterConfig, lazy } from 'ice'
import Layout from '@/Layout'
import Media from '@/pages/Media'
import AnalysisPage from '@/pages/Analysis'
import ROUTE_NAMES from '@/constants/routes'
import Task from './pages/Task'
import { currentLocaleConfig } from '@/i18n'

export interface IRouterConfigTs extends IRouterConfig {
  name?: string;
  routeName?: ROUTE_NAMES;
  children?: IRouterConfigTs[];
}

const routerConfig: IRouterConfigTs[] = [
  {
    path: '/not-found',
    name: '页面未找到',
    component: lazy(() => import('@/pages/NotFound')),
    exact: true
  },
  {
    path: '/forbidden',
    name: '无权限',
    component: lazy(() => import('@/pages/Forbidden')),
    exact: true
  },
  {
    path: '/rebuild-detail/:uuid',
    name: '',
    routeName: ROUTE_NAMES.TASK_REBUILD_DETAIL,
    component: lazy(() => import('@/pages/RebuildDetail')),
    children: [
      {
        path: 'home',
        name: '变量作业详情主页',
        exact: true,
        component: lazy(() => import('@/pages/RebuildDetail/Home'))
      },
      {
        path: 'land',
        name: '地块绘制',
        exact: true,
        component: lazy(() => import('@/pages/RebuildDetail/Land'))
      },
      {
        path: 'sync-plot',
        name: '同步地块',
        exact: true,
        component: lazy(() => import('@/pages/RebuildDetail/SyncPlot'))
      },
      {
        path: 'segment-edit',
        name: '语义编辑',
        exact: true,
        component: lazy(() => import('@/pages/RebuildDetail/SegmentEdit'))
      },
      {
        path: ':plotUuid/fertilizer',
        name: '处方图',
        exact: true,
        component: lazy(() => import('@/pages/RebuildDetail/Fertilizer'))
      },
      {
        path: 'route-plan',
        name: '航线规划',
        exact: true,
        component: lazy(() => import('@/pages/RebuildDetail/RoutePlan'))
      },
      {
        path: 'start-point',
        name: '起始点设置',
        exact: true,
        component: lazy(() => import('@/pages/RebuildDetail/StartPoint'))
      }
    ]
  },
  {
    path: '/media/rebuild/details/:jobuuid',
    name: '云重建详情',
    component: lazy(() => import('@/pages/Media/Rebuild/Details')),
    exact: true
  },
  {
    path: '/media/photo/preview/:id',
    name: '相片预览',
    component: lazy(() => import('@/pages/Media/Photo/Preview')),
    exact: true
  },
  {
    path: '/media',
    redirect: '/media/photo',
    exact: true
  },
  {
    path: '/',
    component: Layout,
    name: 'root',
    children: [
      {
        path: 'v2',
        component:  lazy(() => import('@/pages/IframePage'))
      },
      {
        path: 'task',
        name: '作业规划',
        component: Task,
        children: [
          {
            path: 'farm',
            name: '农田规划',
            routeName: ROUTE_NAMES.TASK_FARM,
            component: lazy(() => import('@/pages/Task/MultiSpectral')),
            exact: true
          },
          {
            path: 'multi-spectral',
            name: '变量作业',
            routeName: ROUTE_NAMES.TASK_MULTI_SPECTRAL,
            component: lazy(() => import('@/pages/Task/MultiSpectral')),
            exact: true
          },
          {
            path: 'fruit',
            name: '果树规划',
            routeName: ROUTE_NAMES.TASK_FRUIT,
            component: lazy(() => import('@/pages/Task/MultiSpectral')),
            exact: true
          }
        ]
      },
      {
        path: 'analysis',
        name: '农业分析',
        component: AnalysisPage,
        children: [
          {
            path: 'crop-estimation',
            name: '测产分析',
            routeName: ROUTE_NAMES.ANALYSIS_CROP_ESTIMATION,
            component: lazy(() => import('@/pages/Analysis/CropEstimation')),
            exact: true
          }
        ]
      },
      {
        path: 'user',
        name: '用户中心',
        component: lazy(() => import('@/pages/User'))
      },
      {
        path: 'rebuild/:id/gsd',
        name: '高程分析',
        component: lazy(() => import('@/pages/IframePage')),
        exact: true
      },
      {
        path: '/',
        name: '管理',
        children: [
          {
            path: 'media',
            name: '素材管理',
            component: Media,
            children: [
              {
                path: 'photo',
                name: '素材管理',
                routeName: ROUTE_NAMES.MEDIA_PHOTO,
                component: lazy(() => import('@/pages/Media/Photo')),
                exact: true
              },
              {
                path: 'rebuild',
                name: '重建图',
                routeName: ROUTE_NAMES.MEDIA_REBUILD,
                component: lazy(() => import('@/pages/Media/Rebuild')),
                exact: true
              }
            ]
          }
        ]
      }
    ]
  }
]

if (IS_PERSONAL_VERSION) {
  // 处理根目录的地址
  routerConfig.unshift(
    {
      path: '/',
      redirect: '/media/photo',
      exact: true
    }
  )

  routerConfig.unshift(
    ...[
      {
        path: '/login',
        name: '个人版登录',
        exact: true,
        component: lazy(() => import('@/pages/PersonalLogin'))
      },
      {
        path: '/personal-apply',
        name: '个人版申请',
        exact: true,
        component: lazy(() => import('@/pages/PersonalApply'))
      }
    ]
  )
} else {
  routerConfig.unshift(
    {
      path: '/login',
      name: '企业版本登录',
      exact: true,
      component: lazy(() => import('@/pages/EnterpriseLogin'))
    }
  )

  // 处理根目录的地址
  routerConfig.unshift(
    ...[
      {
        path: '/',
        redirect: '/v2/home',
        exact: true
      },
      {
        path: '/v2',
        redirect: '/v2/home',
        exact: true
      }
    ]
  )

  // 企业版才有作业监管
  const rootRoute = routerConfig.find((item) => item.name === 'root')
  if (rootRoute) {
    rootRoute.children?.unshift({
      path: '/supervise',
      name: '作业监管',
      exact: true,
      component:  lazy(() => import('@/pages/Supervise'))
    })
  }
}

// 只有中国大陆才有faq
const rootRoute = routerConfig.find((item) => item.name === 'root')
if (currentLocaleConfig.isInChina && rootRoute) {
  rootRoute.children?.unshift({
    path: 'faq',
    name: '常见问题',
    component: lazy(() => import('@/pages/FAQ'))
  })
}

/**
 * 把not found的 redirect匹配加入到各级路由的字路由里
 * 从而使得所有页面的不存在路径都可以跳转到404页面
*/
function pushNotFoundRedirect(config: IRouterConfigTs) {
  if (!config.children) return
  if (config.children) {
    config.children.forEach((item) => {
      pushNotFoundRedirect(item)
    })
    config.children.push({
      path: '*',
      redirect: '/not-found'
    })
  }
}

routerConfig.forEach((item) => {
  pushNotFoundRedirect(item)
})

export default routerConfig
