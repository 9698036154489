/**
 * @file graphql的基础请求接口模块
 * @author aron.yao 2022-06-28
 * @modified super.yang 2022-10-31
 */
import axios from 'axios'
import { AxiosResponse, AxiosError } from 'axios'
import { print } from 'graphql/language/printer'
import { DocumentNode, Kind, OperationDefinitionNode } from 'graphql'
import baseRequest from './request'
import { genCustErrorByAxiosError, errorStatusTypeMap } from './googleError'
import { BasicResourceError, RESOURCES, TYPES } from 'agras-error'
import { gotoForbiddenPage, gotoLoginPage } from '@/services/auth'

import { IGraphqlRes } from '@/types/request'

import { RevokablePromise } from './request'

/**
 * 从query查询字符串中过滤出前端自定义的接口名
 *
 * @param query graphql接口的查询字符串
 * @returns 接口的name
 */
function getRequestName(query: DocumentNode) {
  if (!query) return null
  const operation = query.definitions.find((def) => def.kind === Kind.OPERATION_DEFINITION) as OperationDefinitionNode
  const operationName = operation?.name?.value || null
  return operationName
}

interface IGraphqlPostData {
  query: string;
  variables?: any;
}

/**
 * 组合post的数据对象
 *
 * @param query graphql的查询语句字符串
 * @param variables graphql的查询语句变量
 * @returns
 */
function composePostData(query, variables): IGraphqlPostData {
  const result: IGraphqlPostData = {
    query
  }
  if (variables) {
    result.variables = variables
  }

  return result
}

/**
 * 错误处理的策略对象
*/
const errorHandleStrategies = {
  [errorStatusTypeMap.UNAUTHENTICATED]: gotoLoginPage,
  [errorStatusTypeMap.PERMISSION_DENIED]: gotoForbiddenPage
}

/**
 * 封装统一的异常处理逻辑，和返回特定的异常类型对象
 *
 * @param err 请求的异常
 */
function errorHandler(err: AxiosError) {
  const response = err.response

  if (axios.isCancel(err)) {
    return new BasicResourceError(RESOURCES.REQUEST, TYPES.CLIENT_CANCELLED, err)
  }

  if (!response) {
    /** 非请求报错的情况 **/
    return new BasicResourceError(RESOURCES.RUNTIME, TYPES.UNKNOWN, err)
  }

  // 权限以及访问错误处理
  if (errorHandleStrategies[response.status]){
    errorHandleStrategies[response.status]()
  }

  return genCustErrorByAxiosError(err)
}

/**
 * 获取真实的响应数据
 *
 * @param requestPromise axios的请求对象
 * @returns 真实的响应数据的异步对象
 */
function pickRealResData<T>(requestPromise: Promise<AxiosResponse<IGraphqlRes<T>>>) {
  return requestPromise.then((res) => res.data.data)
}

/**
 * graphql的接口调用
 *
 * @param queryStr graphql的查询语句字符串
 * @param variables graphql的查询语句变量
 */
export default function graphqlRequest<R, V = any>(
  query: DocumentNode,
  variables?: V
): RevokablePromise<R> {
  const queryStr = print(query)
  const originalRevokableResult = baseRequest.post<IGraphqlRes<R>>('/api/graphql', composePostData(queryStr, variables), {
    query: {
      name: getRequestName(query)
    }
  })

  return new RevokablePromise((resolve, reject) => {
    const result = pickRealResData<R>(originalRevokableResult).catch((err) => {
      reject(errorHandler(err))
    }) as RevokablePromise<R>
    result.then(resolve)
  }, originalRevokableResult.cancel)
}
