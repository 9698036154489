/**
 * @file 照片库模块接口
 * @author aron.yao 2022-06-28
 */
import graphqlRequest from '@/services/graphqlRequest'
import photoHomeListGql from './gql/fetchPhotoHomeList.gql'
import createMappingGql from './gql/createMapping.gql'
import createPlotPatrolGql from './gql/createPlotPatrol.gql'
import createAutoProcessingJobGql from './gql/createAutoProcessingJob.gql'
import queryPhotoTotalCountGql from './gql/queryPhotoTotalCount.gql'
import updateMappingGql from './gql/updateMapping.gql'
import queryMappingDetailGql from './gql/queryMappingDetail.gql'
import queryPhotoListGql from './gql/queryPhotoList.gql'
import deleteMappingGql from './gql/deletePhotoHomeMapping.gql'
import createSourceImagesGql from './gql/createSourceImages.gql'
import startAutoProcessingJobGql from './gql/startAutoProcessingJob.gql'
import sendUploadFailedMessageGql from './gql/sendUploadFailedMessage.gql'
import deletePhotosGql from './gql/deleteSourcePhotos.gql'
import queryPhotoDetailGql from './gql/queryPhotoDetail.gql'
import queryMappingBoundingBoxGql from './gql/queryMappingBoundingBox.gql'
import queryMappingRelativeProcessingJobsGql from './gql/queryMappingRelativeProcessingJobs.gql'

import {
  FetchPhotoHomeListQuery,
  FetchPhotoHomeListQueryVariables,
  CreateMappingMutation,
  CreateMappingMutationVariables,
  CreatePlotPatrolMutation,
  CreatePlotPatrolMutationVariables,
  CreateAutoProcessingJobMutation,
  CreateAutoProcessingJobMutationVariables,
  QueryPhotoTotalCountQuery,
  QueryPhotoTotalCountQueryVariables,
  UpdateMappingMutation,
  UpdateMappingMutationVariables,
  QueryMappingDetailQuery,
  QueryMappingDetailQueryVariables,
  QueryPhotoListQueryVariables,
  QueryPhotoListQuery,
  DeletePhotoHomeMappingMutationVariables,
  DeletePhotoHomeMappingMutation,
  CreateSourceImagesMutationVariables,
  CreateSourceImagesMutation,
  SendUploadFailedMessageMutationVariables,
  SendUploadFailedMessageMutation,
  DeleteSourcePhotosMutationVariables,
  DeleteSourcePhotosMutation,
  QueryPhotoDetailQueryVariables,
  QueryPhotoDetailQuery,
  StartAutoProcessingJobMutationVariables,
  StartAutoProcessingJobMutation,
  QueryMappingBoundingBoxQueryVariables,
  QueryMappingBoundingBoxQuery,
  QueryMappingRelativeProcessingJobsQueryVariables,
  QueryMappingRelativeProcessingJobsQuery
} from '@/types/graphql-types.d'

// 拉取 照片库列表数据
export function fetchPhotoHomeList(variables: FetchPhotoHomeListQueryVariables) {
  return graphqlRequest<FetchPhotoHomeListQuery, FetchPhotoHomeListQueryVariables>(photoHomeListGql, variables)
}

// 创建 照片库
export function createMapping(variables: CreateMappingMutationVariables) {
  return graphqlRequest<CreateMappingMutation, CreateMappingMutationVariables>(createMappingGql, variables)
}

// 更新照片库
export function updateMapping(variables: UpdateMappingMutationVariables) {
  return graphqlRequest<UpdateMappingMutation, UpdateMappingMutationVariables>(updateMappingGql, variables)
}

// 创建 巡田任务
export function createPlotPatrol(variables: CreatePlotPatrolMutationVariables) {
  return graphqlRequest<CreatePlotPatrolMutation, CreatePlotPatrolMutationVariables>(createPlotPatrolGql, variables)
}

// 创建 自动重建任务
export function createAutoProcessingJob(variables: CreateAutoProcessingJobMutationVariables) {
  return graphqlRequest<CreateAutoProcessingJobMutation, CreateAutoProcessingJobMutationVariables>(createAutoProcessingJobGql, variables)
}

// 查询 某个照片库下的总数
export function queryPhotoTotalCount(variables: QueryPhotoTotalCountQueryVariables) {
  return graphqlRequest<QueryPhotoTotalCountQuery, QueryPhotoTotalCountQueryVariables>(queryPhotoTotalCountGql, variables)
}

// 查询 照片库的详细信息
export function queryMappingDetail(variables: QueryMappingDetailQueryVariables) {
  return graphqlRequest<QueryMappingDetailQuery, QueryMappingDetailQueryVariables>(queryMappingDetailGql, variables)
}

// 查询 某个照片库下的照片列表
export function queryPhotoList(variables: QueryPhotoListQueryVariables) {
  return graphqlRequest<QueryPhotoListQuery, QueryPhotoListQueryVariables>(queryPhotoListGql, variables)
}
// 删除照片库
export function deletePhotoHomeMapping(variables: DeletePhotoHomeMappingMutationVariables) {
  return graphqlRequest<DeletePhotoHomeMappingMutation, DeletePhotoHomeMappingMutationVariables>(deleteMappingGql, variables)
}

// 拉取上传照片的oss信息
export function fetchOssToken(variables: CreateSourceImagesMutationVariables) {
  return graphqlRequest<CreateSourceImagesMutation, CreateSourceImagesMutationVariables>(createSourceImagesGql, variables)
}

// 开启 自动重建任务
export function startAutoProcessingJob(variables: StartAutoProcessingJobMutationVariables) {
  return graphqlRequest<StartAutoProcessingJobMutation, StartAutoProcessingJobMutationVariables>(startAutoProcessingJobGql, variables)
}

// 发送照片库上传失败的短信
export function sendUploadFailedMessage(variables: SendUploadFailedMessageMutationVariables) {
  return graphqlRequest<SendUploadFailedMessageMutation, SendUploadFailedMessageMutationVariables>(sendUploadFailedMessageGql, variables)
}


// 删除照片文件夹
export function deletePhotos(variables: DeleteSourcePhotosMutationVariables) {
  return graphqlRequest<DeleteSourcePhotosMutation, DeleteSourcePhotosMutationVariables>(deletePhotosGql, variables)
}

// 查询 照片库的详细信息
export function queryPhotoDetail(variables: QueryPhotoDetailQueryVariables) {
  return graphqlRequest<QueryPhotoDetailQuery, QueryPhotoDetailQueryVariables>(queryPhotoDetailGql, variables)
}

// 查询 照片库的视口范围
export function queryMappingBoundingBox(variables: QueryMappingBoundingBoxQueryVariables) {
  return graphqlRequest<QueryMappingBoundingBoxQuery, QueryMappingBoundingBoxQueryVariables>(queryMappingBoundingBoxGql, variables)
}

// 查询 照片库关联的重建任务列表
export function queryMappingRelativeProcessingJobs(variables: QueryMappingRelativeProcessingJobsQueryVariables) {
  return graphqlRequest<QueryMappingRelativeProcessingJobsQuery, QueryMappingRelativeProcessingJobsQueryVariables>(queryMappingRelativeProcessingJobsGql, variables)
}
