/**
 * @file ga初始化
 * @author lucius.lu
 */
import { execSrcScript } from 'agras-utils'

export function initGA() {
  /**
   * 为了延迟ga的上报，“优化”load时间
   */
  execSrcScript(`https://www.googletagmanager.com/gtag/js?id=${process.env.ICE_GA_ID}`, { async: true }).catch(() => null)

  window.gtag('js', new Date())
  if (process.env.ICE_GA_ID) {
    window.gtag('config', process.env.ICE_GA_ID)
  }
}

