/**
 * @file 异常类型定义
 * @author aron.yao 2022-02-06
 */

/**
 * 基本异常类型定义
 */
export enum TYPES {
  UNKNOWN = 0, // 未知错误
  NET_WORK_ERROR = 1, // 网络错误，比如没有网络，超时等
  // 接口http码对应的错误类型
  BAD_REQUEST = 400, // 400
  UNAUTHORIZED = 401, // 401
  FORBIDDEN = 403, // 403
  NOT_FOUND = 404, // 404
  ALREADY_EXISTS = 409, // 409
  CONFLICT = 410, // 410
  TOO_MANY_REQUEST = 429, // 429
  CANCELLED = 499, // 499
  SERVER_INTERNAL_ERROR = 500, // 500
  // 前端特定的错误类型
  FILE_PARSE_FAIL, // 文件解析失败
  DATA_FORMAT_INVALID, // 格式错误
  EXPIRE, // 过期
  CLIENT_CANCELLED, // 客户端主动取消
  DOWNLOAD_URL_NULL, // 后端返回的接口中用于文件下载url为空
  TASK_PROCESSING_FAILED, // 后端的数据导出/压缩 任务处理失败
  FETCH_FILE_ERROR, // 文件拉取失败
  SAVE_FILE_ERROR, // 文件保存失败
}

/**
 * 基本异常类型的文案
 */
export const TYPES_TXT = {
  [TYPES.UNKNOWN]: 'Unknown',
  [TYPES.NET_WORK_ERROR]: 'Network error',
  [TYPES.BAD_REQUEST]: 'Request Context Error',
  [TYPES.UNAUTHORIZED]: 'User Unauthorized',
  [TYPES.FORBIDDEN]: 'Forbidden',
  [TYPES.NOT_FOUND]: 'Not Found',
  [TYPES.ALREADY_EXISTS]: 'Resource Already Exists',
  [TYPES.CONFLICT]: 'Request Aborted',
  [TYPES.TOO_MANY_REQUEST]: 'Too Many Request',
  [TYPES.CANCELLED]: 'Cancelled',
  [TYPES.CLIENT_CANCELLED]: 'Client Cancelled',
  [TYPES.SERVER_INTERNAL_ERROR]: 'Server Internal Error',
  [TYPES.FILE_PARSE_FAIL]: 'File parse error',
  [TYPES.DATA_FORMAT_INVALID]: 'Data format error',
  [TYPES.EXPIRE]: 'Expired',
  [TYPES.DOWNLOAD_URL_NULL]: 'Download url does not exist',
  [TYPES.TASK_PROCESSING_FAILED]: 'Task processing failed',
  [TYPES.FETCH_FILE_ERROR]: 'Fetch file fail',
  [TYPES.SAVE_FILE_ERROR]: 'Save file fail'
}

/**
 * 资源类型
 */
export enum RESOURCES {
  RUNTIME, // 执行代码
  REQUEST, // 网络请求
  NET_WORK, // 网络
  LOGIN_INFO, // 登录信息
  CAPTCHA, // 验证码
  SEGMENT_TFW, // 语义的tfw文件
  SEGMENT_TIFF, // 语义的tiff文件
  SEGMENT_COLOR_TABLE, // 语义的colortable文件
  FERTILIZER_TFW, // 处方图 tfw 文件
  FERTILIZER_TIFF, // 处方图 tiff 文件
  TREECENTER_FILE, // 树心数据文件
  MAPPING = 'mappings', // 照片库资源
  LAND = 'lands', // 地块资源
  PROCESSING_JOB_LAND = 'plot', // 云重建地块资源
  USER = 'user', // 用户
  DEVICE = 'device', // 设备
  LEGEND = 'legend', // 图例
  PROCESSING_JOBS = 'processing_jobs', // 重建任务
  DOWNLOAD = 'download' // 文件下载
}

/**
 * 资源类型的文案
 */
export const RESOURCES_TXT = {
  [RESOURCES.RUNTIME]: 'Runtime',
  [RESOURCES.REQUEST]: 'Ajax Request',
  [RESOURCES.NET_WORK]: 'Network',
  [RESOURCES.LAND]: 'Land Data',
  [RESOURCES.PROCESSING_JOB_LAND]: 'Plot Data',
  [RESOURCES.CAPTCHA]: 'Login Captcha',
  [RESOURCES.LOGIN_INFO]: 'Login Info',
  [RESOURCES.SEGMENT_TFW]: 'Segment Tfw File',
  [RESOURCES.SEGMENT_TIFF]: 'Segment Tiff File',
  [RESOURCES.FERTILIZER_TFW]: 'Fertilizer Tfw File',
  [RESOURCES.FERTILIZER_TIFF]: 'Fertilizer Tiff File',
  [RESOURCES.TREECENTER_FILE]: 'Tree center File',
  [RESOURCES.LEGEND]: 'Legend Data',
  [RESOURCES.PROCESSING_JOBS]: 'Processing Job',
  [RESOURCES.DOWNLOAD]: 'File download'
}

/**
 * 对应业务系统功能模块
 */
export enum MODULES {
  SESSION, // 会话态
  SEGMENT, // 语义
  FERTILIZER, // 处方图
  PLOT_LIST, // 地块列表
  PLOT_DRAW, // 地块绘制
  CROP_ESTIMATION, // 测产分析
  PROCESSING_JOB_LAND // 变量作业的地块管理
}

/**
 * 系统功能模块的文案
 */
export const MODULES_TXT = {
  [MODULES.SESSION]: 'Session',
  [MODULES.SEGMENT]: 'Segment',
  [MODULES.FERTILIZER]: 'Fertilizer',
  [MODULES.PLOT_LIST]: 'Plot List',
  [MODULES.PLOT_DRAW]: 'Plot Draw',
  [MODULES.CROP_ESTIMATION]: 'Crop Estimation'
}
