/**
 * @file 工具方法类
 * @author xinqiang.wei 2022/7/4
 */
import dayjs from 'dayjs'
import {
  isNumber
} from 'lodash-es'
import { t } from 'i18next'
import { UserProfileQuery } from '@/types/graphql-types'
import { fetchApplyInfo } from '@/services/apis/personal'
import { HOUR_TO_DAY, UNKNOWN_TXT, MILLISECOND_TO_SECOND, SECOND_TO_HOUR, HOUR_TO_MINUTE } from '@/constants/common'
import { region, ROUTE_PATHS } from '@/constants/routes'
import { ConfigInfo } from '@/services/apis/config'
import store from '@/store'
import { APPLY_STATUS } from '@/constants/personal'

// personal-apply 的路由正则
const personalApplyReg = new RegExp(`^\(${region.REGION_URL_PREFIX}\)?${ROUTE_PATHS.PERSONAL_APPLY}`)

export const ms2Second = (ms: number | string): number => {
  return dayjs(ms).unix()
}

export const getStartSecond = (ms: number | string): number => {
  return dayjs(ms).startOf('day').unix()
}

export const getEndSecond = (ms: number | string): number => {
  return dayjs(ms).endOf('day').unix()
}

/**
 * 转为 graphql 需要的分页信息
 *
 * @param {*} pageNumber paramDesc
 * @param {*} pageSize paramDesc
 * @return {{after: string, first: *}}
 */
export function page2FirstAfter(pageNumber, pageSize) {
  if (isNumber(pageNumber) && pageNumber > 0 && isNumber(pageSize) && pageSize > 0) {
    return {
      first: pageSize,
      after: ((pageNumber - 1) * pageSize).toString()
    }
  }
  throw new Error('pageNumber and pageSize illegal')
}


/**
 * 判断浏览器是否支持webp格式
 * @return {[Boolean]}
 */
export function isSupportWebp(): Boolean {
  try {
    return document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0
  } catch (err) {
    return false
  }
}

/**
 * 生成随机字符串
 *
 * @return {*}
 */
export function uuid() {
  let d = new Date().getTime()
  const result = 'xyxyxyxyxyxyxyxyxy'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : ((r & 0x7) | 0x8)).toString(16)
  })
  return result.substr(0, 12)
}

export const promiseAllSettled = function (promises): Promise<any> {
  const mappedPromises = promises.map((p) => {
    return p
      .then((value) => {
        return {
          status: 'fulfilled',
          value
        }
      })
      .catch((reason) => {
        return {
          status: 'rejected',
          reason
        }
      })
  })
  return Promise.all(mappedPromises)
}


export const changeFavicon = (src) => {
  const link = document.createElement('link'),
    oldLink = document.getElementById('dynamic-favicon')
  link.id = 'dynamic-favicon'
  link.rel = 'shortcut icon'
  link.href = src
  if (oldLink) {
    document.head.removeChild(oldLink)
  }
  document.head.appendChild(link)
}

export const changeTitleAndFavicon = (config?: ConfigInfo) => {
  /** 如果是个人版，就不修改icon以及title **/
  if (IS_PERSONAL_VERSION) {
    return
  }

  changeFavicon(config?.favicon)
  document.title = config?.name || ''
}


export const changePersonalTitle = () => {
  if (IS_PERSONAL_VERSION) {
    document.title = t('agrasSystemName')
  }
}

/**
 * 检查是否申请通过
 */
export const checkApplyStatus = async ({ userProfile }: UserProfileQuery, history) => {

  /** 如果不是个人版，就不进行下面的逻辑处理 **/
  if (!IS_PERSONAL_VERSION) {
    return
  }

  // 如果是登录页，也不跳转留在登录页即可
  if (location.pathname.includes(`${region.REGION_URL_PREFIX}/login`)) {
    return
  }

  const permissionFlag = userProfile?.permissionFlag
  if (!permissionFlag) {
    try {
      // 这里是用户的申请列表 默认取第一个表示用户最后的一次请求
      // 若没有则表示用户从来没有申请过
      const res = await fetchApplyInfo()
      const nodes = (res?.applicationForms && res.applicationForms?.nodes) || []
      // 0-待处理，1-通过，2-拒绝
      // 状态不为0的时候，需要跳转到申请页面，否则则到申请中的页面
      const status = nodes[0]?.status
      store.dispatch.system.updateApplyStatus(status as APPLY_STATUS)
      // 带上条数信息，用于判断是否需要弹窗
      history.push(ROUTE_PATHS.PERSONAL_APPLY)
    } catch (e) {
      console.warn('e', e)
    }
    // 如果已授权，又匹配到了/personal-apply路由，就跳转到媒体库页面
  } else if (personalApplyReg.test(location.pathname)) {
    history.push(ROUTE_PATHS.MEDIA_PHOTO_PATH)
  }
}


export const number2Fixed = (num: number | string, fixed = 2) => {
  const number = Number(num)
  if (isNaN(number) || number === 0) {
    return UNKNOWN_TXT
  }
  return parseFloat(number.toFixed(fixed))
}

const dateCount = (endTime) => {
  // 现在时间
  const now = dayjs().valueOf()
  //截止时间
  const until = dayjs(endTime).valueOf()
  // 计算时会发生隐式转换，调用valueOf()方法，转化成时间戳的形式
  const days = (until - now) / MILLISECOND_TO_SECOND / SECOND_TO_HOUR / HOUR_TO_DAY
  // 下面都是简单的数学计算
  const day = Math.floor(days)
  const hours = (days - day) * HOUR_TO_DAY
  const hour = Math.floor(hours)
  const minutes = (hours - hour) * HOUR_TO_MINUTE
  const minute = Math.floor(minutes)
  const seconds = (minutes - minute) * HOUR_TO_MINUTE
  const second = Math.floor(seconds)
  return {
    day,
    hour,
    minute,
    second
  }
}


/**
 * 输入任务开始时间和结束时间，计算任务剩余时间
 * @param startTime
 * @param percent
 */
export const getTimeRemaining = (startTime: string, percent: number) => {
  const start = dayjs(startTime).valueOf()
  const now = dayjs().valueOf()
  const residualTimestamp = (now - start) / percent
  // 异常数据，后台时间跟前端时间对不上
  const endTime = start + (residualTimestamp < 0 ? 0 : residualTimestamp)
  return dateCount(endTime)
}

export const decimalToPercent = (num: number) => {
  return (num * 100).toFixed(0)
}

// 根据时间排序
export function sortByTime<T>(arr: T[], key: string) {
  return arr.sort(function compare(a, b) {
    const dateA = new Date(a[key]).getTime()
    const dateB = new Date(b[key]).getTime()
    return dateB - dateA
  })
}