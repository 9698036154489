/**
 * @file 用户信息相关逻辑
 * @author aron.yao 2022-08-15
 */
import { getInitialData } from 'ice'

/**
 * 获取展示的系统用户名
 */
export function displayUserName() {
  const state = getInitialData()
  return state.initialStates.profile.userProfile?.name
    || state.initialStates.profile.userProfile?.phone
    || state.initialStates.profile.userProfile?.email
}