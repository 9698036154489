/**
 * @file 个人信息
 * @author lian.duan 2022-07-09
 */
import { UserProfileQuery } from '@/types/graphql-types.d'

export interface IProfile {
  userProfile: UserProfileQuery;
}

export default {
  // 定义 model 的初始 state
  state: {
    userProfile: {
      name: '',
      phone: '',
      email: '',
      firstLogin: false,
      uuid: '',
      permissionFlag: false,
      createdAt: '',
      endAt: '',
      member: {
        organization: {
          name: '',
          maxDepartmentLevel: 0
        },
        RoleMemberDepartment: {
          department: {
            id: ''
          }
        }
      },
      departmentLinks: []
    } as {
      name: string;
      phone: string;
      email: string;
      firstLogin: boolean;
      uuid: string;
      permissionFlag: boolean;
      createdAt: string;
      endAt: string;
      member: {
        organization: {
          name: string;
          maxDepartmentLevel: number;
        };
        RoleMemberDepartment: {
          department: {
            id: string;
          };
        };
      };
      departmentLinks: {
        name: string;
      }[];
    }
  },

  // 定义改变该模型状态的纯函数
  reducers: {
    /**
     * @param prevState
     * @param payload
     */
    update(prevState, payload: IProfile) {
      return {
        ...prevState,
        ...payload
      }
    },
    /**
     * 更新首次是否登录的标志
     * @param prevState
     * @param payload
     */
    updateFirstLogin(prevState, payload: boolean) {
      prevState.userProfile.firstLogin = payload
    }
  }
}
