/**
 * @file 语义 相关的类型
 * @author lian.duan 2022-09-20
 */

// 语义信息相关的文件名
export enum SEGMENT_FILE_NAMES {
  TIF = 'segment.tif',
  TFW = 'segment.tfw',
  COLOR_TABLE = 'segmentAPI.json'
}

// 语义图 colorTable的枚举类型
export enum SEGMENT_COLOR_TABLE_LABEL {
  TREE = 'tree',
  BUILDING = 'building',
  POLE = 'pole',
  // 光哥要求删掉了
  // ROAD = 'road',
  // GROUND = 'ground',
  WATER = 'water',
  RICE = 'rice',
  OTHER = 'other',
  TREE_CENTER = 'treeCenter'
}

// 语义颜色调色盘
export interface SEGMENT_COLOR_PALLET {
  lable: SEGMENT_COLOR_TABLE_LABEL;
  color: string;
}

// rgb数组类型
export type RGB_COLOR = [number, number, number]

// 绘制类型
export enum DRAW_TYPE {
  EDGE,
  TREE_CENTER
}