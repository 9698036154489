/**
 * @file 巡田
 * @author lian.duan 2022-07-04
 */

export enum PATROL_FARM_VIEW_TYPE {
  OVERLOOK= 0, // 俯瞰
  BIRD_EYE= 1 // 鸟瞰
}

export default {
  PATROL_FARM_VIEW_TYPE
}
