/**
 * @file 判断字符串是否存在 Emoji
 * @author lian.duan 2021-09-15
 */

/**
 * 判断字符串是否存在 Emoji
 *
 * @param {*} substring paramDesc
 * @return {boolean}
 */
// eslint-disable-next-line complexity
export function isEmojiCharacter(substring: string): boolean {
  for (let i = 0; i < substring.length; i += 1) {
    const hs = substring.charCodeAt(i)
    if (hs >= 0xd800 && hs <= 0xdbff) {
      if (substring.length > 1) {
        const ls = substring.charCodeAt(i + 1)
        const uc = ((hs - 0xd800) * 0x400) + (ls - 0xdc00) + 0x10000
        if (uc >= 0x1d000 && uc <= 0x1f77f) {
          return true
        }
      }
    } else if (substring.length > 1) {
      const ls = substring.charCodeAt(i + 1)
      if (ls === 0x20e3) {
        return true
      }
    } else {
      if (hs >= 0x2100 && hs <= 0x27ff) {
        return true
      }
      if (hs >= 0x2B05 && hs <= 0x2b07) {
        return true
      }
      if (hs >= 0x2934 && hs <= 0x2935) {
        return true
      }
      if (hs >= 0x3297 && hs <= 0x3299) {
        return true
      }
      if (hs === 0xa9 || hs === 0xae || hs === 0x303d || hs === 0x3030 ||
        hs === 0x2b55 || hs === 0x2b1c || hs === 0x2b1b ||
        hs === 0x2b50) {
        return true
      }
    }
  }
  return false
}

export default {
  isEmojiCharacter
}
