/**
 * @file 部门树
 * @author Yangholmes 2022-07-29
 */

import { DepartmentTree } from '@/types/graphql-types.d'

export default {
  state: {
    isLoaded: false,
    department: {} as DepartmentTree
  },

  reducers: {
    /**
     * 更新数据
     * @param prevState
     * @param payload
     */
    updateDepartment(prevState, department: DepartmentTree) {
      return {
        ...prevState,
        department
      }
    },
    /**
     * 数据加载完成
     * @param prevState
     * @returns
     */
    dataLoaded(prevState) {
      return {
        ...prevState,
        isLoaded: true
      }
    }
  }
}
