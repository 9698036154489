/**
 * @file 基本错误类型定义
 * @author aron.yao 2022-02-15
 */

import isNil from 'lodash/isNil'

import {
  TYPES,
  TYPES_TXT,
  RESOURCES,
  RESOURCES_TXT,
  MODULES,
  MODULES_TXT
} from './types'


/**
* 获取Error的name信息
* @param type Error的基本类型信息
* @param resource Error的资源信息
* @param module Error的模块信息
* @returns Error的name信息
*/
export function getErrorName(
  type: TYPES,
  resource?: RESOURCES,
  module?: MODULES
): string {
  const m = isNil(module) ? '' : MODULES_TXT[module || '']
  const r = isNil(resource) ? '' : RESOURCES_TXT[resource || '']
  const t = TYPES_TXT[type]

  return `【Error】{${m}} [${r}, ${t}]`.replace('undefined', '').replace(/\{\}\s/, '').replace(/\[,\s/, '[')
}


/*** 基本异常类型 ***/
export class BasicTypeError extends Error {
  constructor(
    public readonly type: TYPES,
    // 对应的原始异常对象
    public readonly originalError?: Error,
    message?: string
  ) {
    super(message)
    this.name = getErrorName(type)
  }

  static isBasicTypeError(err: Error) {
    return err instanceof BasicTypeError
  }

  isBadRequestError() {
    return this.type === TYPES.BAD_REQUEST
  }

  isUnauthorizedError() {
    return this.type === TYPES.UNAUTHORIZED
  }

  isForbiddenError() {
    return this.type === TYPES.FORBIDDEN
  }

  isNotFoundError() {
    return this.type === TYPES.NOT_FOUND
  }

  isTooManyRequestError() {
    return this.type === TYPES.TOO_MANY_REQUEST
  }

  isServerInternalError() {
    return this.type === TYPES.SERVER_INTERNAL_ERROR
  }

  isParseFailError() {
    return this.type === TYPES.FILE_PARSE_FAIL
  }

  isInvalidDataFormatError() {
    return this.type === TYPES.DATA_FORMAT_INVALID
  }
}

// 请求体异常
export class BadRequestError extends BasicTypeError {
  constructor(
    public readonly resource: RESOURCES,
    originalError?: Error,
    message?: string
  ) {
    super(TYPES.BAD_REQUEST, originalError, message)
  }
}

// 未授权
export class UnauthorizedError extends BasicTypeError {
  constructor(
    public readonly resource: RESOURCES,
    originalError?: Error,
    message?: string
  ) {
    super(TYPES.UNAUTHORIZED, originalError, message)
  }
}

/**
* 无权限的异常
*/
export class ForbiddenError extends BasicTypeError {
  constructor(
    public readonly resource: RESOURCES,
    originalError?: Error,
    message?: string
  ) {
    super(TYPES.FORBIDDEN, originalError, message)
  }
}

// 资源缺失异常
export class NotFoundError extends BasicTypeError {
  constructor(
    public readonly resource: RESOURCES,
    originalError?: Error,
    message?: string
  ) {
    super(TYPES.NOT_FOUND, originalError, message)
  }
}

// 资源解析失败异常
export class FileParseFailError extends BasicTypeError {
  constructor(
    public readonly resource: RESOURCES,
    originalError?: Error,
    message?: string
  ) {
    super(TYPES.FILE_PARSE_FAIL, originalError, message)
  }
}

// 服务出错的异常
export class ServerInternalError extends BasicTypeError {
  constructor(
    public readonly resource: RESOURCES,
    originalError?: Error,
    message?: string
  ) {
    super(TYPES.SERVER_INTERNAL_ERROR, originalError, message)
  }
}

// 数据类型出错的异常
export class DataInvalidError extends BasicTypeError {
  constructor(
    public readonly resource: RESOURCES,
    originalError?: Error,
    message?: string
  ) {
    super(TYPES.DATA_FORMAT_INVALID, originalError, message)
  }
}
