/**
 * @file 校验是否能开启重建任务
 * @author lian.duan 2023-05-16
 */
import { validStartProcessingJob, queryMappingJpgCount } from '@/services/apis/rebuild'
import { VALIDATE_START_REBUILD_RESULT } from '@/types/rebuild.d'
import { MIN_REBUILD_JPG_COUNT } from '@/constants/rebuild'
import { PHOTO_TYPES } from '@/types/photo.d'
import { Index } from '@/types/graphql-types.d'

export interface MappingOptions {
  uuid: string;
  jobType: PHOTO_TYPES;
  indexList: Index[];
}

/**
 * 校验照片库的指数是否合规
 * @param options 照片库参数
 * @returns boolean
 */
export async function validateMappingIndexList(options: MappingOptions) {
  try {
    const validateData = await validStartProcessingJob({
      uuid: options.uuid,
      jobType: options.jobType,
      indexList: options.indexList
    })
    return validateData?.mapping?.validStartProcessingJob?.ok === VALIDATE_START_REBUILD_RESULT.VALID
  } catch (error) {
    throw error
  }
}

/**
 * 校验照片库的jpg数量是否合规
 * @param mappingUuid 照片库uuid
 * @returns boolean
 */
export async function validateMappingJpgCount(mappingUuid: string) {
  try {
    const validateData = await queryMappingJpgCount({ mappingUuid })
    const jpgCount = validateData?.mapping?.sourceImages?.totalCount || 0
    return jpgCount >= MIN_REBUILD_JPG_COUNT
  } catch (error) {
    throw error
  }
}

/**
 * 校验照片库是否达到可重建的条件
 * 1. 照片的指数齐全
 * 2. 照片的数量大于等于6张
 * @param options 照片库参数
 */
export async function validateMappingCanRebuild(options: MappingOptions) {
  try {
    return {
      isValidIndexResult: await validateMappingIndexList(options),
      isValidJpgCount: await validateMappingJpgCount(options.uuid)
    }
  } catch (error) {
    throw error
  }
}