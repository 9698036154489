/**
 * @file 重建
 * @author lian.duan 2022-07-22
 */
import { REBUILD_BAND_NAME, QUALITY_LEVEL, MULTISPECTRAL_REBUILD_TYPE, ProcessingJobParams } from '@/types/rebuild.d'
import { PHOTO_TYPES } from '@/types/photo.d'
import { REBUILD_TYPES } from '@/types/rebuild.d'
import { QueryRcProcessingJobDetailQuery, QueryRebuildDetailsByProcessingJobUuidQuery } from '@/types/graphql-types.d'

/**
 * 是否为重建任务
 * @param jobType
 * @returns
 */
export function isRebuildType(jobType: PHOTO_TYPES) {
  return [
    PHOTO_TYPES.CITY,
    PHOTO_TYPES.FARM,
    PHOTO_TYPES.FRUIT_TREE,
    PHOTO_TYPES.MULTI_SPECTRAL
  ].includes(jobType)
}

/**
 * blue band可以不需要传上去，算法已经废弃了该波段
 * @param bandName 光谱
 * @returns
 */
export function isBluleBand(bandName: REBUILD_BAND_NAME): boolean {
  return bandName === REBUILD_BAND_NAME.BLUE
}

/**
 * 是否为ndvi重建所需要的光谱
 * @param bandName 光谱
 * @returns
 */
export function isNdviRebuildBand(bandName: REBUILD_BAND_NAME): boolean {
  return [REBUILD_BAND_NAME.RED, REBUILD_BAND_NAME.NIR].includes(bandName)
}

export function addArrValuesToSet(set: Set<any>, arr: Array<any>): Set<any> {
  arr.forEach((item) => {
    set.add(item)
  })
  return set
}

/**
 * 根据重建类型 获取光谱list
 * @param multispectralRebuildTypes
 * @returns
 */
export function getAuxBandList(multispectralRebuildTypes: MULTISPECTRAL_REBUILD_TYPE[]): REBUILD_BAND_NAME[] {
  const bandList: Set<REBUILD_BAND_NAME> = new Set()

  multispectralRebuildTypes.forEach((type) => {
    switch (type) {
      case MULTISPECTRAL_REBUILD_TYPE.NDVI:
      case MULTISPECTRAL_REBUILD_TYPE.OSAVI:
        addArrValuesToSet(bandList, [REBUILD_BAND_NAME.NIR, REBUILD_BAND_NAME.RED])
        break
      case MULTISPECTRAL_REBUILD_TYPE.GNDVI:
        addArrValuesToSet(bandList, [REBUILD_BAND_NAME.NIR, REBUILD_BAND_NAME.GREEN])
        break
      case MULTISPECTRAL_REBUILD_TYPE.NDRE:
        addArrValuesToSet(bandList, [REBUILD_BAND_NAME.NIR, REBUILD_BAND_NAME.RED_EDGE])
        break
      case MULTISPECTRAL_REBUILD_TYPE.LCI:
        addArrValuesToSet(bandList, [REBUILD_BAND_NAME.NIR, REBUILD_BAND_NAME.RED_EDGE, REBUILD_BAND_NAME.RED])
        break
      default:
        break
    }
  })

  // 将set转换为数组
  return Array.from(bandList)
}

/**
 * 拼接创建任务参数 json
 *
 * @param {number} jobType 重建任务类型枚举，JOB_TYPES
 * @param {number} quality 重建任务质量，REBUILD_QUALITY
 * @return {*}
 */
export function rebuildTaskParams2Json(
  jobType: PHOTO_TYPES,
  quality: QUALITY_LEVEL,
  // 默认为重建全部光谱
  multispectralRebuildTypes: MULTISPECTRAL_REBUILD_TYPE[] = [
    MULTISPECTRAL_REBUILD_TYPE.NDVI,
    MULTISPECTRAL_REBUILD_TYPE.OSAVI,
    MULTISPECTRAL_REBUILD_TYPE.GNDVI,
    MULTISPECTRAL_REBUILD_TYPE.NDRE,
    MULTISPECTRAL_REBUILD_TYPE.LCI
  ]
) {
  // NOTICE: 城市模式已废弃了，为兼容历史数据，以前创建的城市模式，被归到了农田模式
  const type = (jobType === PHOTO_TYPES.CITY) ? PHOTO_TYPES.FARM : jobType

  const params = {
    map_mode: type,
    quality_level: quality,
    // 以下参数暂时写死
    use_gcj02: false,
    use_Wallis: true, // 减轻条纹问题的优化参数
    aux_band_list: getAuxBandList(multispectralRebuildTypes || []), // 多光谱模式中算法需要处理的波段名称列表
    index_list: multispectralRebuildTypes || [], // 多光谱模式中算法需要生成的指数图列表
    output_geo_CS: 'EPSG:4326' // 可选，地理坐标系，用户选择，格式样例:'EPSG:4326'  默认为空字符串
  }

  return JSON.stringify(params)
}

/**
 * 解析重建结果参数
 * @param paramsJson 重建结果参数
 * @return ProcessingJobParams 格式数据
 */
export const parseProcessingJobParams = (paramsJson: string): ProcessingJobParams => {
  if (!paramsJson) {
    return {}
  }
  const paramsObj = JSON.parse(paramsJson) as ProcessingJobParams
  return paramsObj
}

/**
 * 功能如其名
 * @param ProcessingJobResultObject ProcessingJobQuery 结果
 * @return {L.LatLngBoundsLiteral}
 */
export const queryBoundsFromProcessingJobResultObject = (
  processingJob: QueryRcProcessingJobDetailQuery['remoteControllerProcessingJob'] | QueryRebuildDetailsByProcessingJobUuidQuery['processingJob']
): [number, number][] | undefined => {
  const { downLeft = {}, upperRight = {} } = processingJob || {}
  return (upperRight?.lat && upperRight?.lng
    && downLeft?.lat && downLeft?.lng) ?
    [
      [upperRight.lat, upperRight.lng],
      [downLeft.lat, downLeft.lng]
    ]
    :
    undefined
}

/**
 * 将一些模式合并起来
 * 这里主要是将城市模式合并为农田模式
 * @param type 
 * @returns 
 */
export const mergeRebuildTypes = (type: REBUILD_TYPES) => {
  if (type === PHOTO_TYPES.CITY) {
    return PHOTO_TYPES.FARM
  }
  return type
}