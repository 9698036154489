/**
 * @file 系统的全局配置
 * @author aron.yao 2022-08-05
 */
import { APPLY_STATUS } from '@/constants/personal'

export interface ISystemState {
  // 菜单是否显示
  isMenuVisible: boolean;
  // 是否遮挡菜单
  isMenuMasked: boolean;
  // 是否显示 问题反馈模态框
  ifShowFeedbackModal: boolean;
  // 是否显示 日志更新模态框
  ifShowReleaseNoteModal: boolean;
  // 是否显示 个人信息弹窗
  ifShowPersonalInfoPopover: boolean;
  // 是否显示 设置弹窗
  ifShowSettingPopover: boolean;
  // 是否显示 区域切换模态框
  ifShowRegionModal: boolean;
  // 申请状态
  applyStatus: APPLY_STATUS;
}

export default {
  state: {
    isMenuVisible: true,
    isMenuMasked: false,
    ifShowFeedbackModal: false,
    ifShowReleaseNoteModal: false,
    ifShowPersonalInfoPopover: false,
    ifShowSettingPopover: false,
    ifShowRegionModal: false,
    applyStatus: APPLY_STATUS.PROCESSING
  },

  reducers: {
    /**
     * 设置菜单显示状态
     */
    toggleMenuVisible(prevState: ISystemState, payload: boolean) {
      prevState.isMenuVisible = payload
    },
    /**
     * 设置菜单遮挡状态
     */
    toggleMenuMasked(prevState: ISystemState, payload: boolean) {
      prevState.isMenuMasked = payload
    },
    /**
     * 设置用户反馈摸态框显示状态
     */
    toggleFeedback(prevState: ISystemState, payload: boolean) {
      prevState.ifShowFeedbackModal = payload
    },
    /**
     * 设置日志更新摸态框显示状态
     */
    toggleReleaseNote(prevState: ISystemState, payload: boolean) {
      prevState.ifShowReleaseNoteModal = payload
    },
    /**
     * 设置个人信息显示状态
     */
    toggleIfShowPersonalInfoPopover(prevState: ISystemState, payload: boolean) {
      prevState.ifShowPersonalInfoPopover = payload
    },
    /**
     * 设置设置弹窗
     */
    toggleIfShowSettingPopover(prevState: ISystemState, payload: boolean) {
      prevState.ifShowSettingPopover = payload
    },
    /**
     * 设置 显示区域切换的弹窗
     */
    toggleIfShowRegionModal(prevState: ISystemState, payload: boolean) {
      prevState.ifShowRegionModal = payload
    },
    updateApplyStatus(prevState: ISystemState, payload: APPLY_STATUS) {
      prevState.applyStatus = payload
    }
  }
}
