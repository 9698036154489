/**
 * @file Media页面公共store
 * @author xinqiang.wei 2022/7/1
 */

import { createStore, IStoreModels, IStoreDispatch, IStoreRootState } from 'ice'
import media from './models/media'
import photoTable from './models/photoTable'
import fileUpload from './models/fileUpload'

interface IAppStoreModels extends IStoreModels {
  media: typeof media;
  photoTable: typeof photoTable;
  fileUpload: typeof fileUpload;
}

const mediaStore: IAppStoreModels = {
  media,
  photoTable,
  fileUpload
}

const store = createStore(mediaStore)

export default store

export type IMediaDispatch = IStoreDispatch<typeof mediaStore>;
export type IMediaState = IStoreRootState<typeof mediaStore>;
