// 照片库类型
export enum PHOTO_TYPES {
  FARM = 0, // 农田
  CITY = 1, // 城市
  FRUIT_TREE = 2, // 果树
  MULTI_SPECTRAL = 4, // 多光谱
  PATROL_FARM = 5 // 巡田
}

// oss上传状态
export enum OSS_INFO_STATUS {
  NOT_UPLOADED = 0, // 未上传oss
  UPLOADED = 1 // 已上传oss
}

// 上传的状态
export enum FILE_UPLOAD_STATUS {
  // 等待上传中
  PENDING = 'pending',
  // 上传成功
  FINISHED = 'finished',
  // 正在上传
  LOADING = 'loading',
  // 上传失败
  FAIL = 'fail',
  // 文件格式错误
  FILE_ERROR = 'fileError'
}

// 文件扩展名类型
export enum FILE_EXTENSION_TYPE {
  JPG = 'JPG',
  TIF = 'TIF'
}

export interface IErrorInfo {
  fileId: string;
  errorMsg: string;
}

export interface PhotoTableRecordType {
  uuid: string;
  key: string;
  name: string;
  photoCounts: number;
  type: PHOTO_TYPES;
  organization: {name: string; uuid: string}[];
  time: string;
  mark: string;
  showSkeleton?: boolean;
  operation?: boolean;
  forNDVI: boolean;
}

// 照片预览的btn
export enum ViewerActionType {
  // 放大
  zoomIn = 1,
  // 缩小
  zoomOut = 2,
  // 前一页
  prev = 3,
  // 下一页
  next = 4,
  // 右转90度
  rotateLeft = 5,
  // 左转90度
  rotateRight = 6,
  // 重置
  reset = 7,
}
