/**
 * @file 区域
 * @author lian.duan 2023-03-28
 */
import type { AgrasRegion } from 'agras-i18n-utils'
import { AgrasRegions } from 'agras-i18n-utils'

type RegionMap = {
  [key in Exclude<AgrasRegion, 'CN'>]: string
}

// 区域和国家的映射表
export const regionCountryMap: RegionMap = {
  'JP': '日本',
  'US': 'United States',
  'CA': 'Canada',
  'MX': 'México',
  'BR': 'Brasil',
  'CL': 'Chile',
  'ZA': 'South Africa',
  'NZ': 'New Zealand',
  'AU': 'Australia',
  'default': 'Other Regions'
}

// 区域和语言的映射表
export const regionLangMap: RegionMap = {
  'JP': '日本語',
  'US': 'English',
  'CA': 'English',
  'MX': 'Español',
  'BR': 'Português (BR)',
  'CL': 'Español',
  'ZA': 'English',
  'NZ': 'English',
  'AU': 'English',
  'default': 'English'
}

// 中国跟日本不在隐私条款范围内，不需要显示
export const scioWhitelistRegions = AgrasRegions.filter((region) => !['JP', 'CN'].includes(region))
