/**
 * @file 重建结果图层工具
 * @author Yangholmes 2022-08-12
 */

import { dateFormat, photoTypeText } from '@/services/filters'
import { PHOTO_TYPES } from '@/types/photo.d'
import { JobInfo } from './InfoCard'
import positionIcon from '@/components/assets/position.svg'
import positionHoverIcon from '@/components/assets/position-hover.svg'
import { t } from 'i18next'
import { runI22t, Quantity } from 'agras-i22t'
import { QueryRebuildDetailsByProcessingJobUuidQuery, QueryRcProcessingJobDetailQuery } from '@/types/graphql-types'
import { number2Fixed } from '@/utils/toolMethod'

// 为兼容ts类型，强行给 remoteControllerProcessingJob 加上这些类型
type RcProcessingJobDetailQuery = QueryRcProcessingJobDetailQuery['remoteControllerProcessingJob'] & {
  tags?: {
    nodes: Array<{ uuid: string; name: string } | null>;
  };
  department?: { name?: string | null; uuid?: string | null } | null;
  Mapping?: { __typename?: 'Mapping'; forNDVI: boolean } | null;
}

export const queryJobInfoFromProcessingJobResult = (
  processingJob: RcProcessingJobDetailQuery | QueryRebuildDetailsByProcessingJobUuidQuery['processingJob']
): JobInfo => {
  const { getUnitKey, convertValue } = runI22t()
  const {
    name: jobName = '',
    area = 0,
    createdAt,
    captureTime,
    type
  } = processingJob || {}
  const { nodes: tagNodes } = processingJob?.tags || {}
  const { name: departmentName = '' } = processingJob?.department || {}
  const { forNDVI = false } = processingJob?.Mapping || {}

  const tags = tagNodes?.map((t) => t?.name || '')
  const createdDt = dateFormat(createdAt)
  const captureDt = dateFormat(captureTime)

  const jobType = photoTypeText(type as PHOTO_TYPES)
  const departmentUuid = processingJob?.department?.uuid

  const areaText = number2Fixed(convertValue(area || 0, Quantity.Area))

  const otherInfos: JobInfo['otherInfos'] = [
    {
      label: t('pages.multiSpectral.mapTaskCard.area'),
      value: `${areaText} ${t(getUnitKey(Quantity.Area))}`
    },
    {
      label: t('pages.media.rebuild.create.modal.form.type.label'),
      value: jobType,
      remark: (forNDVI && type === PHOTO_TYPES.MULTI_SPECTRAL) ? {
        icon: 'info',
        content: t('pages.media.photo.createPhoto.form.bandOptions.content.multispectralType.onlyNDVI')
      } : undefined
    },
    {
      label: t('pages.multiSpectral.mapTaskCard.captureDate'),
      value: captureDt
    },
    {
      label: t('pages.media.photo.createTime.label'),
      value: createdDt
    }
  ]

  !IS_PERSONAL_VERSION && otherInfos.splice(2, 0, {
    label: t('pages.media.photo.preview.photoListDescription.labels.department'),
    value: departmentName
  })

  return {
    jobName,
    tags,
    otherInfos,
    forNDVI,
    departmentUuid
  }
}

const positionIconImg = new Image()
positionIconImg.src = positionIcon
const positionHoverIconImg = new Image()
positionHoverIconImg.src = positionHoverIcon

export const processingIcons = {
  positionIcon: {
    url: positionIcon,
    img: positionIconImg
  },
  positionHoverIcon: {
    url: positionHoverIcon,
    img: positionHoverIconImg
  }
}
/**
 *
 * @param isHover 是否hover
 * @return
*/
export function queryProcessingPhotoIcon(isHover: boolean) {
  if (isHover) {
    return processingIcons['positionHoverIcon']
  }
  return processingIcons['positionIcon']
}