/**
 * @file 菜单配置项
 * 路由规则和菜单项无法复用。路由匹配的顺序和菜单顺序对不上
 *
 * @author aron.yao 2022-10-27
 */
import { t } from 'i18next'

import { compact } from 'lodash-es'
import { Icon } from '@/components/Icon'

import Permission, { PERMIT_MODULES } from '@/services/permission'

interface IMenuConfig {
  icon?: any;
  name: string;
  path: string;
  children?: IMenuConfig[];
  // 菜单的后面增加分割线
  hasAfterDivider?: boolean;
  type?: 'divider';
  // 菜单项需要的权限列表，为且的关系
  permissions: string[];
}

// 渲染菜单图标的方法
function renderMenuIcon(iconName: string) {
  return (
    <span role='img' className='anticon'>
      <Icon iconName={iconName} size='large' color={'#D9D9D9'}/>
    </span>
  )
}

const overallMenuConfig = (): IMenuConfig[] => {
  return [
    {
      path: '/v2/home',
      name: '数据总览',
      icon: renderMenuIcon('data-overview'),
      permissions: [PERMIT_MODULES.OVERVIEW.CHECK]
    },
    {
      path: '/v2/sandbox',
      name: '农业地图',
      icon: renderMenuIcon('sandbox'),
      hasAfterDivider: true,
      permissions: [PERMIT_MODULES.SANDBOX.CHECK]
    },
    // 菜单分割线
    {
      path: 'divider-after-sandbox', // 没用，LayoutPro必须传这个才会渲染
      name: 'divider-after-sandbox', // 没用，LayoutPro必须传这个才会渲染
      icon: 'divider-after-sandbox', // 没用，LayoutPro必须传这个才会渲染
      type: 'divider',
      permissions: [PERMIT_MODULES.OVERVIEW.CHECK, PERMIT_MODULES.SANDBOX.CHECK]
    },
    {
      path: '/v2/realtime-supervise',
      name: '实时监管',
      icon: renderMenuIcon('realtime-supervise'),
      permissions: [PERMIT_MODULES.REALTIME.CHECK]
    },
    {
      path: '/supervise',
      name: '作业监管',
      icon: renderMenuIcon('task-supervise'),
      permissions: [PERMIT_MODULES.TASK.CHECK]
    },
    {
      path: '/',
      name: t('menu.taskPlan.title'),
      icon: renderMenuIcon('job-plan'),
      permissions: [
        PERMIT_MODULES.FARM_PLAN.CHECK,
        PERMIT_MODULES.MULTI_SPECTRAL.CHECK,
        PERMIT_MODULES.FRUIT_PLAN.CHECK,
        PERMIT_MODULES.PATROL_FARM.CHECK
      ],
      children: [
        {
          path: '/task/farm',
          name: t('menu.taskPlan.subMenu.farm'),
          permissions: [PERMIT_MODULES.FARM_PLAN.CHECK]
        },
        {
          path: '/task/multi-spectral',
          name: t('menu.taskPlan.subMenu.multiSpetral'),
          permissions: [PERMIT_MODULES.MULTI_SPECTRAL.CHECK]
        },
        {
          path: '/task/fruit',
          name: t('menu.taskPlan.subMenu.fruit'),
          permissions: [PERMIT_MODULES.FRUIT_PLAN.CHECK]
        },
        {
          path: '/v2/patrol-farm',
          name: t('menu.taskPlan.subMenu.partrolFarm'),
          permissions: [PERMIT_MODULES.PATROL_FARM.CHECK]
        }
      ]
    },
    {
      path: '/',
      name: t('menu.analysis.title'),
      icon: renderMenuIcon('agro-analyze'),
      permissions: [PERMIT_MODULES.CROP_ESTIMATION.CHECK, PERMIT_MODULES.SEEDLING.CHECK],
      children: [
        {
          path: '/analysis/crop-estimation',
          name: t('menu.analysis.subMenu.cropEstimation'),
          permissions: [PERMIT_MODULES.CROP_ESTIMATION.CHECK]
        },
        {
          path: '/v2/seedling',
          name: '出苗分析',
          permissions: [PERMIT_MODULES.SEEDLING.CHECK]
        }
      ]
    },
    {
      path: '/v2/mapping-auth',
      name: t('menu.mappingAuth.title'),
      icon: renderMenuIcon('map-auth'),
      permissions: [PERMIT_MODULES.MAPPING_AUTH.CHECK]
    },
    {
      path: 'divider-after-mapping-auth',
      name: 'divider-after-mapping-auth',
      icon: 'divider-after-mapping-auth',
      type: 'divider',
      permissions: [
        PERMIT_MODULES.MEDIA_PHOTO.CHECK,
        PERMIT_MODULES.MEDIA_REBUILD.CHECK,
        PERMIT_MODULES.TP_FERTILIZER.CHECK,
        PERMIT_MODULES.PLOT.CHECK,
        PERMIT_MODULES.DEVICE.CHECK,
        PERMIT_MODULES.WEATHER.CHECK,
        PERMIT_MODULES.USERS.CHECK,
        PERMIT_MODULES.DEPARTMENTS.CHECK
      ]
    },
    {
      path: '/',
      name: t('menu.manage.title'),
      icon: renderMenuIcon('manage'),
      permissions: [
        PERMIT_MODULES.MEDIA_PHOTO.CHECK,
        PERMIT_MODULES.MEDIA_REBUILD.CHECK,
        PERMIT_MODULES.TP_FERTILIZER.CHECK,
        PERMIT_MODULES.PLOT.CHECK,
        PERMIT_MODULES.DEVICE.CHECK,
        PERMIT_MODULES.WEATHER.CHECK,
        PERMIT_MODULES.USERS.CHECK
      ],
      children: [
        {
          path: '/media',
          name: t('menu.manage.subMenu.media'),
          permissions: [PERMIT_MODULES.MEDIA_PHOTO.CHECK, PERMIT_MODULES.MEDIA_REBUILD.CHECK]
        },
        {
          path: 'v2/fertilizer',
          name: t('menu.manage.subMenu.thirdPartyFertilizer'),
          permissions: [PERMIT_MODULES.TP_FERTILIZER.CHECK]
        },
        {
          path: '/v2/plots',
          name: t('menu.manage.subMenu.plots'),
          permissions: [PERMIT_MODULES.PLOT.CHECK]
        },
        {
          path: 'v2/devices',
          name: '设备管理',
          permissions: [PERMIT_MODULES.DEVICE.CHECK]
        },
        {
          path: 'v2/weather',
          name: '气象管理',
          permissions: [PERMIT_MODULES.WEATHER.CHECK]
        },
        {
          path: 'v2/users',
          name: '用户管理',
          permissions: [PERMIT_MODULES.USERS.CHECK]
        }
      ]
    },
    {
      path: '/v2/departments',
      name: '组织架构',
      icon: renderMenuIcon('organization'),
      permissions: [PERMIT_MODULES.DEPARTMENTS.CHECK]
    }
  ]
}

// 过滤没有权限的模块
function filterMenuConfig(config) {
  return compact(config.map((item) => {
    if (Permission.inst.checkModulesPermission(item.permissions)) {
      if (item.children) {
        item.children = filterMenuConfig(item.children)
      }
      return item
    }
    return undefined
  }))
}

let resultCache

export default async function getMenuConfig() {
  await Permission.promise

  if (!resultCache) {
    resultCache = filterMenuConfig(overallMenuConfig())
  }

  return resultCache
}
