/**
 * @file 系统信息
 * @author lian.duan 2023-06-13
 */
// 版本
export enum SYSTEM_EDITION {
  PERSONAL,
  ENTERPRISE,
  GOVERNMENT
}

/**
 * 获取系统的版本类型
 */
export function getSystemEdition(isGovernment: boolean): SYSTEM_EDITION {
  if (IS_PERSONAL_VERSION) {
    return SYSTEM_EDITION.PERSONAL
  }
  // 是否为政府版的字段是从接口来的
  if (isGovernment) {
    return SYSTEM_EDITION.GOVERNMENT
  }
  return SYSTEM_EDITION.ENTERPRISE
}
