/**
 * @file 智农常量定义汇总
 * @author aron.yao 2021-10-26
 */
import * as L from 'leaflet'

export * from './src/keyCodes'

// 地块点类型
export enum PLOT_POINT_TYPES {
  EDGE = 'plotEdge', // 边界点
  CALI = 'plotCali' // 标定点
}

// 喷洒类型的枚举类型
export enum SPRAY_TYPE {
  // 区域规划-连续喷洒
  AREA_CONTINUOUS_SPRAY = 0,
  // 区域规划-树心定点喷洒
  AREA_TREE_CENTER_POINT_SPRAY = 1,
  // 自由航线规划-连续喷洒
  FREE_CONTINUOUS_SPRAY = 3,
  // 自由航线规划-树心定点喷洒
  FREE_TREE_CENTER_POINT_SPRAY = 4,
  // 自由航线规划-过树心连续喷洒
  FREE_TREE_CENTER_CONTINUOUS_SPRAY = 5,
  // 区域规划-过树心连续喷洒
  AREA_TREE_CENTER_CONTINUOUS_SPRAY = 6,
}

// 中国地图区域范围
export const CHINA_BOUNDS: L.LatLngTuple[] = [
  [56.80087831233043, 133.7255859375],
  [14.944784875088372, 75.849609375]
]

/* 高程 */

// 无效的高程值，即重建范围外的位置对应的高程值
export const GSD_TIF_INVALID_VALUE = -9999

// 语义和他们的值们
export enum SEGMENT_VALUE_MAP {
  OTHER = 0,
  TREE = 1,
  BUILDING = 2,
  POLE = 3,
  GROUND = 4,
  WATER = 5
}

/**
 * ppe生成的航点类型
 */
export enum PPE_POINT_TYPE {
  // 普通航点
  NORMAL_POINT = 0,
  // 果树模式航线转折点
  TREE_MODE_TURNING_POINT = 1,
  // 果树模式障碍物点
  TREE_OBSTACLE_POINT = 2,
  // 树心模式航点
  TREE_MODE_MODE_POINT = 3,
  // 智能续航点类型
  ASSISTANT_RESUME_POINT = 4,
}

/**
 * ppe生成的航点行为
 */
export enum PPE_ACTION_TYPE {
  // 工作
  WORK = 0,
  // 边缘
  EDGE = 1,
  // 绕行
  DETOUR = 2,
  // 扫边
  BOUNDARY_SCAN = 3,
  // 其他
  OTHER = -1
}

// 重建任务的类型
export enum REBUILD_JOB_TYPES {
  FARM = 0, // 农田模式
  CITY = 1, // 城市模式（即大田模式)
  FRUIT_TREE = 2, // 果树模式
  MULTI_SPECTRAL = 4 // 多光谱模式
}

// 地图可使用的最大边界范围
export const MAP_MAX_BOUNDS = L.latLngBounds([-90, -180], [90, 180])

// google 图源的url
export const GOOGLE_SATELLITE_TILE_URL = 'https://mt{s}.google.com/maps/vt/lyrs=y&scale=2&x={x}&y={y}&z={z}'
// google 图源 cdn
export const GOOGLE_MAP_TILE_CDN = [...Array(4)].map((_, i) => i.toString())
// 天地图 图源 cdn
export const TIANDI_MAP_TILE_CDN = [...Array(8)].map((_, i) => i.toString())

// 后端返回graphql错误的schema
export const GRAPHQL_API_ERROR_MESSAGE = 'graphql.errors.{module}.{reason}'
// 智农单位制的template
export const AGRAS_UNIT_TEMPLATE = 'agrasUnit.unit.{code}.{quantity}'

// 默认的地图缩放的最小层级，目前和basemap中的保持一致
export const DEFAULT_MIN_ZOOM = 4
// 默认的地图缩放的最大层级
export const DEFAULT_MAX_ZOOM = 23
// 天地图最大zoom级别
export const TIANDI_MAP_MAX_ZOOM = 18
// 谷歌地图最大zoom级别
export const GOOGLE_MAP_MAX_ZOOM = 19

// 谷歌地图的图层配置
export const GOOGLE_TILE_LAYER_OPTIONS = {
  maxZoom: DEFAULT_MAX_ZOOM,
  subdomains: GOOGLE_MAP_TILE_CDN,
  maxNativeZoom: GOOGLE_MAP_MAX_ZOOM
}

// 天地图的图层配置
export const TIANDI_TILE_LAYER_OPTIONS = {
  maxZoom: DEFAULT_MAX_ZOOM,
  subdomains: TIANDI_MAP_TILE_CDN,
  maxNativeZoom: TIANDI_MAP_MAX_ZOOM
}
