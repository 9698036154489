/**
 * @file 雪碧图标
 * @author Yangholmes 2022-06-16
 */
import { createRef, forwardRef } from 'react'
import { useEffect, useState } from 'react'
import './index.less'

export interface Props {
  /** 图标名称 */
  iconName: string;
  className?: string;
  /** 图标大小，默认是 medium */
  size?:
  'small' |
  'medium' |
  'large' |
  'huge';
  /** 填充颜色 */
  color?: string;
  fillRule?: 'evenodd' | 'nonzero';
  /** 鼠标移入 */
  onMouseEnter?: () => void;
  /** 鼠标移出 */
  onMouseLeave?: () => void;
  /** 点击事件 */
  onClick?: () => void;
}

export const Icon = forwardRef<React.RefObject<HTMLDivElement>, Props>((props, ref) => {
  const { iconName, size = 'medium', color, className = '', fillRule = 'evenodd', onMouseEnter, onMouseLeave, onClick } = props
  const [symbolId, setSymbolId] = useState('')
  const iconRef = ref as React.RefObject<HTMLDivElement> || createRef<HTMLDivElement>()

  useEffect(() => {
    const fetchIcon = () => {
      const symbol = `icon-${iconName}`
      setSymbolId(symbol)
    }
    fetchIcon()
  }, [iconName])

  return (
    <span 
      className={`smart-ui-icon ${size} ${className}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      ref={iconRef}
    >
      <svg
        fillRule={fillRule}
        width='100%'
        height='100%'
        aria-hidden='true'
        className={`${symbolId} smart-ui-icon-svg`}
      >
        <use href={`#${symbolId}`} fill={color} />
      </svg>
    </span>
  )
})

export default Icon