/**
 * @file 部门接口
 * 从老智农拷贝过来的
 * @author lian.duan
 * @modified Yangholmes 2022-07-28
 */

import graphqlRequest from '@/services/graphqlRequest'
import gql from 'graphql-tag'

import { DepartmentFenceQuery, DepartmentFenceQueryVariables, DepartmentTree, SubDepartmentsFenceQuery, SubDepartmentsFenceQueryVariables } from '@/types/graphql-types.d'
import basicDepartmentTree from './gql/basicDepartmentTree.gql'
import queryDepartmentFenceGql from './gql/queryDepartmentFence.gql'
import querySubDepartmentsFenceGql from './gql/querySubDepartmentsFence.gql'

/**
 * 检索部门树，按照深度检索，默认深度是 1
 * bug：函数形参默认值在编译后会丢失，必须在调用的时候传入，默认值不可靠
 * @param level
 * @param departmentFragment
 * @returns
 */
export const queryDepartmentTree = (level = 1, departmentFragment = basicDepartmentTree ) => {
  let departmentStr = `
    department {
      ...departmentFragment
    }
    %s
  `
  const subDepartmentStr = `
    children {
      department {
        ...departmentFragment
      }
      %s
    }
  `
  for (let i = 0; i < level; i += 1) {
    departmentStr = departmentStr.replace('%s', subDepartmentStr)
  }
  departmentStr = departmentStr.replace('%s', '')

  const query = gql`
    query departmentTree {
      departmentTree{
        children {
          ${departmentStr}
        }
      }
    }
    ${departmentFragment}
  `
  return graphqlRequest<{
    departmentTree: DepartmentTree;
  }>(query)
}

// 获取部门边界信息
export function queryDepartmentFence(variables: DepartmentFenceQueryVariables) {
  return graphqlRequest<DepartmentFenceQuery>(queryDepartmentFenceGql, variables)
}

// 获取直接子部门列表，包含边界信息
export function querySubDepartmentsFence(variables: SubDepartmentsFenceQueryVariables) {
  return graphqlRequest<SubDepartmentsFenceQuery>(querySubDepartmentsFenceGql, variables)
}
