/**
 * @file 菜单布局
 * @author xinqiang.wei 2022/6/30
 */
import cn from 'classnames'
import ProLayout from '@ant-design/pro-layout'
import React, { Suspense, lazy, useEffect, useState } from 'react'
import { useLocation, useHistory } from 'ice'
import { IRouterConfigTs } from '@/routes'
import { renderMenuRender } from './menu'
import style from './index.module.less'
import rootStore from '@/store'
import store from '@/store'

import { ROUTE_PATHS } from '@/constants/routes'

import getMenuConfig from './menuConfig'

const ReleaseNoteModal = lazy(() => import('@/pages/ReleaseNote'))
const FeedbackModal = lazy(() => import('@/pages/Feedback/ReportFeedbackModal'))
const RegionModal = lazy(() => import('@/pages/RegionModal'))

interface defaultPropsOption {
  route: {
    routes: IRouterConfigTs[];
  };
  location: {
    pathname: string;
  };
}

const Menu: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [pathname, setPathname] = useState<string>('')
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const [defaultProps, setDefaultProps] = useState<defaultPropsOption>()
  const location = useLocation()
  const history = useHistory()

  const [config] = store.useModel('config')
  const [systemState, systemDispatchers] = rootStore.useModel('system')

  useEffect(() => {
    getMenuConfig().then((routes) => {
      setDefaultProps({
        route: {
          routes
        },
        location: {
          pathname: '/'
        }
      })
    })
  }, [])

  const onClickMenuItem = (item) => {
    const path = item.path
    // 相同的路由不重复push
    if (path === location.pathname) {
      return
    }
    // 特殊处理素材管理路由
    if (path === ROUTE_PATHS.MEDIA_ROOT_PATH) {
      // 如果是素材管理的子路由，就无需跳转（为了解决点击子路由tab时，菜单会刷新的问题）
      const reg = new RegExp(`^${ROUTE_PATHS.MEDIA_ROOT_PATH}`)
      if (reg.test(location.pathname)) {
        return
      }
      // 点击素材管理，默认跳转到照片库路由，避免两次路由跳转，导致菜单栏重复的渲染
      setPathname(ROUTE_PATHS.MEDIA_PHOTO_PATH)
      history.push(ROUTE_PATHS.MEDIA_PHOTO_PATH)
    } else {
      setPathname(path)
      history.push(path)
    }
  }

  useEffect(() => {
    setPathname(location.pathname)
  }, [location])

  return (
    <div
      className={style['page-container']}
      id='smart-agro-layout'
    >
      <ProLayout
        title={''}
        className={cn({
          'pro-layout-hidden': !systemState.isMenuVisible,
          'pro-layout-masked': systemState.isMenuMasked
        })}
        logo={collapsed ? null : config.logo}
        breakpoint={false}
        headerRender={false}
        rightContentRender={false}
        disableMobile={true}
        {...defaultProps}
        siderWidth={systemState.isMenuVisible ? 224 : 0}
        location={{
          pathname
        }}
        menuFooterRender={(props) => renderMenuRender(props || {}, setCollapsed)}
        navTheme={'light'}
        menuItemRender={(item, dom) => {
          /**
           * ProLayout不支持分割线，需要自己hack实现
           * 见issue：https://github.com/ant-design/ant-design-pro-layout/issues/298
           */
          if (item.type === 'divider') {
            return <div className={style.divider} />
          }

          const { showBottomBorder } = item
          // pro_layout_parentKeys 可以使用来判断是否为一级菜单，用于显示分割线
          const classNamePrefix = (showBottomBorder && !item.pro_layout_parentKeys.length) ? 'show' : 'hide'
          return (
            <div className={cn(style[`${classNamePrefix}-bottom-border`], {
              active: item.path && location.pathname.indexOf(item.path) === 0
            })}
            >
              <a onClick={() => onClickMenuItem(item)}>{dom}</a>
            </div>
          )
        }}
        fixSiderbar={true}
        collapsed={collapsed}
      >
        {children}
      </ProLayout>
      <Suspense fallback={<></>}>
        <FeedbackModal
          visible={systemState.ifShowFeedbackModal}
          onVisibleChange={(v) => { systemDispatchers.toggleFeedback(v) }}
        />
        {
          systemState.ifShowReleaseNoteModal ? <ReleaseNoteModal /> : null
        }
        {
          systemState.ifShowRegionModal ? <RegionModal /> : null
        }
      </Suspense>
    </div>
  )
}

export default Menu
