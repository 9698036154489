/**
 * @file cookie条款的hook
 * @author aron.yao
 */
import { useEffect } from 'react'
import Scio from '@dji/scio'
import cookieRef from '@/services/cookieReference'
import { initGA } from '@/utils/ga'
import { currentLocaleConfig } from '@/i18n'

let scio: Scio

export default function useCookieReference() {
  useEffect(() => {
    // TODO: inScioWhitelist
    if (currentLocaleConfig.inWhitelist) {
      if (scio) return

      scio = cookieRef.init()
      scio.on('initialized', () => initGA())
    } else {
      initGA()
    }
  }, [])
}
