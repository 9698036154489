/**
 * @file 重建
 * @author lian.duan 2022-07-04
 */
import { PHOTO_TYPES } from '@/types/photo.d'

// 重建类型，要从PHOTO_TYPES中去掉巡田
export type REBUILD_TYPES = PHOTO_TYPES.CITY | PHOTO_TYPES.FARM | PHOTO_TYPES.FRUIT_TREE | PHOTO_TYPES.MULTI_SPECTRAL

// 重建等级
export enum QUALITY_LEVEL {
  HIGH = 1,
  MEDIUM = 2,
  LOW = 3
}

// 多光谱重建的子类型
export enum SUB_MULTISPECTRAL_TYPE {
  ONLY_NDVI = 0, // 仅NDVI
  ALL_BANDS = 1 // 全部指数
}

// 重建的光谱
export enum REBUILD_BAND_NAME {
  RED = 'Red',
  RED_EDGE = 'RedEdge',
  NIR = 'NIR',
  // NOTICE: 光哥说，blue类型的不用上传
  BLUE = 'Blue',
  GREEN = 'Green'
}

// 多光谱重建 类型
export enum MULTISPECTRAL_REBUILD_TYPE {
  NDVI = 'NDVI',
  GNDVI = 'GNDVI',
  NDRE = 'NDRE',
  LCI = 'LCI',
  OSAVI = 'OSAVI',
  MCARI = 'MCARI'
}

// 后端校验 是否允许开始重建的状态
export enum VALIDATE_START_REBUILD_RESULT {
  // 不允许重建
  INVALID = 0,
  // 可以重建
  VALID = 1
}

export interface ProcessingJobParams {
  Debug?: number;
  aux_band_list?: REBUILD_BAND_NAME[];
  fertilizer_param?: {
    mode?: number;
    grid_gsd?: number;
    valid_label?: number;
    plots?: null;
  };
  grid_gsd?: number;
  index_list?: MULTISPECTRAL_REBUILD_TYPE[];
  map_mode?: number;
  output_CS_from_WKT?: string;
  output_geo_CS?: string;
  output_geo_desc?: {
    cs_type?: string;
    geo_cs?: string;
    override_vertical_cs?: string;
  };
  quality_level?: number;
  use_Wallis?: boolean;
  use_gcj02?: boolean;
  valid_label?: number;
}

