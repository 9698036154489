/**
 * @file 自定义Popover组件
 * @author aron.yao 2022-08-11
 */

import { FC } from 'react'
import { Popover, PopoverProps } from 'antd'
import './index.less'

export type AgPopoverProps = PopoverProps

const AgPopover: FC<AgPopoverProps> = (props) => {
  return (
    <Popover
      {...props}
      // 合并overlayClassName属性
      overlayClassName={`ag-popover ${props.overlayClassName || ''}`}
    >
      {props.children}
    </Popover>
  )
}

export default AgPopover