/**
 * @file 功能模块枚举
 * @author aron.yao 2022-11-04
 */

// 用户管理
const USERS = {
  CHECK: 'userCheck', // 查看用户管理
  EDIT: 'userModify', // 编辑用户信息
  ADD: 'userAdd' // 添加用户
}

// 设备管理
const DEVICE = {
  CHECK: 'deviceCheck', // 查看设备管理
  EDIT: 'deviceModify', // 编辑设备信息
  ADD: 'deviceAdd' // 添加设备
}

// 组织架构
const DEPARTMENTS = {
  CHECK: 'departmentCheck', // 查看组织架构
  EDIT: 'departmentModify', // 编辑组织架构
  ADD: 'departmentAdd', // 添加组织架构
  DELETE: 'departmentDelete' // 删除组织架构
}

// 数据概览
const OVERVIEW = {
  CHECK: 'overviewCheck' // 查看
}

// 农业沙盘
const SANDBOX = {
  CHECK: 'sandboxCheck' // 查看
}

// 实时监管
const REALTIME = {
  CHECK: 'realtimeCheck' // 查看
}

// 作业管理
const TASK = {
  CHECK: 'taskCheck', // 查看
  EDIT: 'taskModify', // 编辑
  ADD: 'taskAdd', // 添加
  DELETE: 'taskDelete', // 删除
  DATA_EXPORT: 'taskDataExport' // 数据导出
}

// 农田规划
const FARM_PLAN = {
  CHECK: 'farmPlanCheck' // 查看
}

// 变量作业
const MULTI_SPECTRAL = {
  CHECK: 'multiSpectralCheck' // 查看
}

// 果树规划
const FRUIT_PLAN = {
  CHECK: 'fruitPlanCheck' // 查看
}

// 巡田记录
const PATROL_FARM = {
  CHECK: 'patrolFarmCheck', // 查看
  EDIT: 'patrolFarmModify', // 编辑
  ADD: 'patrolFarmAdd', // 添加
  DELETE: 'patrolFarmDelete' // 删除
}

// 测产分析
const CROP_ESTIMATION = {
  CHECK: 'cropEstimationCheck' // 查看
}

// 出苗分析
const SEEDLING = {
  CHECK: 'seedlingCheck', // 查看
  EDIT: 'seedlingModify', // 编辑
  ADD: 'seedlingAdd', // 添加
  DELETE: 'seedlingDelete' // 删除
}

// 地图授权
const MAPPING_AUTH = {
  CHECK: 'mappingAuthCheck', // 查看
  ADD: 'mappingAuthAdd', // 添加
  DELETE: 'mappingAuthDelete' // 删除
}

// 素材库
const MEDIA = {
  CHECK: 'mediaCheck' // 查看
}

// 地块管理
const PLOT = {
  CHECK: 'plotCheck', // 查看
  EDIT: 'plotModify', // 编辑
  ADD: 'plotAdd', // 添加
  DELETE: 'plotDelete', // 删除
  CHECK_RECORDS: 'plotCheckRecords' // 查看地块的飞行记录
}

// 气象数据
const WEATHER = {
  CHECK: 'weatherCheck' // 查看
}

// 素材库-照片库
const MEDIA_PHOTO = {
  CHECK: 'mediaPhotoCheck' // 查看
}

// 素材库-重建图
const MEDIA_REBUILD = {
  CHECK: 'mediaRebuildCheck' // 查看
}

// 云重建
const REBUILD = {
  CHECK: 'rebuildCheck', // 查看
  ADD: 'rebuildAdd', // 添加
  DELETE: 'rebuildDelete' // 删除
}

// 标签管理
const TAGS = {
  CHECK: 'tagsCheck', // 查看
  EDIT: 'tagsModify', // 编辑
  ADD: 'tagsAdd', // 添加
  DELETE: 'tagsDelete' // 删除
}

// 第三方处方图
const TP_FERTILIZER = {
  CHECK: 'TPFertilizerCheck', // 查看
  EDIT: 'TPFertilizerEdit', // 编辑
  ADD: 'TPFertilizerAdd', // 添加
  DELETE: 'TPFertilizerDelete' // 删除
}

// 设置-问题反馈
const FEEDBACK = {
  CHECK: 'feedbackCheck' // 查看
}

// 设置-更新日志
const RELEASE_NOTES = {
  CHECK: 'releaseNotesCheck' // 查看
}

// 设置-常见问题
const FAQ = {
  CHECK: 'faqCheck' // 查看
}

// 所有的功能模块
export const PERMIT_MODULES = {
  OVERVIEW,
  SANDBOX,
  REALTIME,
  WEATHER,
  PLOT,
  TASK,
  FARM_PLAN,
  MULTI_SPECTRAL,
  FRUIT_PLAN,
  PATROL_FARM,
  CROP_ESTIMATION,
  SEEDLING,
  MAPPING_AUTH,
  MEDIA,
  USERS,
  DEVICE,
  DEPARTMENTS,
  MEDIA_PHOTO,
  MEDIA_REBUILD,
  REBUILD,
  TAGS,
  TP_FERTILIZER,
  FEEDBACK,
  RELEASE_NOTES,
  FAQ
}

export default PERMIT_MODULES
