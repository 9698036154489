/**
 * @file Task公共store
 * @author hellobin.chen 2022-09-07
 */
import { IStoreModels, createStore, IStoreDispatch, IStoreRootState } from 'ice'
import multiSpectral from './models/multiSpectral'

interface IAppStoreModels extends IStoreModels {
  multiSpectral: typeof multiSpectral;
}

const taskStore: IAppStoreModels = {
  multiSpectral
}

const store = createStore(taskStore)

export default store

export type ITaskDispatch = IStoreDispatch<typeof taskStore>
export type ITaskState = IStoreRootState<typeof taskStore>
