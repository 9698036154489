/**
 * @file 照片库列表数据展示
 * @author super.yang 2022-07-15
*/
import { PhotoTableRecordType, PHOTO_TYPES } from '@/types/photo.d'

/**
 *
 * @param datas 后端请求到的table数据
 * @return
*/
export const getRenderTableData = (datas): PhotoTableRecordType[] => {
  const tableData = datas ? datas.map((data) => data.showSkeleton ? data : ({
    key: data.uuid,
    uuid: data.uuid,
    name: data.name,
    photoCounts: data.sourceImages.totalCount,
    type: data.jobType,
    organization: data.departmentLinks,
    time: data.createdAt,
    mark: data.remark,
    operation: true,
    forNDVI: data.forNDVI
  })) : []
  return tableData
}
/**
 *
 * @param pageSize 骨架屏行数
 * @return
*/
export const getInitTableData = (pageSize: number): PhotoTableRecordType[] => (
  Array(pageSize).fill('').map((_, index) => ({
    uuid: '',
    key: `initTableItem${index}`,
    name: '',
    photoCounts: -1,
    type: PHOTO_TYPES.FARM,
    organization: [],
    time: '',
    mark: '',
    showSkeleton: true,
    forNDVI: false
  }))
)