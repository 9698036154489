/**
 * @file 通用常用变量
 * @author xinqiang.wei 2022/8/4
 */

// 照片库表格初始分页条数
export const INIT_PAGESIZE = 20
// 照片库表格初始页数
export const INIT_PAGE = 1
// 照片库数据无备注时的默认值
export const UNKNOWN_TXT = '-'

export const TYPE_ALL_VALUE = -999

export enum TYPE_ALL {
  TYPE_ALL = TYPE_ALL_VALUE
}

// 重建任务名称最大长度
export const REBUILD_NAME_LENGTH = 40
// 重建任务地块名称最大长度
export const PLOT_NAME_LENGTH = 40
// 毫秒转秒
export const MILLISECOND_TO_SECOND = 1000
// 一小时多秒
export const SECOND_TO_HOUR = 3600
// 一天多少小时
export const HOUR_TO_DAY = 24
// 一小时多少分钟
export const HOUR_TO_MINUTE = 60
// 默认骨架条数
export const SKELETON_NUMBER = 10
// 默认一年的天数
export const daysOfOneYear = 365

// 骨架屏条目宽度
export const SKELETON_WIDTH = {
  /* 小宽度 */
  'small': 120,
  /* 中等宽度 */
  'medium': 200,
  /* 中等偏大宽度 */
  'mediumPlus': 276,
  /* 大宽度 */
  'large': 292
}

// 重建来源
export enum REBUILD_SOURCE {
  // 智农
  SMART_FARM = 'sf',
  // 遥控器
  REMOTE_CONTROL = 'rc',
  // 智图PC
  TERRA_PC = 'terra',
}

// 数据加载状态
export enum DATA_LOAD_STATUS {
  LOADING,
  FAIL,
  SUCCESS
}

