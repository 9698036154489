/**
 * @file 农业分析 入口页面
 * @author lian.duan 2022-09-01
 */
import { FC } from 'react'
import style from './index.module.less'

const AnalysisPage: FC = ({ children }) => {

  return (
    <div className={style['analysis-page-container']}>
      {children}
    </div>
  )
}

export default AnalysisPage
