/**
 * @file 文件上传 store
 * @author lian.duan 2022-07-16
 */
import { IMediaState, IMediaDispatch } from '@/pages/Media/store'
import { IUploadTaskInfo, FILE_UPLOAD_STATUS, FOLDER_UPLOAD_STATUS, MAPPING_UPLOAD_STATUS } from '@/types/upload.d'
import { startAutoProcessingJob, updateMapping } from '@/services/apis/photo'
import { isRebuildType } from '@/utils/rebuild'
import { Index } from '@/types/graphql-types.d'
import { validateMappingCanRebuild } from '@/helpers/validateProcessingJob'

function findIndexById(data, id) {
  return data.findIndex((item) => item.id === id)
}
interface IState {
  UploadTasks: IUploadTaskInfo[];
  uploadingQueueIds: string[];
  subscribeMappings: string[];
}

const fileUploadState: IState = {
  // 上传任务
  UploadTasks: [],
  // 正在上传的队列id
  uploadingQueueIds: [],
  // socket订阅的照片库
  subscribeMappings: []
}

export default {
  state: fileUploadState,
  reducers: {
    // 新增一个需要订阅的照片库
    addSubscribeMappings(prevState: IMediaState, mappingUuid: string) {
      if (!prevState.subscribeMappings.includes(mappingUuid)) {
        prevState.subscribeMappings = [...prevState.subscribeMappings, mappingUuid]
      }
    },
    // 从订阅照片库列表中删除
    deleteSubscribeMappings(prevState: IMediaState, mappingUuid: string) {
      const index = prevState.subscribeMappings.findIndex((item) => item === mappingUuid)
      if (index === -1) return
      prevState.subscribeMappings.splice(index, 1)
    },
    // 新增一个任务id到上传队列中
    addUploadingQueueIds(prevState: IMediaState, queueId: string) {
      prevState.uploadingQueueIds = [...prevState.uploadingQueueIds, queueId]
    },
    // 从上传队列中减少一个任务
    removeUploadingQueueIds(prevState: IMediaState, queueId: string) {
      const index = prevState.uploadingQueueIds.findIndex((item) => item === queueId)
      if (index === -1) return
      prevState.uploadingQueueIds.splice(index, 1)
    },
    // 增加 一个文件上传任务
    addFilesUploadTask(prevState: IMediaState, payload: IUploadTaskInfo) {
      prevState.UploadTasks.push(payload)
    },
    // 更新文件上传的状态
    updateFileStatus(prevState: IMediaState, payload) {
      if (prevState.UploadTasks.length === 0) return
      const {
        queueId, fileId, status, progress, errorMsg
      } = payload

      const folderIndex = prevState.UploadTasks.findIndex((item) => item.id === queueId)
      if (folderIndex === -1) return
      // 再找到相应文件的index
      const matchFileIndex = prevState.UploadTasks[folderIndex].files.findIndex((file) => file.id === fileId)
      if (matchFileIndex === -1) return

      // 更新该文件的状态、进度
      prevState.UploadTasks[folderIndex].files[matchFileIndex] = {
        ...prevState.UploadTasks[folderIndex].files[matchFileIndex],
        status,
        progress,
        errorMsg
      }

      // 更新成功和失败的数量
      const { uploadInfo } = prevState.UploadTasks[folderIndex]
      switch (status) {
        case FILE_UPLOAD_STATUS.SUCCESS:
        case FILE_UPLOAD_STATUS.SKIP:
          uploadInfo.successCount += 1
          break
        case FILE_UPLOAD_STATUS.FAIL:
          uploadInfo.failCount += 1
          break
        default:
          break
      }
    },
    // 重置失败文件的状态
    resetFailFileStaus(prevState: IMediaState, queueId) {
      if ((prevState.UploadTasks.length === 0)) return

      const folderIndex = prevState.UploadTasks.findIndex((item) => item.id === queueId)
      if (folderIndex === -1) return

      const { uploadInfo, files } = prevState.UploadTasks[folderIndex]

      // 重置失败数量和上传状态
      uploadInfo.failCount = 0
      uploadInfo.status = FOLDER_UPLOAD_STATUS.LOADING

      // 重置成功的数量（点击重新上传，会出现上传数大于总数的问题，因此在这里重置一遍成功数量）
      const successFiles = files.filter((item) => [FILE_UPLOAD_STATUS.SUCCESS, FILE_UPLOAD_STATUS.SKIP].includes(item.status))
      uploadInfo.successCount = successFiles.length

      // 重置失败文件的状态、进度、错误
      prevState.UploadTasks[folderIndex].files = files
        .map((item) => ({
          ...item,
          status: item.status === FILE_UPLOAD_STATUS.FAIL ? FILE_UPLOAD_STATUS.PENDING : item.status,
          progress: item.status === FILE_UPLOAD_STATUS.FAIL ? 0 : item.progress,
          errorMsg: ''
        }))
    },
    // 更新上传任务
    updateFolderStatus(prevState: IMediaState, payload) {
      if (prevState.UploadTasks.length === 0) return

      const { queueId, status } = payload
      const folderIndex = prevState.UploadTasks.findIndex((item) => item.id === queueId)
      if (folderIndex === -1) return

      prevState.UploadTasks[folderIndex].uploadInfo.status = status

      // 如果是停止状态，那下面的所有文件也要更新为停止状态
      if (status === FOLDER_UPLOAD_STATUS.STOP) {
        prevState.UploadTasks[folderIndex].files = prevState.UploadTasks[folderIndex].files.map((item) => {
          if (item.status === FILE_UPLOAD_STATUS.PENDING) {
            return {
              ...item,
              status: FILE_UPLOAD_STATUS.STOP
            }
          }
          return item
        })
      }
    },
    // 修改照片库的自动重建状态
    updateMappingAutoStatus(prevState: IMediaState, payload) {
      if (prevState.UploadTasks.length === 0) return
      const { queueId, auto } = payload

      const finishedStatus = [
        FOLDER_UPLOAD_STATUS.STOP,
        FOLDER_UPLOAD_STATUS.FEEDBACK,
        FOLDER_UPLOAD_STATUS.AUTO_REBUILD_FAIL,
        FOLDER_UPLOAD_STATUS.NOT_ENOUGH_PHOTOS,
        FOLDER_UPLOAD_STATUS.SUCCESS
      ]
      const index = findIndexById(prevState.UploadTasks, queueId)
      const data = prevState.UploadTasks[index]
      const { jobType } = data.mappingInfo
      const { status } = data.uploadInfo

      if (!finishedStatus.includes(status) && isRebuildType(jobType)) {
        prevState.UploadTasks[index] = {
          ...prevState.UploadTasks[index],
          mappingInfo: {
            ...prevState.UploadTasks[index].mappingInfo,
            auto
          }
        }
      }
    },
    // 更新照片库的上传状态
    updateUploadTaskStatus(prevState: IMediaState, payload) {
      if (prevState.UploadTasks.length === 0) return
      const { queueId, uploadStatus } = payload
      const index = findIndexById(prevState.UploadTasks, queueId)

      prevState.UploadTasks[index] = {
        ...prevState.UploadTasks[index],
        uploadInfo: {
          ...prevState.UploadTasks[index].uploadInfo,
          status: uploadStatus
        }
      }
    },
    // 清空 文件上传任务
    clearUploadTasks(prevState: IMediaState, payload = []) {
      prevState.UploadTasks = payload
      prevState.uploadingQueueIds = payload
      prevState.subscribeMappings = payload
    }
  },
  effects: (dispatch: IMediaDispatch) => ({
    // 更新 上传任务的状态
    async updateMappingUploadStatus(payload: {
      mappingStatus: any;
      queueId: string;
    }, mediaState: IMediaState) {
      const { queueId, mappingStatus } = payload

      // 取出该照片库
      const data = mediaState.fileUpload.UploadTasks.find((item) => item.id === queueId)
      // 只有当该照片库正在同步中时，才会更新状态
      if (!data || (data.uploadInfo.status !== FOLDER_UPLOAD_STATUS.SYNCHRONIZING)) return

      const mappingUuid = data?.mappingInfo.uuid

      // 更新该照片库关联上传任务的状态
      function updateQueueStatus(uploadStatus: FOLDER_UPLOAD_STATUS) {
        dispatch.fileUpload.updateUploadTaskStatus({
          queueId, uploadStatus
        })
      }

      switch (mappingStatus.uploadStatus) {
        case MAPPING_UPLOAD_STATUS.FINISHED:
          dispatch.fileUpload.deleteSubscribeMappings(queueId)
          // 自动重建任务需要开启自动重建
          if (mappingStatus.auto) {
            try {
              const validateResult = await validateMappingCanRebuild({
                uuid: mappingUuid,
                jobType: data?.mappingInfo.jobType,
                // 仅 ndvi重建，只需要一个光谱类型
                indexList: data?.mappingInfo.forNDVI ? [Index.Ndvi] : [Index.Ndvi, Index.Gndvi, Index.Lci, Index.Ndre, Index.Osavi]
              })
              if (!validateResult.isValidJpgCount) {
                // 如果照片库数量不足，那此次自动重建也将会关闭
                await updateMapping({ uuid: mappingUuid, auto: false })
                updateQueueStatus(FOLDER_UPLOAD_STATUS.NOT_ENOUGH_PHOTOS)
              } else if (!validateResult.isValidIndexResult) {
                // 如果指数校验不合规，那此次自动重建也将会关闭
                await updateMapping({ uuid: mappingUuid, auto: false })
                updateQueueStatus(FOLDER_UPLOAD_STATUS.AUTO_REBUILD_FAIL)
              } else {
                // 校验成功，那就开始重建
                await startAutoProcessingJob({ mappingUuid })
                updateQueueStatus(FOLDER_UPLOAD_STATUS.SUCCESS)
              }
            } catch (error) {
              // 此次自动重建也将会关闭
              await updateMapping({ uuid: mappingUuid, auto: false })
              updateQueueStatus(FOLDER_UPLOAD_STATUS.AUTO_REBUILD_FAIL)
            }
          } else {
            // 非自动重建任务，就变成最终的 SUCCESS 状态
            updateQueueStatus(FOLDER_UPLOAD_STATUS.SUCCESS)
          }
          break
        case MAPPING_UPLOAD_STATUS.FAILED:
          dispatch.fileUpload.deleteSubscribeMappings(queueId)
          // 照片库上传完成后，更新其上传状态
          updateQueueStatus(FOLDER_UPLOAD_STATUS.FEEDBACK)
          break
        default:
          break
      }
    }
  })
}
