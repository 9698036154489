/**
 * @file 重建任务相关接口
 * 根据不同的来源做一些适配
 * @author lian.duan 2023-10-26
 */
import {
  queryCommonJobFile,
  queryEditProcessingJobInfo,
  queryProcessingJobs,
  updateProcessingJob,
  deleteProcessingJob,
  queryProcessingJob
} from '@/services/apis/rebuild'
import {
  queryRcCommonJobFile,
  queryEditRcProcessingJobInfo,
  queryRcProcessingJobs,
  updateRcProcessingJob,
  deleteRcProcessingJob,
  queryRcProcessingJobDetail
} from '@/services/apis/remoteController'
import { REBUILD_SOURCE } from '@/constants/common'
import {
  QueryRcCommonJobFileQuery,
  QueryCommonJobFileQuery,
  QueryRcCommonJobFileQueryVariables,
  QueryCommonJobFileQueryVariables,
  QueryRebuildDetailsByProcessingJobUuidQuery,
  QueryRcProcessingJobDetailQuery
} from '@/types/graphql-types.d'
import ProcessingJobsFilterModel from './processingJobsFilter'
import { page2FirstAfter } from '@/utils/toolMethod'
import { parseProcessingJobParams, queryBoundsFromProcessingJobResultObject } from '@/utils/rebuild'
import { queryJobInfoFromProcessingJobResult } from '@/pages/Media/Rebuild/Details/utils'
import { PHOTO_TYPES } from '@/types/photo.d'
import { ProcessingJobsQuery, ProcessingJobEditData } from './types'

/**
 * 查询重建任务的文件下载地址
 * @param query 查询参数
 * @param source 来源
 * @returns 下载地址
 */
export function fetchProcessingJobFileUrs(
  query: QueryRcCommonJobFileQueryVariables | QueryCommonJobFileQueryVariables,
  source: REBUILD_SOURCE
): Promise<QueryRcCommonJobFileQuery['remoteControllerProcessingJob'] | QueryCommonJobFileQuery['processingJob']> {
  switch (source) {
    case REBUILD_SOURCE.REMOTE_CONTROL:
    case REBUILD_SOURCE.TERRA_PC:
      return queryRcCommonJobFile(query).then((res) => res.remoteControllerProcessingJob)
    case REBUILD_SOURCE.SMART_FARM:
    default:
      return queryCommonJobFile(query).then((res) => res.processingJob)
  }
}

/**
 * 拉取编辑框的重建信息
 */
export function fetchEditProcessingJobInfo(uuid: string, source: REBUILD_SOURCE) {
  switch (source) {
    case REBUILD_SOURCE.REMOTE_CONTROL:
    case REBUILD_SOURCE.TERRA_PC:
      return queryEditRcProcessingJobInfo({ uuid }).then((res) => ({
        uuid,
        name: res?.remoteControllerProcessingJob?.name || '',
        tagUuids: [],
        source
      }))
    case REBUILD_SOURCE.SMART_FARM:
    default:
      return queryEditProcessingJobInfo({ uuid }).then((res) => ({
        uuid,
        name: res?.processingJob?.name || '',
        tagUuids: res?.processingJob?.tags?.nodes?.map((item) => item?.uuid) as string[] || [],
        source: REBUILD_SOURCE.SMART_FARM,
        mappingName: res.processingJob?.Mapping?.name || ''
      }))
  }
}

/**
 * 拉取重建任务列表
 */
export function fetchProcessingJobs(query: ProcessingJobsQuery) {
  const processingJobFilter = new ProcessingJobsFilterModel(query.searchParams)
  switch (query.source) {
    case REBUILD_SOURCE.REMOTE_CONTROL:
    case REBUILD_SOURCE.TERRA_PC:
      return queryRcProcessingJobs({
        filter: processingJobFilter.rcProcessingJobsGqlFilter,
        ...page2FirstAfter(query.pageNumber, query.pageSize)
      }).then((res) => res.remoteControllerProcessingJobs)
    case REBUILD_SOURCE.SMART_FARM:
    default:
      return queryProcessingJobs({
        filter: processingJobFilter.processingJobsGqlFilter,
        ...page2FirstAfter(query.pageNumber, query.pageSize)
      }).then((res) => res.processingJobs)
  }
}

/**
 * 更新重建任务
 */
export function updateProcessingJobInfo(data: ProcessingJobEditData) {
  const { uuid, name, tagUuids, source } = data
  switch (source) {
    case REBUILD_SOURCE.REMOTE_CONTROL:
    case REBUILD_SOURCE.TERRA_PC:
      return updateRcProcessingJob({
        uuid,
        name
      }).then(() => ({
        tags: []
      }))
    case REBUILD_SOURCE.SMART_FARM:
    default:
      return updateProcessingJob({
        uuid,
        name,
        tagUuids
      }).then((res) => ({
        tags: res.updateProcessingJob?.processingJob?.tags?.nodes
      }))
  }
}

/**
 * 删除重建任务
 */
export function deleteRebuildTask(uuid: string, source: REBUILD_SOURCE) {
  switch (source) {
    case REBUILD_SOURCE.REMOTE_CONTROL:
    case REBUILD_SOURCE.TERRA_PC:
      return deleteRcProcessingJob({ uuid })
    case REBUILD_SOURCE.SMART_FARM:
    default:
      return deleteProcessingJob({ uuid })
  }
}

/**
 * 格式化重建任务详情数据
 */
function formatCommonProcessingJobDetail(data: QueryRebuildDetailsByProcessingJobUuidQuery['processingJob'] | QueryRcProcessingJobDetailQuery['remoteControllerProcessingJob']) {
  return {
    type: data?.type || PHOTO_TYPES.FARM,
    bounds: queryBoundsFromProcessingJobResultObject(data),
    xyzTileFormat: data?.xyzTileFormat || '',
    jobInfo: queryJobInfoFromProcessingJobResult(data)
  }
}

/**
 * 拉取重建任务详情
 */
export function fetchProcessingJobDetail(uuid: string, source: REBUILD_SOURCE) {
  switch (source) {
    case REBUILD_SOURCE.REMOTE_CONTROL:
    case REBUILD_SOURCE.TERRA_PC:
      return queryRcProcessingJobDetail({ uuid }).then((res) => {
        return {
          ...formatCommonProcessingJobDetail(res.remoteControllerProcessingJob),
          // 遥控器目前只有农田和果树模式的数据，智图只有果树模式，不需要解析indexList数据
          indexList: [],
          maximumLevel: res.remoteControllerProcessingJob?.maximumLevel
        }
      })
    case REBUILD_SOURCE.SMART_FARM:
    default:
      return queryProcessingJob({ uuid }).then((res) => {
        return {
          ...formatCommonProcessingJobDetail(res.processingJob),
          indexList: parseProcessingJobParams(res.processingJob?.params || '').index_list,
          maximumLevel: res.processingJob?.mapReport?.MapTile.MaximumLevel
        }
      })
  }
}