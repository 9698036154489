/**
 * @file 重建图查询表单数据
 * @author xinqiang.wei 2022/8/2
 */

import { IRootState } from '@/store'
import { INIT_PAGE, INIT_PAGESIZE } from '@/constants/common'
import { initForm } from '@/pages/Media/Rebuild/FilterPanel'
import { ISearchForm } from '@/pages/Media/Rebuild/types.d'

// 表格查询参数
interface IRebuildFormState {
  pageSize: number;
  pageNumber: number;
  params: ISearchForm;
}

const initState: IRebuildFormState = {
  pageSize: INIT_PAGESIZE,
  pageNumber: INIT_PAGE,
  // 存储当前查询的参数,需要点击查询才会把form表单里面的输入数据同步到params中
  params: {
    ...initForm
  }
}

export default {
  state: initState,
  reducers: {
    // 设置查询条件
    setParams(prevState: IRootState, payload) {
      return {
        ...prevState,
        params: { ...payload.params, ...payload }
      }
    },

    // 重新设置查询条件
    resetParams(prevState: IRootState) {
      return {
        ...prevState,
        params: { ...initForm }
      }
    },

    // 更新当前表格分页数量
    updatePageSize(prevState, payload) {
      prevState.pageSize = payload
    },
    // 更新当前表格页码
    updatePageNumber(prevState, payload) {
      prevState.pageNumber = payload
    }
  }
}
