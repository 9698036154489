/**
 * @file
 * @author xinqiang.wei 2022-07-01
 */
import { IMediaState } from '@/pages/Media/store'
import { INIT_PAGE, INIT_PAGESIZE } from '@/constants/common'


interface IFormBase {
  name?: string;
  startTime?: number;
  endTime?: number;
  // 所属组织
  departmentUuids?: string;
  pageNumber?: number;
  pageSize?: number;
}

export const PHOTO_TYPES_ALL = -999


export interface IFormOptions extends IFormBase {
  // 可以传多个 0代表农田模式 1代表城市模式（即大田模式） 2代表果树模式 4代表多光谱模式
  // 确定类型后修改
  type?: number;
}

export interface IFormParams extends IFormBase {
  // 可以传多个 0代表农田模式 1代表城市模式（即大田模式） 2代表果树模式 4代表多光谱模式
  // 确定类型后修改
  type?: number[];
}

export const initForm: IFormOptions = {
  name: '',
  // 可以传多个 0代表农田模式 1代表城市模式（即大田模式） 2代表果树模式 4代表多光谱模式
  type: PHOTO_TYPES_ALL,
  startTime: undefined,
  endTime: undefined,
  // 所属组织
  departmentUuids: ''
}

export default {
  state: {
    pageSize: INIT_PAGESIZE,
    pageNumber: INIT_PAGE,
    // 存储当前查询的参数,需要点击查询才会把form表单里面的输入数据同步到params中
    params: {}
  },
  reducers: {
    // 设置查询条件
    setParams(prevState: IMediaState, payload) {
      return {
        ...prevState,
        params: { ...initForm, ...payload }
      }
    },

    // 更新当前表格分页数量
    updatePageSize(prevState, payload) {
      prevState.pageSize = payload
    },
    // 更新当前表格页码
    updatePageNumber(prevState, payload) {
      prevState.pageNumber = payload
    }
  }
}