/**
 * @file 重建模块接口
 * @author lian.duan 2022-08-05
 */
import graphqlRequest from '@/services/graphqlRequest'

import validStartProcessingJobGql from './gql/validStartProcessingJob.gql'
import queryRebuildIndexListGql from './gql/queryRebuildIndexList.gql'
import createProcessingJobGql from './gql/createProcessingJob.gql'
import startProcessingJobGql from './gql/startProcessingJob.gql'
import queryCommonJobFileGql from './gql/queryCommonJobFile.gql'
import deleteProcessingJobGql from './gql/deleteProcessingJob.gql'
import updateProcessingJobGql from './gql/updateProcessingJob.gql'
import queryRebuildDetailsByProcessingJobUuid from './gql/queryRebuildDetailsByProcessingJobUuid.gql'
import queryProcessingJobsGql from './gql/queryProcessingJobs.gql'
import queryEditProcessingJobInfoGql from './gql/queryEditProcessingJobInfo.gql'
import querySelectMappingListGql from './gql/querySelectMappingList.gql'
import queryProcessingPhotoListGql from './gql/queryProcessingPhotoList.gql'
import queryMappingJpgCountGql from './gql/queryMappingJpgCount.gql'

import {
  ValidStartProcessingJobQueryVariables,
  ValidStartProcessingJobQuery,
  CreateProcessingJobMutationVariables,
  CreateProcessingJobMutation,
  StartProcessingJobMutationVariables,
  QueryCommonJobFileQueryVariables,
  QueryCommonJobFileQuery,
  QueryRebuildIndexListQueryVariables,
  QueryRebuildIndexListQuery,
  StartProcessingJobMutation,
  QueryProcessingJobsQueryVariables,
  QueryProcessingJobsQuery,
  DeleteProcessingJobMutationVariables,
  DeleteProcessingJobMutation,
  UpdateProcessingJobMutationVariables,
  UpdateProcessingJobMutation,
  QuerySelectMappingListQueryVariables,
  QuerySelectMappingListQuery,
  QueryRebuildDetailsByProcessingJobUuidQueryVariables,
  QueryRebuildDetailsByProcessingJobUuidQuery,
  QueryEditProcessingJobInfoQueryVariables,
  QueryEditProcessingJobInfoQuery,
  QueryProcessingPhotoListQuery,
  QueryProcessingPhotoListQueryVariables,
  QueryMappingJpgCountQueryVariables,
  QueryMappingJpgCountQuery
} from '@/types/graphql-types.d'

// 获取重建任务列表

export function queryProcessingJobs(variables: QueryProcessingJobsQueryVariables) {
  return graphqlRequest<QueryProcessingJobsQuery, QueryProcessingJobsQueryVariables>(queryProcessingJobsGql, variables)
}

// 校验 是否可以进行重建
export function validStartProcessingJob(variables: ValidStartProcessingJobQueryVariables) {
  return graphqlRequest<ValidStartProcessingJobQuery, ValidStartProcessingJobQueryVariables>(validStartProcessingJobGql, variables)
}

// 该照片库允许重建的多光谱indexlist
export function queryRebuildIndexList(variables: QueryRebuildIndexListQueryVariables) {
  return graphqlRequest<QueryRebuildIndexListQuery, QueryRebuildIndexListQueryVariables>(queryRebuildIndexListGql, variables)
}

// 创建 重建任务
export function createProcessingJob(variables: CreateProcessingJobMutationVariables) {
  return graphqlRequest<CreateProcessingJobMutation, CreateProcessingJobMutationVariables>(createProcessingJobGql, variables)
}

// 开启 重建任务
export function startProcessingJob(variables: StartProcessingJobMutationVariables) {
  return graphqlRequest<StartProcessingJobMutation, StartProcessingJobMutationVariables>(startProcessingJobGql, variables)
}


// 获取下载文件状态
export function queryCommonJobFile(variables: QueryCommonJobFileQueryVariables) {
  return graphqlRequest<QueryCommonJobFileQuery, QueryCommonJobFileQueryVariables>(queryCommonJobFileGql, variables)
}

// 删除重建任务
export function deleteProcessingJob(variables: DeleteProcessingJobMutationVariables) {
  return graphqlRequest<DeleteProcessingJobMutation, DeleteProcessingJobMutationVariables>(deleteProcessingJobGql, variables)
}

// 更新重建任务
export function updateProcessingJob(variables: UpdateProcessingJobMutationVariables) {
  return graphqlRequest<UpdateProcessingJobMutation, UpdateProcessingJobMutationVariables>(updateProcessingJobGql, variables)
}

// 选择照片库素材列表
export function querySelectMappingList(variables: QuerySelectMappingListQueryVariables) {
  return graphqlRequest<QuerySelectMappingListQuery, QuerySelectMappingListQueryVariables>(querySelectMappingListGql, variables)
}

// 获取重建任务
export function queryProcessingJob(variables: QueryRebuildDetailsByProcessingJobUuidQueryVariables) {
  return graphqlRequest<QueryRebuildDetailsByProcessingJobUuidQuery, QueryRebuildDetailsByProcessingJobUuidQueryVariables>(queryRebuildDetailsByProcessingJobUuid, variables)
}

// 获取重建任务
export function queryEditProcessingJobInfo(variables: QueryEditProcessingJobInfoQueryVariables) {
  return graphqlRequest<QueryEditProcessingJobInfoQuery, QueryEditProcessingJobInfoQueryVariables>(queryEditProcessingJobInfoGql, variables)
}

// 获取单个重建任务下对应的照片列表
export function queryProcessingJobPhotoLIst(variables: QueryProcessingPhotoListQueryVariables) {
  return graphqlRequest<QueryProcessingPhotoListQuery, QueryProcessingPhotoListQueryVariables>(queryProcessingPhotoListGql, variables)
}

// 获取 照片库下jpg的数量
export function queryMappingJpgCount(variables: QueryMappingJpgCountQueryVariables) {
  return graphqlRequest<QueryMappingJpgCountQuery, QueryMappingJpgCountQueryVariables>(queryMappingJpgCountGql, variables)
}
