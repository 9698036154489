/**
 * @file 模块异常类型定义
 * @author aron.yao 2022-02-06
 */

import {
  TYPES,
  RESOURCES,
  MODULES
} from './types'
import { getErrorName } from './typeError'
import { BasicResourceError } from './resourceError'

// 在资源异常的基础上增加模块信息，扩展出模块异常
export class BasicModuleError extends BasicResourceError {
  constructor(
    public readonly module: MODULES,
    resource: RESOURCES,
    type: TYPES,
    originalError?: Error,
    message?: string
  ) {
    super(resource, type, originalError, message)
    this.name = getErrorName(type, resource, module)
  }
}

/** 登录模块的异常类型 **/
export class LoginModuleError extends BasicModuleError {
  constructor(
    resource: RESOURCES,
    type: TYPES,
    originalError?: Error,
    message?: string
  ) {
    super(MODULES.SESSION, resource, type, originalError, message)
    this.name = getErrorName(type, resource, MODULES.SESSION)
  }
}

/** 测产分析的异常类型 **/
export class CropEstimationModuleError extends BasicModuleError {
  constructor(
    resource: RESOURCES,
    type: TYPES,
    originalError?: Error,
    message?: string
  ) {
    super(MODULES.CROP_ESTIMATION, resource, type, originalError, message)
    this.name = getErrorName(type, resource, MODULES.CROP_ESTIMATION)
  }
}

/** 变量作业地块编辑的异常类型 **/
export class ProcessingJobLandModuleError extends BasicModuleError {
  constructor(
    resource: RESOURCES,
    type: TYPES,
    originalError?: Error,
    message?: string
  ) {
    super(MODULES.PROCESSING_JOB_LAND, resource, type, originalError, message)
    this.name = getErrorName(type, resource, MODULES.PROCESSING_JOB_LAND)
  }
}
