/**
 * @file 媒体库 首页
 * @author lian.duan 2022-06-17
 */
import { FC, useState, useEffect, lazy, Suspense } from 'react'
import Tabs from '@/components/Tabs'
import style from './index.module.less'
import { Icon } from '@/components/Icon'
import { useLocation } from 'ice'
import pageStore from '@/pages/Media/store'
import classNames from 'classnames'
import { ROUTE_PATHS } from '@/constants/routes'
import { t } from 'i18next'

enum TAB_KEY {
  PHOTO = 'photo',
  REBUILD = 'rebuild'
}
const UploadCard = lazy(() => import('./Photo/UploadCard'))

const MediaPage: FC = ({ children }) => {
  const tabOptions = [
    {
      key: TAB_KEY.PHOTO,
      name: t('pages.media.header.photo.title')
    },
    {
      key: TAB_KEY.REBUILD,
      name: t('pages.media.header.rebuild.title')
    }
  ]
  const location = useLocation()
  const [isShowUploadPanel, setIsShowUploadPanel] = useState<boolean>(false)
  const [uploadState] = pageStore.useModel('fileUpload')

  useEffect(() => {
    if (uploadState.UploadTasks.length > 0) {
      setIsShowUploadPanel(true)
    }
  }, [uploadState.UploadTasks.length])

  useEffect(() => {
    setIsShowUploadPanel(false)
  }, [location.pathname])

  const toggleShowUploadPanel = () => {
    setIsShowUploadPanel(!isShowUploadPanel)
  }

  const renderTabBarRightContent = () => {
    const isPhotoRoute = location.pathname === ROUTE_PATHS.MEDIA_PHOTO_PATH
    return (
      <Suspense fallback={<></>}>
        <span
          onClick={toggleShowUploadPanel}
          className={classNames({
            [style['upload-content-hide']]: !isPhotoRoute
          })}
        >
          <Icon
            className={style['upload-cloud']}
            size='huge'
            iconName='upload-cloud'
          />
        </span>
        <UploadCard show={isShowUploadPanel}/>
      </Suspense>
    )
  }

  return (
    <div className={style['page-card']}>
      <Tabs
        title={t('pages.media.header.page.title')}
        baseUrl={'/media'}
        tabOptions={tabOptions}
        rightContent={renderTabBarRightContent()}
      />
      {children}
    </div>
  )
}

export default MediaPage
