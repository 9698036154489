/**
 * @file 个人版
 * @author lian.duan 2024-02-20
 */

export enum APPLY_STATUS {
  /** 待处理 */
  PROCESSING = 0,
  /** 通过 */
  SUCCESS = 1,
  /** 拒绝 */
  REJECT = 2
}