/**
 * @file cookie reference组件
 * @author aron.yao
 *
 * 文档：https://code.djicorp.com/fe/scio
 */

import Scio from '@dji/scio'
import { AgrasLang } from 'agras-i18n-utils'
import { currentLocaleConfig } from '@/i18n'
import './style.less'

import { ICE_MODES } from '@/constants/modes'
import { scioWhitelistRegions } from '@/constants/region'

export default class CookieRef {
  static lang() {
    switch (currentLocaleConfig.lang) {
      case 'es':
        return 'es'
      case 'pt':
      /** 暂时不支持葡萄牙语，使用英文代替 */
      case 'en':
      case 'ja':
      case 'zh':
      default:
        return 'en'
    }
  }

  static env() {
    switch (ICE_MODE) {
      case ICE_MODES.TEST:
        return 'TEST'
      case ICE_MODES.PROD:
        return 'PROD'
      case ICE_MODES.DEV:
      case ICE_MODES.LOCAL:
      default:
        return 'DEV'
    }
  }

  static domain() {
    return (ICE_MODE === ICE_MODES.PROD) ? '.djiag.com' : '.dbeta.me'
  }

  /** 语言非法时的回退的语言 */
  static fallbackLng: AgrasLang = 'en'

  static init() {
    return new Scio({
      delay: 1000,
      language: CookieRef.lang(),
      fallbackLng: CookieRef.fallbackLng,
      country: currentLocaleConfig.region,
      /** scio中需要新增的国家, scio支持的国家地区见 src/options/country.js */
      extraCountry: scioWhitelistRegions,
      appId: __PAMS_ID__,
      environment: CookieRef.env(),
      services: {
        statistics: [
          // 初始化脚本地址
          /retcode\.alicdn\.com/,
          // google脚本
          /www\.googletagmanager\.com/
        ]
      },
      cookies: {
        statistics: [
          { name: '_ga', domain: CookieRef.domain() },
          { name: '_gid', domain: CookieRef.domain() },
          { name: /^_ga_.*/, domain: CookieRef.domain() }
        ]
      }
    })
  }
}
