/**
 * @file 全局store
 * @author lian.duan 2022-07-09
 */
import {
  IStoreDispatch,
  IStoreModels,
  IStoreRootState,
  createStore
} from 'ice'

import profile from '@/models/profile'
import config from '@/models/config'
import rebuildForm from '@/models/media/rebuild/rebuildForm'
import rebuildTable from '@/models/media/rebuild/rebuildTable'
import system from '@/models/system'
import departmentTree from '@/models/departmentTree'
import deviceTypes from '@/models/deviceTypes'

interface IAppStoreModels extends IStoreModels {
  profile: typeof profile;
  config: typeof config;
  rebuildForm: typeof rebuildForm;
  rebuildTable: typeof rebuildTable;
  system: typeof system;
  departmentTree: typeof departmentTree;
  deviceTypes: typeof deviceTypes;
}

const appModels: IAppStoreModels = {
  profile,
  config,
  rebuildForm,
  rebuildTable,
  system,
  departmentTree,
  deviceTypes
}

const store = createStore(appModels)

export default store

export type IRootDispatch = IStoreDispatch<typeof appModels>;
export type IRootState = IStoreRootState<typeof appModels>;
