/**
 * @file 隐私协议和用户协议
 * @author lian.duan 2023-04-26
 */

import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import Terms from '@/helpers/terms'
import cn from 'classnames'
import { currentLocaleConfig } from '@/i18n'
import styles from './index.module.less'

const TermsLink: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()

  // 非个人版不显示隐私条款
  if (!IS_PERSONAL_VERSION) {
    return null
  }

  return (
    <Fragment>
      <a
        className={cn(styles['link'], className)}
        href={Terms.privacyPolicy}
        target='_blank'
        rel='noreferrer'
      >
        {t('pages.personalLogin.loginCard.privacyPolicy')}
      </a>
      <a
        className={cn(styles['link'], className)}
        href={Terms.userTerms}
        target='_blank'
        rel='noreferrer'
      >
        {t('pages.personalLogin.loginCard.useTerms')}
      </a>
      {
        currentLocaleConfig.inWhitelist ?
          (
            <a
              className={cn(styles['link'], className, 'cc-consent-prefernces')}
            >
              {t('pages.personalLogin.loginCard.cookiePreference')}
            </a>
          )
          : null
      }
    </Fragment>
  )
}

export default TermsLink
