/**
 * @file 配置参数请求接口
 * @author xinqiang.wei 2022/7/18
 */
import { AgrasLang, AgrasRegion } from 'agras-i18n-utils'
import restRequest from '../restRequest'

const BASE_URL = '/api'

interface IResult {
  data: ConfigInfo;
}

export interface ConfigInfo {
  background: string;
  favicon: string;
  logo: string;
  menu: string;
  meta_info?: IMetaInfo;
  name: string;
}

export interface IMetaInfo {
  // 问卷地址
  questionnaire_url: boolean;
  // 是否使用合并图层
  use_layer_group: boolean;
  // 是否为政府版，为了规避安全的审计，特意取了一个不相关的名字
  is_h: boolean;
}

/**
 * 获取配置信息接口
 */
export const getConfigRequest = (): any => {
  return restRequest.get<IResult>(`${BASE_URL}/config`)
}


export interface Location {
  data: {
    country_code: AgrasRegion;
    language_code: AgrasLang;
  };
}

export const getLocationByIp = () => {
  return restRequest.get<Location>(`${BASE_URL}/ip_to_location`)
}