/**
 * @file 基于axios的基本请求模块
 * 支持统一的错误拦截，取消逻辑
 * @author aron.yao 2022-06-28
 */
import { throttle } from 'lodash-es'
import { message } from 'antd'
import qs from 'qs'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { v4 } from 'uuid'
import { isGoogleErrorFormat } from './googleError'

// 覆盖axios的方法定义，支持返回可取消的请求
declare module 'axios' {
  interface Axios {
    get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): RevokablePromise<R>;
    delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): RevokablePromise<R>;
    post<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig): RevokablePromise<R>;
    put<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig): RevokablePromise<R>;
    patch<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig): RevokablePromise<R>;
  }
}

// 后端接口的超时时间
const BACKEND_API_TIMEOUT = 60000
// 网络错误时的提示间隔
const ERROR_MSG_THROTTLE_INTERVAL = 1000

const instance = axios.create({
  baseURL: '',
  // 这里的超时时间，要尽量和后端保持一致
  timeout: BACKEND_API_TIMEOUT,
  headers: {
    'Accept-Language': 'zh-CN,zh',
    'Content-Type': 'application/json',
    'x-new-version': 'true'
  },
  transformRequest: [
    function (data, headers) {
      /**
       * 用户实现tracing，后端可根据该请求头追踪前后端的完整链路
       */
      if (headers) {
        headers['x-request-id'] = v4()
      }
      return JSON.stringify(data)
    }
  ]
})

/**
 * NOTICE: 暂时先关掉，目前后端给的信息不足
 */
const ifOpenGlobalNonBusinessError = false

// 是否为网络错误或者服务端崩溃异常，非业务报错
const toastNonBusinessError = throttle((error: AxiosError) => {
  if (!ifOpenGlobalNonBusinessError) {
    return
  }
  if (error.message === 'Network Error' && !error.response) {
    message.error('网络异常')
  }
  if (
    error.response
    && error.response.status === 500
    && error.response.data
    && !isGoogleErrorFormat(error.response.data)
  ) {
    message.error('服务器异常')
  }
}, ERROR_MSG_THROTTLE_INTERVAL)

// 统一拦截
instance.interceptors.response.use(
  undefined,
  (error) => {
    /**
     * 针对服务端异常问题统一报错，即非业务报错做统一拦截
     * NOTICE: 暂时先关掉，目前后端给的信息不足
     */
    toastNonBusinessError(error)
    return Promise.reject(error)
  }
)

export interface AxiosInstRequestConfig extends AxiosRequestConfig {
  // 查询参数的对象
  query?: any;
}

/**
 * 生成url的查询字符串
 *
 * @param query 查询对象
 * @returns url的查询字符串
 */
function composeUrl(url: string, query?: any) {
  return `${url}${query ? `?${qs.stringify(query)}` : ''}`
}

// promise初始化方法的类型
type promiseExecutor<T> = (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void

// 可取消的异步对象
export class RevokablePromise<T> extends Promise<T> {
  cancel: () => void

  constructor(promiseConstructor: promiseExecutor<T>, cancelFunc: () => void) {
    super(promiseConstructor)

    this.cancel = cancelFunc
  }
}

// 装饰器的请求方法类型
interface decoratorRequestMethod<T> {
  (config: AxiosInstRequestConfig): RevokablePromise<AxiosResponse<T>>;
}

/**
 * 请求取消的统一包装方法
 *
 * @param request
 * @param config
 * @returns
 */
function wrapCancel<T>(request: decoratorRequestMethod<T>, config?: AxiosInstRequestConfig) {
  const controller = new AbortController()
  const realConfig = config ? {
    signal: controller.signal,
    ...config
  } : {
    signal: controller.signal
  }

  const result = request(realConfig)

  result.cancel = function () {
    if (config && config.signal) {
      console.warn('已经指定了signal，无法内部取消')
    } else {
      // 外部没有传signal时，调用内部的abort方法
      controller.abort()
    }
  }

  return result
}

function wrapCancelForGet<T>(url: string, config?: AxiosInstRequestConfig): RevokablePromise<AxiosResponse<T>> {
  return wrapCancel((realConfig) => instance.get<T>(url, realConfig), config)
}

function wrapCancelForPost<T>(url: string, data?: any, config?: AxiosInstRequestConfig): RevokablePromise<AxiosResponse<T>> {
  return wrapCancel((realConfig) => instance.post<T>(url, data, realConfig), config)
}

export default {
  get<T>(url: string, config?: AxiosInstRequestConfig): RevokablePromise<AxiosResponse<T>> {
    return wrapCancelForGet<T>(composeUrl(url, config && config.query), config)
  },
  post<T>(url: string, data?: any, config?: AxiosInstRequestConfig): RevokablePromise<AxiosResponse<T>> {
    return wrapCancelForPost<T>(composeUrl(url, config && config.query), data, config)
  }
}
