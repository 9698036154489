/**
 * @file ice定义mode，现在用作环境
 * @author aron.yao 2022-07-20
 */

export enum ICE_MODES {
  LOCAL = 'local',
  DEV = 'dev',
  TEST = 'test',
  PROD = 'prod'
}