/**
 * @file 可点击标签组件
 * @author aron.yao 2022-08-11
 */

import { FC } from 'react'
import './index.less'

interface AgLinkProps {
  onClick: () => void;
}

const AgLink: FC<AgLinkProps> = (props) => {
  const { onClick } = props

  return (
    <a className='ag-link' onClick={onClick}>
      { props.children }
    </a>
  )
}

export default AgLink