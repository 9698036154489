/**
 * @file config数据类型
 * @author xinqiang.wei 2022/7/18
 */
interface IConfig {
  // 农场名
  name: string;
  // 完整的logo
  logo: string;
  // 用于菜单收起后的小型logo
  menu: string;
  // 登录页的背景图
  background: string;
  favicon: string;
  // 天地图瓦片接口的key
  tianditu_key: string;
}

const config = {
  state: {
    // 农场名
    name: '',
    // 完整的logo
    logo: '',
    // 用于菜单收起后的小型logo
    menu: '',
    // 登录页的背景图
    background: '',
    favicon: '',
    tianditu_key: ''
  },
  // 定义改变该模型状态的纯函数
  reducers: {
    /**
     * @param prevState
     * @param payload
     */
    update(prevState, payload: IConfig) {
      return {
        ...prevState,
        ...payload
      }
    }
  }
}

export default config
