/**
  * @file 测产分析store
  * @author super.yang 2022-09-22
*/

import { FormType } from '@/businessComponents/FilterPanelInList/type'

interface ICropEstimationState {
  currentSelectedTaskUuid: string;
  // 正在重试中的测产任务列表
  cropRetryUuidsList: Set<string>;
  // 刷新当前照片点数据
  refreshPhotoLocationLayerFlag: boolean;
  // 是否要更新websocket订阅
  needUpdateSocket: boolean;
  ifShowPlotGuide: boolean;
  searchForm?: FormType;
}

export default {
  state: {
    currentSelectedTaskUuid: '',
    cropRetryUuidsList: new Set<string>(),
    refreshPhotoLocationLayerFlag: false,
    needUpdateSocket: true,
    // 是否显示添加地块的新手引导
    ifShowPlotGuide: false,
    searchForm: undefined
  },
  reducers: {
    // 改变当前所选中的任务uuid
    changeCurrentSelectedTaskUuid(prevState: ICropEstimationState, payload: string) {
      prevState.currentSelectedTaskUuid = payload
    },
    // 将正在测产中的任务加入到队列中
    addCropRetry(prevState: ICropEstimationState, uuid: string) {
      prevState.cropRetryUuidsList.add(uuid)
    },
    // 将测产成功或者失败的任务移出队列
    removeCropRetry(prevState: ICropEstimationState, uuid: string) {
      prevState.cropRetryUuidsList.delete(uuid)
    },
    // 清空测产任务队列
    clearCropRetry(prevState: ICropEstimationState) {
      prevState.cropRetryUuidsList.clear()
    },
    // 改变是否刷新照片点数据标志
    changeRefreshPhotoLocationLayerFlag(prevState: ICropEstimationState, visibility: boolean) {
      prevState.refreshPhotoLocationLayerFlag = visibility
    },
    changeNeedUpdateSocket(preState: ICropEstimationState, need: boolean) {
      preState.needUpdateSocket = need
    },
    // 改变新手引导页的可见性
    changePlotGuideVisibility(prevState: ICropEstimationState, payload: boolean) {
      prevState.ifShowPlotGuide = payload
    },
    updateSearchForm(prevState: ICropEstimationState, form: FormType) {
      prevState.searchForm = form
    }
  }
}