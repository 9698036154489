/**
 * @file 用户模块接口
 * @author aron.yao 2022-06-28
 */
import graphqlRequest from '@/services/graphqlRequest'

// 用户配置信息的
import userProfileGql from './gql/userProfile.gql'
import queryPilotPermissionsGql from './gql/queryPilotPermissions.gql'
import createPilotPermissionGql from './gql/createPilotPermission.gql'
import updatePilotPermissionGql from './gql/updatePilotPermission.gql'
import deletePilotPermissionGql from './gql/deletePilotPermission.gql'
import notifyPilotPermissionGql from './gql/notifyPilotPermission.gql'
import {
  UserProfileQuery,
  PilotPermissionsQuery,
  PilotPermissionsQueryVariables,
  CreatePilotPermissionMutation,
  CreatePilotPermissionMutationVariables,
  UpdatePilotPermissionMutation,
  UpdatePilotPermissionMutationVariables,
  DeletePilotPermissionMutation,
  DeletePilotPermissionMutationVariables,
  NotifyPilotPermissionMutation,
  NotifyPilotPermissionMutationVariables
} from '@/types/graphql-types.d'

// 获取用户信息
export function fetchUserProfile() {
  return graphqlRequest<UserProfileQuery>(userProfileGql)
}

/**
 * 获取 pilot 授权表
 * @return
 */
export function fetchPilotPermissions() {
  return graphqlRequest<PilotPermissionsQuery, PilotPermissionsQueryVariables>(queryPilotPermissionsGql).then((res) => {
    const nodes = res.permissions?.nodes || []
    return nodes as NonNullable<NonNullable<NonNullable<Required<PilotPermissionsQuery>['permissions']>['nodes'][number]>>[]
  }).catch((err) => {
    throw err
  })
}

/**
 * 添加新授权
 * @param data 新授权
 * @return
 */
export function createPilotPermission(data: CreatePilotPermissionMutationVariables) {
  return graphqlRequest<CreatePilotPermissionMutation, CreatePilotPermissionMutationVariables>(createPilotPermissionGql, data)
}

/**
 * 更新授权
 * @param data 已存在的授权
 * @return
 */
export function updatePilotPermission(data: UpdatePilotPermissionMutationVariables) {
  return graphqlRequest<UpdatePilotPermissionMutation, UpdatePilotPermissionMutationVariables>(updatePilotPermissionGql, data)
}

/**
 * 删除授权
 * @param data 待删除记录
 * @return
 */
export function deletePilotPermission(data: DeletePilotPermissionMutationVariables) {
  return graphqlRequest<DeletePilotPermissionMutation, DeletePilotPermissionMutationVariables>(deletePilotPermissionGql, data)
}

export function notifyPermission(data: NotifyPilotPermissionMutationVariables) {
  return graphqlRequest<NotifyPilotPermissionMutation, NotifyPilotPermissionMutationVariables>(notifyPilotPermissionGql, data)
}
