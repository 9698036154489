/**
 * @file 智农国际化相关的工具函数
 * @author super.yang 2023-4-6
*/

import { AgrasLang } from 'agras-i18n-utils'

/** 将语言映射为 antd以及用户中心支持的语言格式 */
export function getISOLocaleByLang(locale: AgrasLang) {
  switch (locale) {
    case 'zh':
      return 'zh_CN'
    case 'ja':
      return 'ja_JP'
    case 'pt':
      return 'pt_BR'
    case 'es':
      return 'es_ES'
    case 'en':
    default:
      return 'en_US'
  }
}

/** 按需加载dayjs需要的locale */
export function loadDayjsLocaleByLang(locale: AgrasLang) {
  switch (locale) {
    case 'zh':
      return import('dayjs/locale/zh-cn')
    case 'ja':
      return import('dayjs/locale/ja')
    case 'pt':
      return import('dayjs/locale/pt-br')
    case 'es':
      return import('dayjs/locale/es')
    case 'en':
    default:
      return import('dayjs/locale/en-au')
  }
}