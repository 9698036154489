/**
 * @file 个人版用户申请模块
 * @author aron.yao 2022-06-28
 */
import graphqlRequest from '@/services/graphqlRequest'

// 用户配置信息的
import applyInfoGql from './gql/applyInfo.gql'
import createApplyInfoGql from './gql/createApplyInfo.gql'
import {
  ApplyInfoQuery,
  ApplyInfoQueryVariables,
  CreateApplyInfoMutation,
  CreateApplyInfoMutationVariables
} from '@/types/graphql-types.d'

// 获取用户是否申请信息
export function fetchApplyInfo() {
  return graphqlRequest<ApplyInfoQuery, ApplyInfoQueryVariables>(applyInfoGql)
}

// 提交表单申请信息
export function createApplyInfo(variables: CreateApplyInfoMutationVariables) {
  return graphqlRequest<CreateApplyInfoMutation, CreateApplyInfoMutationVariables>(createApplyInfoGql, variables)
}
