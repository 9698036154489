import { PHOTO_TYPES } from '@/types/photo.d'

// 照片库信息
export interface IMappingInfo {
  uuid: string;
  name: string;
  jobType: PHOTO_TYPES;
  forNDVI: boolean;
  auto: boolean;
  notify: boolean;
}

// 文件夹上传信息
export interface IFolderUploadInfo {
  // 成功数
  successCount: number;
  // 失败数
  failCount: number;
  // 上传的总数
  totalCount: number;
  // 文件夹上传状态
  status: FOLDER_UPLOAD_STATUS;
  // 照片库的数量
  photosCount?: number;
  // 上传速率
  // uploadSpeed: number | undefined;
  // // 剩余上传时间
  // remainTime: number | undefined;
}

// 上传任务
export interface IUploadTaskInfo {
  // 上传任务的id，具有唯一性
  id: string;
  // 照片库信息
  mappingInfo: IMappingInfo;
  // 上传信息
  uploadInfo: IFolderUploadInfo;
  // 文件列表
  files: IFileInfo[];
}

// 文件信息
export interface IFileInfo {
  id: string;
  name: string;
  file: File;
  // 文件类型，jpg或者tif类型
  type: string;
  progress: number;
  status: FILE_UPLOAD_STATUS;
  errorMsg?: string;
}

// 上传的状态
export enum FILE_UPLOAD_STATUS {
  // 等待上传中
  PENDING = 'pending',
  // 上传成功
  SUCCESS = 'success',
  // 正在上传
  LOADING = 'loading',
  // 上传失败（文件格式错误，接口问题）
  FAIL = 'fail',
  // 停止上传
  STOP = 'stop',
  // 跳过上传
  SKIP = 'skip'
}

// 文件夹的上传状态
export enum FOLDER_UPLOAD_STATUS {
  // 等待上传中
  PENDING = 'pending',
  // 照片库最终成功的状态
  SUCCESS = 'success',
  // 正在上传
  LOADING = 'loading',
  // 上传失败
  UPLOAD_FAIL = 'uploadFail',
  // 上传成功
  // UPLOAD_SUCCESS = 'uploadSucess',
  // 停止上传
  STOP = 'stop',
  // 照片同步中
  SYNCHRONIZING = 'synchronizing',
  // 同步失败，反馈问题
  FEEDBACK = 'feedback',
  // 自动重建失败
  AUTO_REBUILD_FAIL = 'autoRebuildFail',
  // 照片数量不足，自动重建失败
  NOT_ENOUGH_PHOTOS = 'notEnoughPhotos'
}

// 照片库的上传状态（这是后端返回的）
export enum MAPPING_UPLOAD_STATUS {
  // 此时的照片库是没有照片的空状态
  PENDING = 'Pending',
  // 此时的照片库是有图片正在上传但是没传完
  UPLOADING = 'Uploading',
  // 此时的照片库有图片且图片已经全部传完
  FINISHED = 'Finished',
  // 此时的照片库存在上传或同步失败的照片
  FAILED = 'Failed'
}
