/**
 * @file 重建表格
 * @author xinqiang.wei 2022/8/2
 */

import { IProcessingJobsStatus, IRebuildTaskItem, ISearchForm, REBUILD_REASON_CODE, REBUILD_STATUS } from '@/pages/Media/Rebuild/types.d'
import { INIT_PAGE, INIT_PAGESIZE, SKELETON_NUMBER, REBUILD_SOURCE } from '@/constants/common'
import { IRootDispatch, IRootState } from '@/store'
import { mappingRebuildStatus } from '@/businessComponents/DownloadTree/status'
import { TASK_STATUS_ITEM } from '@/types/common.d'
import { IMediaState } from '@/pages/Media/store'
import { getInitTableData } from '@/utils/table'
import { IRebuildDataPayload } from '@/businessComponents/EditRebuild'
import { fetchProcessingJobs } from '@/services/processingJobs'

import { t } from 'i18next'

export interface ITableData {
  // 重建表格列表数据
  table: IRebuildTaskItem[];
  // 重建表格列表条数
  totalCount: number;
}

// 初始化表格数据
export const iniTableData: ITableData = {
  table: [],
  totalCount: 0
}

const getRebuildReason = (errCode: string) => {
  switch (errCode) {
    case REBUILD_REASON_CODE.LACK_OF_RESOURCE_137:
      return 'rebuildError.lackOfResource'
    case REBUILD_REASON_CODE.PARAMETER_ERROR_1:
    case REBUILD_REASON_CODE.PARAMETER_ERROR_1002:
      return 'rebuildError.parameterError'
    case REBUILD_REASON_CODE.UNDEROVERLAP_134:
      return 'rebuildError.underoverlap'
    case REBUILD_REASON_CODE.SERVER_EXCEPTION_139:
    case REBUILD_REASON_CODE.SERVER_EXCEPTION_3010:
      return 'rebuildError.serverException'
    default:
      return 'rebuildError.serverException'
  }
}

const START_STATUS = 0.01
const END_STATUS = 0.99

/**
 * 处理表格数据，主要是转换状态以及处理进度条
 * @param tableData
 */
const handlerTableData = (tableData: IRebuildTaskItem): IRebuildTaskItem => {
  if ([REBUILD_SOURCE.REMOTE_CONTROL, REBUILD_SOURCE.TERRA_PC].includes(tableData.source)) {
    /**
     * 兼容处理，遥控器/智图pc 过来的任务，默认就是重建成功的
     */
    tableData.rebuildStatus = REBUILD_STATUS.SUCCESS
  } else if (typeof tableData.status === 'number') {
    // 重建状态映射
    const { status, name } = mappingRebuildStatus(tableData.status)
    tableData.rebuildStatus = status
    tableData.rebuildStatusName = name
    tableData.rebuildReason = (status === REBUILD_STATUS.FAILED) ? t(getRebuildReason(tableData.errCode)) : ''

    if (status === REBUILD_STATUS.PENDING) {

      // 如果状态还是处理中，并且进度100%，手动重置为99%，与产品后台沟通这样做。
      // 任务完成后，后台还有部分收尾工作，故需要重置成99%
      if (tableData.percentRemote === 1) {
        tableData.percentRemote = END_STATUS
      }

      // 任务在准备中，进度0%，需要手动设置为0.01，展示剩余时间。
      if (tableData.percentRemote === 0) {
        tableData.percentRemote = START_STATUS
      }
    }
  }

  return tableData
}

export default {
  state: iniTableData,
  reducers: {
    // 更新表格数据
    updateTableData(prevState: IRootState, payload) {
      const nodes = payload?.nodes || []
      prevState.table = nodes.map((item) => {
        return handlerTableData({
          ...item,
          source: payload.source || REBUILD_SOURCE.SMART_FARM
        })
      })
    },
    // 更新表格数据数量
    updateTableDataCount(prevState: IRootState, payload: { totalCount: number }) {
      prevState.totalCount = payload.totalCount
    },

    // 更新表格某一条数据，用于更新进度条
    updateTimeRemaining(prevState: IRootState, payload: IProcessingJobsStatus[]) {

      // 将数组组装成map，key为uuid，value为table数据
      const updateTableItem = {}
      payload.forEach((i) => {
        updateTableItem[i.uuid] = {
          ...i
        }
      })

      prevState.table = prevState.table.map((item) => {
        if (updateTableItem[item.uuid]) {
          item = handlerTableData({ ...item, ...updateTableItem[item.uuid] })
        }
        // 重建状态映射
        // item.timeRemaining = getTimeRemaining(item.createdAt, item.percentRemote)
        return item
      })
    },

    // 点击重试，重置表格状态
    resetTaskPercent(prevState: IRootState, uuid: string) {
      prevState.table = prevState.table.map((item) => {
        if (item.uuid === uuid) {
          // 产品要求重置成1%，表示重新开始了
          item.percentRemote = START_STATUS
          // 重置状态成准备中
          item.status = TASK_STATUS_ITEM.PREPARING
          item = handlerTableData(item)
        }
        return item
      })
    },

    // 表格骨架屏数据
    resetTableData(prevState: IMediaState, payload: { pageSize: number }) {
      prevState.table = getInitTableData(payload.pageSize)
    },

    // 编辑之后，更新表格数据
    updateTableDataByEdit(prevState: IMediaState, payload: IRebuildDataPayload) {
      prevState.table.forEach((item) => {
        if (item.uuid === payload.uuid) {
          item.name = payload.name
        }
      })
    }
  },
  effects: (dispatch: IRootDispatch) => ({
    async getTableData(_params, rootState?: IRootState) {
      dispatch.rebuildTable.resetTableData({ pageSize: SKELETON_NUMBER })

      try {
        // 从rebuildForm取出请求参数
        const { pageNumber = INIT_PAGE, pageSize = INIT_PAGESIZE, params } = rootState?.rebuildForm || {}
        const searchParams = (params || {}) as ISearchForm

        const res = await fetchProcessingJobs({
          source: searchParams?.source,
          searchParams,
          pageNumber,
          pageSize
        })

        /**
         * 如果没有数据，也需要更新页面总数
         */
        const totalCount = res?.totalCount
        if ((totalCount && totalCount !== rootState?.rebuildTable.totalCount) || totalCount === 0) {
          dispatch.rebuildTable.updateTableDataCount({ totalCount })
        }
        // 更新表格数据
        dispatch.rebuildTable.updateTableData({ nodes: res?.nodes || [], source: searchParams?.source })

      } catch (error) {
        // 错误处理
        // 更新表格数据
        dispatch.rebuildTable.updateTableData([])
        dispatch.rebuildTable.updateTableDataCount({ totalCount: 0 })
      }
    }
  })
}
