/**
 * @file appProvider
 * @author Yangholmes 2023-09-07
 */

import { FC } from 'react'
import useCookieReference from './hooks/useCookieReference'
import appStore from '@/store'
import { useMount } from 'ahooks'
import { ConfigProvider } from 'antd'

import basicDepartmentTree from '@/services/apis/department/gql/basicDepartmentTree.gql'
import { queryDepartmentTree } from './services/apis/department'
import { Locale } from 'antd/lib/locale-provider'

export interface AppProviderProps {
  locale: Locale;
}

export const AppProvider: FC<AppProviderProps> = (props) => {
  const { locale, children } = props

  useCookieReference()

  const [userProfile] = appStore.useModel('profile')
  const [, departmentTreeDispatch] = appStore.useModel('departmentTree')

  useMount(() => {
    const maxDepartmentLevel = userProfile?.userProfile?.member?.organization?.maxDepartmentLevel || 0

    queryDepartmentTree(maxDepartmentLevel, basicDepartmentTree).then((res) => {
      departmentTreeDispatch.updateDepartment(res.departmentTree)
      departmentTreeDispatch.dataLoaded()
    })
  })

  return (
    // 文档：https://ant.design/components/config-provider-cn/
    <ConfigProvider
      // 移除按钮中 2 个汉字之间的空格
      autoInsertSpaceInButton={false}
      // 配置组件的语言
      locale={locale}
    >
      {children}
    </ConfigProvider>
  )
}

