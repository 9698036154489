/**
 * @file
 * @author Yangholmes 2022-07-28
 */

import { DepartmentTreeNode } from '.'

import { DepartmentNode, Maybe } from '@/types/graphql-types.d'

/**
 * 转换 department 数据，令其符合 antd.TreeSelect 组件数据规范
 * @param nodes
 * @returns
 */
export const genDepartmentData = (nodes: Maybe<Maybe<DepartmentNode>[]> = []): DepartmentTreeNode[] => {
  return (nodes || []).map((node) => {
    let children: DepartmentTreeNode[] | undefined = undefined
    if (!node) {
      return {
        uuid: '',
        name: '',
        id: ''
      }
    }
    if (node.children) {
      children = genDepartmentData(node.children)
    }
    return {
      uuid: node.department?.uuid || '',
      name: node.department?.name || '',
      id: node.department?.id || '',
      children
    }
  })
}
