/**
 * @file 重建相关的常量
 * @author lian.duan 2022-08-02
 */
import { QUALITY_LEVEL, MULTISPECTRAL_REBUILD_TYPE } from '@/types/rebuild.d'
import { photoTypeText, rebuildSource2Txt } from '@/services/filters'
import { PHOTO_TYPES } from '@/types/photo.d'
import { t } from 'i18next'
import { REBUILD_SOURCE } from '@/constants/common'

export const qualityLevelOptions = () => {
  return [
    {
      label: t('common.qualityLevelOptions.high'),
      value: QUALITY_LEVEL.HIGH
    }, {
      label: t('common.qualityLevelOptions.medium'),
      value: QUALITY_LEVEL.MEDIUM
    }, {
      label: t('common.qualityLevelOptions.low'),
      value: QUALITY_LEVEL.LOW
    }
  ]
}

// 重建类型
export const jobTypeOptions = () => {
  return [
    {
      label: photoTypeText(PHOTO_TYPES.FARM),
      value: PHOTO_TYPES.FARM
    },
    {
      label: photoTypeText(PHOTO_TYPES.FRUIT_TREE),
      value: PHOTO_TYPES.FRUIT_TREE
    },
    {
      label: photoTypeText(PHOTO_TYPES.MULTI_SPECTRAL),
      value: PHOTO_TYPES.MULTI_SPECTRAL
    }
  ]
}

// 重建指数map
export const indexListMap = [
  MULTISPECTRAL_REBUILD_TYPE.NDVI,
  MULTISPECTRAL_REBUILD_TYPE.GNDVI,
  MULTISPECTRAL_REBUILD_TYPE.NDRE,
  MULTISPECTRAL_REBUILD_TYPE.LCI,
  MULTISPECTRAL_REBUILD_TYPE.OSAVI
]

// 默认的重建照片库筛选类型,主要是用于筛选出可以用于重建的照片库
export const defaultFilterRebuildTypes = [
  PHOTO_TYPES.CITY,
  PHOTO_TYPES.FARM,
  PHOTO_TYPES.FRUIT_TREE,
  PHOTO_TYPES.MULTI_SPECTRAL
]

// 开启重建任务最少需要的jpg数量
export const MIN_REBUILD_JPG_COUNT = 6

// 重建来源选择器数据
export const rebuildSourceOptions = () => {
  return [
    {
      label: rebuildSource2Txt(REBUILD_SOURCE.SMART_FARM),
      value: REBUILD_SOURCE.SMART_FARM
    },
    {
      label: rebuildSource2Txt(REBUILD_SOURCE.REMOTE_CONTROL),
      value: REBUILD_SOURCE.REMOTE_CONTROL
    },
    {
      label: rebuildSource2Txt(REBUILD_SOURCE.TERRA_PC),
      value: REBUILD_SOURCE.TERRA_PC
    }
  ]
}