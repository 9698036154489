/**
 * @file 地图相关工具函数
 * @author jamie.zhan 2022-09-08
 */
import L from 'leaflet'

export interface MapRegion {
  bounds: L.LatLngBounds;
  zoomLevel: number;
}

// 后端常见的Bbox类型
export interface Bbox {
  downLeft?: {
    lat?: number | null;
    lng?: number | null;
  } | null;
  upperRight?: {
    lat?: number | null;
    lng?: number | null;
  } | null;
}

// 航线用的Bbox类型，ES引擎返回的原始数据类型
export interface EsBbox {
  topLeft?: {
    lat?: number | null;
    lng?: number | null;
  } | null;
  bottomRight?: {
    lat?: number | null;
    lng?: number | null;
  } | null;
}

// 后端bbox数据专场Leaflet的bounds类型
export function bbox2Bounds(bbox: Bbox) {
  const { downLeft, upperRight } = bbox || {}

  if (typeof downLeft?.lat === 'number'
    && typeof downLeft?.lng === 'number'
    && typeof upperRight?.lat === 'number'
    && typeof upperRight?.lng === 'number'
  ) {
    return new L.LatLngBounds(
      { lat: downLeft.lat, lng: downLeft.lng },
      { lat: upperRight.lat, lng: upperRight.lng }
    )
  }
  return undefined
}

// 后端es bbox数据转成Leaflet的bounds类型，用于飞行航线的bbox
export function esBbox2Bounds(bbox: EsBbox) {
  const { bottomRight, topLeft } = bbox || {}

  if (typeof bottomRight?.lat === 'number'
    && typeof bottomRight?.lng === 'number'
    && typeof topLeft?.lat === 'number'
    && typeof topLeft?.lng === 'number'
  ) {
    return new L.LatLngBounds(
      { lat: bottomRight.lat, lng: topLeft.lng },
      { lat: topLeft.lat, lng: bottomRight.lng }
    )
  }
  return undefined
}

// Leaflet的bounds类型转成后端bbox数据
export function bounds2Bbox(bounds: L.LatLngBounds) {
  return {
    downLeft: bounds.getSouthWest().wrap(),
    upperRight: bounds.getNorthEast().wrap()
  }
}

// Leaflet的bounds类型转成后端es bbox数据，用于飞行航线的bbox
export function bounds2EsBbox(bounds: L.LatLngBounds) {
  return {
    topLeft: bounds.getNorthWest().wrap(),
    bottomRight: bounds.getSouthEast().wrap()
  }
}