/**
 * @file 重建任务相关筛选接口
 * @author lian.duan 2023-10-24
 */

import { ISearchForm } from '@/pages/Media/Rebuild/types.d'
import { getEndSecond, getStartSecond } from '@/utils/toolMethod'
import { TYPE_ALL, REBUILD_SOURCE } from '@/constants/common'
import { REBUILD_TASK_STATUS } from '@/businessComponents/DownloadTree/status'
import { PHOTO_TYPES } from '@/types/photo.d'
import { ProcessingJobsFilter, RemoteControllerProcessingJobsFilter, JobType } from '@/types/graphql-types.d'

/**
* 将前端的重建类型转换为接口的查询字段
* @param jobType 重建类型
*/
export function formJobTypes2GqlFilter(jobType: PHOTO_TYPES) {
  if (jobType === PHOTO_TYPES.FARM) {
    // 如果是农田模式，需要传入 农田以及城市模式的类型，兼容历史数据
    return [PHOTO_TYPES.FARM, PHOTO_TYPES.CITY]
  }
  return [jobType]
}

/**
* 将前端的重建类型转换为wms瓦片的查询字段
* @param jobType 重建类型
*/
export function formJobTypes2WmsTileUrl(jobType: PHOTO_TYPES) {
  if (jobType === PHOTO_TYPES.FARM) {
    return `job_types[]=${PHOTO_TYPES.FARM}&job_types[]=${PHOTO_TYPES.CITY}`
  }
  return `job_types[]=${jobType}`
}

export default class ProcessingJobsFilterModel {
  constructor(private readonly searchForm: ISearchForm) { }

  get time() {
    if (this.searchForm.startTime && this.searchForm.endTime) {
      return {
        startTime: getStartSecond(this.searchForm.startTime),
        endTime: getEndSecond(this.searchForm.endTime)
      }
    }
    return {}
  }

  get name() {
    if (this.searchForm.name?.trim()) {
      return {
        name: this.searchForm?.name?.trim()
      }
    }
    return {}
  }

  get jobType() {
    if (this.searchForm.jobType !== TYPE_ALL.TYPE_ALL) {
      return {
        jobTypes: formJobTypes2GqlFilter(this.searchForm.jobType as PHOTO_TYPES)
      }
    }
    return {}
  }

  get statuses() {
    if (this.searchForm.status !== TYPE_ALL.TYPE_ALL) {
      return {
        statuses: REBUILD_TASK_STATUS[this.searchForm.status]
      }
    }
    return {}
  }

  get departmentUuids() {
    if (this.searchForm.departmentUuids) {
      return {
        departmentUuids: [this.searchForm.departmentUuids]
      }
    }
    return {}
  }

  get source() {
    switch (this.searchForm.source) {
      case REBUILD_SOURCE.REMOTE_CONTROL:
        return JobType.Agras
      case REBUILD_SOURCE.TERRA_PC:
        return JobType.Terra
      default:
        return undefined
    }
  }


  /**
   * 智农端的重建任务筛选
   */
  get processingJobsGqlFilter(): ProcessingJobsFilter {
    return {
      ...this.name,
      ...this.time,
      ...this.jobType,
      ...this.statuses,
      ...this.departmentUuids
    }
  }

  /**
   * 遥控器的重建任务筛选
   * 屏蔽status的筛选，因为遥控器过来的任务没有重建状态
   */
  get rcProcessingJobsGqlFilter(): RemoteControllerProcessingJobsFilter {
    return {
      ...this.name,
      ...this.time,
      ...this.jobType,
      jobType: this.source
    }
  }
}
