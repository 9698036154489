/**
  * @file 智农国际化相关的工具函数
  * @author super.yang 2023-2-23
  * 只可以用于v2及v3，因为这两个项目的域名一致，其他项目域名不一致无法读取cookie
*/

import Cookie from 'js-cookie'

// 智农支持的多语言类型
/**
  * 地区和语言的对应关系, 谷歌标准中不一致的语言码进行了标注
  *  中国大陆 - 简体中文 ----- CN -> zh （google: zh-CN）
  *   墨西哥 - 西语     ----- MX -> es
  *   巴西 - 葡语       ----- BR -> pt （google: pt-BR）
  *   智利 - 西语       ----- CL -> es
  *   美国 - 英语       ----- US -> en
  *   加拿大 - 英语     ----- CA -> en
  *   澳洲 - 英语       ----- AU -> en
  *   新西兰 - 英语     ----- NZ -> en
  *   日本 - 日语       ----- JP -> ja
  *   南非 - 英语       ----- ZA -> en
  *   其他 - 英语       ----- 'default' -> en
  *
  * @remark 参考标准：https://zh.wikipedia.org/wiki/ISO_639-1
*/
export const AgrasLangs = ['zh', 'ja', 'pt', 'es', 'en'] as const
export type AgrasLang = typeof AgrasLangs[number]
// 默认语言，对应"其它地区"
export const DEFAULT_LANG = 'en'

export const CHINESE_LANG = 'zh'

// 智农支持的区域类型 'default'表示其它
export const OTHER_REGION = 'default'
export const AgrasValidRegions = ['MX', 'BR', 'JP', 'CN', 'AU', 'ZA', 'CL', 'NZ', 'CA', 'US'] as const
// 谷歌支持的国家码与iso一致
export const AgrasRegions = [...AgrasValidRegions, OTHER_REGION] as const

export type AgrasRegion = typeof AgrasRegions[number]

// region 国家码的 cookie name
export const REGION_COOKIE_NAME = 'agro-region'

// language 语言码的 cookie name
export const LANG_COOKIE_NAME = 'agro-lang'

// 谷歌支持的语言码，zh跟pt与iso标准不一致，其它一致
export type AgrasGoogleLang = Extract<AgrasLang, 'es' | 'ja' | 'en'> | 'zh-CN' | 'pt-BR'

// 空字符串，Cookie.get获取不到会返回undefined
const EMPTY_STRING = ''

// 中国大陆的国家码
// 详见 https://en.wikipedia.org/wiki/ISO_3166-2
export const CHINA_REGION_CODES = 'CN'

export default class I18nUtils {
  /** 从cookie中读取国家 */
  static get region() {
    return (Cookie.get(REGION_COOKIE_NAME) || EMPTY_STRING) as AgrasRegion
  }
  /** 从cookie中读取语言，如果没有则返回空字符串 */
  static get lang() {
    return (Cookie.get(LANG_COOKIE_NAME) || EMPTY_STRING) as AgrasLang
  }
  /** 判断是否在中国大陆地区 */
  static get isInChina() {
    if (!this.region) return false
    return CHINA_REGION_CODES === this.region.toUpperCase()
  }
  /** 将国家码转化为谷歌地图支持的语言码  详见 https://developers.google.com/custom-search/docs/xml_results_appendices#countryCodes */
  static transferToGoogleLangCode(lang: AgrasLang): AgrasGoogleLang {
    /** 英语、日语、西班牙语谷歌跟iso标准是一样的, 原样返回。zh跟pt不一致，需进行转换 */
    switch (lang) {
      case 'zh':
        return 'zh-CN'
      case 'pt':
        return 'pt-BR'
      default:
        return lang
    }
  }
  static isRegionValid(region: AgrasRegion) {
    return AgrasRegions.includes(region)
  }
  static isLangValid(lang: AgrasLang) {
    return AgrasLangs.includes(lang)
  }
}

// 地区和语言的映射关系表
export const REGION_LANG_MAP: Record<AgrasRegion, AgrasLang> = {
  'MX': 'es',
  'BR': 'pt',
  'JP': 'ja',
  'CN': 'zh',
  'AU': 'en',
  'ZA': 'en',
  'CL': 'es',
  'NZ': 'en',
  'CA': 'en',
  'US': 'en',
  [OTHER_REGION]: 'en'
}
