/**
 * @file 根据ip地址跳转到对应区域
 * @author lian.duan 2024-01-10
 */
import Cookies from 'js-cookie'
import { AgrasRegion, REGION_COOKIE_NAME, LANG_COOKIE_NAME, AgrasLang } from 'agras-i18n-utils'
import I18nUtils from 'agras-i18n-utils'
import { getLocationByIp } from '@/services/apis/config'
import * as Sentry from '@sentry/browser'

const shareOptions = {
  // 过期时间180天
  expires: 180,
  secure: false,
  sameSite: 'lax' as const,
  httpOnly: false
}
export const RegionCookieOptions = {
  ...shareOptions
}
export const LangCookieOptions = {
  ...shareOptions
}

export async function setCookieByIp() {
  // 企业版不需要获取
  if (!IS_PERSONAL_VERSION) {
    return Promise.resolve()
  }
  const region = Cookies.get(REGION_COOKIE_NAME)
  const lang = Cookies.get(LANG_COOKIE_NAME)
  try {
    if (!I18nUtils.isRegionValid(region as AgrasRegion) || !I18nUtils.isLangValid(lang as AgrasLang)) {
      /**
      * 非法的区域：为空，非法
      * 海外地区：根据ip获取地区，然后重定向到对应国家的根目录
      * 中国大陆地区：直接跳转到中国，不再解析ip
      */
      let geoRegion: AgrasRegion
      let geoLang: AgrasLang
      if (IS_OVERSEA) {
        const res = await getLocationByIp()
        const { country_code, language_code } = res?.data?.data
        geoRegion = country_code
        geoLang = language_code
      } else {
        geoRegion = 'CN'
        geoLang = 'zh'
      }
      Cookies.set(REGION_COOKIE_NAME, geoRegion, RegionCookieOptions)
      Cookies.set(LANG_COOKIE_NAME, geoLang, LangCookieOptions)
    }
  } catch (error) {
    Cookies.set(REGION_COOKIE_NAME, 'default', RegionCookieOptions)
    Cookies.set(LANG_COOKIE_NAME, 'en', LangCookieOptions)
    Sentry.captureMessage('ip接口拉取失败')
  }
}