/**
 * @file 单位制映射表
 * @author Yangholmes 2023-04-07
 */

import { UnitCode } from 'agras-i22t'
import type { AgrasRegion } from 'agras-i18n-utils'

/** 单位制映射表 */
export const unitCodeMap: Partial<{
  [key in AgrasRegion]: UnitCode;
}> = {
  'CN': UnitCode.Cn,
  'US': UnitCode.Uk,
  'default': UnitCode.Std
}
