/**
 * @file google错误模型的公共逻辑
 * @author aron.yao 2022-07-16
 */
import { AxiosError } from 'axios'
import { BasicResourceError, NetWorkResourceError, RESOURCES, TYPES } from 'agras-error'
import { GRAPHQL_API_ERROR_MESSAGE } from 'agras-constants'
import { t } from 'i18next'
import { ObjectKeyPaths } from '@/i18n/react-i18next.d'
import { AgrasGraphqlError } from 'agras-i22t/graphqlErrorMessage/types.d'
import { currentLocaleConfig } from '@/i18n'

enum DetailInfoKeys {
  resourceInfo = 'type.googleapis.com/google.rpc.ResourceInfo',
  localMessage = 'type.googleapis.com/google.rpc.LocalizedMessage',
  errorInfoMessage = 'type.googleapis.com/google.rpc.ErrorInfo'
}

// google api的错误类型和前端自定义错误类型的映射表
// 文档：https://www.bookstack.cn/read/API-design-guide/API-design-guide-07-%E9%94%99%E8%AF%AF.md
export const errorStatusTypeMap = {
  'INVALID_ARGUMENT': TYPES.BAD_REQUEST,
  'FAILED_PRECONDITION': TYPES.BAD_REQUEST,
  'OUT_OF_RANGE': TYPES.BAD_REQUEST,
  'UNAUTHENTICATED': TYPES.UNAUTHORIZED,
  'PERMISSION_DENIED': TYPES.FORBIDDEN,
  'NOT_FOUND': TYPES.NOT_FOUND,
  'ABORTED': TYPES.CONFLICT,
  'ALREADY_EXISTS': TYPES.ALREADY_EXISTS,
  'RESOURCE_EXHAUSTED': TYPES.TOO_MANY_REQUEST,
  'CANCELLED': TYPES.CANCELLED,
  'DATA_LOSS': TYPES.SERVER_INTERNAL_ERROR,
  'UNKNOWN': TYPES.SERVER_INTERNAL_ERROR,
  'INTERNAL': TYPES.SERVER_INTERNAL_ERROR,
  'NOT_IMPLEMENTED': TYPES.SERVER_INTERNAL_ERROR,
  'UNAVAILABLE': TYPES.SERVER_INTERNAL_ERROR,
  'DEADLINE_EXCEEDED': TYPES.SERVER_INTERNAL_ERROR
}

// 后端的错误描述信息
interface googleErrorDescription {
  // 出错的资源，对应前端的资源定义
  resource: RESOURCES;
  // 后端的错误的status字段，和前端的resource对应
  // 当后端报执行时错误时，这个字段就为空
  rawResource?: RESOURCES;
  // 错误提示信息
  msg?: string;
  // 出错的类型
  type: TYPES;
}

function getTranslatedErrorMessage(errorModule: string, reason: string) {
  const errorKey = GRAPHQL_API_ERROR_MESSAGE.replace('{module}', errorModule).replace('{reason}', reason) as ObjectKeyPaths<AgrasGraphqlError>
  return t(errorKey) as string
}

function getDetailValue(details: any, key: DetailInfoKeys.resourceInfo): RESOURCES | undefined
function getDetailValue(details: any, key: DetailInfoKeys.errorInfoMessage): string | undefined
function getDetailValue(details: any, key: DetailInfoKeys.localMessage): string | undefined

/**
 * 从错误详情中获取对应的key的值
 *
 * @param details error的详细信息
 * @param key 要读取的error信息的key
 */
function getDetailValue(details: any, key: DetailInfoKeys): RESOURCES | string | undefined {
  const infoObj = details.find((item) => item['@type'] === key)
  if (!infoObj) {
    return undefined
  }

  switch (key) {
    case DetailInfoKeys.resourceInfo:
      return infoObj.resourceName
    case DetailInfoKeys.localMessage:
      return infoObj.message
    case DetailInfoKeys.errorInfoMessage:
      const [module, reason] = (infoObj.reason || '').split('.')
      if (module && reason) {
        return getTranslatedErrorMessage(module, reason)
      }
      return undefined
    default:
      return undefined
  }
}

/**
 * NOTICE: 目前后端没有用起来，只有很少的几个resource，所以无法做到和现在的前端RESOURCES一一对应
 * 后端完善后再补充
 *
 * @param resource 后端定义的resource
 */
function convertResource(resource: RESOURCES | undefined) {
  return resource || RESOURCES.NET_WORK
}

/**
 * 获取报错信息
 * 1. 如果前端有定义的错误信息，就优先使用前端定义的
 * 2. 若前端未定义错误，且在国内，再检查接口是否有返回错误信息
 */
function getErrorMsg(details: any) {
  const googleApiErrorMsg = getDetailValue(details, DetailInfoKeys.errorInfoMessage)
  const googleApiLocalizedMsg = getDetailValue(details, DetailInfoKeys.localMessage)

  if (googleApiErrorMsg) {
    return googleApiErrorMsg
  }
  // 检查接口是否有返回报错信息，只在国内使用，因为后端没有做多语言适配
  if (currentLocaleConfig.isInChina && googleApiLocalizedMsg) {
    return googleApiLocalizedMsg
  }
  return t('common.googleError.serverError')
}

/**
 * 将google错误转为描述信息
 */
function formatGoogleError(error: any): googleErrorDescription {
  // 先处理服务器执行时报错
  if (
    !error.extensions ||
    !error.extensions.details
  ) {
    return {
      resource: RESOURCES.NET_WORK,
      rawResource: undefined,
      msg: t('common.googleError.serverError'),
      type: TYPES.SERVER_INTERNAL_ERROR
    }
  }

  const rawResource = getDetailValue(error.extensions.details, DetailInfoKeys.resourceInfo)

  return {
    resource: convertResource(rawResource),
    rawResource,
    msg: getErrorMsg(error.extensions.details),
    type: errorStatusTypeMap[error.extensions.status] || TYPES.SERVER_INTERNAL_ERROR
  }
}

export function formatAxiosError(err: AxiosError): googleErrorDescription[] {
  if (!(err.response as any).data?.errors) {
    return []
  }

  return (err?.response as any).data.errors.map((error) => formatGoogleError(error))
}

/**
 * 根据axios的异常对象，构造前端定制化的异常对象
 * 谷歌错误模型见：https://cloud.google.com/apis/design/errors?hl=zh_cn
 *
 * @param err axios异常对象
 * @returns 定制化的异常对象
 */
export function genCustErrorByAxiosError(err: AxiosError): BasicResourceError {
  if (!(err.response as any).data?.errors) {
    // 没有响应体则认为是网络错误，比如没有网络
    return new NetWorkResourceError(TYPES.NET_WORK_ERROR)
  }

  const errors = formatAxiosError(err)

  /**
   * NOTICE: 暂时只取第一个，暂时没有一个请求出现多个提示的场景
   */
  return new BasicResourceError(errors[0].resource, errors[0].type, err, errors[0].msg)
}

/**
 * 判断是否为google error模型
 */
export function isGoogleErrorFormat(error: any) {
  return error.errors && error.errors.length
}
