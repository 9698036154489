/**
 * @file 带有路由切换的tab标签页
 * @author lian.duan 2022-06-20
 */

import { FC, useEffect } from 'react'
import { Tabs, TabsProps } from 'antd'
import { useHistory, useLocation } from 'ice'
import style from './index.module.less'
import { useState } from 'react'

const { TabPane } = Tabs

export interface ITabsProps extends TabsProps {
  // 标题
  title: string;
  // 路由的基础路径
  baseUrl: string;
  tabOptions: Array<{
    // 这个key要与路由地址对应
    key: string;
    name: string;
  }>;
  // tab 右侧内容
  rightContent?: JSX.Element;
}

const TabsComp: FC<ITabsProps> = (props) => {
  const history = useHistory()
  const location = useLocation()
  const [activeKey, setActiveKey] = useState('')
  const { title, tabOptions, rightContent, baseUrl, onTabClick, ...otherProps } = props

  useEffect(() => {
    const data = tabOptions.find((item) => location.pathname.includes(item.key))
    if (data) {
      setActiveKey(data.key)
    }
  }, [location.pathname])


  const onClick = (key: string, e) => {
    // 如果点击的就是当前路由，就不重复push路由了
    if (activeKey === key) return
    history.push(baseUrl ? `${baseUrl}/${key}` : key)
    onTabClick?.(key, e)
  }

  const operations = {
    left: <span className={style.title}>{title}</span>,
    right: rightContent
  }

  return (
    <Tabs
      activeKey={activeKey}
      centered
      tabBarExtraContent={operations}
      onTabClick={onClick}
      {...otherProps}
    >
      {
        tabOptions.map((item) => (
          <TabPane tab={item.name} key={item.key} />
        ))
      }
    </Tabs>
  )
}

export default TabsComp
