/**
 * @file 场景作业-变量作业 model
 * @author hellobin.chen 2022-09-07
 */

import { ProcessingJobsFilter } from '@/types/graphql-types'
import { ITaskDispatch, ITaskState } from '@/pages/Task/store'
import { INIT_PAGE, INIT_PAGESIZE } from '@/constants/common'
import { page2FirstAfter } from 'agras-utils'
import { fetchTaskProcessingJobsList } from '@/services/apis/task'
import { TaskItemModel } from '@/businessComponents/TaskItemCard/model'
import { IRebuildDataPayload } from '@/businessComponents/EditRebuild'
import { TASK_STATUS_ITEM } from '@/types/common.d'
import { FormType } from '@/businessComponents/FilterPanelInList/type'

export interface IMultiSpectralData {
  list: TaskItemModel[];
  pageNumber: number;
  totalCount: number;
  hasNextPage: boolean;
  currentSelectedTask?: TaskItemModel;
  overlapTasks: TaskItemModel[];
  lastDeletedTask?: TaskItemModel;
  searchForm?: FormType;
}

const initMultiSpectralData: IMultiSpectralData = {
  list: [],
  pageNumber: INIT_PAGE,
  totalCount: 0,
  hasNextPage: false,
  currentSelectedTask: undefined,
  overlapTasks: [],
  lastDeletedTask: undefined,
  searchForm: undefined
}

export default {
  state: initMultiSpectralData,
  reducers: {
    // 更新列表数据源
    updateListData(prevState: IMultiSpectralData, payload: { data: TaskItemModel[]; isFirstFetch?: boolean }) {
      if (payload.isFirstFetch === true) {
        prevState.list = payload.data
      } else {
        const list = prevState.list
        prevState.list = list.length > 0 ? list.concat(payload.data) : payload.data
      }
    },
    updateCurrentPage(prevState: IMultiSpectralData, pageNum: number) {
      prevState.pageNumber = pageNum
    },
    // 删除列表某一项
    deleteTask(prevState: IMultiSpectralData, payload: TaskItemModel) {
      const list = prevState.list
      const index = list.findIndex((item) => item.uuid === payload.uuid)
      if (index >= 0) {
        prevState.list = list.filter((item) => item.uuid !== payload.uuid)
        prevState.totalCount -= 1
        // 删除当前项后选中列表中的下一项
        prevState.currentSelectedTask = prevState.list[index]
        prevState.overlapTasks = []
        prevState.lastDeletedTask = payload
      }
    },
    // 更新当前的重建任务
    updateCurrentTask(prevState: IMultiSpectralData, payload: IRebuildDataPayload) {
      if (!prevState.currentSelectedTask) {
        return
      }
      const newTask: TaskItemModel = {
        ...prevState.currentSelectedTask,
        name: payload.name,
        tags: payload.tags
      }
      prevState.list = prevState.list.map((item) => {
        return item.uuid === payload.uuid ? newTask : item
      })
      prevState.overlapTasks = prevState.overlapTasks.map((item) => {
        return item.uuid === payload.uuid ? newTask : item
      })
      prevState.currentSelectedTask = newTask
    },
    // 更新列表数量
    updateListDataCount(prevState: IMultiSpectralData, totalCount: number) {
      prevState.totalCount = totalCount
    },
    updateHasNextPage(prevState: IMultiSpectralData, hasNextPage: boolean) {
      prevState.hasNextPage = hasNextPage
    },
    updateCurrentSelectedTask(prevState: IMultiSpectralData, task?: TaskItemModel) {
      prevState.currentSelectedTask = task
    },
    updateOverlapTask(prevState: IMultiSpectralData, taskList: TaskItemModel[]) {
      prevState.overlapTasks = taskList
    },
    clear(prevState: IMultiSpectralData) {
      Object.keys(prevState).forEach((key) => {
        prevState[key] = initMultiSpectralData[key]
      })
    },
    updateSearchForm(prevState: IMultiSpectralData, form?: FormType) {
      prevState.searchForm = form
    }
  },
  effects: (dispatch: ITaskDispatch) => ({
    async getMultiSpectralListData(payload: { isFirstFetch?: boolean; filter?: ProcessingJobsFilter }, taskState: ITaskState) {
      try {
        let currentPage
        if (payload.isFirstFetch) {
          // loading第一页数据的时候，总数要先显示0，等结果回来之后再更新
          dispatch.multiSpectral.updateListDataCount(0)
          currentPage = INIT_PAGE
        } else {
          currentPage = taskState.multiSpectral.pageNumber + 1
        }
        const queryPageParams = page2FirstAfter(currentPage, INIT_PAGESIZE)
        const filter = { ...payload.filter, status: TASK_STATUS_ITEM.FINISHED }
        const query = {
          filter,
          ...queryPageParams
        }

        const res = await fetchTaskProcessingJobsList(query)
        dispatch.multiSpectral.updateHasNextPage(res.processingJobs?.pageInfo?.hasNextPage ?? false)
        dispatch.multiSpectral.updateListDataCount(res.processingJobs?.totalCount ?? 0)
        const models = res.processingJobs?.nodes.map(TaskItemModel.fromTaskProcessingJobsFragment) ?? []
        dispatch.multiSpectral.updateListData({ data: models, isFirstFetch: payload.isFirstFetch })
        dispatch.multiSpectral.updateCurrentPage(currentPage)
        return res
      } catch (error) {
        throw error
      }
    }
  })
}
