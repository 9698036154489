/**
 * @file 权限控制模块
 * @author aron.yao 2022-11-04
 */
import Defer from 'agras-defer'
import {
  ROLES,
  USER_PERMITS,
  ADMIN_PERMITS,
  PERSONAL_PERMITS,
  PERSONAL_OVERSEA_PERMITS,
  GOVERNMENT_ADMIN_PERMITS,
  GOVERNMENT_USER_PERMITS
} from './role'

import modules from './modules'
import { currentLocaleConfig } from '@/i18n'
import { SYSTEM_EDITION } from '@/helpers/system'

export const PERMIT_MODULES = modules

export default class Permission {
  static inst: Permission
  static defer: Defer<Permission> = new Defer()
  static promise = Permission.defer.promise

  /**
   * 初始化单例对象
   */
  static init(
    edition: SYSTEM_EDITION,
    departmentRole: ROLES,
    organizationRole: ROLES
  ) {
    if (!Permission.inst) {
      Permission.inst = new Permission(
        edition,
        departmentRole,
        organizationRole
      )
      Permission.defer.resolve(Permission.inst)
    }
    return Permission.inst
  }

  /**
   * @param edition 系统版本
   * @param departmentRole 部门角色
   * @param organizationRole 组织角色
   */
  constructor(
    private readonly edition: SYSTEM_EDITION,
    private readonly departmentRole: ROLES,
    private readonly organizationRole: ROLES
  ) { }

  /**
   * 检查个人版用户是否有该模块的权限
   * @param moduleName 功能模块名
   */
  checkPersonalPermission(moduleName: string) {
    if (currentLocaleConfig.isInChina) {
      return PERSONAL_PERMITS.includes(moduleName)
    }
    return PERSONAL_OVERSEA_PERMITS.includes(moduleName)
  }

  /**
   * 检查企业版用户是否有该模块的权限
   * @param role 角色
   * @param moduleName 功能模块名
   */
  checkEnterpriseRolePermission(
    role: ROLES,
    moduleName: string
  ) {
    switch (role) {
      case ROLES.ADMIN:
        return ADMIN_PERMITS.includes(moduleName)
      case ROLES.USER:
      default:
        return USER_PERMITS.includes(moduleName)
    }
  }

  /**
 * 检查政府版用户是否有该模块的权限
 *
 * @param role 角色
 * @param moduleName 功能模块名
 */
  checkGovernmentRolePermission(
    role: ROLES,
    moduleName: string
  ) {
    switch (role) {
      case ROLES.ADMIN:
        return GOVERNMENT_ADMIN_PERMITS.includes(moduleName)
      case ROLES.USER:
      default:
        return GOVERNMENT_USER_PERMITS.includes(moduleName)
    }
  }

  /**
   * 检查当前用户是否有该模块的权限
   *
   * @param moduleName 模块名
   */
  checkPermission(moduleName: string) {
    switch (this.edition) {
      case SYSTEM_EDITION.PERSONAL:
        return this.checkPersonalPermission(moduleName)
      case SYSTEM_EDITION.GOVERNMENT:
        return this.checkGovernmentRolePermission(this.departmentRole, moduleName)
          || this.checkGovernmentRolePermission(this.organizationRole, moduleName)
      case SYSTEM_EDITION.ENTERPRISE:
      default:
        /**
        * TODO: departmentRole和organizationRole要统一成后端的Role字段
        */
        return this.checkEnterpriseRolePermission(this.departmentRole, moduleName)
          || this.checkEnterpriseRolePermission(this.organizationRole, moduleName)
    }
  }

  /**
   * 检查当前用户是否有对应一批模块的权限
   * 需要 所有模块 都有权限才返回true
   * @param moduleNames 模块名的数组
   */
  checkPermissions(
    moduleNames: string[]
  ) {
    if (!moduleNames.length) {
      return true
    }

    return moduleNames.every((item) => this.checkPermission(item))
  }

  /**
   * 检查当前用户是否有对应模块的权限
   * 只要有一个模块有权限，就返回true
   * @param moduleNames 模块名
   * @returns boolean
   */
  checkModulesPermission(moduleNames: string[]) {
    if (!moduleNames.length) {
      return true
    }
    return moduleNames.some((item) => this.checkPermission(item))
  }
}