/**
 * @file 场景作业接口
 * @author jamie.zhan 2022-09-09
 */
import graphqlRequest from '@/services/graphqlRequest'
import {
  QueryMutiSpectualTaskClusterQuery,
  QueryMutiSpectualTaskClusterQueryVariables,
  QueryTaskProcessingJobsQueryVariables,
  QueryTaskProcessingJobsQuery,
  StartCompressionJobMutationVariables,
  StartCompressionJobMutation,
  CompressionJobStatusQueryVariables,
  CompressionJobStatusQuery,
  FirstNdviJobInPlotQueryVariables,
  FirstNdviJobInPlotQuery,
  StartDownloadNdviFertilizerMutationVariables,
  StartDownloadNdviFertilizerMutation,
  CompressingNdviJobStatusQueryVariables,
  CompressingNdviJobStatusQuery,
  TaskInvalidInfoQueryVariables,
  TaskInvalidInfoQuery
} from '@/types/graphql-types'
import queryTaskClusterGql from './gql/queryTaskCluster.gql'
import queryTaskProcessingJobsGql from './gql/queryTaskProcessingJobs.gql'
import startCompressionJobGql from './gql/startCompressionJob.gql'
import queryCompressionJobStatusGql from './gql/queryCompressionJobStatus.gql'
import queryFirstNdviJobInPlotGql from './gql/queryFirstNdviJobInPlot.gql'
import startCompressionNdviJobGql from './gql/startDownloadNdviJob.gql'
import queryCompressionNdviJobStatusGql from './gql/queryCompressingNdviJobStatus.gql'
import queryInvalidWorkAreaGql from './gql/queryTaskInvalidInfo.gql'

export function fetchTaskCluster(variables: QueryMutiSpectualTaskClusterQueryVariables) {
  return graphqlRequest<QueryMutiSpectualTaskClusterQuery>(queryTaskClusterGql, variables)
}

export function fetchTaskProcessingJobsList(variables: QueryTaskProcessingJobsQueryVariables) {
  return graphqlRequest<QueryTaskProcessingJobsQuery>(queryTaskProcessingJobsGql, variables)
}

export function startCompressionJob(variables: StartCompressionJobMutationVariables) {
  return graphqlRequest<StartCompressionJobMutation>(startCompressionJobGql, variables)
}

export function fetchCompressionJobStatus(variables: CompressionJobStatusQueryVariables) {
  return graphqlRequest<CompressionJobStatusQuery>(queryCompressionJobStatusGql, variables)
}

export function fetchFirstNdviJobInPlot(variables: FirstNdviJobInPlotQueryVariables) {
  return graphqlRequest<FirstNdviJobInPlotQuery>(queryFirstNdviJobInPlotGql, variables)
}

export function startCompressionNdviJob(variables: StartDownloadNdviFertilizerMutationVariables) {
  return graphqlRequest<StartDownloadNdviFertilizerMutation>(startCompressionNdviJobGql, variables)
}

export function fetchCompressionNdviJobStatus(variables: CompressingNdviJobStatusQueryVariables) {
  return graphqlRequest<CompressingNdviJobStatusQuery>(queryCompressionNdviJobStatusGql, variables)
}

/** 查询单个作业的违规信息 */
export function fetchTaskInvalidTaskInfo(variables: TaskInvalidInfoQueryVariables) {
  return graphqlRequest<TaskInvalidInfoQuery>(queryInvalidWorkAreaGql, variables)
}