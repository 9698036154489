/**
 * @file filters
 * @author lian.duan 2022-06-29
 */

import { t } from 'i18next'
import { PHOTO_TYPES } from '@/types/photo.d'
import { PATROL_FARM_VIEW_TYPE } from '@/types/patrol.d'
import dayjs from 'dayjs'
import { UNKNOWN_TXT } from '@/constants/photo'
import { PlantType } from '@/types/graphql-types.d'
import { DateFormat } from '@/types/common.d'
import { SEGMENT_COLOR_TABLE_LABEL } from '@/types/segment.d'
import { REBUILD_SOURCE } from '@/constants/common'
import { REBUILD_TYPES } from '@/types/rebuild.d'

export function unkown(value: string): string {
  return value || UNKNOWN_TXT
}

/**
 * 照片库类型转为文案
 *
 * @param {JOB_TYPE} type 照片库类型
 * @return {string}
 */
export function photoTypeText(type: PHOTO_TYPES) {
  switch (type) {
    case PHOTO_TYPES.FARM:
    case PHOTO_TYPES.CITY:
      return t('common.photoType.farm')
    case PHOTO_TYPES.FRUIT_TREE:
      return t('common.photoType.fruitTree')
    case PHOTO_TYPES.MULTI_SPECTRAL:
      return t('common.photoType.multiSpectral')
    case PHOTO_TYPES.PATROL_FARM:
      return t('common.photoType.patrolFarm')
    default:
      return t('common.photoType.unknown')
  }
}

/**
 * 巡田类型 转换为文本
 *
 * @param {PATROL_FARM_VIEW_TYPE} type 巡田类型
 * @return {string}
 */
export function patrolFarmViewType2Txt(type: PATROL_FARM_VIEW_TYPE) {
  switch (type) {
    case PATROL_FARM_VIEW_TYPE.OVERLOOK:
      return t('common.patrolViewType.overlook')
    case PATROL_FARM_VIEW_TYPE.BIRD_EYE:
      return t('common.patrolViewType.birdEye')
    default:
      return '-'
  }
}

/**
 * 格式化日期
 *
 * @param {*} val 日期
 * @param {*} format 日期格式化参数
 * @param {*} errorTxt {string}
 * @return {*}
 */

export function dateFormat(val, format: DateFormat = DateFormat.YearMonthDay, errorTxt = UNKNOWN_TXT) {
  if (val) {
    return dayjs(val).format(format)
  }
  return errorTxt
}

/**
 * 组装部门的层级名字
 * @param {*} val paramDesc
 * @param {*} val 分隔符
 * @param {*} splitStr paramDesc
 * @return {*}
 */
export function departmentsPathName(val?: ({ name?: string | null } | null)[] | null, splitStr = '/') {
  if (!val) {
    return ''
  }
  return val.map((item) => item?.name ?? '')
    .reverse()
    .join(splitStr)
}

/**
 * 判断输入数值是不是安全的整数或者浮点数
 * @param {number} num
 * @return {boolean} true：安全 false：不安全
 */
export const isSafeNumber = (num: Number) => {
  return !Number.isNaN(num) && Number.isFinite(num)
}

/**
 * 保留小数位数
 * @param num 待处理数值
 * @param digit 精度，默认为 2
 * @param fallback 当输入值不安全时，输出这个字符串，默认为'-'
 * @return {string}
 */
export const toSafeDecimalPlaces = (num: number, digit = 2, fallback = '-'): string => {
  if (isSafeNumber(num)) {
    return Number.prototype.toFixed.call(num, digit)
  }
  return fallback || '-'
}

/**
 * 保留小数位数，自动删除小数部分末位 0
 * @param num 待处理数值
 * @param digit 精度，默认为 2
 * @param fallback 当输入值不安全时，输出这个字符串，默认为'-'
 * @return {string}
 */
export const toSafeDecimalPlacesTrimEndingZero = (num: number, digit = 2, fallback = '-'): string => {
  if (isSafeNumber(num)) {
    return Number.prototype.toString.call(Number.parseFloat(toDecimalPlaces(num, digit)), 10)
  }
  return fallback || '-'
}

/**
 * 保留小数位数
 * @param num 待处理数值
 * @param digit 默认为 2
 * @return {string}
 */
export const toDecimalPlaces = (num: number, digit = 2): string => Number.prototype.toFixed.call(num, digit)

/**
 * 保留小数位数，自动删除小数部分末位 0
 * @param num 待处理数值
 * @param digit 默认为 2
 * @return {string}
 */
export const toDecimalPlacesTrimEndingZero = (num: number, digit = 2): string => Number.prototype.toString.call(Number.parseFloat(toDecimalPlaces(num, digit)), 10)

/**
 * 作物类型转为文案
 *
 * @param {JOB_TYPE} type 作物类型
 * @return {string}
 */
export function plantTypeText(type: PlantType) {
  switch (type) {
    case PlantType.Wheat:
      return t('common.plant.wheat')
    case PlantType.Rice:
      return t('common.plant.rice')
    default:
      return t('common.plant.unknown')
  }
}

/**
 * 语义 类型表转换文案
 * @param type 语义类型
 * @returns 文案
 */
export function segmentColorTable2Txt(type: SEGMENT_COLOR_TABLE_LABEL) {
  switch (type) {
    case SEGMENT_COLOR_TABLE_LABEL.RICE:
      return t('common.segmentType.rice')
    case SEGMENT_COLOR_TABLE_LABEL.TREE:
      return t('common.segmentType.tree')
    case SEGMENT_COLOR_TABLE_LABEL.BUILDING:
      return t('common.segmentType.building')
    case SEGMENT_COLOR_TABLE_LABEL.POLE:
      return t('common.segmentType.pole')
    case SEGMENT_COLOR_TABLE_LABEL.WATER:
      return t('common.segmentType.water')
    case SEGMENT_COLOR_TABLE_LABEL.OTHER:
      return t('common.segmentType.other')
    case SEGMENT_COLOR_TABLE_LABEL.TREE_CENTER:
      return t('common.segmentType.treeCenter')
    default:
      return UNKNOWN_TXT
  }
}

/*
 * 千米每小时 转 米每秒
 *
 * @param {*} val paramDesc
 * @return {*}
 */
export function kmhToms(val: number) {
  if (!val) return val
  return +val * (1000 / 3600)
}

/**
 * 根据数值大小，获取转换成万，亿之后的值
 *
 * @param {number} val 面积数值，亩数或者公顷数
  * @return {*}
 */
export function convertNumberUnit(val: number) {
  if (Math.abs(val) >= 10 ** 8) {
    return {
      value: val / (10 ** 8),
      unit: '亿'
    }
  }
  if (Math.abs(val) >= 10 ** 4) {
    return {
      value: val / (10 ** 4),
      unit: '万'
    }
  }
  return {
    value: val,
    unit: ''
  }
}

/**
 *
 * @param val 面积
 * @returns 带单位的面积
 */
export function formatAreaWithBigUnit(val: number) {
  const { value, unit } = convertNumberUnit(val)
  return `${toDecimalPlacesTrimEndingZero(value)}${unit}`
}

/*
 * 时间转时间范围
 *
 * @param {object} begin 开始时间和结束
 * @param {object} end 结束时间
 * @param {string} format 日期格式化参数
 * @param {*} timeFormat paramDesc
 * @return {*}
 */
export function toDateTimeRange(begin: number, end: number, timeFormat = 'HH:mm:ss') {
  if (!begin || !end) {
    return t('common.dateTime.unknown')
  }
  const beginDate = dayjs.unix(begin)
  const endDate = dayjs.unix(end)
  let endDateTxt = ''
  if (beginDate.year() === endDate.year()) {
    if (
      beginDate.month() === endDate.month()
      && beginDate.date() === endDate.date()
    ) {
      endDateTxt = endDate.format(timeFormat)
    } else {
      endDateTxt = endDate.format(`M/D ${timeFormat}`)
    }
  } else {
    endDateTxt = endDate.format(`YYYY/M/D ${timeFormat}`)
  }
  return `${beginDate.format(`YYYY/M/D ${timeFormat}`)}-${endDateTxt}`
}

/**
 * 控制数值小数精度
 *
 * @param {*} number 数值
 * @param {*} precision 小数精度位数
 * @param {*} precisions paramDesc
 * @return {*}
 */
export function precision(
  number: number,
  precisions = 1
) {
  if (typeof number !== 'number') return NaN
  return parseFloat((number || 0).toFixed(precisions))
}

/**
 * 将值为null的数字类型 转换成 -
 *
 * @param {*} val paramDesc
 * @param {*} prec paramDesc
 * @return {*}
 */
export function formatNumber(val?: number | null, prec = 1) {
  return (typeof val !== 'number' || Number.isNaN(val)) ? UNKNOWN_TXT : precision(val, prec)
}

/**
 * 根据数值大小，获取转换成万，亿之后的值
 *
 * @param {number} val 面积数值，亩数或者公顷数
 * @param {*} value paramDesc
 * @return {*}
 */
export function autoValueScale(value: number) {
  const val = +value
  if (Math.abs(val) >= 10 ** 8) {
    return val / (10 ** 8)
  }
  if (Math.abs(val) >= 10 ** 4) {
    return val / (10 ** 4)
  }
  return val
}

/**
 * 后台时间转换 面积的数值是0.01亩的倍数
 *
 * @param {*} value 亩
 * @param {*} val paramDesc
 * @return {*}
 */
export function formatArea(val?: number | null) {
  const value = parseFloat(val || 0)
  if (value) {
    return value * 0.01
  }
  return 0
}

/**
 * 获取数值的大小范围
 *
 * @param {*} val 数值
 * @param {*} value paramDesc
 * @return {*}
 */
export function getValueScale(value: number) {
  const val = +value
  if (Math.abs(val) >= 10 ** 8) {
    return '亿'
  }
  if (Math.abs(val) >= 10 ** 4) {
    return '万'
  }
  return ''
}

/**
 * 毫升转成升
 *
 * @param {number} value 毫升数
 * @return {*}
 */
export function milliliter2Liter(value: number) {
  return +value / 1000
}

/**
 * 克转成千克
 *
 * @param {number} value 克
 * @return {number}
 */
export function gram2Kilogram(
  value: number
) {
  return +value / 1000
}

/**
 * 米每秒 转 千米每小时
 *
 * @param {*} val paramDesc
 * @param {*} precisions paramDesc
 * @return {*}
 */
export function msTokmh(val: number) {
  return +val * (3600 / 1000)
}

/**
 * 重建来源转成文案
 * @param source 来源 
 * @returns 
 */
export function rebuildSource2Txt(source: REBUILD_SOURCE) {
  switch (source) {
    case REBUILD_SOURCE.REMOTE_CONTROL:
      return t('common.rebuildSource.remoteControl')
    case REBUILD_SOURCE.TERRA_PC:
      return t('common.rebuildSource.terraPc')
    case REBUILD_SOURCE.SMART_FARM:
    default:
      return t('common.rebuildSource.smartFarm')
  }
}

/**
 * 重建类型转换成目录上的文案
 * @param jobType 重建类型
 */
export function jobType2MenuTxt(jobType?: REBUILD_TYPES) {
  switch (jobType) {
    case PHOTO_TYPES.MULTI_SPECTRAL:
      return t('menu.taskPlan.subMenu.multiSpetral')
    case PHOTO_TYPES.FRUIT_TREE:
      return t('menu.taskPlan.subMenu.fruit')
    case PHOTO_TYPES.FARM:
      return t('menu.taskPlan.subMenu.farm')
    default:
      return ''
  }
}
