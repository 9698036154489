/**
 * @file restfull接口的请求模块
 * @author aron.yao 2022-07-14
 */
import { AxiosError, AxiosResponse } from 'axios'
import baseRequest, { AxiosInstRequestConfig, RevokablePromise } from './request'
import { LoginModuleError, BasicResourceError, RESOURCES, TYPES } from 'agras-error'
import { ENTERPRISE_LOGIN_API, ENTERPRISE_CAPTCHA_API } from '@/constants/login'

/**
 * 封装统一的异常处理逻辑，和返回特定的异常类型对象
 *
 * @param err 请求的异常
 */
function errorHandler(err: AxiosError) {
  const response = err.response
  let error
  // 非请求报错的情况
  if (!response || !response.config) {
    error = new BasicResourceError(RESOURCES.RUNTIME, TYPES.UNKNOWN)
  } else {
    /**
     * 请求报错的情况
     */
    switch (response.config.url) {
      case ENTERPRISE_LOGIN_API:
        error = new LoginModuleError(RESOURCES.LOGIN_INFO, response.status || TYPES.UNKNOWN, err)
        break
      case ENTERPRISE_CAPTCHA_API:
        error = new LoginModuleError(RESOURCES.CAPTCHA, response.status || TYPES.UNKNOWN, err)
        break
      default:
        // todo 需要改成默认类型
        error = new BasicResourceError(RESOURCES.RUNTIME, TYPES.UNKNOWN)
    }
  }

  return error
}

/**
 * 对请求进行异常处理，取消操作的封装
 *
 * @param requestResult 请求的异步对象
 */
function wrapHandler<T>(requestResult: RevokablePromise<AxiosResponse<T>>): RevokablePromise<AxiosResponse<T>> {
  return new RevokablePromise((resolve, reject) => {
    requestResult
      .then(resolve)
      .catch((err) => {
        reject(errorHandler(err))
      })
  }, requestResult.cancel)
}

export default {
  get<T>(url: string, config?: AxiosInstRequestConfig): RevokablePromise<AxiosResponse<T>> {
    const originalRevokableResult = baseRequest.get<T>(url, config)
    return wrapHandler(originalRevokableResult)
  },
  post<T>(url: string, data?: any, config?: AxiosInstRequestConfig): RevokablePromise<AxiosResponse<T>> {
    const originalRevokableResult = baseRequest.post<T>(url, data, config)
    return wrapHandler(originalRevokableResult)
  }
}
