/**
 * @file 自定义select组件
 * @author aron.yao 2022-06-23
 */
import { FC, useState } from 'react'
import { Select, SelectProps } from 'antd'
import { Icon } from '@/components/Icon'
import './index.less'

const AgSelect: FC<SelectProps> = ({
  className, children, onDropdownVisibleChange, ...props
}) => {
  const [rotate, setRotate] = useState<boolean>(false)

  const onChange = (val) => {
    setRotate(val)
    onDropdownVisibleChange?.(val)
  }

  return (
    <Select
      listHeight={310}
      dropdownMatchSelectWidth={false}
      suffixIcon={<Icon className={rotate ? 'rotate-icon' : ''} iconName='down' color='rgba(0, 0, 0, 0.45)'/>}
      onDropdownVisibleChange={onChange}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      {...props}
      className={`ag-select ${className}`}
    >
      {children}
    </Select>
  )
}

export default AgSelect
