import { departmentsPathName } from '@/services/filters'
import { TaskProcessingJobsFragmentFragment } from '@/types/graphql-types'
import { PHOTO_TYPES } from '@/types/photo.d'
import { bbox2Bounds } from '@/utils/map'
import dayjs, { Dayjs } from 'dayjs'
import { compact } from 'lodash-es'

export class TaskItemModel {
  public constructor(
    public uuid: string,
    public name: string,
    public area: number,
    public type: PHOTO_TYPES,
    public tags: { name: string; uuid: string }[],
    public department: string,
    public createTime: Dayjs,
    public captureDate: Dayjs | null,
    public indexList: string[],
    public bounds?: L.LatLngBounds
  ) { }

  public static fromTaskProcessingJobsFragment(response: TaskProcessingJobsFragmentFragment): TaskItemModel {
    const paramsJson = response.params ? JSON.parse(response.params) : {}
    return new TaskItemModel(
      response.uuid ?? '',
      response.name ?? '',
      response.area ?? 0,
      response.type ?? PHOTO_TYPES.FARM,
      TaskItemModel.getTags(response),
      departmentsPathName(response.departmentLinks),
      dayjs(response.createdAt),
      response.captureTime ? dayjs(response.captureTime) : null,
      paramsJson.index_list ?? [],
      bbox2Bounds({ downLeft: response.downLeft, upperRight: response.upperRight })
    )
  }

  private static getTags(response: TaskProcessingJobsFragmentFragment) {
    if (response.tags && response.tags.nodes) {
      return compact(response.tags.nodes.map((tag) => {
        if (tag) {
          return { name: tag.name, uuid: tag.uuid }
        }
        return null
      }))
    }
    return []
  }
}
