/**
 * @file 左侧菜单渲染
 * @author xinqiang.wei 2022/6/30
 */
import { Tooltip } from 'antd'
import cn from 'classnames'
import { t } from 'i18next'

// components
import { Icon } from '@/components/Icon'
import AgPopover, { AgPopoverProps } from '@/components/Popover'
import AgAvatar from '@/components/Avatar'
import AgLink from '@/components/Link'
import TermsLink from '@/businessComponents/Terms'

// store
import store from '@/store'

// helpers
import { displayUserName } from '@/helpers/userInfo'
import { history } from 'ice'

// services
import { logout } from '@/services/auth'
import { unkown, dateFormat } from '@/services/filters'
import Permission, { PERMIT_MODULES } from '@/services/permission'

// types
import type { SiderMenuProps } from '@ant-design/pro-layout/lib/components/SiderMenu/SiderMenu'
import type { MouseEventHandler } from 'react'
import {
  bottomList,
  BottomListOptions,
  BottomMenuType
} from '../bottomMeunConfig'

// style
import style from '@/Layout/index.module.less'
import AgInfoLabel from '../InfoLabel'
import { ROUTE_PATHS } from '@/constants/routes'
import { DateFormat } from '@/types/common.d'

const renderCollapsed = (item: BottomListOptions): JSX.Element | null => {
  const { showCollapsedBtn } = item
  if (!showCollapsedBtn) {
    return null
  }
  return (
    <Icon
      className={cn(style['menu-item-content-icon-collapsed'])}
      iconName={'double-left'}
      size='large'
    />
  )
}

// 个人信息面板
function personaInfoPopoverContent() {
  const [profileState] = store.useModel('profile')

  return (
    <>
      <div className={style['avatar-name-container']}>
        <div className={style['avatar-name']}>
          <AgAvatar />
          <p className={style['user-name']}>{unkown(displayUserName())}</p>
        </div>
      </div>
      <div className={style['account-info']}>
        <AgInfoLabel
          label={t('pages.personalInfoCard.account')}
          content={unkown(profileState.userProfile.phone || profileState.userProfile.email)}
        />
        <AgInfoLabel
          label={t('pages.personalInfoCard.createTime')}
          content={dateFormat(profileState.userProfile.createdAt, DateFormat.YearMonthDay)}
        />
        {
          IS_PERSONAL_VERSION ? (
            <AgInfoLabel
              label={t('pages.personalInfoCard.endAt')}
              content={dateFormat(profileState.userProfile.endAt, DateFormat.YearMonthDay)}
            />
          ) : null
        }
      </div>
      <div className={style['logout']}>
        <AgLink onClick={() => logout(false)}>{t('pages.personalInfoCard.logout')}</AgLink>
      </div>
    </>
  )
}

// 设置菜单内容
function settingPopoverContent(feedbackClickHandler?: () => void) {
  const [, systemDispatchers] = store.useModel('system')
  const onClickFeedback = () => {
    feedbackClickHandler?.()
    systemDispatchers.toggleFeedback(true)
  }
  // 更新日志
  const onClickReleaseNote = () => {
    systemDispatchers.toggleReleaseNote(true)
  }
  // 常见问题
  const onClickFaq = () => {
    // 如果已经在faq页面，就不要添加新的路由了
    if (history?.location.pathname === ROUTE_PATHS.FAQ_PATH) return
    history?.push(ROUTE_PATHS.FAQ_PATH)
  }
  const settingList = [
    {
      permissions: [PERMIT_MODULES.FEEDBACK.CHECK],
      title: t('menu.setting.subMenu.feedback'),
      event: onClickFeedback
    },
    {
      permissions: [PERMIT_MODULES.RELEASE_NOTES.CHECK],
      title: '更新日志',
      event: onClickReleaseNote
    },
    {
      permissions: [PERMIT_MODULES.FAQ.CHECK],
      title: '常见问题',
      event: onClickFaq
    }
  ]
  return  (
    <div className={style['setting-content']}>
      {
        settingList.map((item) => {
          return (
            Permission.inst.checkPermissions(item.permissions)
              ? <div key={item.title} className={style['setting-item']} onClick={item.event}>{item.title}</div>
              : null
          )
        })
      }
      <TermsLink className={style['setting-item']}/>
    </div>
  )
}

/**
 * 渲染底部单个菜单项内容
 *
 * @param collapsed 是否处于收起状态
 * @param iconName 图标名
 * @param name 菜单项名
 */
function menuItemContentRender(
  collapsed: boolean,
  iconName: string,
  name?: string | (() => string),
  onClick?: MouseEventHandler
) {
  return (
    <p className={style['menu-item-content']} onClick={onClick}>
      {
        <Icon
          className={cn(style['menu-item-content-icon'],
            {
              [style['menu-item-content-icon-collapsed']]: false
            })}
          iconName={iconName}
          size='large'
        />
      }
      {!collapsed && <span className={style['menu-item-content-name']}>{name instanceof Function ? name() : name}</span>}
    </p>
  )
}

const sharePopoverProps = {
  /**
   * NOTICE: 要比modal的遮罩低，否则用户反馈弹窗出来之后popover会在遮罩上面
   */
  zIndex: 999,
  trigger: 'click'
}

function getFooterMenuItemPopoverProps(
  item: BottomListOptions,
  collapsed: boolean,
  visible?: boolean,
  onVisibleChange?: AgPopoverProps['onVisibleChange']
) {

  switch (item.type) {
    case BottomMenuType.Setting:
      return (
        <AgPopover
          overlayClassName={style['setting-menu-popover']}
          visible={visible}
          onVisibleChange={onVisibleChange}
          placement='left'
          align={{ offset: [8] }}
          // content={settingPopoverContent(() => setIfShowSettingPopover(false))}
          content={settingPopoverContent()}
          {...sharePopoverProps}
        >
          {
            menuItemContentRender(collapsed, item.icon, item.title, undefined)
          }
        </AgPopover>
      )
    case BottomMenuType.PersonInfo:
      return IS_PERSONAL_VERSION ? (
        <AgPopover
          visible={visible}
          onVisibleChange={onVisibleChange}
          placement='leftBottom'
          overlayClassName={style['personal-info-menu-popover']}
          content={personaInfoPopoverContent()}
          {...sharePopoverProps}
        >
          {
            menuItemContentRender(collapsed, item.icon, item.title, undefined)
          }
        </AgPopover>
      ) : menuItemContentRender(collapsed, item.icon, item.title, () => {
        if (history?.location.pathname !== ROUTE_PATHS.USER_PATH) {
          history?.push(ROUTE_PATHS.USER_PATH)
        }
      })
    case BottomMenuType.Collapsed:
      return (
        <Icon
          className={cn(style['menu-item-content-icon-collapsed'])}
          iconName={'double-right'}
          size='large'
        />
      )
    default:
      return null
  }
}

const renderMenuFooter = (
  props: SiderMenuProps,
  item: BottomListOptions,
  visible?: boolean,
  onVisibleChange?: AgPopoverProps['onVisibleChange']
): JSX.Element | null => {
  return getFooterMenuItemPopoverProps(item, !!props.collapsed, visible, onVisibleChange)
}

export const renderMenuRender = (
  props: SiderMenuProps,
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>
): JSX.Element => {
  const [systemState, systemDispatchers] = store.useModel('system')

  const bottomListTmp = [...bottomList()]

  const changeSetting = () => {
    setCollapsed((prevState) => !prevState)
  }

  if (props?.collapsed) {
    bottomListTmp.push({
      type: BottomMenuType.Collapsed,
      icon: 'double-right'
    })
  }

  function renderMenuFooterByType(props: SiderMenuProps, item: BottomListOptions) {
    switch (item.type) {
      case BottomMenuType.Setting:
        return renderMenuFooter(props, item, systemState.ifShowSettingPopover, (val) => systemDispatchers.toggleIfShowSettingPopover(val))
      case BottomMenuType.PersonInfo:
        return renderMenuFooter(props, item, systemState.ifShowPersonalInfoPopover, (val) => systemDispatchers.toggleIfShowPersonalInfoPopover(val))
      case BottomMenuType.Region:
        return menuItemContentRender(!!props?.collapsed, item.icon, item.title, () => systemDispatchers.toggleIfShowRegionModal(true))
      case BottomMenuType.Collapsed:
        return renderMenuFooter(props, item)
      default:
        return null
    }
  }

  return (
    <div className={style['menu-extra-content']}>
      {
        bottomListTmp.map((item: BottomListOptions) => {
          const { type, title, icon } = item
          const isCollapsedType = type === BottomMenuType.Collapsed
          return (
            <div
              key={`${type}_${title}_${icon}`}
              className={style['menu-footer-content']}
            >
              <div
                className={cn(style['menu-collapsed'], {
                  [style['menu-collapsed-collapsed']]: isCollapsedType
                })}
                onClick={() => isCollapsedType && changeSetting()}
              >
                {
                  props?.collapsed ? (
                    <Tooltip
                      title={title}
                      placement='right'
                      className={cn({
                        [style['menu-footer-tooltip']]: true
                      })}
                    >
                      {renderMenuFooterByType(props, item)}
                    </Tooltip>
                  ) :
                    renderMenuFooterByType(props || {}, item)
                }
                {
                  item.showCollapsedBtn && !props?.collapsed ? (
                    <div
                      onClick={changeSetting}
                      className={cn(style['menu-footer-collapse-icon'])}
                    >{renderCollapsed(item)}
                    </div>
                  ) : null
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}
