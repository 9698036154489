/**
 * @file 国际化
 *
 * 两个方案可切换：
 *
 * 1. 语言包独立管理，先安装 `yarn add i18next-http-backend'` ，打开 HttpBackend plugin
 *
 * 2. 语言包不独立管理，请使用 vite 编译那一段，vite 将会在构建阶段将语言包打包到项目资源中，并附带 hash
 *
 * @author Yangholmes 2023-02-06
 */

import { ICE_MODES } from '@/constants/modes'
import { scioWhitelistRegions } from '@/constants/region'
import I18nUtils, { CHINA_REGION_CODES, CHINESE_LANG } from 'agras-i18n-utils'
import i18n, { CustomTypeOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'
// import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend'

/** 
 * 获取当前的多语言配置, 如果是企业版则使用默认的多语言配置, 个人版使用cookie里的字段
 * 
 */
export class CurrentLocaleConfig {
  get region() {
    return IS_PERSONAL_VERSION ? I18nUtils.region : CHINA_REGION_CODES
  }

  get lang() {
    return IS_PERSONAL_VERSION ?  I18nUtils.lang : CHINESE_LANG
  }

  get isInChina() {
    return IS_PERSONAL_VERSION ?  I18nUtils.isInChina : true
  }

  get inWhitelist() {
    if (IS_PERSONAL_VERSION) {
      /** 判断地区是否在白名单中，找不到地区则返回true */
      return I18nUtils.region ? scioWhitelistRegions.includes(I18nUtils.region) : true
    } 
    return false
  }
}

export const currentLocaleConfig = new CurrentLocaleConfig()

/** 备选的命名空间 */
type namespaces = 'translation'

/**
 * 定义一个 i18n
 * @param lang 当前语言
 * @return i18n 实例
 */
export default async function defineI18n(lang = 'en') {
  if (!i18n.isInitialized) {
    await i18n
      // i18n 组件支持，如果没有需要可以不安装
      .use(initReactI18next)
      // 自动按需加载语言（基于 http 请求）
      // .use(HttpBackend)
      // 利用 vite 编译压缩 json ，可以添加 hash ，方便做缓存
      .use({
        type: 'backend',
        read<Namespace extends namespaces>(
          language: string,
          namespace: Namespace,
          callback: (
            errorValue: unknown,
            translations: null | CustomTypeOptions['resources']['translation']
          ) => void
        ) {
          import(`./locales/${language}/index.ts`)
            .then((resources) => {
              callback(null, resources.default)
            })
            .catch((error) => {
              callback(error, null)
            })
        }
      })
      .init({
        fallbackLng: 'en',
        lng: lang,
        // 本地打开 debug 信息
        debug: ICE_MODE === ICE_MODES.LOCAL
      })
  } else {
    console.warn('i18next 已经初始化过了，不需要重复初始化，更改语言请使用 `i18n.changeLanguage()`')
  }
  return i18n
}
