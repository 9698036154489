/**
 * @file 展示信息的组件
 * @author aron.yao 2022-08-11
 */

import { FC } from 'react'
import './index.less'

interface AgInfoLabelProps {
  label: string;
  content: string;
}

const AgInfoLabel: FC<AgInfoLabelProps> = (props) => {
  const { label, content } = props

  return (
    <div className='ag-info-label'>
      <label className='ag-info-label-label'>{label}</label>
      <span className='ag-info-label-content'>{content}</span>
    </div>
  ) 
}

export default AgInfoLabel