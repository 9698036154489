/**
 * @file 根据当前区域，修正个人版路由
 * @author lian.duan 2023-10-17
 */
import { AgrasValidRegions } from 'agras-i18n-utils'

import { region } from '@/constants/routes'

type AgrasValidRegion = typeof AgrasValidRegions[number]

export function correctPersonalRoutePrefix() {
  if (!IS_PERSONAL_VERSION) return

  const { pathname } = location
  const prefix = pathname.split('/')[1]
  const isValidRegionPrefix = AgrasValidRegions.includes(prefix.toUpperCase() as AgrasValidRegion)

  // 合法的路由前缀
  if (isValidRegionPrefix) {
    /**
     * 如果是合法的路由前缀，需要根据当前的区域，自动修正路由
     * /cn/media/photo -> /mx/media/photo
     */
    if (region.REGION_PREFIX !== prefix) {
      const path = pathname.replace(new RegExp(`^/${prefix}`), region.REGION_URL_PREFIX)
      location.replace(path || '/')
    }
  } else {
    /**
     * 非法的路由前缀，但是在非其他区域下就要加上国家码
     * /media/photo -> /mx/media/photo
     * /xx/media/photo -> /mx/xx/media/photo -> mx/not-found
     */
    if (region.REGION_PREFIX !== '') {
      location.replace(`${region.REGION_URL_PREFIX}${pathname}`)
    }
  }
}