/**
 * @file sentry初始化模块
 * @author aron.yao 2022-07-20
 */
import * as Sentry from '@sentry/browser'
import { HttpClient } from '@sentry/integrations'
import type { Integration } from '@sentry/types/types/integration'

import { ICE_MODES } from '@/constants/modes'
import { currentLocaleConfig } from '@/i18n'

const ifOpenSentry = [ICE_MODES.TEST, ICE_MODES.PROD].indexOf(ICE_MODE) !== -1

const modeEnvsMap: {
  [key in typeof ICE_MODES[keyof typeof ICE_MODES]]: string
} = {
  [ICE_MODES.LOCAL]: 'dev',
  [ICE_MODES.DEV]: 'dev',
  [ICE_MODES.TEST]: 'test',
  [ICE_MODES.PROD]: 'prod'
}

/**
 * 将ice的mode转为sentry的环境
 */
function iceMode2SentryEnv() {
  return modeEnvsMap[ICE_MODE] || 'dev'
}

const sentryModule = {
  /**
   * sentry初始化
   */
  init() {
    Sentry.init({
      enabled: Boolean(ifOpenSentry && currentLocaleConfig.isInChina),
      dsn: __SENTRY_DSN__,
      environment: iceMode2SentryEnv(),
      sampleRate: 1,
      tracesSampleRate: 0.2,
      // 这里的release版本号要和上传sourcemap时的releaseVersion一致
      release: `${__PAMS_ID__}@${__VERSION__}`,
      integrations: [
        new Sentry.BrowserTracing({
          beforeNavigate: (context) => {
            return {
              ...context,
              name: window.location.pathname
                .replace(/\/[a-f0-9]{32}$/g, '/:pageId')
                .replace(/\/\d+$/g, '/:pageId')
            }
          }
        }),
        new HttpClient({
          failedRequestStatusCodes: [[500, 599], 400],
          failedRequestTargets: [
            ...[/(?!.*apm-server.*).*/, /(?!.*google-analytics.*).*/]
          ]
        }) as unknown as Integration
      ],
      denyUrls: [
        /.*apm-server.*/,
        /.*google-analytics.*/,
        /.*arms.aliyuncs.com.*/,
        /.*arms-retcode.aliyuncs.com.*/,
        /.*doubleclick.net.*/
      ],
      ignoreErrors: [
        // Browser extension
        /^(chrome|moz|safari)-extension:\/\//i,
        // 用户本地的file协议打开的文件报错
        /^file:\/\/\//i,
        /ResizeObserver loop limit exceeded/
      ],
      beforeSend(event, hint) {
        const error = hint.originalException as { name: string }
        if (error?.name?.startsWith('【Error】')) {
          event.level = 'info'
        }

        return event
      }
    })

    Sentry.setTag('agro-name', 'smart-agro-pc')
  },
  /**
   * 上报异常到sentry
   *
   * @param err 捕获到的异常对象
   */
  captureException(err: Error | PromiseRejectionEvent) {
    if (err instanceof Error) {
      Sentry.captureException(err)
    } else {
      Sentry.captureException(err.reason)
    }
  }
}

// 初始化sentry配置
sentryModule.init()

export default sentryModule
