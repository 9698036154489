/**
 * @file 筛选区域
 * @author xinqiang.wei 2022年08月03日
 */
import { FC, useState, useEffect } from 'react'
import { Button, Col, DatePicker, Input, Row, Select } from 'antd'
import AgSelect from '@/components/Select'
import style from './index.module.less'
// 引用页面状态
import store from '@/store'
import { TYPE_ALL, REBUILD_SOURCE } from '@/constants/common'
import { cloneDeep } from 'lodash-es'
import { isEmojiCharacter } from 'agras-utils'
import { jobTypeOptions, rebuildSourceOptions } from '@/constants/rebuild'
import { ISearchForm, REBUILD_STATUS } from '@/pages/Media/Rebuild/types.d'
import { DepartmentTreeSelect } from '@/components/DepartmentTreeSelect'
import { t } from 'i18next'
import useGtag from '@/monitoring/gtag/hooks'
import { currentLocaleConfig } from '@/i18n'

const { Option } = Select

// 由于typescript、@types/react、@types/react-dom版本不一致引起的问题
// 尝试升级解决,但是还有其他ts类型问题。可以查看下面issues查看版本问题
// https://github.com/ant-design/pro-components/issues/5053
// https://github.com/ant-design/ant-design/issues/34952
const { RangePicker }: any = DatePicker

export const initForm: ISearchForm = {
  /** 任务类型，默认所有类型 */
  jobType: TYPE_ALL.TYPE_ALL,
  /** 任务状态 */
  status: TYPE_ALL.TYPE_ALL,
  /** 任务创建时间 */
  startTime: '',
  /** 任务创建时间 */
  endTime: '',
  /** 任务名称 */
  name: '',
  /** 组织字段 */
  departmentUuids: '',
  /** 数据来源 */
  source: REBUILD_SOURCE.SMART_FARM
}

interface IRebuildType {
  value?: number | string;
  label: string;
}

const getColSpan = () => {
  // 企业版
  if (!IS_PERSONAL_VERSION) {
    return {
      name: 3,
      jobType: 3,
      department: 3,
      source: 0,
      status: 3,
      rangePicker: 8,
      operate: 4
    }
  }
  // 国内个人版
  if (currentLocaleConfig.isInChina) {
    return {
      name: 3,
      jobType: 3,
      department: 0,
      source: 3,
      status: 3,
      rangePicker: 8,
      operate: 4
    }
  }
  // 海外个人版
  return {
    name: 4,
    jobType: 4,
    department: 0,
    source: 0,
    status: 4,
    rangePicker: 8,
    operate: 4
  }
}

const colSpan = getColSpan()

const FilterPanel: FC = () => {

  const gtagEvent = useGtag('media/rebuild')

  const REBUILD_STATUS_NAME_LIST = [
    {
      key: TYPE_ALL.TYPE_ALL,
      value: t('pages.media.rebuild.filter.rebuildStatusTypes.all')
    },
    {
      key: REBUILD_STATUS.PENDING,
      value: t('pages.media.rebuild.status.pending')
    },
    {
      key: REBUILD_STATUS.SUCCESS,
      value: t('pages.media.rebuild.status.success')
    },
    {
      key: REBUILD_STATUS.FAILED,
      value: t('pages.media.rebuild.status.failure')
    }
  ]

  const [rebuildFormState, rebuildFormDispatchers] = store.useModel('rebuildForm')
  const [, tableDispatchers] = store.useModel('rebuildTable')
  const [form, setForm] = useState<ISearchForm>({ ...initForm })

  // 修改查询时间
  const changeTime = (date) => {
    let startTime = ''
    let endTime = ''

    // 如果是清空数据data为空。只有当修改时间才有值，修改的时候才进行设置时间数据
    if (date) {
      startTime = date[0]
      endTime = date[1]
    }
    setForm({ ...form, startTime, endTime })
  }

  // 选中某个部门节点
  const onSelectDepartment = (val) => {
    setForm({
      ...form,
      departmentUuids: val
    })
  }


  /**
   * 监听store params的数据变化同步更新到form数据中
   */
  useEffect(() => {
    setForm(rebuildFormState.params as ISearchForm)
  }, [rebuildFormState.params])

  const resetFormValue = () => {
    setForm({ ...initForm })
    rebuildFormDispatchers.resetParams()
    rebuildFormDispatchers.updatePageNumber(1)
    // 更新当前页数以及分页数量
    tableDispatchers.getTableData()
    gtagEvent.current?.('ag_reset')
  }

  // 提交查询
  const searchHandler = async () => {
    rebuildFormDispatchers.updatePageNumber(1)
    rebuildFormDispatchers.setParams(form)
    tableDispatchers.getTableData()
    gtagEvent.current?.('ag_search')
  }


  const rebuildTypes: IRebuildType[] = cloneDeep(jobTypeOptions())

  // 增加一个全部查询选择
  rebuildTypes.unshift({
    value: TYPE_ALL.TYPE_ALL,
    label: t('pages.media.rebuild.filter.rebuildTypes.all')
  })

  const handlerNameChange = (e) => {
    if (!isEmojiCharacter(e.target.value)) {
      setForm({ ...form, name: e.target.value })
    }
  }

  return (
    <Row gutter={16} wrap={false}>
      <Col span={colSpan.name}>
        <Input
          placeholder={t('pages.media.rebuild.name.label')}
          value={form.name}
          onChange={handlerNameChange}
        />
      </Col>
      <Col span={colSpan.jobType}>
        <AgSelect
          style={{ width: '100%' }}
          value={form.jobType}
          onChange={(value) => setForm({ ...form, jobType: value })}
        >
          {rebuildTypes.map((item) => (
            <Option
              title={''}
              value={item.value}
              key={item.value}
            >
              {item.label}
            </Option>
          )
          )}
        </AgSelect>
      </Col>
      <Col span={colSpan.department}>
        <DepartmentTreeSelect
          insertDefault={true}
          treeDefaultExpandedKeys={['']}
          value={form.departmentUuids}
          onSelect={onSelectDepartment}
        />
      </Col>
      <Col span={colSpan.source}>
        <AgSelect
          style={{ width: '100%' }}
          value={form.source}
          onChange={(value) => setForm({ ...form, source: value })}
        >
          {rebuildSourceOptions().map((item) => (
            <Option
              title={''}
              value={item.value}
              key={item.value}
            >
              {item.label}
            </Option>
          )
          )}
        </AgSelect>
      </Col>
      <Col span={colSpan.status}>
        <AgSelect
          style={{ width: '100%' }}
          value={form.status}
          onChange={(value) => setForm({ ...form, status: value })}
        >
          {REBUILD_STATUS_NAME_LIST.map((item) => (
            <Option title={''} value={item.key} key={item.key}>{item.value}</Option>
          )
          )}
        </AgSelect>
      </Col>
      <Col span={colSpan.rangePicker}>
        <RangePicker value={[form.startTime, form.endTime]} onChange={changeTime}/>
      </Col>
      <Col span={colSpan.operate} className={style['operate-button']}>
        <Button type='text' onClick={resetFormValue}>{t('pages.media.filter.operation.reset')}</Button>
        <Button onClick={searchHandler}>{t('pages.media.filter.operation.search')}</Button>
      </Col>
    </Row>
  )
}
export default FilterPanel
