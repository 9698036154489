/**
 * @file 设计图loading
 * @author xinqiang.wei 2022/8/12
 */

import loading from '@/assets/images/loading.png'
import { FC } from 'react'
import './index.less'
import cn from 'classnames'

interface AgLoadingProps {
  size?: 'small'|'medium'|'huge';
}

const AgLoading: FC<AgLoadingProps> = (props) => {
  const { size = 'huge' } = props
  return (
    <div className={cn('ag-loading-icon', size)}>
      <img className='ag-loading-img' src={loading} alt=''/>
    </div>
  )
}

export default AgLoading
