/**
 * @file gtag hooks
 * @author Yangholmes 2023-06-19
 */

import { Modules } from './modules'
import { useRef } from 'react'
import { gtagByModule } from '.'
import { useMount } from 'ahooks'

/**
 * 基于 事件-模块-组件 模型的 gtag 上报 胡可
 * @param module 模块名
 * @param contentView 挂载时立即上报模块访问事件，默认不立即上报
 * @param value 附加的组件值
 * @return gtagEvent React.Ref 句柄
 */
const useGtag = (module: Modules, contentView = false, value?: string) => {
  const ref = useRef<ReturnType<typeof gtagByModule>>()

  useMount(() => {
    const _gtagEvent = gtagByModule(module)
    contentView && _gtagEvent?.('ag_content_view', undefined, value || '-')
    ref.current = _gtagEvent
  })

  return ref
}

export default useGtag
