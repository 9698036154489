/**
 * @file gtag 工具
 * @author Yangholmes 2023-06-19
 */

import { Events } from './events'
import { Modules } from './modules'

export const DEFAULT_VALUE = '-'

/**
 * 按模块使用 gtag event 埋点
 * @param module 模块名称
 * @return 返回一个包含 模块 信息的待执行 gtag 函数句柄，
 */
export function gtagByModule(module: Modules) {
  return (event: Events, eventTarget?: string, eventTargetValue?: string | number) => {
    gtag('event', event, {
      module,
      'event_target': eventTarget,
      'event_target_value': eventTargetValue || DEFAULT_VALUE
    })
  }
}

